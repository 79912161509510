import { createContext, useContext } from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import {
  EventStatus,
  LiveShoppingEvent,
  OnMessageCallback,
  SocketMessage,
  OriginOfProducts,
  ScriptSettings,
  ChatMessage,
} from 'types';

export interface EventDetailCtx {
  eventId: string;
  eventStatus: EventStatus;
  loadingStatus: boolean;
  eventData: LiveShoppingEvent;
  endInProgress: boolean;
  loadingEventData: boolean;
  isLive: boolean;
  isOffline: boolean;
  isTransmitting: boolean | null;
  transmissionType: string | undefined;
  changeStatus: () => void;
  setTransmissionType: (type: string) => void;
  eventSocket: {
    isConnected: boolean;
    sendMessage: <T>(message: SocketMessage<T>) => void;
    pinMessage: <T>(message: SocketMessage<T>) => void;
    unPinMessage: <T>(message: SocketMessage<T>) => void;
    onMessage: <T>(
      cb: OnMessageCallback<T>,
      execOnlyIfIsAction?: string,
    ) => void;
  };
  account: string;
  originOfProducts: OriginOfProducts | '';
  settings: Partial<ScriptSettings>;
  setSettings: React.Dispatch<React.SetStateAction<Partial<ScriptSettings>>>;
  setSelectedMessage: (message: ChatMessage | null) => void;
  selectedMessage: ChatMessage | null;
  pinnedMessage: ChatMessage | null;
  setPinnedMessage: (message: ChatMessage | null) => void;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<LiveShoppingEvent, unknown>>;
}

export const EventDetailCtxDefault: EventDetailCtx = {
  eventId: '',
  eventStatus: EventStatus.CREATED,
  loadingStatus: false,
  eventData: {} as LiveShoppingEvent,
  endInProgress: false,
  loadingEventData: false,
  isLive: false,
  isOffline: false,
  isTransmitting: null,
  transmissionType: 'horizontal',
  changeStatus: () => null,
  setTransmissionType: () => null,
  eventSocket: {
    isConnected: false,
    onMessage: () => null,
    sendMessage: () => null,
    pinMessage: () => null,
    unPinMessage: () => null,
  },
  account: '',
  originOfProducts: '',
  settings: {},
  setSettings: () => null,
  setSelectedMessage: () => null,
  selectedMessage: null,
  pinnedMessage: null,
  setPinnedMessage: () => null,
};

export const EventDetailContext = createContext<EventDetailCtx>(
  EventDetailCtxDefault,
);
export const useEventDetail = () => useContext(EventDetailContext);
