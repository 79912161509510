import {
  NizzaGetProductHandlerInput,
  NizzaGetProductListHandlerInput,
  NizzaProductDataSourceConfig,
} from '@nizza/core';
import { createProductDataSource } from '@nizza/utils';

type ResolveProductListProps = Partial<NizzaProductDataSourceConfig> &
  NizzaGetProductListHandlerInput;

type ResolveProductProps = Partial<NizzaProductDataSourceConfig> &
  NizzaGetProductHandlerInput;

export const resolveProduct = async (props: ResolveProductProps) => {
  return createProductDataSource(props).getProduct(props);
};

export const resolveProductList = async (props: ResolveProductListProps) => {
  return createProductDataSource(props).getProductList(props);
};
