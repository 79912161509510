import {
  createAggregateRoot,
  IAggregateRoot,
  NizzaEventStatus,
} from '@nizza/core';
import { MinimalEventProps, RawMinimalEvent } from './types';

export class MinimalNizzaEvent implements IAggregateRoot, MinimalEventProps {
  readonly root = createAggregateRoot<MinimalEventProps, MinimalNizzaEvent>(
    this,
  );

  id!: string;
  account!: string;
  name!: string;
  thumbnail!: string;
  thumbnails!: string[];
  video!: string;
  status!: NizzaEventStatus;
  created!: Date;

  private constructor(props: MinimalEventProps) {
    this.root.setProps(props);
  }

  static toDomain(raw: RawMinimalEvent): MinimalNizzaEvent {
    const {
      eventName,
      createdAt,
      status,
      thumbnailsPath,
      recordVideoPath,
      ...rest
    } = raw;

    const thumbnail =
      !thumbnailsPath || thumbnailsPath.includes('undefined')
        ? 'https://cdn.nizza.com/shared/imgs/thumbnail-default.png'
        : thumbnailsPath;

    const data: MinimalEventProps = {
      ...rest,
      thumbnail,
      video: recordVideoPath ?? null,
      name: eventName,
      created: new Date(Number(createdAt)),
      status: status as NizzaEventStatus,
    };

    return new MinimalNizzaEvent(data);
  }

  static fromPrimitives(data: MinimalEventProps): MinimalNizzaEvent {
    return new MinimalNizzaEvent(data);
  }

  toPrimitives() {
    const { root, created, ...rest } = this;
    return {
      ...rest,
      created: created.toISOString(),
    };
  }
}
