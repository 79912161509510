/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query';
import {
  getEventAnalytics,
  getTimeLineService
} from 'api';
import { useAccount } from 'hooks';
import { FC, ReactNode, memo, useEffect, useState } from 'react';
import { EventStatus } from 'types';
import { useEventDetail } from '..';
import { AnalyticContext, AnalyticCtx } from './analyticContext';

interface Props {
  consumer?: (context: AnalyticCtx) => ReactNode;
  children?: ReactNode;
}

export const AnalyticProvider: FC<Props> = memo(props => {
  const { consumer, children } = props;
  const { eventId, eventStatus } = useEventDetail();
  const { accountInfo, accountInBase64 } = useAccount();
  const [enableQuerys, setEnableQuerys] = useState(true);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    isLoading: loadingAnalytics,
    data: analyticsData,
    refetch: refetchAnalitycs,
  } = useQuery(
    ['eventAnalytics', eventId, accountInfo.account],
    () => getEventAnalytics(eventId, accountInBase64),
    {
      enabled: !!accountInBase64 && enableQuerys,
    },
  );

  const {
    isLoading: loadingTimeLineData,
    data: timeLineData = [],
    refetch: refetchTimeLine,
  } = useQuery(
    ['eventTimeLineData', eventId, timezone],
    () => getTimeLineService.excecute(accountInfo.account, eventId),
    {
      enabled: !!accountInBase64 && enableQuerys,
    },
  );

  const refetchAllAnalitycs = () => {
    refetchAnalitycs();
    refetchTimeLine();
  };

  useEffect(() => {
    const status = eventStatus;

    if (status === EventStatus.LIVE) {
      setEnableQuerys(true);
      setInterval(() => refetchAllAnalitycs(), 5000);
    } else {
      setEnableQuerys(false);
    }
  }, [eventStatus]);

  const context: AnalyticCtx = {
    loadingAnalytics,
    analyticsData,
    loadingTimeLineData,
    timeLineData: timeLineData as any[],
  };

  return (
    <AnalyticContext.Provider value={context}>
      {consumer ? consumer(context) : children}
    </AnalyticContext.Provider>
  );
});
