import React, { useEffect, useState, useContext } from 'react';

import { IvsRealTime } from '../../types/livestreaming';

import { SettingContext, ActionsContext } from '../../context';
import ViewersIcon from '../icons/ViewersIcon';
import styles from './viewers.module.css';

interface ViewersProps {
  isMobile: boolean;
  isFinalized?: boolean;
  ivsRealTime?: IvsRealTime;
}

export const Viewers = ({
  isMobile,
  isFinalized,
  ivsRealTime,
}: ViewersProps) => {
  const {
    setting: { showViewers: initShowViewers },
  } = useContext(ActionsContext);
  const { infoSocket } = useContext(SettingContext);
  const { showCounter: socketShowViewers } = infoSocket || {};

  const [showViewers, setShowViewers] = useState(true);
  const [viewers, setViewers] = useState(0);
  const [transmiting, setTransmiting] = useState(false);

  useEffect(() => {
    if (initShowViewers === undefined) {
      setShowViewers(
        socketShowViewers === undefined ? true : socketShowViewers,
      );
    } else {
      setShowViewers(initShowViewers);
    }
  }, [initShowViewers, socketShowViewers]);

  useEffect(() => {
    if (ivsRealTime && ivsRealTime.status === 'LIVE') {
      setViewers(ivsRealTime.viewerCount);
      setTransmiting(true);
    } else {
      setViewers(0);
      setTransmiting(false);
    }
  }, [ivsRealTime]);

  if (!infoSocket) {
    return null;
  }

  return !isFinalized && showViewers && transmiting ? (
    <div
      className={`${styles.viewersContainer} ${
        isMobile && styles.viewersContainerMobile
      }`}
    >
      <div className={styles.viewerIcon}>
        <ViewersIcon
          size={`${isMobile ? '14.5' : '20'}`}
          viewBox="0 0 400 400"
        />
      </div>
      <div className={styles.viewers}>{viewers}</div>
    </div>
  ) : null;
};
