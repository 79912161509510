import React from 'react';

const ElectronicsIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 470 470"
  >
    <g>
      <path
        d="M414.114,111.384H55.887c-7.107,0-12.89,5.782-12.89,12.89v189.84c0,7.107,5.782,12.89,12.89,12.89h358.227
		c7.107,0,12.89-5.782,12.89-12.89v-189.84C427.003,117.166,421.221,111.384,414.114,111.384z M413.003,313.004H56.997v-187.62
		h356.006V313.004z"
      />
      <path
        d="M453.111,338.25V108.798c0-12.832-10.439-23.271-23.271-23.271H40.16c-12.832,0-23.271,10.439-23.271,23.271V338.25H0
		v21.151c0,13.825,11.248,25.072,25.072,25.072h419.855c13.825,0,25.072-11.248,25.072-25.072V338.25H453.111z M30.889,108.798
		c0-5.112,4.159-9.271,9.271-9.271h389.68c5.112,0,9.271,4.159,9.271,9.271V338.25H30.889V108.798z M456,359.401
		c0,6.105-4.967,11.072-11.072,11.072H25.072c-6.105,0-11.072-4.967-11.072-11.072v-7.151h442V359.401z"
      />
      <rect x="95.556" y="147.559" width="150.752" height="14" />
      <rect x="95.556" y="175.707" width="106.414" height="14" />
      <rect x="95.556" y="203.856" width="210.167" height="14" />
      <rect x="95.556" y="232.004" width="138.338" height="14" />
    </g>
  </svg>
);
export default ElectronicsIcon;
