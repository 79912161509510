/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useObservable, useQuestion } from 'hooks';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { AnswerDTO } from 'types';
import { answerSchema } from '../yupSchema';

interface Props {
  id: string;
  text: string;
  onSave: (id: string, text: string) => void;
}

export const AnswerForm: FC<Props> = props => {
  const { id, text, onSave } = props;
  const { modalSaveBtnClickEvents, modalType, saveStatus } = useQuestion();
  const modalSaveBtnClickEvent = useObservable(modalSaveBtnClickEvents);

  const { formState, register, handleSubmit, watch, reset } =
    useForm<AnswerDTO>({
      resolver: yupResolver(answerSchema),
      mode: 'onBlur',
      defaultValues: {
        ...(text && { text }),
      },
    });
  const { ref: textRef, ...textRest } = register('text');
  const watchText = watch('text');
  const { errors } = formState;

  useEffect(() => {
    if (modalSaveBtnClickEvent) {
      handleSubmit(() => null)();
    }
  }, [modalSaveBtnClickEvent]);

  useEffect(() => {
    if (modalType === 'add' && saveStatus === 'success') {
      reset();
    }
  }, [saveStatus]);

  useEffect(() => {
    onSave(id, watchText);
  }, [watchText]);

  return (
    <FormGroup className="w-100">
      <Input
        type="text"
        invalid={!!errors.text}
        innerRef={textRef}
        {...textRest}
      />
      <FormFeedback>{errors.text?.message}</FormFeedback>
    </FormGroup>
  );
};
