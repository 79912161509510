/* eslint-disable react-hooks/exhaustive-deps */
import { Product, productService } from '@nizza/event-products';
import { useAccount } from 'hooks';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { addGeneratedProductProps, buildPostMessage } from 'shared';
import { useEventDetail } from '../events';
import { ProductListContext, ProductListCtx } from './productListContext';
interface Props {
  products?: Product[];
  disableQuery?: boolean;
  consumer?: (context: ProductListCtx) => ReactNode;
  children?: ReactNode;
}

const enum ProductListProviderEvents {
  GET_PRODUCTS = 'GetProductList',
  SET_PRODUCTS = 'SetProductList',
}

const postMessage = buildPostMessage<ProductListProviderEvents>(window.parent);

export const ProductListProvider: FC<Props> = props => {
  const { children, consumer, products = [], disableQuery = false } = props;
  const { accountInfo } = useAccount();
  const account = accountInfo.account;
  const { eventData } = useEventDetail();
  const [productList, setProductList] = useState<Product[]>([]);
  const productListKey = disableQuery
    ? ['productList', 'disabled']
    : ['productList'];
  useEffect(() => {
    productService.setAccount(account);
  }, [account]);

  const { data = [], isLoading: loadingProducts } = useQuery<Product[]>(
    productListKey,
    () => productService.search(),
    {
      enabled: !eventData.collection?.source && !!account && !disableQuery,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data.length) setProductList(addGeneratedProductProps(data));
  }, [data]);

  useEffect(() => {
    if (products.length) setProductList(addGeneratedProductProps(products));
  }, [products]);

  useEffect(() => {
    const handleMessages = (event: MessageEvent<any>) => {
      const data = event.data;
      if (
        !data?.source?.includes('Nizza') ||
        data?.target !== 'ProductListProvider' ||
        disableQuery
      )
        return;

      if (
        data?.name === ProductListProviderEvents.SET_PRODUCTS &&
        data?.payload?.length
      ) {
        setProductList(data?.payload);
      }
    };

    window.addEventListener('message', handleMessages, false);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, []);

  useEffect(() => {
    if (eventData.collection?.id) {
      postMessage(
        ProductListProviderEvents.GET_PRODUCTS,
        eventData.collection.id,
      );
    }
  }, [eventData.collection?.id]);

  const context: ProductListCtx = {
    productList,
    loadingProducts: loadingProducts && !disableQuery,
    productService,
    setProductList,
  };

  return (
    <ProductListContext.Provider value={context}>
      {consumer ? consumer(context) : children}
    </ProductListContext.Provider>
  );
};
