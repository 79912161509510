import BabyIcon from 'app/components/icons/BabyIcon';
import ElectronicsIcon from 'app/components/icons/Electronics';
import BeautyIcon from 'app/components/icons/FashionBeauty';
import FoodIcon from 'app/components/icons/Food';
import GeneralIcon from 'app/components/icons/General';
import HomeIcon from 'app/components/icons/Home';
import PetsIcon from 'app/components/icons/PetsIcon';

const IconSelector = (key: string) => {
  switch (key) {
    case 'Pets':
      return <PetsIcon />;
    case 'Electronics':
      return <ElectronicsIcon />;
    case 'Fashion and Beauty':
      return <BeautyIcon />;
    case 'General':
      return <GeneralIcon />;
    case 'Home':
      return <HomeIcon />;
    case 'Food':
      return <FoodIcon />;
    case 'Baby':
      return <BabyIcon />;
    default:
      return <GeneralIcon />;
  }
};

export default IconSelector;
