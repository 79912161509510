interface Props {
  size?: number;
  fill?: string;
  stroke?: string;
}

export const PlayIcon = ({
  size = 58,
  fill = 'rgba(50,56,69,0.32)',
  stroke = '#f8f7fc',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 69 69"
    >
      <g fill={fill} stroke={stroke} strokeWidth="1">
        <circle cx="34.5" cy="34.5" r="34.5" stroke="none" />
        <circle cx="34.5" cy="34.5" r="34" fill="none" />
      </g>
      <path
        d="M-955.779,753.879l-23.674-12.734a3.733,3.733,0,0,0-3.769.114,3.733,3.733,0,0,0-1.787,3.321l.809,26.87a3.732,3.732,0,0,0,1.983,3.208,3.767,3.767,0,0,0,1.789.456,3.756,3.756,0,0,0,1.98-.57l22.865-14.135a3.733,3.733,0,0,0,1.786-3.321A3.733,3.733,0,0,0-955.779,753.879Zm-24.8,17.735a.3.3,0,0,1-.166-.268l-.809-26.87a.3.3,0,0,1,.15-.278.3.3,0,0,1,.315-.01l23.674,12.734a.3.3,0,0,1,.166.268.3.3,0,0,1-.15.278L-980.265,771.6A.3.3,0,0,1-980.581,771.613Z"
        transform="translate(1006.095 -724.042)"
        fill="#fff"
      />
    </svg>
  );
};
