import { useEventDetail } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

interface Props {
  type: string;
  image: string;
  selected: boolean;
}

export const TransmissionButton: FC<Props> = props => {
  const { t } = useTranslation();
  const { setTransmissionType } = useEventDetail();
  const { type, image, selected } = props;
  const title = t(`LiveShopping.TransmisionType.${type}.Title`);
  const text = t(`LiveShopping.TransmisionType.${type}.Text`);

  return (
    <Wrapper
      className="shadow p-0 rounded position-relative "
      selected={selected}
      onClick={() => setTransmissionType(type)}
    >
      <i
        className="bx bx-check-circle position-absolute"
        style={{
          display: selected ? 'block' : 'none',
          top: -12,
          right: -14,
          fontSize: 25,
          color: '#fff',
          paddingTop: 1,
          paddingRight: 0.2,
          background: 'var(--bs-primary)',
          borderRadius: '50%',
        }}
      />
      <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
        <img
          className="mw-100"
          width={361}
          height={216}
          src={image}
          alt="placeholder"
        />
        <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
          <h4 className="font-weight-normal">{title}</h4>
          <p className="font-size-16 m-0 font-weight-light">{text}</p>
        </div>
      </div>
    </Wrapper>
  );
};

type WrapperProps = {
  selected: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  background-color: var(--bs-white);
  transition: all ease 0.25s;

  ${props =>
    props.selected
      ? css`
          cursor: default;
          border: solid 3px var(--bs-primary);
          width: 361px;
          height: 361px;
        `
      : css`
          cursor: pointer;
          border: solid 3px var(--bs-gray-400);

          width: 361px;
          height: 361px;
        `};
`;
