import { Product, ProductService } from '@nizza/event-products';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export interface ProductListCtx {
  productList: Product[];
  loadingProducts: boolean;
  setProductList: Dispatch<SetStateAction<Product[]>>;
  productService: ProductService;
}

export const productListCtxDefault: ProductListCtx = {
  productList: [],
  loadingProducts: false,
  setProductList: () => null,
  productService: undefined as any,
};

export const ProductListContext = createContext<ProductListCtx>(
  productListCtxDefault,
);
export const useProductList = () => useContext(ProductListContext);
