/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useObservable, useQuestion } from 'hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Form as BootstrapForm,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { QuestionDTO, QuestionTypes } from 'types';
import { useDebounce } from 'use-debounce';
import { AnswerList } from './Answer';
import { mainSchema } from './yupSchema';

const defaultFormData = {
  question: '',
  time: 20,
  type: QuestionTypes.QUIZ,
  answers: [],
};

export const Form = () => {
  const { t } = useTranslation();
  const {
    modalType,
    modalSaveBtnClickEvents,
    selectedQuestion,
    saveStatus,
    saveQuestion,
    openModalFor,
  } = useQuestion();
  const modalSaveBtnClickEvent = useObservable(modalSaveBtnClickEvents);

  const { formState, register, handleSubmit, setValue, watch, reset } =
    useForm<QuestionDTO>({
      resolver: yupResolver(mainSchema),
      mode: 'onBlur',
      defaultValues: {
        ...defaultFormData,
        ...(modalType === 'edit' && selectedQuestion),
      },
    });

  register('answers');
  const { ref: questionRef, ...questionRest } = register('question');
  const { ref: typeRef, ...typeRest } = register('type');
  const { ref: timeRef, ...timeRest } = register('time');
  const { errors } = formState;
  const [watchType] = useDebounce(watch('type'), 500);

  useEffect(() => {
    if (modalSaveBtnClickEvent) {
      handleSubmit(onSubmit)();
    }
  }, [modalSaveBtnClickEvent]);

  useEffect(() => {
    if (saveStatus === 'success') {
      modalType === 'add' && reset(defaultFormData);
      openModalFor(null);
    }
  }, [saveStatus]);

  const onSubmit = (data: QuestionDTO) => {
    if (modalType === 'add') {
      saveQuestion(data);
    } else {
      saveQuestion(data, selectedQuestion?.index);
    }
  };

  return (
    <BootstrapForm>
      <FormGroup className="mb-3">
        <Label htmlFor="question">
          {t('LiveShopping.Questions.Form.QuestionLabel')}
        </Label>
        <Input
          id="question"
          type="text"
          invalid={!!errors.question}
          innerRef={questionRef}
          {...questionRest}
        />
        <FormFeedback>{errors.question?.message}</FormFeedback>
      </FormGroup>

      <Row>
        <Col>
          <FormGroup className="mb-3">
            <Label htmlFor="time">
              {t('LiveShopping.Questions.Form.TimeLabel')}
            </Label>
            <Input
              id="time"
              type="number"
              step={10}
              min={20}
              invalid={!!errors.time}
              innerRef={timeRef}
              {...timeRest}
            />
            <FormFeedback>{errors.time?.message}</FormFeedback>
          </FormGroup>
        </Col>

        <Col>
          <FormGroup className="mb-3">
            <Label htmlFor="type">
              {t('LiveShopping.Questions.Form.TypeLabel')}
            </Label>
            <Input
              id="type"
              type="select"
              invalid={!!errors.type}
              innerRef={typeRef}
              {...typeRest}
            >
              {Object.values(QuestionTypes).map(x => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </Input>
            <FormFeedback>{errors.type?.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <Label className="mt-3">
            {t('LiveShopping.Questions.Detail.TableTitle')}
          </Label>

          <AnswerList onChange={e => setValue('answers', e)} type={watchType} />
        </Col>
      </Row>
    </BootstrapForm>
  );
};
