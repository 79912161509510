import { useEffect } from 'react';
import { useLivestreamingContext } from '../context';
import { PlayerProduct } from '../types';

export const useShowHighlightsForFinishedEvents = (
  handleSetProduct: (
    productsId: string[],
    storageProducts: PlayerProduct[],
  ) => void,
  isFinalized: boolean,
  products?: PlayerProduct[],
) => {
  const { currentHightLightProductId } = useLivestreamingContext();

  useEffect(() => {
    if (!isFinalized) return;

    if (!products) return;

    handleSetProduct(currentHightLightProductId, products);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHightLightProductId]);
};
