import { AppModuleStoreList, StoreProvider } from '@nizza/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider, ToastProvider } from '@vtex/admin-ui';
import { Provider as JotaiProvider, createStore } from 'jotai';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import { FC, ReactElement, ReactNode } from 'react';

interface CommonProvidersProps {
  children: ReactNode;
  atomStore?: ReturnType<typeof createStore>;
  queryClient?: QueryClient;
  stores?: AppModuleStoreList;
  enableJotaiDevTools?: boolean;
  enableVtexAdminUI?: boolean;
}

const MobXWrapper: FC<{ stores?: AppModuleStoreList, children: ReactNode }> = ({ stores, children }) => {
  return stores ? <StoreProvider stores={stores}>{children}</StoreProvider> : children;
};
MobXWrapper.displayName = 'MobXProvider';

const JotaiWrapper: FC<{ atomStore?: ReturnType<typeof createStore>, children: ReactNode }> = ({ atomStore, children }) => {
  return atomStore ? <JotaiProvider store={atomStore}>{children}</JotaiProvider> : <JotaiProvider>{children}</JotaiProvider>;
};
JotaiWrapper.displayName = 'JotaiProvider';

const ReactQueryWrapper: FC<{ client: QueryClient, children: ReactNode }> = ({ client, children }) => {
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
ReactQueryWrapper.displayName = 'ReactQueryProvider';

const AdminUIWrapper: FC<{ enableVtexAdminUI: boolean, children: ReactNode }> = ({ enableVtexAdminUI, children }) => {
  return enableVtexAdminUI ? (
    <ThemeProvider>
      <ToastProvider>{children}</ToastProvider>
    </ThemeProvider>
  ) : children;
};
AdminUIWrapper.displayName = 'AdminUIProvider';

export const CommonProviders: FC<CommonProvidersProps> = ({
  children,
  atomStore,
  stores,
  queryClient = new QueryClient(),
  enableJotaiDevTools = true,
  enableVtexAdminUI = true,
}) => {

  return (
    <ReactQueryWrapper client={queryClient}>
      <MobXWrapper stores={stores}>
        <JotaiWrapper atomStore={atomStore}>
          <AdminUIWrapper enableVtexAdminUI={enableVtexAdminUI}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
            {enableJotaiDevTools && <JotaiDevTools theme="dark" />}
          </AdminUIWrapper>
        </JotaiWrapper>
      </MobXWrapper>
    </ReactQueryWrapper>
  ) as ReactElement;
};

CommonProviders.displayName = 'CommonProviders';
export default CommonProviders;
