import { ApiGraphqlTypes as AGT } from '@nizza/core';
import { GraphQLClient } from 'graphql-request';
import env from '~env';
import { PinnedMessageRepository, PinnedMessage } from '../domain';

const API_URL = env.apiUrls.graphql;

export class GraphqlPinnedMessageRepository implements PinnedMessageRepository {
  private client: GraphQLClient;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async pin(input: PinnedMessage): Promise<void> {
    const { pinChatMessage } = await this.client.request(
      AGT.PinChatMessageDocument,
      {
        data: {
          account: input.account,
          data: input.data,
          idEvent: input.idEvent,
          pin: input.pin,
          sessionId: input.sessionId,
          username: input.username,
        },
      },
    );

    this.propagateGraphqlErrorIfExists(
      pinChatMessage as AGT.PinChatMessageResult,
    );
  }

  private propagateGraphqlErrorIfExists(res: AGT.PinChatMessageResult) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
