import { CustomStorage } from '@nizza/utils';
import './lib';

export * from './lib';
export * from './lib/Livestreaming';
export * from './lib/modules.style';
export * from './lib/types';
export * from './lib/utils/constants';

CustomStorage.init();
