import { createContext, useContext } from 'react';
import { ConfigPlan, DetailFeatures, AppFeatures } from 'types';

export interface PlanCtx {
  planInfo: ConfigPlan;
  loadingPlanInfo: boolean;
  errorPlanInfo: boolean;
}

export const PlanCtxDefault: PlanCtx = {
  planInfo: {
    plan: '',
    editable: false,
    basic: false,
    label: '',
    feature: {} as Record<AppFeatures, DetailFeatures>,
  },
  loadingPlanInfo: false,
  errorPlanInfo: false,
};

export const PlanContext = createContext<PlanCtx>(PlanCtxDefault);
export const usePlan = () => useContext(PlanContext);
