import { ChatMessage } from '@nizza/core';
import {
  Button,
  IconArrowUpRight,
  IconTrash
} from '@vtex/admin-ui';
import { ChatBlockIcon } from 'assets/icons';
import { FC } from 'react';

interface Props {
  message: ChatMessage;
  onRespondMessage: (message: ChatMessage) => void;
  onDeleteMessage: (message: ChatMessage) => void;
  onBlockUser: (message: ChatMessage) => void;
}

export const ChatUserButtons: FC<Props> = ({
  message,
  onRespondMessage,
  onDeleteMessage,
  onBlockUser,
}) => (
  <>
    <Button
      className="flex items-center justify-center"
      icon={<IconArrowUpRight />}
      variant="neutralTertiary"
      onClick={() => onRespondMessage(message)}
    ></Button>
    <Button
      className="flex items-center justify-center"
      icon={<ChatBlockIcon onClick={() => onBlockUser(message)} />}
      variant="neutralTertiary"
    ></Button>
    <Button
      className="flex items-center justify-center"
      icon={<IconTrash />}
      variant="neutralTertiary"
      onClick={() => onDeleteMessage(message)}
    ></Button>
  </>
);
