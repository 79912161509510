import { createContext, useContext } from 'react';

export type BWModalType = 'add' | 'edit' | null;
export type Word = { index: number; value: string };

export interface BlockedWordListCtx {
  wordList: string[];
  defaultWordList: string[];
  loadingWords: boolean;
  loadingDefaultWords: boolean;
  modalType: BWModalType;
  modalIsOpen: boolean;
  selectedWord: Word | null;
  setSelectedWord: (word: Word | null) => void;
  fetchWordList: () => void;
  openModal: (value: boolean) => void;
  setModalType: (type: BWModalType) => void;
  accountInBase64: string;
}

export const blockedWordListCtxDefault: BlockedWordListCtx = {
  wordList: [],
  defaultWordList: [],
  loadingWords: false,
  loadingDefaultWords: false,
  modalType: null,
  modalIsOpen: false,
  selectedWord: null,
  fetchWordList: () => null,
  openModal: () => null,
  setModalType: () => null,
  setSelectedWord: () => null,
  accountInBase64: '',
};

export const BlockedWordListContext = createContext<BlockedWordListCtx>(
  blockedWordListCtxDefault,
);
export const useBlockedWordList = () => useContext(BlockedWordListContext);
