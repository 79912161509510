import { FC, memo } from 'react';
import { invertColor, randomColor as getRandomColor } from 'shared';
import styled from 'styled-components';
import clsx from 'clsx';

interface Props {
  text?: string;
  img?: string;
  size?: string;
  color?: string;
  background?: string;
  className?: string;
  numInitials?: number;
}

export const Avatar: FC<Props> = memo(props => {
  const randomBackground = getRandomColor();
  const randomColor = invertColor(randomBackground);
  const {
    text,
    img,
    size = '35px',
    background = randomBackground,
    color = randomColor,
    numInitials = 1,
    className,
  } = props;
  const formattedText = (text ? text.replaceAll(' ', '+') : '?')
    .toUpperCase()
    .substr(0, numInitials);
  const wrapperProps = {
    size,
    background,
    color,
  };

  return img ? (
    <img
      src={img}
      className={clsx('avatar-xs rounded-circle', className)}
      alt="avatar"
    />
  ) : text ? (
    <Wrapper className={className} {...wrapperProps}>
      {formattedText}
    </Wrapper>
  ) : (
    <div />
  );
});

const Wrapper = styled.div<any>`
  width: ${props => props.size};
  height: ${props => props.size};
  background-color: ${props => `#${props.background}`};
  color: ${props => `#${props.color}`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
