import React from 'react';
const FoodIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 470 470"
  >
    <g>
      <path
        d="M462.5,270.58c-4.142,0-7.5,3.358-7.5,7.5v22.5H15v-22.5c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v183.137
		c0,4.142,3.358,7.5,7.5,7.5h455c4.142,0,7.5-3.358,7.5-7.5V278.08C470,273.938,466.642,270.58,462.5,270.58z M455,330.58H67.5
		c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5H455v108.137H15V345.58h22.5c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H15v-15
		h440V330.58z"
      />
      <path
        d="M189.28,283.891c1.393,1.137,3.07,1.69,4.738,1.69c2.175,0,4.332-0.941,5.814-2.757l83.154-101.865
		c2.619-3.208,2.142-7.933-1.067-10.553c-3.208-2.619-7.933-2.142-10.553,1.067l-83.154,101.865
		C185.594,276.546,186.071,281.271,189.28,283.891z"
      />
      <path
        d="M226.213,273.338c-2.619,3.208-2.142,7.933,1.067,10.553c1.393,1.137,3.07,1.69,4.738,1.69
		c2.175,0,4.332-0.941,5.814-2.757c0,0,81.987-100.437,83.139-101.847c0.004-0.006,0.009-0.011,0.014-0.017
		c0.462-0.566,0.958-1.114,1.472-1.629c8.355-8.354,21.95-8.354,30.305,0l20.679,20.679c8.355,8.355,8.355,21.95,0,30.306
		c-0.515,0.515-1.063,1.01-1.627,1.471l-49.594,40.484c-3.209,2.62-3.687,7.344-1.067,10.553c1.483,1.816,3.64,2.757,5.814,2.757
		c1.667,0,3.346-0.553,4.738-1.69l49.595-40.485c0.954-0.779,1.878-1.615,2.747-2.484c12.334-12.335,13.951-31.384,4.863-45.486
		c20.311-18.05,46.189-27.922,73.589-27.922c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5c-30.352,0-59.063,10.668-81.858,30.223
		l63.241-63.24c2.929-2.929,2.929-7.677,0-10.606c-2.929-2.929-7.678-2.929-10.606,0l-63.236,63.236
		c19.553-22.795,30.219-51.503,30.219-81.853c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5c0,27.399-9.872,53.278-27.922,73.589
		c-14.102-9.089-33.152-7.472-45.486,4.862c-0.869,0.869-1.705,1.793-2.484,2.747l0.019,0.015c-0.335,0.374-0.648,0.757-0.953,1.13
		L226.213,273.338z"
      />
      <path
        d="M37.477,285.58c4.142,0,7.5-3.358,7.5-7.5V96.334h106.47V278.08c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V91.235
		l28.212-39.54l21.148,29.638H195.8c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h27.071v89.779c0,4.142,3.358,7.5,7.5,7.5
		s7.5-3.358,7.5-7.5V88.834c0-1.562-0.488-3.085-1.395-4.356l-34.317-48.096V8.783c0-4.142-3.358-7.5-7.5-7.5H73.189
		c-4.142,0-7.5,3.358-7.5,7.5v27.598L31.371,84.478c-0.907,1.271-1.395,2.794-1.395,4.356V278.08
		C29.977,282.222,33.334,285.58,37.477,285.58z M80.689,16.283h106.47v15H80.689V16.283z M77.051,46.283h103.043l-25.01,35.051
		H52.041L77.051,46.283z"
      />
      <path
        d="M432.5,285.581c4.142,0,7.5-3.358,7.5-7.5v-12.462c0-15.596-9.857-29.732-24.528-35.176c-3.885-1.44-8.2,0.539-9.641,4.422
		c-1.441,3.884,0.539,8.2,4.422,9.641c8.82,3.273,14.747,11.757,14.747,21.113v12.462C425,282.223,428.358,285.581,432.5,285.581z"
      />
    </g>
  </svg>
);
export default FoodIcon;
