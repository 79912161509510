import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

interface Props {
  cardTitle?: string;
  cardText?: string;
  supportEmail?: string;
  supportButtonTitle?: string;
  pageTitle?: string;
  full?: boolean;
}

export const PermissionsDenied: FC<Props> = memo(props => {
  const { t } = useTranslation();

  const {
    cardTitle = t('General.PermissionsDeniedForModule.Title'),
    cardText = t('General.PermissionsDeniedForModule.Description'),
    supportEmail = 'manuel.garcia@vtex.com.br',
    supportButtonTitle = t(
      'General.PermissionsDeniedForModule.ContactButtonTitle',
    ),
    pageTitle = 'PermissionsDenied',
    full = false,
  } = props;

  const content = (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="no-gutters align-items-center p-3">
                  <Col xs={7}>
                    <CardTitle>{cardTitle}</CardTitle>
                    <CardText>{cardText}</CardText>
                  </Col>
                  <Col xs={5}>
                    <a
                      className="btn btn-outline-primary"
                      href={`mailto:${supportEmail}`}
                    >
                      {supportButtonTitle}
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );

  return full ? <div className="page-content">{content}</div> : content;
});

export default PermissionsDenied;
