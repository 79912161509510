import { NizzaTimeZone } from '@nizza/core';
import { ChatMessage } from './chat';
import { Question } from './questions';

export enum EventStatus {
  CREATED = 'CREATED',
  STARTING = 'STARTING',
  LIVE = 'LIVE',
  FINALIZED = 'FINALIZED',
}

export enum JobStatus {
  COMPLETE = 'COMPLETE',
  PROGRESSING = 'PROGRESSING',
  SUBMITTED = 'SUBMITTED',
}

export enum StreamStatus {
  LIVE = 'LIVE',
  OFFLINE = 'OFFLINE',
  UNHEALTHY = 'UNHEALTHY',
  HEALTHY = 'HEALTHY',
}

export enum IVSSocketStatus {
  LIVE = 'LIVE',
  UNKNOWN = 'UNKNOWN',
}

export enum EventSocketActions {
  //IVS_STATUS = 'sendivsdatarealtime',
  SET_CLIENT_SCRIPT_SETTINGS = 'sendPropertiesToCms',
  SET_TRANSMISSION_TYPE = 'sendtransmitiontype',
  SET_LIVE_CONFIG = 'sendshowCounter',
}

export type TransmissionType = 'horizontal' | 'vertical';

export interface IVSSocketMessage {
  livestreamingId: string;
  startTime: string;
  status: IVSSocketStatus;
  viewerCount: number;
}

export interface LiveShoppingEvent {
  id: string;
  eventName: string;
  utm: string;
  status: EventStatus;
  createdAt: number;
  collection: EventCollection;
  streamData: StreamData;
  info: EventInfo;
  config: EventConfig;
  workspace: string;
  mediaConvert?: MediaConvertData;
  timeZone?: NizzaTimeZone;
  eventCategory?: string;
  eventDateTime?: string;
}

export interface EventCategory {
  name: string;
  description: string;
}

export type CreateEventDTO = {
  eventName: string;
  utm: string;
  collection?: Partial<Omit<EventCollection, 'products'>>;
  eventDateTime?: string | null;
  eventLink?: string;
  timeZone: NizzaTimeZone;
  eventCategory: string;
  account?: string | null;
  eventId?: string;
};

export type UpdateEventDTO = Partial<CreateEventDTO>;

export type GetEventCategoryListArgs = {
  onlyUsed?: number;
  onlyUsedInGP?: number;
};

export interface AnalyticSummary {
  viewers: number;
  uniqueViewers: number;
  likes: number;
  uniqueLikes: number;
  comments: 0;
  uniqueViewersInMobile: number;
  uniqueViewersInDesktop: number;
}

export interface TimeLineData {
  hour: number;
  minute: number;
  time: string;
  likes: number;
  comments: number;
  viewers: number;
  orders: number;
}

export type RequestRecordingEventDTO = {
  id: string;
  admin: boolean;
  email: string;
  account: string;
  name: string;
  sendByEmail: boolean;
};

interface EventCollection {
  id: string;
  products: any[];
  source?: string;
}

interface StreamData {
  lastQuery: string;
  startTime: string;
  state: StreamStatus;
  health: string;
  viewerCount: number;
  spendedMinutes: number;
  metricToRegister: number;
  firstStartTime: number;
}

interface EventInfo {
  uniqueViewersInDesktop: number;
  uniqueViewersInMobile: number;
  likesCount: number;
  viewerCount: number;
  chat: ChatMessage[];
  questions: Question[];
  likes: EventLike[];
  recordings: any[];
  viewers: any[];
}

interface EventLike {
  sessionId: string;
}

interface EventConfig {
  stackName: string;
  ARNs: EventARNs;
  streamClient: EventStreamClient;
  webClient: EventWebClient;
  bundle: EventBundle;
}

interface EventARNs {
  ivs: string;
  stack: string;
}

interface EventStreamClient {
  server: string;
  serverKey: string;
}

interface EventWebClient {
  showCounter: boolean;
  streamURL: string;
  streamWSS: string;
  showProductsSidebar: boolean | undefined;
  showChat: boolean | undefined;
  showLike: boolean | undefined;
  showGif: boolean | undefined;
  infiniteScroll: boolean | undefined;
  showProductsCarousel: boolean | undefined;
  redirectToPdp: boolean | undefined;
  scrollTime: number | undefined;
  emailIsRequired: boolean | undefined;
  cmsCompatibility: boolean;
  showTax: boolean;
  transmitionType: string | undefined;
}

interface EventBundle {
  bannerOne: null;
  bannerSecond: null;
}

interface MediaConvertData {
  downloadRecordPath: string;
  jobId: string;
  jobStatus: string;
  jobPercentComplete: number;
}
