import { LOCALES } from '../locales';

export default {
  [LOCALES.en]: {
    'store/button.close': 'Close',
    'store/button.expecting': 'Expecting...',
    'store/button.send': 'Send',
    'store/inputs.required-placeholder': 'Required',
    'store/live.chat': 'Live chat',
    'store/live.chat-login': 'Enter your name to continue',
    'store/live.chat-login-btn': 'Continue with the chat',
    'store/live.chat-login-email-no-required-placeholder': 'Email',
    'store/live.chat-login-email-required-placeholder': 'Email*',
    'store/live.chat-login-invalid-email': 'Invalid email',
    'store/live.chat-login-invalid-username': 'Invalid username',
    'store/live.chat-login-name-placeholder': 'Name*',
    'store/live.chat-login-username-already-exist':
      'This username already exists',
    'store/live.chat-votes': 'votes',
    'store/seconds': 'Seconds',
    'store/text.add': 'Add to cart',
    'store/text.anonymous-user': 'Anonymous',
    'store/text.before': 'Before',
    'store/text.chat-placeholder': 'Write a comment',
    'store/text.email': 'Email',
    'store/text.email-invalid': 'Invalid email',
    'store/text.false': 'False',
    'store/text.likes': 'Likes',
    'store/text.live-no-video': 'Live broadcasting.',
    'store/text.live-products': 'Products',
    'store/text.name': 'Name',
    'store/text.no-video': 'Waiting for connection with the stream.',
    'store/text.not-stock': 'Out of stock',
    'store/text.now': 'Now',
    'store/text.products': 'Featured Products',
    'store/text.select-answer': 'You must select an answer.',
    'store/text.true': 'True',
    'store/text.unread-messages': 'Unread messages',
    'store/time': 'Time',
    'store/total-votes': 'Total votes',
    'store/text.add-to-cart': 'Product added to cart.',
    'store/text.add-to-cart-error': 'Error adding to cart.',
    'store/text.share': 'To share',
    'store/text.details': 'See Details',
    'chat.message-max-length': 'Messages can be a maximum of 180 characters',
    'chat.show-chat-horizontal-layout-message': 'Show chat replay',
    'chat.hide-chat-horizontal-layout-message': 'Hide chat replay',
    'chat.mobile-record-playback-indicator-message': 'Record',
    'chat.chat-replay-mobile-vertical-layout-message': 'Chat replay',
    'chat-carousel.product-unavailable-message': 'Unavailable',

    'customize/general.back': 'Back',
    'customize/general.background': 'Background',
    'customize/general.price': 'Price',
    'customize/general.text': 'Text',
    'customize/general.message': 'Message',
    'customize/general.section-title': 'Section title',
    'customize/general.section-name': 'Section name',
    'customize/general.icon': 'Icon',
    'customize/general.desktop': 'Desktop',
    'customize/general.mobile': 'Mobile',
    'customize/product-button.add-to-cart-button': 'Add to cart button',
    'customize/product-button.text-inside-button': 'Text inside the button',
    'customize/chat-carousel-product-item.product-carousel':
      'Products on mobile',
    'customize/highlight-product.product-highlight': 'Product highlight',
    'customize/highlight-product.show': 'Show',
    'customize/highlight-product.show-button': 'Show button',
    'customize/highlight-product.show-price': 'Show price',
    'customize/highlight-product.opacity': 'Opacity',
    'customize/product-item.product-cards': 'Products on desktop',
    'customize/product-item.highlight-promotion': 'Highlight promotion',
    'customize/vertical-product-slider.section-title': 'Products section title',
    'customize/message-container-admin.admin-message': 'Admin message',
    'customize/message-container-admin.background-layout-horizontal':
      'Background layout horizontal',
    'customize/message-container-admin.message-layout-horizontal':
      'Message layout horizontal',
    'customize/message-container-admin.background-layout-vertical':
      'Background layout vertical',
    'customize/message-container-admin.message-layout-vertical':
      'Message layout vertical',
    'customize/chat.chat-section-title': 'Chat section title',
    'customize/chat.comment-box-text': 'Comment box text',
    'customize/chat.comment-box-title': 'Comment box',
    'customize/chat.send-message-button.desktop.title': 'Send button desktop',
    'customize/modal-trigger.select-product-variations.title':
      'Selection of product variations',
    'customize/modal-trigger.round-or-square-selector':
      'Style showing variations',
    'customize/modal-trigger.round-selector': 'Round selector',
    'customize/modal-trigger.square-selector': 'Square selector',
  },
};
