import { ApiV1Response, customFetch, searchParams } from '@nizza/core';
import { API_REST_URL_V1 } from 'shared';
import {
  CreateEventDTO,
  DataEventBody,
  EventCategory,
  GetEventCategoryListArgs,
  LiveShoppingEvent,
  RequestRecordingEventDTO,
  UpdateEventDTO,
} from 'types';

export const newEvent = async (event: CreateEventDTO) => {
  const resource = '/liveshopping/events';
  const url = API_REST_URL_V1 + resource;

  return await customFetch<ApiV1Response>({
    url,
    method: 'POST',
    body: JSON.stringify(event),
  });
};

export const editEvent = async (event: UpdateEventDTO) => {
  const url = `${API_REST_URL_V1}/liveshopping/events/${event.eventId}`;
  delete event.eventId;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'PUT',
    body: JSON.stringify(event),
  });

  return Boolean(success);
};

export const getEventList = async (account: string | undefined) => {
  const params = searchParams({ account: account ?? '' });
  const resource = `/liveshopping/events${params}`;
  const url = API_REST_URL_V1 + resource;

  const { data } = await customFetch<ApiV1Response<LiveShoppingEvent[]>>({
    url,
  });
  return data;
};

export const getEventCategoryList = async (args: GetEventCategoryListArgs) => {
  const params = searchParams(args);
  const resource = `/liveshopping/events/categories${params}`;
  const url = API_REST_URL_V1 + resource;

  const { data } = await customFetch<ApiV1Response<EventCategory[]>>({
    url,
  });
  return data;
};

export const getEvent = async (eventId: string, account?: string | null) => {
  const params = searchParams({
    admin: true,
    ...(account && { account }),
  });
  const resource = `/liveshopping/events/${eventId}${params}`;
  const url = API_REST_URL_V1 + resource;

  const { data } = await customFetch<ApiV1Response<LiveShoppingEvent>>({ url });
  return data;
};

export const getEventAnalytics = async (
  eventId: string,
  account?: string | null,
) => {
  const params = searchParams({
    admin: true,
    ...(account && { account }),
  });
  const resource = `/liveshopping/events/${eventId}/analytics${params}`;
  const url = API_REST_URL_V1 + resource;

  const { data } = await customFetch<ApiV1Response>({ url, method: 'GET' });
  return data;
};

export const getEventTimeLineData = async (
  eventId: string,
  account: string,
  timeZone?: string | null,
) => {
  const params = searchParams({
    admin: true,
    ...(account && { account }),
    ...(timeZone && { timeZone }),
  });
  const resource = `/liveshopping/events/${eventId}/timelinedata${params}`;
  const url = API_REST_URL_V1 + resource;

  const { data } = await customFetch<ApiV1Response>({ url, method: 'GET' });
  return data;
};

export const getEventStatus = async (
  eventId: string,
  account?: string | null,
) => {
  const params = searchParams({
    ...(account && { account }),
  });
  const resource = `/liveshopping/events/${eventId}/status${params}`;
  const url = API_REST_URL_V1 + resource;

  return await customFetch<ApiV1Response>({ url });
};

export const startEvent = async (eventId: string, account: string) => {
  const params = searchParams({
    ...(account && { account }),
  });
  const resource = `/liveshopping/events/${eventId}/start${params}`;
  const url = API_REST_URL_V1 + resource;

  return await customFetch<ApiV1Response>({
    url,
    method: 'POST',
  });
};

export const finishEvent = async (eventId: string, account: string) => {
  const params = searchParams({
    ...(account && { account }),
  });
  const resource = `/liveshopping/events/${eventId}/finish${params}`;
  const url = API_REST_URL_V1 + resource;

  return await customFetch<ApiV1Response>({
    url,
    method: 'POST',
  });
};

export const saveDataEvent = async (args: DataEventBody) => {
  const url = `${API_REST_URL_V1}/liveshopping/events/${args.eventId}`;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'PUT',
    body: JSON.stringify({
      showInGlobalPage: args.showInGlobalPage,
      account: args.account,
      eventCategory: args.eventCategory,
    }),
  });

  return Boolean(success);
};

export const requestRecordingEvent = async (args: RequestRecordingEventDTO) => {
  const params = searchParams({
    id: args.id,
    admin: args.admin,
    email: args.email,
    account: args.account,
    sendByEmail: args.sendByEmail,
  });

  const resource = `/liveshopping/events/${args.id}/recordingDownload${params}`;
  const url = API_REST_URL_V1 + resource;
  return await customFetch<ApiV1Response>({
    url,
    method: 'GET',
  });
};
