import { createIntl, createIntlCache } from 'react-intl';
import { LOCALES } from '../i18n/';
import messages from '../i18n/messages';

const getLocale = () => {
  const languageBrowser = window.navigator.language.trim().split(/-|_/)[0];
  const languageAvailable = [LOCALES.en, LOCALES.es, LOCALES.pt];
  return languageAvailable.includes(languageBrowser)
    ? languageBrowser
    : LOCALES.en;
};

const intlFormatMessageOutsideFC = () => {
  const locale = getLocale();
  const cache = createIntlCache();
  return createIntl({ locale, messages: messages[locale] }, cache);
};

export { intlFormatMessageOutsideFC };
