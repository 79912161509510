import { GlobalStore, RuntimeType } from '../../types';
import { Runtime } from '../runtime';
import { RuntimeStrategy, RuntimeStrategyConfig } from '../types';
import { LocalRuntime } from './local-runtime';
import { VtexIORuntime } from './vtex-io-runtime';
import { VtexLegacyRuntime } from './vtex-legacy-runtime';
import { VtexFastStoreRuntime } from './vtex-faststore-runtime';

export class ExternalRuntime implements RuntimeStrategy {
  static type = RuntimeType.External;

  constructor(private config: RuntimeStrategyConfig) {}

  static isApplicable(runtime: Runtime<GlobalStore>): boolean {
    return (
      !LocalRuntime.isApplicable(runtime) &&
      !VtexLegacyRuntime.isApplicable() &&
      !VtexIORuntime.isApplicable() &&
      !VtexFastStoreRuntime.isApplicable()
    );
  }

  async execute(): Promise<void> {
    // External environment specific logic
  }
}
