import { ProductInput } from '@nizza/event-products';

type FieldsExcludedFromMutations = 'id' | 'account' | 'createdAt';

export type CreateProductDTO = Omit<
  ProductInput,
  FieldsExcludedFromMutations | 'status'
>;

export type UpdateProductDTO = Omit<ProductInput, FieldsExcludedFromMutations>;

export enum OriginOfProducts {
  GLOBALPAGE = 'globalPage',
  CACE = 'CACE',
  PLATFORM = 'platform',
}
