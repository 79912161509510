import { makeAutoObservable } from 'mobx';
import { CustomizeSchemaType } from '../types';
import {
  CustomizeComponentDomain,
  CustomizePropsPrimitives,
  customizationServices,
} from '../core';

export interface SchemasTypes {
  [section: string]: {
    label: string;
    items: CustomizeSchemaType[0][];
  };
}

export type CustomizePropsPrimitivesTransform = Omit<
  CustomizePropsPrimitives,
  'account'
>;

export enum StateFetchCustomization {
  Started = 'Started',
  IsLoading = 'IsLoading',
  EndSchemasServer = 'EndSchemasServer',
}
export class SchemaStore {
  public schemas: SchemasTypes = {};
  public account = '';
  public componentsData: Map<string, CustomizeComponentDomain> = new Map();
  public componentsDefaultData: Map<string, CustomizeComponentDomain> =
    new Map();
  public stateCustomization = StateFetchCustomization.Started;

  constructor() {
    makeAutoObservable(this, {});
  }

  async setAccount(account: string) {
    this.account = account;
    customizationServices.setAccount(account);
    await this.fetchAllCustomizationData();
  }

  async saveDataInServer(component: CustomizeComponentDomain) {
    this.stateCustomization = StateFetchCustomization.IsLoading;
    await customizationServices.updateCustomization(component);
    this.stateCustomization = StateFetchCustomization.EndSchemasServer;
  }

  async fetchAllCustomizationData() {
    this.stateCustomization = StateFetchCustomization.IsLoading;
    const components = await customizationServices.getAllCustomizations();
    this.stateCustomization = StateFetchCustomization.EndSchemasServer;

    components.forEach(component => {
      this.componentsData.set(
        this.key_(component.section, component.id),
        component,
      );
    });

    return this.componentsData;
  }

  async deleteAllCustomizationData() {
    await customizationServices.deleteAllCustomizations();
    this.restoreAllStateDefault();
  }

  private restoreAllStateDefault() {
    this.componentsData = new Map(this.componentsDefaultData);
  }

  private key_(section: string, id: string) {
    return `${section}__${id}`;
  }

  saveInStateData(
    formData: CustomizePropsPrimitivesTransform,
    field: 'componentsData' | 'componentsDefaultData' = 'componentsData',
  ) {
    this[field].set(
      this.key_(formData.section, formData.id),
      CustomizeComponentDomain.fromPrimitives({
        ...formData,
        account: this.account,
      }),
    );
  }

  saveDefaultData(formData: CustomizePropsPrimitivesTransform) {
    if (this.componentsData.get(this.key_(formData.section, formData.id)))
      return;
    this.saveInStateData(formData);
    this.saveInStateData(formData, 'componentsDefaultData');
  }

  getDataOfComponent(section: string, id: string) {
    return this.componentsData.get(this.key_(section, id));
  }

  registerSchemaComponent(schema: CustomizeSchemaType) {
    const [section, values] = Object.entries(schema)[0];
    let schemaRef = Object.assign({}, { ...this.schemas });
    const items = schemaRef[section]?.items || [];
    items.push(values);
    schemaRef = {
      [section]: {
        label: this.capitalize(section),
        items,
      },
    };
    this.schemas = { ...this.schemas, ...schemaRef };
  }

  private capitalize(word: string) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }
}
