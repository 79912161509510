import {
  useRef,
  useEffect,
  RefObject,
  EffectCallback,
  DependencyList,
  useState,
} from 'react';

export const useRefDepsEffectWihtAutomaticUpdate = createRefDepsHook(
  useEffect,
  true,
);
export const useRefDepsEffect = createRefDepsHook(useEffect, false);

type UseEffectLike = (effect: EffectCallback, deps?: DependencyList) => void;

export function createRefDepsHook(
  useEffectLike: UseEffectLike,
  enabledUpdateAutomatic: boolean,
) {
  return (effect: EffectCallback, refDeps: DependencyList) => {
    const [timer, setTimer] = useState(0);
    const cleanupRef = useRef<(() => void) | undefined>();
    const prevDepsRef = useRef<DependencyList>();

    useEffect(() => {
      let timer_: NodeJS.Timeout | null = null;

      if (enabledUpdateAutomatic) {
        timer_ = setInterval(() => {
          setTimer(prev => prev + 1);
        }, 2000);
      }

      return () => {
        timer_ && clearInterval(timer_);
      };
    }, []);

    useEffectLike(() => {
      const prevDeps = prevDepsRef.current;

      if (
        prevDeps &&
        [...refDeps, timer].every((v, i) => {
          return Object.is(isRefObj(v) ? v.current : v, prevDeps[i]);
        })
      ) {
        return;
      }

      cleanupRef.current?.();
      cleanupRef.current = effect()! as any;
      prevDepsRef.current = [...refDeps, timer].map(v =>
        isRefObj(v) ? v.current : v,
      );
    });

    useEffectLike(() => () => cleanupRef.current?.(), []);
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isRefObj(ref: any): ref is RefObject<any> {
  try {
    return (ref !== null || ref !== undefined) && 'current' in ref;
  } catch (error) {
    return false;
  }
}
