/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect } from 'react';
import type { PlayerMobileHoverControls } from '../../../types';
import {
  PauseIcon,
  PlayIcon,
  AdvanceTenSeconds,
  BackTenSeconds,
} from '../../icons';
import styles from '../../../styles.module.css';

export const MobileHoverControls = ({
  firstTimeMuted,
  handleNothing,
  status,
  isFinalized,
  isMobile,
  handleMainButton,
  showHoverOptions,
  handleAdvanceTenSeconds,
  handleBackTenSeconds,
  handleHideOnInactivity,
  setShowHoverOptions,
  pictureInPicture,
  isVerticalLayout,
}: PlayerMobileHoverControls) => {
  const [reduceTriggerArea, setReduceTriggerArea] = useState(false);
  const [reduceTriggerAreaTimeout, setReduceTriggerAreaTimeout] =
    useState<NodeJS.Timeout>();

  useEffect(() => {
    if (!isVerticalLayout) return;

    if (!showHoverOptions) {
      const Timeout = setTimeout(() => {
        setReduceTriggerArea(true);
      }, 500);
      setReduceTriggerAreaTimeout(Timeout);
    }

    if (showHoverOptions) {
      reduceTriggerAreaTimeout && clearTimeout(reduceTriggerAreaTimeout);
      setReduceTriggerArea(false);
    }
  }, [showHoverOptions]);

  if (!isMobile) return null;

  return isFinalized && !firstTimeMuted && !pictureInPicture ? (
    <div
      style={{
        opacity: showHoverOptions ? '1' : '0',
        transition: 'opacity 500ms ease 0ms',
      }}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (!firstTimeMuted && !showHoverOptions) {
          setShowHoverOptions(false);
          handleHideOnInactivity();
        }
      }}
      onKeyDown={handleNothing}
      className={`${
        isVerticalLayout
          ? !reduceTriggerArea
            ? styles.playerVideoMobileHoverOptionsPositionVertical
            : styles.playerVideoMobileHoverOptionsPositionVerticalClose
          : styles.playerVideoMobileHoverOptionsPositionHorizontal
      } ${
        isVerticalLayout
          ? styles.playerVideoMobileHoverOptionsVertical
          : styles.playerVideoMobileHoverOptionsHorizontal
      }`}
      aria-label="Player Video Mobile Show Hover Options"
    >
      {showHoverOptions && (
        <div
          className={`${styles.backTenSeconds}`}
          onClick={() => {
            handleBackTenSeconds();
            handleHideOnInactivity(status !== 'Playing');
          }}
          onKeyDown={handleNothing}
        >
          {<BackTenSeconds />}
        </div>
      )}

      {showHoverOptions && (
        <div
          className={`${styles.mobileMainButton}`}
          onClick={() => {
            handleMainButton();
            handleHideOnInactivity(status === 'Playing');
          }}
          onKeyDown={handleNothing}
        >
          {status === 'Playing' ? (
            <PauseIcon size="58" viewBox="0 0 400 400" isMobile={isMobile} />
          ) : (
            <PlayIcon size="58" viewBox="0 0 400 400" isMobile={isMobile} />
          )}
        </div>
      )}

      {showHoverOptions && (
        <div
          className={`${styles.advanceTenSeconds}`}
          onClick={() => {
            handleAdvanceTenSeconds();
            handleHideOnInactivity(status !== 'Playing');
          }}
          onKeyDown={handleNothing}
        >
          {<AdvanceTenSeconds />}
        </div>
      )}
    </div>
  ) : null;
};
