import { AnswerDTO, QuestionDTO, QuestionTypes } from 'types';
import * as yup from 'yup';

export const answerSchema: yup.SchemaOf<AnswerDTO> = yup
  .object({
    text: yup.string().max(20).required(),
  })
  .defined();

export const mainSchema: yup.SchemaOf<QuestionDTO> = yup
  .object({
    question: yup.string().required(),
    type: yup.mixed().oneOf(Object.values(QuestionTypes)),
    time: yup.number().min(20).required(),
    answers: yup.array(answerSchema),
  })
  .defined();
