import { HighlightProduct } from 'lib/types';

const NAME_PRODUCT_STORAGE = 'product';

export default class ProductLocalStorage {
  static getContent() {
    return JSON.parse(
      localStorage.getItem(NAME_PRODUCT_STORAGE)!,
    ) as HighlightProduct;
  }

  static save(product: HighlightProduct) {
    localStorage.setItem(NAME_PRODUCT_STORAGE, JSON.stringify(product));
  }

  static restore() {
    localStorage.removeItem(NAME_PRODUCT_STORAGE);
  }
}
