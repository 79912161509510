import styled from 'styled-components';

export const Smartphone = styled.div`
  position: relative;
  width: 29px;
  height: 50px;
  border: 2px black solid;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-radius: 3px;
  overflow: hidden;
  min-width: 29px;
`;

interface ContentProps {
  isVertical: boolean;
}

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isVertical }) => (isVertical ? '88px' : '100%')};
  margin-left: ${({ isVertical }) => (isVertical ? '-30px' : '0')};
  & > * {
    width: 100%;
  }
`;
