import { GPEventType } from './globalPage';

export interface AccountInfo {
  id: string;
  account: string;
  accountType: AccountType;
  username: string;
  currencyConfig: AccountCurrencyConfig;
  contractType: AccountContractType;
  nextDateMetricRecord: string;
  spendedMinutes: number;
  unlimitedService: boolean;
  hoursAvailable: number;
  contractStatus: AccountContractStatusType;
  createdAt: string;
  installationDateApp: string;
  cxAccount: string;
  freeService: boolean;
  subscriptionType: AccountSubscriptionType | null;
  minutesAvailable: number;
  globalPage: AccountGlobalPage;
  stripe?: AccountStripe;
  descriptionPlan: AccountPlanDescriptionType;
  planType?: string;
  selectFreeTrial?: boolean;
  exceededFeatures?: ExceededFeatures;
  host: string | null;
}

type StripeSubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'trialing'
  | 'unpaid';

export interface AccountStripe {
  customerId: string;
  subscriptionId: string;
  subscriptionStatus: StripeSubscriptionStatus;
}

export type ExceededFeatures = {
  videoRecording: number;
  viewers: number;
};

export interface ExternalCreateSubs {
  name: AccountSubscriptionType;
  trialDays?: number;
  returnUrl?: string;
  amount: number;
  currencyCode?: string;
}

export type SubscriptionStatus =
  | 'active'
  | 'cancelled'
  | 'declined'
  | 'expired'
  | 'frozen'
  | 'pending'
  | 'accepted';

export interface ExternalSubscriptionInfo {
  createdAt: string;
  id: string;
  name: string;
  status: SubscriptionStatus;
}

export enum AccountType {
  VTEX = 'VTEX',
  VTEX_IO = 'VTEX-IO',
  VTEX_CMS = 'PORTAL-VTEX',
  PLATFORM = 'PLATFORM',
  UNKNOWN = 'UNKNOWN',
}

export interface AccountCurrencyConfig {
  currency: string;
  currencyCustomDecimalsNumber: number;
  currencyCustomSymbol: string;
}

export enum AccountContractType {
  PLATFORM = 'platform_module',

  /**
   * The following values are used for the VTEX ecosystem
   */
  ADENDA = 'subscription_module',
  APP_STORE = 'app_store',
}

export enum AccountContractStatusType {
  ACTIVE = 'active_contract',
  INACTIVE = 'inactive_contract',
}

export enum AccountSubscriptionType {
  LITE = 'lite',
  STANDARD = 'standard',
  PRO = 'pro',
}

export enum AccountPlanDescriptionType {
  LITE = 'PLAN_LITE',
  /**
   * It should be "PLAN_STANDARD" but someone made a mistake and
   * it is difficult to change this value without correcting the
   * data in the DB
   */
  STANDARD = 'PLAN_ESTANDAR',
  PRO = 'PLAN_PRO',
  NONE = 'NO_PLAN',
}

export interface AccountGlobalPage {
  activeSections: GPEventType[];
}
