import { AddToCartAction, HookState, runAddToCartHooks } from '@nizza/core';
import { vtexLegacyAddToCart } from '@nizza/utils';
import { useActions, useSettings } from '../context';
import { LivestreamingProps, PlayerProduct } from '../types';
import { Dispatch, SetStateAction } from 'react';

enum OriginOfProducts {
  CACE = 'CACE',
  GLOBAL_PAGE = 'globalPage',
  PLATFORM = 'platform',
}

type AddToCartProps = {
  variationSelectorState?: [string, Dispatch<SetStateAction<string>>];
};

export type AddToCartPlayerSettings = Pick<
  LivestreamingProps,
  | 'redirectTo'
  | 'isInfinite'
  | 'showChat'
  | 'showLike'
  | 'showQuickView'
  | 'showProductsCarousel'
  | 'showSidebarProducts'
  | 'showViewers'
  | 'time'
>;

type ContextData = {
  playerSettings: AddToCartPlayerSettings;
};

const error = (message: string, ...args: any[]) =>
  console.error(`[useAddToCart] ${message}`, ...args);

const defaultErrorMessage = 'store/text.add-to-cart-error';
const messages = {
  errorAddingToCart: defaultErrorMessage,
  errorOpeningQuickView: defaultErrorMessage,
  errorOpeningNewTab: defaultErrorMessage,
  successAddingToCart: 'store/text.add-to-cart',
};

export const useAddToCart = (props: AddToCartProps) => {
  const { setting } = useActions();
  const { setAlertMessage } = useSettings();
  const isCACE = setting.originOfProducts === OriginOfProducts.CACE;
  const isGlobalPage =
    setting.originOfProducts === OriginOfProducts.GLOBAL_PAGE;
  const isPlatform = setting.originOfProducts === OriginOfProducts.PLATFORM;

  const {
    redirectTo,
    isInfinite,
    showChat,
    showLike,
    showQuickView,
    showProductsCarousel,
    showSidebarProducts,
    showViewers,
    time,
  } = setting;

  const variationSelectorStateNotValid = () => {
    error('variationSelectorState is required for open the quick view');
    showErrorMessage(messages.errorOpeningQuickView);
  };

  const validateStrategy = (
    state: HookState<ContextData>,
    quantity: number,
  ): AddToCartAction => {
    const { actionFactory, product } = state;
    const thereAreVariations = !!product?.variationSelector?.length;
    const { addToCartLink, pdpLink } = product;
    let action: AddToCartAction;

    if (isCACE || isGlobalPage || isPlatform) {
      const link = isCACE ? addToCartLink : pdpLink;
      action = actionFactory.openTab(link);
    } else if (!showVariationSelector && thereAreVariations) {
      if (showQuickView) {
        action = actionFactory.openVariationSelector(true);
      } else {
        action = actionFactory.openTab(pdpLink);
      }
    } else if (redirectTo && (!thereAreVariations || showVariationSelector)) {
      action = actionFactory.openTab(addToCartLink);
    } else {
      action = actionFactory.addToCart({ product, quantity });
    }

    return action;
  };

  const [
    showVariationSelector = '', // This is the product id
    setShowVariationSelector = variationSelectorStateNotValid,
  ] = props.variationSelectorState || [];

  function addToCart(product: PlayerProduct, quantity = 1) {
    runAddToCartHooks<ContextData>({
      product,
      userAddToCartConfig: setting.addToCartConfig,
      context: {
        quantity,
        isVariationSelectorOpen: !!showVariationSelector,
        data: {
          playerSettings: {
            redirectTo,
            isInfinite,
            showChat,
            showLike,
            showQuickView,
            showProductsCarousel,
            showSidebarProducts,
            showViewers,
            time,
          },
        },
      },
      hookStrategy: {
        validate: state => validateStrategy(state, quantity),
      },
      actionFactory: {
        openTab: openNewTab,
        addToCart: vtexLegacyAddToCart,
        openVariationSelector: v =>
          setShowVariationSelector(v ? product.id : ''),
      },
      notifyFactory: {
        error: () => showErrorMessage(messages.errorAddingToCart),
        success: () => showSuccessMessage(messages.successAddingToCart),
      },
    });
  }

  function openNewTab(link: string) {
    if (link) {
      window.open(link, '_blank');
    } else {
      error(`The link "${link}" is invalid`);
      showErrorMessage(messages.errorOpeningNewTab);
    }
  }

  function showSuccessMessage(value: string) {
    setAlertMessage({ type: 'success', value });
  }

  function showErrorMessage(value: string) {
    setAlertMessage({ type: 'error', value });
  }

  return addToCart;
};
