/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'polyfills';
import { CustomStorage } from '@nizza/utils';

import * as ReactDOM from 'react-dom/client';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales';

// Cognito config
import { Amplify } from 'aws-amplify';
import { AMPLIFY } from './shared';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
CustomStorage.init();

Amplify.configure({ Auth: AMPLIFY.auth });

const root = ReactDOM.createRoot(
  document.getElementById('nizza-root') as HTMLElement,
);

const HelmetProviderAny = HelmetProvider as any;

root.render(
  // TODO: Migrate react router v5 to v6
  // https://nx.dev/recipes/other/react-18#react-18-migration
  // <StrictMode>
  <HelmetProviderAny>
    <App />
  </HelmetProviderAny>,
  // </StrictMode>,
);

// Hot reloadable translation json files
if ((module as any).hot) {
  (module as any).hot.accept(['./locales'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
