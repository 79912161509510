import { AccountRepository } from './domain/repositories/accountRepository';
import { UserRepository } from './domain/repositories/userRepository';
import { GraphqlAccountRepository } from './infrastructure/GraphqlAccountRepository';
import { GraphqlChatMessageRepository } from './infrastructure/GraphqlChatMessageRepository';
import { GraphqlUserRepository } from './infrastructure/GraphqlUserRepository';

export * from './domain';
export * from './infrastructure';
export * from './services';

const chatMessageRepository = new GraphqlChatMessageRepository();
const accountRepository: AccountRepository = new GraphqlAccountRepository();
const userRepository: UserRepository = new GraphqlUserRepository();

export { chatMessageRepository, accountRepository, userRepository };
