import styled from 'styled-components';

export const EventsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1366px) {
    flex-direction: row;
  }

  @media screen and (max-width: 1920px) {
    flex-direction: row;
  }
`;
