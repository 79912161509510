import { useObserver } from 'mobx-react-lite';
import { getNizza, NizzaStore } from './NizzaStore';
import { useMemo } from 'react';

/**
 * Returns the NizzaStore using memoization.
 *
 * @return {NizzaStore} The NizzaStore object
 */
export function useNZ(): NizzaStore {
  return useMemo(getNizza, []);
}

/**
 * Custom hook for selecting and observing specific properties from NizzaStore.
 *
 * @param selector - A function that receives the NizzaStore instance and returns the selected part.
 * @returns The selected store part, being observed for changes.
 *
 * @example
 * const accountName = useNizza(store => store.accountName);
 */
export function useNizza<T>(selector: (store: NizzaStore) => T): T {
  const store = useNZ();
  return useObserver(() => {
    return selector(store);
  });
}
