import { FC, ReactNode } from 'react';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

type Props = {
  children: ReactNode;
};

export const VerticalLayout: FC<Props> = props => {
  return (
    <div id="layout-wrapper">
      <Header />
      <Sidebar />
      <div className="main-content">{props.children}</div>
    </div>
  );
};
