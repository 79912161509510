/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncLiveEdit = lazyLoad(
  () => import('./Page'),
  module => module.LiveEdit,
);
