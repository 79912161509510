import { FC, SVGProps } from 'react';

export const ChatBlockIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.074 5.93721C12.2474 7.12214 12.2474 9.04362 11.074 10.2289C9.90069 11.4138 7.99796 11.4138 6.82426 10.2289C5.65091 9.04397 5.65091 7.12248 6.82426 5.93721C7.99762 4.75229 9.90034 4.75229 11.074 5.93721Z"
        fill="#808080"
      />
      <path
        d="M3.70789 14.8632V15.6146C3.70789 16.0294 5.7159 17.1067 9.15346 17.1067C12.591 17.1067 13.9088 16.3608 14.098 16.1455C14.2178 16.0094 14.2918 15.8217 14.2918 15.6143V14.8629C14.2918 12.5895 11.6777 11.1122 8.99986 11.1122C6.32206 11.1122 3.70789 12.5892 3.70789 14.8629V14.8632Z"
        fill="#808080"
      />
      <circle cx="9" cy="9" r="8.25" stroke="#808080" stroke-width="1.5" />
      <path
        d="M4.53952 3.16486L14.6989 13.6852L13.2422 15.0919L3.08286 4.57154L4.53952 3.16486Z"
        fill="#808080"
      />
      <path
        d="M4.35969 3.33852L14.5191 13.8589L14.8787 13.5115L4.71936 2.99119L4.35969 3.33852ZM13.4221 14.9182L3.26269 4.39787L2.90302 4.7452L13.0624 15.2655L13.4221 14.9182Z"
        fill="white"
      />
    </svg>
  );
};
