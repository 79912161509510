/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useContext,
  useRef,
} from 'react';

import {
  ActionsContext,
  SettingContext,
  useActions,
  useControlsContext,
} from '../../../context';
import type { MediaPlayer } from '../../../types';
import { getDeviceType } from '../../../utils';
import { usePlayerFunctions, usePlayerLayout } from '../../../hooks';
import { DesktopControls, MobileControls } from '../Control';
import ShareComponents from '../../ShareComponents';
import { ProductToCart, PromotionAnimations } from '../..';

import styles from './streamPlayer.module.css';
import { LOCAL_STORAGE_WEB_CLIENT } from '../../../utils/constants';

interface HighlightProps {
  detector: boolean;
  fullScreen: boolean;
  handleFullScreen: () => void;
  handleFullScreenMobile: () => void;
}

type streamPlayerProps = {
  player: MediaPlayer;
  setShowVariation: React.Dispatch<React.SetStateAction<string>>;
  transmitionType: string | undefined;
  streamUrl: string | undefined;
  isFinalized: boolean;
  setHighlightProps: React.Dispatch<React.SetStateAction<any>>;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  collectionId?: string;
  hideChat: boolean;
  variationSelectorState: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
  highlightProps: HighlightProps;
};

export const StreamPlayer = ({
  player,
  setShowVariation,
  transmitionType,
  streamUrl,
  isFinalized,
  setHighlightProps,
  setFullScreen,
  collectionId,
  hideChat,
  variationSelectorState,
  highlightProps,
}: streamPlayerProps) => {
  const [detector, setDetector] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState(false);
  const { isModalLive, activePromo, showCarouselChat, setIsModalLive } =
    useContext(SettingContext);
  const {
    setting: { showSidebarProducts },
  } = useActions();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    setting: { isInGlobalPage },
  } = useContext(ActionsContext);

  const mobileOS = getDeviceType() === 'mobile';

  const { isVerticalLayout, mainContainer, videoEl } =
    usePlayerLayout(transmitionType);
  const isMobile = getDeviceType() === 'mobile';

  const isModalLiveFromServer =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_WEB_CLIENT) || '{}')
      ?.modalLive || false;

  const handleModalMobile = () => setIsModalLive(!isModalLive);
  const {
    BUFFERING,
    firstTimeMuted,
    fullScreen,
    handleFullScreen,
    handleFullScreenMobile,
    handleMainButton,
    handleMobileOptions,
    handleMute,
    handleNothing,
    handlePictureAndPicture,
    handleVolume,
    IDLE,
    inactive,
    muted,
    overlay,
    pictureInPicture,
    PLAYING,
    setInactive,
    setOverlay,
    showOptions,
    status,
    volume,
    progress,
    handleVideoProgress,
    handleOnTimeUpdate,
    showHoverOptions,
    setShowHoverOptions,
    handleHideOnInactivity,
    handleBackTenSeconds,
    handleAdvanceTenSeconds,
    currentTime,
    duration,
    hideMobileHoverCtrlsTimeout,
  } = usePlayerFunctions({ player, videoEl, mainContainer, streamUrl });

  useEffect(() => {
    setFullScreen(fullScreen || (isMobile && Boolean(isModalLive)));
  }, [fullScreen, isMobile, isModalLive]);

  useEffect(() => {
    setDetector(mobileOS);
    setHighlightProps({
      detector,
      fullScreen,
      handleFullScreen,
      handleFullScreenMobile,
    });
  }, [fullScreen, mobileOS, pictureInPicture]);

  const { setMobileHoverCtrlsProps } = useControlsContext();

  const mobileHoverCtrlsProps = useMemo(() => {
    return {
      firstTimeMuted,
      handleNothing,
      status,
      isFinalized,
      isMobile,
      handleMainButton,
      showHoverOptions,
      handleAdvanceTenSeconds,
      handleBackTenSeconds,
      handleHideOnInactivity,
      setShowHoverOptions,
      pictureInPicture,
      isVerticalLayout,
    };
  }, [
    firstTimeMuted,
    status,
    showHoverOptions,
    pictureInPicture,
    isVerticalLayout,
    hideMobileHoverCtrlsTimeout,
  ]);

  useEffect(() => {
    setMobileHoverCtrlsProps && setMobileHoverCtrlsProps(mobileHoverCtrlsProps);
  }, [mobileHoverCtrlsProps]);

  const ControlWrapper = useMemo(() => {
    const props = {
      BUFFERING,
      firstTimeMuted,
      fullScreen,
      handleFullScreen,
      handleFullScreenMobile,
      handleMainButton,
      handleMobileOptions,
      handleMute,
      handleNothing,
      handlePictureAndPicture,
      handleVolume,
      IDLE,
      inactive,
      isVerticalLayout,
      muted,
      overlay,
      pictureInPicture,
      PLAYING,
      setInactive,
      setOverlay,
      showOptions,
      status,
      videoEl,
      volume,
      progress,
      handleVideoProgress,
      handleOpenShare: () => setOpenShare(true),
      isFinalized,
      setShowVariation,
      transmitionType,
      isMobile,
      showCarouselChat,
      showSidebarProducts,
      collectionId,
      isModalLive,
      handleModalMobile,
      isModalLiveFromServer,
      showHoverOptions,
      setShowHoverOptions,
      handleHideOnInactivity,
      handleBackTenSeconds,
      handleAdvanceTenSeconds,
      currentTime,
      duration,
      variationSelectorState,
      highlightProps,
    };

    return (
      <Fragment>
        {isMobile ? (
          <MobileControls {...props} />
        ) : !fullScreen && isVerticalLayout ? (
          <MobileControls {...props} />
        ) : (
          <DesktopControls {...props} />
        )}
        {activePromo && activePromo.message !== '' && (
          <PromotionAnimations
            canvas={canvasRef}
            message={activePromo.message}
            animation={activePromo.animation}
            forceMobile={isMobile}
          />
        )}
      </Fragment>
    );
  }, [
    BUFFERING,
    firstTimeMuted,
    fullScreen,
    handleFullScreen,
    handleFullScreenMobile,
    handleMainButton,
    handleMobileOptions,
    handleMute,
    handleNothing,
    handlePictureAndPicture,
    handleVolume,
    IDLE,
    inactive,
    isVerticalLayout,
    muted,
    overlay,
    pictureInPicture,
    PLAYING,
    setInactive,
    setOverlay,
    showOptions,
    status,
    videoEl,
    volume,
    progress,
    handleVideoProgress,
    isFinalized,
  ]);

  return (
    <div
      ref={mainContainer}
      className={`${styles.playerUi} ${!isVerticalLayout && isMobile && styles.playerUiMobileHorizontal
        } ${hideChat &&
        isMobile &&
        !isVerticalLayout &&
        isFinalized &&
        styles.playerUiMobileHorizontalHideChat
        }`}
      onMouseOver={() => !inactive && setOverlay(true)}
      onMouseMove={() => {
        setInactive(false);
        setOverlay(true);
      }}
      onMouseOut={() => setOverlay(false)}
      onFocus={handleNothing}
      onBlur={handleNothing}
      style={
        isVerticalLayout && isModalLive && !detector && !isInGlobalPage
          ? { width: '25vw' }
          : isModalLive && detector
            ? { height: '100%', maxHeight: '100%' }
            : {}
      }
    >
      {openShare && (
        <ShareComponents
          handleClose={() => setOpenShare(false)}
          isVerticalLayout={isVerticalLayout}
          isMobile={isMobile}
        />
      )}
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 2,
        }}
      />
      <video
        className={`${styles.playerVideoEl} ${!isVerticalLayout && isMobile && styles.playerVideoElHorizontalMobile
          }`}
        controls={false}
        ref={videoEl}
        playsInline
        muted={muted}
        id="player-video-el"
        style={{
          objectFit:
            (isVerticalLayout && !fullScreen) ||
              (isVerticalLayout && fullScreen && isMobile) ||
              (!isVerticalLayout && isMobile)
              ? 'cover'
              : 'contain',
          backgroundColor: '#000000e0',
        }}
        onTimeUpdate={handleOnTimeUpdate}
      />
      {ControlWrapper}
      <div className={styles.containerProductCart}>
        <ProductToCart />
      </div>
    </div>
  );
};
