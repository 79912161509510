import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { useAppConfig } from '../appConfig';

const initialState = {
  id: '',
  username: '',
  attributes: {
    email: '',
    email_verified: false,
    phone_number: '',
    phone_number_verified: false,
    sub: '',
    'custom:account': '',
  },
};

export const useCognito = () => {
  const [cognitoUser, setCognitoUser] = useState(initialState);
  const [success, setSuccess] = useState(false);
  const { pathname } = useLocation();
  const { auth } = useAppConfig();

  useEffect(() => {
    const isInGlobalPage = pathname.split('/')[1] === 'global-page';
    // This is used by external platforms such as shopify
    const authIsDisabled = auth?.enable === false;

    if (isInGlobalPage || authIsDisabled) return;

    Auth.currentUserInfo().then(currentUser => {
      if (currentUser) {
        setCognitoUser(currentUser);

        setSuccess(false);
      } else setSuccess(true);
    });
  }, [pathname, auth]);

  return { cognitoUser, success };
};
