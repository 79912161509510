import { LOCALES } from '../locales';

export default {
  [LOCALES.pt]: {
    'store/button.close': 'Fechar',
    'store/button.expecting': 'Esperando...',
    'store/button.send': 'Enviar',
    'store/inputs.required-placeholder': 'Requerido',
    'store/live.chat': 'Bate-papo ao vivo',
    'store/live.chat-login': 'Digite seu nome para continuar',
    'store/live.chat-login-btn': 'Continue com o chat',
    'store/live.chat-login-email-no-required-placeholder': 'Email',
    'store/live.chat-login-email-required-placeholder': 'Email*',
    'store/live.chat-login-invalid-email': 'E-mail inválido',
    'store/live.chat-login-invalid-username': 'Nome de usuário Inválido',
    'store/live.chat-login-name-placeholder': 'Nome*',
    'store/live.chat-login-username-already-exist':
      'Esse nome de usuário já existe',
    'store/live.chat-votes': 'votos',
    'store/seconds': 'Segundos',
    'store/text.add': 'Adicionar',
    'store/text.anonymous-user': 'Anônimo',
    'store/text.before': 'Antes',
    'store/text.chat-placeholder': 'Escreva um comentário',
    'store/text.email': 'Email',
    'store/text.email-invalid': 'E-mail inválido',
    'store/text.false': 'Falso',
    'store/text.likes': 'Gostos',
    'store/text.live-no-video': 'Transmissão ao vivo.',
    'store/text.live-products': 'Produtos',
    'store/text.name': 'Nome',
    'store/text.no-video': 'Aguardando conexão com o stream.',
    'store/text.not-stock': 'Fora de estoque',
    'store/text.now': 'Agora',
    'store/text.products': 'Produtos em destaque',
    'store/text.select-answer': 'Você deve selecionar uma resposta.',
    'store/text.true': 'Verdadero',
    'store/text.unread-messages': 'Mensagens não lidas',
    'store/time': 'Tempo',
    'store/total-votes': 'Votos totais',
    'store/text.add-to-cart': 'Produto adicionado ao carrinho.',
    'store/text.add-to-cart-error': 'Erro ao adicionar ao carrinho.',
    'store/text.share': 'Compartilhar',
    'store/text.details': 'Ver detalhes',
    'chat.message-max-length':
      'As mensagens podem ter um máximo de 180 caracteres',
    'chat.show-chat-horizontal-layout-message':
      'Mostrar repetição do bate-papo',
    'chat.hide-chat-horizontal-layout-message':
      'Ocultar repetição do bate-papo',
    'chat.mobile-record-playback-indicator-message': 'Gravação',
    'chat.chat-replay-mobile-vertical-layout-message': 'Repetição do bate-papo',
    'chat-carousel.product-unavailable-message': 'Não disponível',

    'customize/general.back': 'De volta',
    'customize/general.background': 'Fundo',
    'customize/general.price': 'Preço',
    'customize/general.text': 'Texto',
    'customize/general.message': 'Mensagem',
    'customize/general.section-title': 'Título seção',
    'customize/general.section-name': 'Nome seção',
    'customize/general.icon': 'Ícone',
    'customize/general.desktop': 'Área de Trabalho',
    'customize/general.mobile': 'Móvel',
    'customize/product-button.add-to-cart-button':
      'Botão de adicionar ao carrinho',
    'customize/product-button.text-inside-button': 'Texto dentro do botão',
    'customize/chat-carousel-product-item.product-carousel':
      'Produtos no celular',
    'customize/highlight-product.product-highlight': 'Destaque do produto',
    'customize/highlight-product.show': 'Mostrar',
    'customize/highlight-product.show-button': 'Mostrar botão',
    'customize/highlight-product.show-price': 'Mostrar preço',
    'customize/highlight-product.opacity': 'opacidade',
    'customize/product-item.product-cards': 'Produtos em computador',
    'customize/product-item.highlight-promotion': 'Promoção de destaque',
    'customize/vertical-product-slider.section-title': 'Título seção produto',
    'customize/message-container-admin.admin-message':
      'Mensagem do administrador',
    'customize/message-container-admin.background-layout-horizontal':
      'Fundo layout horizontal',
    'customize/message-container-admin.message-layout-horizontal':
      'Mensagem layout horizontal',
    'customize/message-container-admin.background-layout-vertical':
      'Fundo layout vertical',
    'customize/message-container-admin.message-layout-vertical':
      'Mensagem layout vertical',
    'customize/chat.chat-section-title': 'Título seção bate-papo',
    'customize/chat.comment-box-text': 'Texto da caixa de comentários',
    'customize/chat.comment-box-title': 'Caixa de comentários',
    'customize/chat.send-message-button.desktop.title':
      'Botão enviar no computador',
    'customize/modal-trigger.select-product-variations.title':
      'Selección de variaciones de producto',
    'customize/modal-trigger.round-or-square-selector':
      'Style showing variations',
    'customize/modal-trigger.round-selector': 'Seletor redondo',
    'customize/modal-trigger.square-selector': 'Seletor quadrado',
  },
};
