export enum AccountTypes {
  VTEX = 'VTEX',
  VTEX_IO = 'VTEX-IO',
  VTEX_CMS = 'PORTAL-VTEX',
  PLATFORM = 'PLATFORM',
  UNKNOWN = 'UNKNOWN',
}
export enum AccountContractTypes {
  PLATFORM = 'platform_module',

  /**
   * The following values are used for the VTEX ecosystem
   */
  ADENDA = 'subscription_module',
  APP_STORE = 'app_store',
}

export enum AccountContractStatusTypes {
  ACTIVE = 'active_contract',
  INACTIVE = 'inactive_contract',
}

export enum AccountSubscriptionTypes {
  LITE = 'lite',
  STANDARD = 'standard',
  PRO = 'pro',
}

export enum AccountPlanDescriptionTypes {
  LITE = 'PLAN_LITE',
  /**
   * It should be "PLAN_STANDARD" but someone made a mistake and
   * it is difficult to change this value without correcting the
   * data in the DB
   */
  STANDARD = 'PLAN_ESTANDAR',
  PRO = 'PLAN_PRO',
  NONE = 'NO_PLAN',
}

export enum GlobalPageSectionTypes {
  PAST = 'past',
  LIVE = 'live',
  FUTURE = 'future',
}
