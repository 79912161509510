import { ReactNode } from 'react';
import { AppFeatures } from './plans';

export enum AppModule {
  LIVE_SHOPPING = 'liveShopping',
  PERSONAL_SHOPPER = 'personalShopper',
  PRODUCTS = 'products',
  BILLING = 'billing',
  EXTERNAL_BILLING = 'externalBilling',
  CONFIG = 'config',
  SETTINGS = 'settings',
  USERS = 'users',
  ACCOUNT_MANAGEMENT = 'accountManagement',
}

export type AppLayoutConfig = {
  sideBarIsOpen: boolean;
};

export type AppModuleConfig = {
  omit?: AppModule[];
  only?: AppModule[];
};

export type AppMenuItem = {
  title: string;
  icon?: string;
};

export type AppRoute = {
  path: string;
  element: ReactNode;
  index?: boolean;
  module?: AppModule;
  menu?: AppMenuItem;
  enableUsingFeaturePlan?: AppFeatures;
};

export type AppComponentConfig = {
  eventCollectionSelect: EventCollectionSelectConfig;
  eventPreview: EventPreviewConfig;
  subscriptionButton: SubscriptionButtonConfig;
  billing: BillingConfig;
  stepByStep: StepByStep;
};

export type EventCollectionSelectOption = {
  label: string;
  value: any;
};

export type AuthenticationConfig = {
  account: string;
  enable: boolean;
};

type EventCollectionSelectConfig = {
  source: string;
  options?: EventCollectionSelectOption[];
};

type EventPreviewConfig = {
  enable: boolean;
};

type SubscriptionButtonConfig = {
  external: boolean;
};

type BillingConfig = {
  useCustomPlans: boolean;
};

type StepByStep = {
  enable: boolean;
};
