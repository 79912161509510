/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { AccountTypes } from '@nizza/core';
import { Dispatch, useEffect } from 'react';
import { Actions } from './reducer';

export const useSetLivestreaming = (
  idLivestreaming: string,
  account: string,
  host: string,
  playBackStartTime: number,
  dispatch: Dispatch<Actions>,
  accountType: AccountTypes,
) => {
  useEffect(() => {
    if (idLivestreaming && account) {
      dispatch({
        type: 'SET_LIVESTREAM_CONFIG',
        args: {
          idLivestreaming,
          account,
          host,
          playBackStartTime,
          accountType,
        },
      });
    }
  }, [idLivestreaming, account, host, playBackStartTime, accountType]);
};
