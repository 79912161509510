import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import {
  AppComponentConfig,
  AppLayoutConfig,
  AppModuleConfig,
  AppRoute,
  AuthenticationConfig,
} from 'types';

export interface AppConfigCtx {
  layout: AppLayoutConfig;
  modules?: AppModuleConfig;
  components?: Partial<AppComponentConfig>;
  auth?: AuthenticationConfig;
  protectedRoutes: AppRoute[];
  setProtectedRoutes: Dispatch<SetStateAction<AppRoute[]>>;
}

export const AppConfigCtxDefault: AppConfigCtx = {
  layout: {
    sideBarIsOpen: false,
  },
  protectedRoutes: [],
  setProtectedRoutes: () => null,
};

export const AppConfigContext =
  createContext<AppConfigCtx>(AppConfigCtxDefault);
export const useAppConfig = () => useContext(AppConfigContext);
