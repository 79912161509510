/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncCreateAccount = lazyLoad(
  () => import('./CreateAccount'),
  module => module.CreateAccount,
);

export const AsyncAccountInfo = lazyLoad(
  () => import('./AccountInfo'),
  module => module.AccountInfo,
);

export const AsyncSetPlan = lazyLoad(
  () => import('./SetPlan'),
  module => module.SetPlan,
);
