import {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  ChangeEvent,
} from 'react';

import type { MediaPlayer, StreamPlayerType } from '../types';
import { getElementById, getMobileOS } from '../utils';
import { useLivestreamingContext } from '../context/livestreaming/livestreamingContext';
import { HightLightHistoryElement } from '../types';

type PlayerFuntionsProps = {
  mainContainer: React.RefObject<HTMLDivElement>;
  player: MediaPlayer;
  videoEl: React.RefObject<StreamPlayerType>;
  streamUrl: string | undefined;
};

const usePlayerFunctions = (props: PlayerFuntionsProps) => {
  const { mainContainer, player, videoEl, streamUrl } = props;
  const { PLAYING, IDLE, BUFFERING } = window.IVSPlayer.PlayerState;

  const [pictureInPicture, setPictureInPicture] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(true);
  const [firstTimeMuted, setFirstTimeMuted] = useState<boolean>(true);
  const [status, setStatus] = useState<string>(IDLE);
  const [overlay, setOverlay] = useState<boolean>(false);
  const [inactive, setInactive] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(100);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00:00');
  const [duration, setDuration] = useState('00:00:00');
  const [showHoverOptions, setShowHoverOptions] = useState(false);
  const [timeToHideTimeout, setTimeToHideTimeout] = useState<
    number | undefined
  >();

  const {
    chatHistory,
    chat,
    handleSetChat,
    highlightHistory,
    handleSetHightLight,
    currentHightLightProductId,
    playBackStartTime,
  } = useLivestreamingContext();
  const elementIdPlayerVideo = 'player-video-el';

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const handleMainButton = (): void => {
    const state = player.getState();

    switch (state) {
      case PLAYING:
        player.pause();
        break;

      case IDLE:
        player.play();
        break;

      default:
        break;
    }
  };

  const handlePictureAndPicture = (): void => {
    try {
      if (!videoEl.current) return;

      setPictureInPicture(prev => {
        if (prev) document.exitPictureInPicture();
        else if (videoEl.current) videoEl.current.requestPictureInPicture();

        return !prev;
      });
    } catch (err) {
      setPictureInPicture(false);
    }
  };

  const getFullScreenElement = () => {
    let fullScreenElement;

    if (document.fullscreenElement) {
      fullScreenElement = document.fullscreenElement;
    } else if (document.mozFullScreenElement) {
      fullScreenElement = document.mozFullScreenElement;
    } else if (document.webkitFullscreenElement) {
      fullScreenElement = document.webkitFullscreenElement;
    } else if (document.msFullscreenElement) {
      fullScreenElement = document.msFullscreenElement;
    }

    return fullScreenElement;
  };

  const getExitFullScreenElement = () => {
    let exitFullScreenElement;

    if (document.exitFullscreen) {
      exitFullScreenElement = document.exitFullscreen;
    } else if (document.mozCancelFullScreen) {
      exitFullScreenElement = document.mozCancelFullScreen;
    } else if (document.webkitExitFullscreen) {
      exitFullScreenElement = document.webkitExitFullscreen;
    } else if (document.msExitFullscreen) {
      exitFullScreenElement = document.msExitFullscreen;
    }

    return exitFullScreenElement;
  };

  const getRequestFullScreenElement = (
    mainContainerCurrent: HTMLDivElement | Element | null,
  ) => {
    let requestFullscreen;

    if (mainContainerCurrent) {
      if (mainContainerCurrent.requestFullscreen) {
        requestFullscreen = mainContainerCurrent.requestFullscreen;
      } else if (mainContainerCurrent.mozRequestFullScreen) {
        requestFullscreen = mainContainerCurrent.mozRequestFullScreen;
      } else if (mainContainerCurrent.webkitRequestFullscreen) {
        requestFullscreen = mainContainerCurrent.webkitRequestFullscreen;
      } else if (mainContainerCurrent.msRequestFullscreen) {
        requestFullscreen = mainContainerCurrent.msRequestFullscreen;
      }
    }

    return requestFullscreen;
  };

  function exitFullScreen(callback?: () => void) {
    const exitFullScreen: any = getExitFullScreenElement();

    if (!exitFullScreen) {
      console.error(`exitFullScreen is undefined`);
      return false;
    }

    exitFullScreen.bind(document)();

    if (callback) callback();
    return true;
  }

  async function handleFullScreen(): Promise<void> {
    if (!mainContainer.current) return;

    if (pictureInPicture) {
      handlePictureAndPicture();
      await delay(500);
    }

    mainContainer.current.onfullscreenchange = (): void => {
      const fullScreenElement = getFullScreenElement();

      if (fullScreenElement) return;

      exitFullScreen();
      setFullScreen(false);
    };

    setFullScreen(prev => {
      if (prev) {
        exitFullScreen();
      } else {
        const requestFullscreen = getRequestFullScreenElement(
          mainContainer.current,
        );

        requestFullscreen && requestFullscreen.bind(mainContainer.current)();
      }

      return !prev;
    });
  }

  const handleFullScreenMobile = (): void => {
    const mobileDiv = getElementById('containerFullScreenMobile');

    if (!mobileDiv) return;

    const enter = () => {
      const requestFullscreen = getRequestFullScreenElement(mobileDiv);

      if (requestFullscreen) {
        requestFullscreen.bind(mobileDiv)();

        const mobileDivPlayer =
          getElementById(elementIdPlayerVideo)?.parentElement;

        if (mobileDivPlayer) {
          mobileDivPlayer.style.height = '100vh';
          mobileDivPlayer.style.maxHeight = '100vh';
        }
      } else if (videoEl.current) {
        videoEl.current.webkitEnterFullscreen();
      }
    };

    const exit = () => {
      const exitExitFullScreen = exitFullScreen();

      if (exitExitFullScreen) {
        const mobileDivPlayer =
          getElementById(elementIdPlayerVideo)?.parentElement;

        if (mobileDivPlayer) {
          mobileDivPlayer.style.height = '78.2vh';
          mobileDivPlayer.style.maxHeight = '78.2vh';
        }
      } else if (videoEl.current) {
        videoEl.current.webkitExitFullScreen();
      }
    };

    mobileDiv.onfullscreenchange = (): void => {
      const fullScreenElement = getFullScreenElement();

      if (fullScreenElement) return;

      exitFullScreen(exit);
      setFullScreen(false);
    };

    setFullScreen(prev => {
      if (prev) {
        exit();
      } else {
        enter();
      }

      return !prev;
    });
  };

  const handleMute = () => {
    setMuted(prev => {
      player.setMuted(!prev);
      if (prev) {
        setVolume(prevVol => {
          const newVol = prevVol === 0 ? 100 : prevVol;

          player.setVolume(newVol / 100);

          return newVol;
        });
      }

      return !prev;
    });
    if (firstTimeMuted) {
      setFirstTimeMuted(false);
    }
  };

  const handleVolume = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    const isMuted = value === 0;

    setFirstTimeMuted(false);
    setVolume(value);
    player.setVolume(value / 100);
    setMuted(isMuted);
    player.setMuted(isMuted);
  };

  const checkIfWebKit = useCallback((): boolean => {
    const ua = navigator.userAgent.toLowerCase();

    if (
      ua.indexOf('chrome') === ua.indexOf('android') &&
      ua.indexOf('safari') !== -1
    ) {
      // accessed via a WebKit-based browser
      return true;
    }

    return (
      ua.indexOf('ipad') !== -1 ||
      ua.indexOf('iphone') !== -1 ||
      ua.indexOf('ipod') !== -1
    );
  }, []);

  const initPlayer = () => {
    if (!videoEl.current) return;
    if (!streamUrl) return;

    player.pause();
    player.attachHTMLVideoElement(videoEl.current);
    player.load(streamUrl);
    player.play();
    setTimeout(() => {
      if (videoEl.current) {
        videoEl.current.currentTime = playBackStartTime;
      }
    }, 500);
    player.setMuted(true);

    const vid = getElementById(elementIdPlayerVideo) as HTMLVideoElement;

    const webKitFullScreenChange = () => {
      const fullScreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;

      if (fullScreenElement) return;

      const exitFullScreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

      if (exitFullScreen) exitFullScreen.bind(document)();
      setFullScreen(false);
    };

    const webKitEndFullScreen = () => {
      // handle end full screen
      setTimeout(() => {
        player.setMuted(false);
        player.play();
      }, 1000);
    };

    document.addEventListener('webkitfullscreenchange', webKitFullScreenChange);

    if (vid) {
      vid.addEventListener('webkitendfullscreen', webKitEndFullScreen);
    }

    return () => {
      document.removeEventListener(
        'webkitfullscreenchange',
        webKitFullScreenChange,
      );
      if (vid)
        vid.removeEventListener('webkitendfullscreen', webKitEndFullScreen);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initPlayer, [player]);

  useEffect(() => {
    const interval = setInterval(() => {
      setStatus(player.getState());
    }, 500);

    return () => clearInterval(interval);
  }, [player]);

  useLayoutEffect(() => {
    let fullInterval: number | undefined;

    if (videoEl.current) {
      videoEl.current.onleavepictureinpicture = () =>
        setPictureInPicture(false);
      const isWebkit = checkIfWebKit();
      fullInterval = window.setInterval(() => {
        if (isWebkit && !document.webkitExitFullscreen && !pictureInPicture)
          setFullScreen(videoEl.current?.webkitDisplayingFullscreen || false);
      }, 500);
    }

    return () => {
      if (fullInterval) clearInterval(fullInterval);
    };
  }, [videoEl, checkIfWebKit, pictureInPicture]);

  useEffect(() => {
    if (
      !videoEl.current ||
      pictureInPicture ||
      getMobileOS() !== 'iOS' ||
      firstTimeMuted
    )
      return;

    const timeOut = setTimeout(() => {
      player.play();
      player.setMuted(false);
      handleHideOnInactivity();
    }, 300);

    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureInPicture]);

  useEffect(() => {
    if (!overlay) return;

    const timeout = setTimeout(() => {
      setInactive(true);
      setOverlay(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [overlay]);

  const handleMobileOptions = () => setShowOptions(prev => !prev);

  const handleVideoProgress = (e: ChangeEvent<HTMLInputElement>) => {
    if (!videoEl.current) return;
    const value = Number(e.target.value);
    setProgress(value);
    videoEl.current.currentTime = (videoEl.current?.duration / 100) * value;
  };

  const handleOnTimeUpdate = () => {
    if (!videoEl.current) return;
    const progress =
      (videoEl.current.currentTime / videoEl.current.duration) * 100;
    setProgress(progress);

    const newChat = chatHistory.filter(message => {
      return !!(
        videoEl.current?.currentTime &&
        message?.second !== undefined &&
        message?.second <= videoEl.current?.currentTime
      );
    });
    if (JSON.stringify(chat) !== JSON.stringify(newChat))
      handleSetChat(newChat);

    const currentHighlight = highlightHistory.find(
      (highlight: HightLightHistoryElement) => {
        return (
          videoEl.current &&
          videoEl.current?.currentTime >= highlight.joinSecond &&
          videoEl.current?.currentTime < highlight.outSecond
        );
      },
    );

    if (!currentHighlight?.productId) {
      if (currentHightLightProductId?.[0] !== undefined) {
        handleSetHightLight([]);
      }
      return;
    }

    const { data, version } = getCurrentHighlightCompatibilityVersions(
      currentHighlight.productId,
    );

    if (
      version === 1 &&
      typeof data === 'string' &&
      data !== currentHightLightProductId?.[0]
    )
      handleSetHightLight([data]);

    if (
      version === 2 &&
      Array.isArray(data) &&
      JSON.stringify(data) !== JSON.stringify(currentHightLightProductId)
    )
      handleSetHightLight(data);
  };

  const getCurrentHighlightCompatibilityVersions = (
    highlight: string,
  ): { version: number; data: string[] | string } => {
    const createResponse = (version_: number, data: string | string[]) => ({
      data,
      version: version_,
    });
    // version 1 highlight is string and in version 2 is string[]
    // this is because exist two version highlight and multiple highlight
    try {
      const highlightParse: string[] = JSON.parse(highlight);

      if (Array.isArray(highlightParse))
        return createResponse(2, highlightParse);

      return createResponse(1, highlight);
    } catch (error) {
      return createResponse(1, highlight);
    }
  };

  const handleHideOnInactivity = (isPaused?: boolean) => {
    if (timeToHideTimeout) {
      clearTimeout(timeToHideTimeout);
    }

    if (isPaused) return;

    const timeToHide = setTimeout(
      () => {
        setShowHoverOptions(false);
      },
      5000,
      [],
    );

    setTimeToHideTimeout(timeToHide);
  };

  const handleAdvanceTenSeconds = () => {
    if (!videoEl.current) return;
    if (!videoEl.current.currentTime) return;
    videoEl.current.currentTime = videoEl.current.currentTime + 10;
  };

  const handleBackTenSeconds = () => {
    if (!videoEl.current) return;
    if (!videoEl.current.currentTime) return;
    videoEl.current.currentTime = videoEl.current.currentTime - 10;
  };

  useEffect(() => {
    if (!videoEl.current) return;
    if (
      !videoEl.current.currentTime ||
      !videoEl.current.duration ||
      videoEl.current.duration === Infinity
    )
      return;

    const durationLessThanHour = videoEl.current.duration < 3600;
    const ct = new Date(videoEl.current.currentTime * 1000)
      .toISOString()
      .substring(durationLessThanHour ? 14 : 11, 19);
    const dtn = new Date(videoEl.current.duration * 1000)
      .toISOString()
      .substring(durationLessThanHour ? 14 : 11, 19);
    setCurrentTime(ct);
    setDuration(dtn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoEl.current?.currentTime]);

  return {
    BUFFERING,
    firstTimeMuted,
    fullScreen,
    handleFullScreen,
    handleFullScreenMobile,
    handleMainButton,
    handleMobileOptions,
    handleMute,
    handlePictureAndPicture,
    handleVolume,
    IDLE,
    inactive,
    muted,
    overlay,
    pictureInPicture,
    PLAYING,
    setInactive,
    setOverlay,
    showOptions,
    status,
    volume,
    progress,
    handleVideoProgress,
    handleOnTimeUpdate,
    showHoverOptions,
    setShowHoverOptions,
    handleHideOnInactivity,
    handleBackTenSeconds,
    handleAdvanceTenSeconds,
    currentTime,
    duration,
    hideMobileHoverCtrlsTimeout: timeToHideTimeout,
  };
};

export default usePlayerFunctions;
