import React from 'react';

declare interface Props {
  size: string;
  viewBox: string;
  isMobile?: boolean;
}

const PlayIcon = ({ size, viewBox, isMobile }: Props) => {
  return !isMobile ? (
    <svg
      fill="white"
      id="fi_3121571"
      height={size}
      viewBox={viewBox}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="play-grounded-live-streaming"
        transform="matrix(3.460674, 0, 0, 3.460674, 25.166292, 25.166292)"
      >
        <circle
          cx="50"
          cy="50"
          r="44.5"
          style={{
            fill: '#000000',
            fillOpacity: 0.5,
            stroke: 'none',
            strokeWidth: 0.398621,
          }}
        />
        <polygon
          points="36.77 71.25 36.77 29.15 73.65 50.2 36.77 71.25"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
      </g>
    </svg>
  ) : (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="57"
        height="57"
        rx="28.5"
        fill="#333333"
        fillOpacity="0.7"
        stroke="white"
      />
      <path
        d="M20.6519 44.8181L45.2579 28.9999L20.6519 13.1818V44.8181Z"
        fill="white"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlayIcon;
