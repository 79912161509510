import { createContext, useContext } from 'react';
import { ChatMessage } from 'types';

export interface ChatCtx {
  messageList: ChatMessage[];
  loadingMessages: boolean;
  deleteMessage: (message: ChatMessage, all?: any) => void;
  blockUser: (message: ChatMessage) => void;
  sendMessage: (message: string, chatName?: string) => void;
  pinMessage: (message: ChatMessage) => void;
  unPinMessage: () => void;
  notifyAdminName: (newUserName: string) => void;
}

export const chatCtxDefault: ChatCtx = {
  messageList: [],
  loadingMessages: false,
  deleteMessage: () => null,
  blockUser: () => null,
  sendMessage: () => null,
  pinMessage: () => null,
  unPinMessage: () => null,
  notifyAdminName: () => null,
};

export const ChatContext = createContext<ChatCtx>(chatCtxDefault);
export const useChat = () => useContext(ChatContext);
