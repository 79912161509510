interface SocialNetworkIconProps {
  name:
    | 'Facebook'
    | 'Twitter'
    | 'Whatssap'
    | 'Google'
    | 'Yahoo'
    | 'Outlook'
    | '';
  link: string;
  color: string;
  size: 24 | 16;
  containerSize: number;
  background: string;
  iconName:
    | 'facebook'
    | 'twitter'
    | 'whatsapp'
    | 'google'
    | 'yahoo'
    | 'microsoft-outlook';
}

export const SocialNetworkIcon = ({
  name,
  link,
  iconName,
  color,
  size,
  background,
  containerSize,
}: SocialNetworkIconProps) => {
  return (
    <a
        className="social-network-icon-link"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <i
          className={`mdi mdi-${iconName} mdi-${size}px social-network-icon`}
          style={{
            color,
            background,
            width: containerSize,
            height: containerSize,
          }}
        ></i>
        {name && <p className="social-network-icon-label">{name}</p>}
      </a>
  );
};
