import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledAlert } from 'reactstrap';
import { FetchStatus } from 'types';

export type ModalSaveStatus =
  | FetchStatus
  | 'hasRepeatedWords'
  | 'isRepeatedWord'
  | 'emptyWords';

interface Props {
  status: ModalSaveStatus;
  repeatDefaultWords: string[];
  repeatCustomWords: string[];
}

export const ModalAlert: FC<Props> = memo(props => {
  const { t } = useTranslation();
  const { status, repeatDefaultWords, repeatCustomWords } = props;
  const waitingStatus = ['idle', 'loading'].includes(status);
  const infoIcon = 'mdi mdi-information'

  const alert = useMemo(() => {
    if (waitingStatus) return null;

    switch (status) {
      case 'success':
        return {
          type: 'success',
          icon: 'mdi mdi-check-all',
          message: 'LiveShopping.BlockedWordList.Modal.SaveSuccessMessage',
        };
      case 'error':
        return {
          type: 'danger',
          icon: 'mdi mdi-block-helper',
          message: 'LiveShopping.BlockedWordList.Modal.SaveErrorMessage',
        };
      case 'hasRepeatedWords':
        return {
          type: 'warning',
          icon: infoIcon,
          message: 'LiveShopping.BlockedWordList.Modal.RepeatedWordsMessage',
        };
      case 'isRepeatedWord':
        return {
          type: 'warning',
          icon: infoIcon,
          message: 'LiveShopping.BlockedWordList.Modal.RepeatedWordMessage',
        };
      case 'emptyWords':
        return {
          type: 'warning',
          icon: infoIcon,
          message: 'LiveShopping.BlockedWordList.Modal.EmptyWordsMessage',
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return alert ? (
    <UncontrolledAlert
      color={alert.type}
      className="alert-dismissible fade show"
      role="alert"
    >
      <i className={`${alert.icon} me-2`}></i> {t(alert.message)}
      {status === 'isRepeatedWord' || status === 'hasRepeatedWords' ? (
        <div>
          <ul>
            {repeatDefaultWords.length ? (
              <li>
                {t('LiveShopping.BlockedWordList.TableDefaultTitle')}:{' '}
                {repeatDefaultWords.toString()}
              </li>
            ) : null}
            {repeatCustomWords.length ? (
              <li>
                {t('LiveShopping.BlockedWordList.TableAccountTitle')}:{' '}
                {repeatCustomWords.toString()}
              </li>
            ) : null}
          </ul>
        </div>
      ) : null}
    </UncontrolledAlert>
  ) : null;
});
