import { AddToCalendarBtn, LiveIcon, PlayIcon, Share } from 'app/components';
import { Card } from 'reactstrap';
import { GPEventType, Share as ShareType } from 'types';
import {
  EventCardContainer,
  EventCardFutureContainer,
  EventCardImage,
  EventCardInfo,
  EventCardInfoContainer,
  EventCardInfoShare,
  EventCardLiveIndicator,
  EventCardPlayButton,
  ParentCards,
} from './StyledEventCard';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';

const getDefaultImg = () => {
  const random = Math.floor(Math.random() * 1000);
  return `https://picsum.photos/280/158?random=${random}`;
};

interface Props {
  img: string;
  title: string;
  link: string;
  sharedLink: string;
  share: ShareType;
  eventType: GPEventType;
  date: string;
  category: string;
}

export const EventCard = ({
  img,
  title,
  link,
  sharedLink,
  share,
  eventType,
  date,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="event-card-container">
      {eventType !== 'future' ? (
        <Card className="event-card">
          <a className="event-card-link" href={link}>
            {eventType === 'live' && (
              <div className="event-card-live">
                <LiveIcon />
              </div>
            )}
            <div className="event-card-img-container">
              <img src={img} className="card-img-top img-fluid" alt={title} />
            </div>

            {eventType === 'past' && (
              <div className="event-card-play-button">
                <PlayIcon />
              </div>
            )}
          </a>

          <div className="card-body event-card-body">
            <div>
              <p className="event-card-title">{title}</p>
              <p className="fw-400 font-size-11">
                {t('GlobalPage.EventCard.LastTrends')}
              </p>
            </div>

            <div className="flex">
              <Share link={sharedLink} share={share} />
            </div>
          </div>
        </Card>
      ) : (
        <div className="event-card event-card-container-future-events">
          <div className="card-body event-card-body-future-events">
            <p className="event-card-title event-card-title-future-events">
              {title}
            </p>

            <div className="event-card-icon-container">
              <AddToCalendarBtn title={title} date={date} link={sharedLink} />
              <Share
                link={sharedLink}
                share={share}
                backgroundButton="#E31C58"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const EventCard2: FC<Props> = props => {
  const { title, link, sharedLink, share, eventType, date, category } = props;
  const { t } = useTranslation();
  const [img, setImg] = useState(props.img || getDefaultImg());

  return (
    <ParentCards>
      {eventType !== 'future' ? (
        <EventCardContainer>
          <a className="event-card-link" href={link}>
            <EventCardImage>
              {eventType === 'live' && (
                <EventCardLiveIndicator>
                  <LiveIcon />
                </EventCardLiveIndicator>
              )}
              <img
                src={img}
                className="card-img-top img-fluid"
                alt={title}
                onError={() => setImg(getDefaultImg())}
              />
              {eventType === 'past' && (
                <EventCardPlayButton>
                  <PlayIcon />
                </EventCardPlayButton>
              )}
            </EventCardImage>
          </a>

          <EventCardInfoContainer>
            <EventCardInfo>
              <p className="">
                {title.slice(0, 20)}
                {title.length > 20 ? '...' : ''}
              </p>
              <p className="font-size-11">
                {t(`GlobalPage.Category.${category}`)}
              </p>
            </EventCardInfo>

            <EventCardInfoShare>
              <Share link={sharedLink} share={share} />
            </EventCardInfoShare>
          </EventCardInfoContainer>
        </EventCardContainer>
      ) : (
        <EventCardFutureContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '15px',
              height: '100%',
            }}
          >
            <div style={{ width: '60%' }}>
              <div style={{ height: '70%' }}>
                <p
                  style={{
                    textAlign: 'left',
                    fontSize: '15px',
                    letterSpacing: '0px',
                    color: '#323845',
                    opacity: 1,
                    margin: 0,
                  }}
                >
                  {`${title.slice(0, 20)}${title.length > 20 ? '...' : ''}`}
                </p>
              </div>
              <div style={{ color: '#323845', fontWeight: 'bold' }}>
                <small>{t(`GlobalPage.Category.${category}`)}</small>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid black',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            ></div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ height: '60%' }}>
                <p
                  style={{
                    textAlign: 'left',
                    font: 'normal normal normal 15px/20px Segoe UI',
                    letterSpacing: '0px',
                    color: '#323845',
                    opacity: 1,
                    margin: 0,
                  }}
                >
                  {new Date(date).toLocaleDateString()}
                </p>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', height: '40%' }}
                className="event-card-icon-container"
              >
                <AddToCalendarBtn title={title} date={date} link={sharedLink} />
                <Share
                  link={sharedLink}
                  share={share}
                  backgroundButton="#E31C58"
                />
              </div>
            </div>
          </div>
        </EventCardFutureContainer>
      )}
    </ParentCards>
  );
};
