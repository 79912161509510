import clsx from 'clsx';
import { FC, memo } from 'react';
import { MenuItem as RPMenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';

type Item = {
  title: string;
  link?: string;
  icon?: string;
};

type Props = Item & {
  id: number;
  items?: Item[];
  open?: boolean;
  defaultOpen?: boolean;
  onClick?: (id: number) => void;
};

export const MenuItem: FC<Props> = memo(props => {
  const {
    id,
    items = [],
    title,
    icon,
    link,
    onClick,
    defaultOpen = false,
    // open = false,
  } = props;
  const { pathname } = useLocation();

  const renderMenuItem = (item: Item) => (
    <RPMenuItem
      key={item.title}
      icon={item.icon && <i className={item.icon} />}
      onClick={() => onClick && onClick(id)}
      component={
        <Link
          to={item.link || '/underdevelopment'}
          className={clsx(pathname === item.link && 'active')}
        />
      }
    >
      {item.title}
    </RPMenuItem>
  );

  return items.length ? (
    <SubMenu
      // open={open}
      label={title}
      defaultOpen={defaultOpen}
      icon={icon && <i className={icon} />}
      onClick={() => onClick && onClick(id)}
      className={clsx(defaultOpen && 'active')}
    >
      {items.map(renderMenuItem)}
    </SubMenu>
  ) : (
    renderMenuItem({ title, icon, link })
  );
});
