/**
 * Asynchronously loads the components
 */

import { FC, lazy, LazyExoticComponent, Suspense } from 'react';
import { PayButtonProps } from './components/PlanDetail';

type Props = {
  type: 'stripe' | 'external';
};

export const ConditionalBilling: FC<Props> = ({ type }) => {
  const Billing = lazy(() =>
    import('./Page').then(m => ({ default: m.Billing })),
  );
  let PlanDetail: LazyExoticComponent<FC<PayButtonProps>>;

  if (type === 'stripe') {
    PlanDetail = lazy(() =>
      import('./components/StripePlanDetail').then(m => ({
        default: m.StripePlanDetail,
      })),
    );
  } else {
    PlanDetail = lazy(() =>
      import('./components/ExternalPlanDetail').then(m => ({
        default: m.ExternalPlanDetail,
      })),
    );
  }

  return (
    <Suspense fallback={<p>Loading Billing....</p>}>
      <Billing PlanDetail={PlanDetail} />
    </Suspense>
  );
};
