import styled from 'styled-components';

export const ParentCards = styled.div`
  width: 25%;
  position: relative;
  @media screen and (max-width: 1920px) {
    min-width: 316px;
  }

  @media screen and (max-width: 1366px) {
    min-width: 280px;
  }

  @media screen and (max-width: 600px) {
    min-width: 200px;
    width: 100%;
  }
`;

export const EventCardContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media screen and (max-width: 1920px) {
    width: 316px;
  }

  @media screen and (max-width: 1366px) {
    width: 280px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const EventCardImage = styled.div`
  flex: 4;
  display: flex;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  max-height: 158px;
  min-height: 158px;

  img {
    height: 100%;
    border-radius: 0;
    display: flex;
    justify-content: center;
    object-fit: cover;
  }
`;

export const EventCardPlayButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EventCardLiveIndicator = styled.div`
  position: absolute;
  top: 12px;
  left: 8px;
`;

export const EventCardInfoContainer = styled.div`
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 0 0 10px 10px;
`;

export const EventCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: left;
  padding: 15px;
  height: 70px;

  p {
    padding: 0;
    margin: 0;
  }
`;

export const EventCardInfoShare = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EventCardFutureContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  max-width: 316px;
  width: 316px;
  height: 100px;
  margin-bottom: 15px;

  @media screen and (max-width: 1920px) {
    width: 316px;
  }

  @media screen and (max-width: 1366px) {
    width: 280px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
`;
