import { FetchStatus, Question, QuestionDTO } from 'types';
import { createContext, useContext, MouseEvent } from 'react';
import { Observable } from 'shared';

export type QModalType = 'detail' | 'add' | 'edit' | 'confirm' | null;

export interface QuestionCtx {
  questionList: Question[];
  loadingQuestions: boolean;
  modalType: QModalType;
  modalIsOpen: boolean;
  selectedQuestion: Question | null;
  sendStatus: Record<string, FetchStatus>;
  deleteStatus: Record<string, FetchStatus>;
  saveStatus: FetchStatus;
  modalSaveBtnClickEvents: Observable<MouseEvent | null>;
  setSelectedQuestion: (word: Question | null) => void;
  fetchQuestionList: () => void;
  openModal: (value: boolean) => void;
  openModalFor: (type: QModalType) => void;
  setModalType: (type: QModalType) => void;
  sendQuestion: (question: Question) => void;
  deleteQuestion: (index: string) => void;
  saveQuestion: (question: QuestionDTO, index?: string) => void;
}

export const questionCtxDefault: QuestionCtx = {
  questionList: [],
  loadingQuestions: false,
  modalType: null,
  modalIsOpen: false,
  selectedQuestion: null,
  sendStatus: {},
  deleteStatus: {},
  saveStatus: 'idle',
  modalSaveBtnClickEvents: null as any,
  fetchQuestionList: () => null,
  openModal: () => null,
  openModalFor: () => null,
  setModalType: () => null,
  setSelectedQuestion: () => null,
  sendQuestion: () => null,
  deleteQuestion: () => null,
  saveQuestion: () => null,
};

export const QuestionContext = createContext<QuestionCtx>(questionCtxDefault);
export const useQuestion = () => useContext(QuestionContext);
