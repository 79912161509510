export const LOCALES = {
  es: 'es',
  en: 'en',
  pt: 'pt',
};

export enum languagesKeys {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
}

export type LanguagesType = {
  [c in languagesKeys]: string;
};

export type LanguagesCustomLabelType = {
  [c in languagesKeys]: keyof typeof languagesKeys;
};

export const languages = [...Object.values(languagesKeys)];

export const customLabels: LanguagesCustomLabelType = {
  en: 'EN',
  es: 'ES',
  pt: 'PT',
};

export const assignedTranslateValues = (values: LanguagesType) =>
  JSON.stringify(values);

export const getTranslatedText = (
  messages: string | Record<string, any>,
): string => {
  try {
    const locate = window.navigator.language.trim().split(/-|_/)[0];
    const getMessageLocate = (msg: Record<string, any>) =>
      msg?.[locate] || msg?.['en'];
    if (typeof messages === 'string')
      return getMessageLocate(JSON.parse(messages));

    return getMessageLocate(messages);
  } catch (error) {
    return '';
  }
};

export const getLocale = () => {
  const languageBrowser = window.navigator.language.trim().split(/-|_/)[0];
  const languageAvailable = [LOCALES.en, LOCALES.es, LOCALES.pt];
  return languageAvailable.includes(languageBrowser)
    ? languageBrowser
    : LOCALES.en;
};
