/* eslint-disable react-hooks/exhaustive-deps */
import { PageSpinner } from 'app/components';
import errorImage from 'assets/images/error-img.png';
import { useAccount, useGlobalPage } from 'hooks';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { GPEventType, GlobalPageEvent } from 'types';
import { EventsContainer } from '../styles';
import { EventCard2 } from './EventCard';
import EventCategoryList from './EventCategory';
import EventList from './EventList';

export const Events = memo(() => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const {
    eventCategoryList,
    pastEventList,
    nowEventList,
    futureEventList,
    pastEventListQuery,
    nowEventListQuery,
    futureEventListQuery,
    eventLoadResult,
  } = useGlobalPage();

  const {
    accountInfo: { account },
    eventAccountParams: eventAccount,
  } = useAccount();

  const getEventsCards = useCallback(
    (eventType: GPEventType, events: GlobalPageEvent[]) => {
      const cards = events.map((item, index) => {
        let account = item.account;

        if (
          eventAccount === 'account' ||
          eventAccount === undefined ||
          eventAccount === null
        ) {
          account = 'account';
        }

        const link = `/global-page/${account}/${item.id}${search}`;
        const sharedLink = `${window.location.origin}${link}`;

        const share = {
          facebook: `${item.share.facebook}${sharedLink}`,
          twitter: `${item.share.twitter}${sharedLink}`,
          whatsapp: `${item.share.whatsapp}${sharedLink}`,
        };
        return (
          <EventCard2
            key={`${item.eventName}_${index}`}
            title={item.eventName}
            img={item.thumbnail}
            share={share}
            link={link}
            sharedLink={sharedLink}
            eventType={eventType}
            date={item.date}
            category={item.eventCategory}
          />
        );
      });

      return <EventsContainer>{cards}</EventsContainer>;
    },
    [search, eventAccount],
  );

  const pastEventsCards = useMemo(
    () => getEventsCards(GPEventType.PAST, pastEventList),
    [pastEventList, getEventsCards],
  );

  const nowEventsCards = useMemo(
    () => getEventsCards(GPEventType.LIVE, nowEventList),
    [nowEventList, getEventsCards],
  );

  const futureEventsCards = useMemo(
    () => getEventsCards(GPEventType.FUTURE, futureEventList),
    [futureEventList, getEventsCards],
  );

  const error = useMemo(
    () => (
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 font-weight-medium mb-4">
                <span role="img" aria-label="emojis">
                  😅
                </span>
              </h1>
              <h4 className="text-uppercase">
                {t('GlobalPage.Carousel.EmptyEvents')} "
                <b>{account || eventAccount}</b>"
              </h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={errorImage} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    ),
    [account, eventAccount],
  );

  const lists = (
    <>
      <EventCategoryList list={eventCategoryList} />

      <EventList
        title="GlobalPage.Carousel.NowEvents.Title"
        eventList={nowEventList}
        eventCards={nowEventsCards}
        eventListQuery={nowEventListQuery}
      />

      <EventList
        title="GlobalPage.Carousel.PastEvents.Title"
        eventList={pastEventList}
        eventCards={pastEventsCards}
        eventListQuery={pastEventListQuery}
      />

      <EventList
        title="GlobalPage.Carousel.FutureEvents.Title"
        eventList={futureEventList}
        eventCards={futureEventsCards}
        eventListQuery={futureEventListQuery}
      />
    </>
  );

  return eventLoadResult === 'success' ? (
    lists
  ) : eventLoadResult === 'error' ? (
    error
  ) : (
    <PageSpinner />
  );
});
