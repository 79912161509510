import { GetTimeLineService } from './domain';
import { TimelineRepository } from './domain';
import { TimelineGraphqlRepository } from './infrastructure';

const timelineRepository: TimelineRepository = new TimelineGraphqlRepository();
const getTimeLineService = new GetTimeLineService(timelineRepository);

export { getTimeLineService };

export * from './domain';

export * from './blockedWords';
export * from './events';
export * from './questions';
