/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  BackgroundType,
  HighlightProductItem,
  HighlightProductSocketActions,
  HighlightProductStorage,
} from 'types';
import useLocalStorage from 'use-local-storage';
import { useEventDetail } from '../eventDetail';
import {
  HighlightProductContext,
  HighlightProductCtx,
} from './highlightProductContext';

interface Props {
  consumer?: (context: HighlightProductCtx) => React.ReactNode;
}

export const HighlightProductProvider: FC<Props> = ({ children, consumer }) => {
  const { eventId, eventSocket, isOffline, isTransmitting, eventData  } = useEventDetail();
  const collectionId = eventData?.collection?.id
  const [highlightProduct, setHighlightProduct] =
    useLocalStorage<HighlightProductStorage>('highlightProduct', {});

  const keyStorageHighlight =  useMemo(() => `${eventId}:${collectionId}`, [eventId, collectionId])

  const backgroundSelected =
    highlightProduct?.[keyStorageHighlight]?.backgroundSelected ?? 'white';
  const productsSelected = highlightProduct?.[keyStorageHighlight]?.products ?? [];

  const updateHighlightProduct = (data: Partial<HighlightProductItem>) => {
    const highlightProductTemp = { ...highlightProduct };
    highlightProductTemp[keyStorageHighlight] = {
      ...highlightProductTemp[keyStorageHighlight],
      ...data,
    };
    setHighlightProduct(highlightProductTemp);
  };

  const sendHighlightProduct = (productsId: string[]) => {
    eventSocket.sendMessage({
      action: HighlightProductSocketActions.SEND,
      products: productsId,
      livestreamingId: eventId,
      showProduct: true,
    });
  };

  const setBackgroundSelected = useCallback(
    (backgroundSelected: BackgroundType) => {
      updateHighlightProduct({ backgroundSelected });

      eventSocket.sendMessage({
        action: HighlightProductSocketActions.SEND,
        products: productsSelected,
        backgroundWhiteHighlight: backgroundSelected,
      });
    },
    [highlightProduct, eventSocket, productsSelected],
  );

  const setProductsSelected = useCallback(
    (products: string[]) => {
      updateHighlightProduct({ products });
      sendHighlightProduct(products);
    },
    [highlightProduct, eventSocket],
  );

  useEffect(() => {
    if (isOffline) {
      const highlightProductTemp = { ...highlightProduct };
      Object.keys(highlightProductTemp).forEach((keyStorage) => {
        if (keyStorage.includes(eventId)) delete highlightProductTemp[keyStorage];
      })
      setHighlightProduct(highlightProductTemp);
    }
  }, [isOffline]);

  useEffect(() => {
    if (isTransmitting) {
      setBackgroundSelected(backgroundSelected);
    }
  }, [isTransmitting, backgroundSelected]);

  const context: HighlightProductCtx = {
    backgroundSelected,
    productsSelected,
    setBackgroundSelected,
    setProductsSelected,
  };

  return (
    <HighlightProductContext.Provider value={context}>
      {consumer ? consumer(context) : children}
    </HighlightProductContext.Provider>
  );
};
