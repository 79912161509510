import { IAggregateRoot } from '@nizza/core';

export interface CustomizePropsPrimitives {
  id: string;
  section: string;
  account: string;
  data: object;
}
export class CustomizeComponentDomain
  implements IAggregateRoot, CustomizePropsPrimitives
{
  id!: string;
  account!: string;
  section!: string;
  data!: object;

  constructor(props: CustomizePropsPrimitives) {
    Object.assign(this, props);
  }

  static fromPrimitives(props: CustomizePropsPrimitives) {
    return new CustomizeComponentDomain(props);
  }

  toPrimitives(): CustomizePropsPrimitives {
    return {
      ...Object.assign({}, this),
    };
  }
}
