import { CreateEventDTO, UpdateEventDTO } from 'types';
import * as yup from 'yup';

/*const timeZone = yup
  .object({
    country: yup.string().required(),
    included: yup.array(yup.string().required()),
    label: yup.string().required(),
    offset: yup.string().required(),
    offsetValue: yup.number().required(),
    value: yup.string().required(),
  })
  .defined();

const eventCategory = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
  })
  .defined();*/

const baseSchema = {
  eventName: yup.string().required(),
  utm: yup.string().required(),
  eventDateTime: yup
    .string()
    .optional()
    .nullable()
    .transform((_, originalValue: string) => {
      return originalValue ? `${originalValue}:00` : null;
    }),
  collection: yup
    .object({
      id: yup.string().optional(),
      source: yup.string().optional(),
    })
    .optional(),
  eventLink: yup.string().optional(),
  account: yup.string().notRequired(),
};

export const createEventSchema: yup.Schema<
  Omit<CreateEventDTO, 'timeZone' | 'eventCategory'>
> = yup.object(baseSchema).defined();

export const updateEventSchema: yup.Schema<UpdateEventDTO> =
  createEventSchema as yup.Schema<UpdateEventDTO>;
