/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncDashboard = lazyLoad(
  () => import('./Page'),
  module => module.Dashboard,
);
