export type AppFeatures =
  | 'minutesAvailable'
  | 'globalPage'
  | 'promotions'
  | 'videoRecording'
  | 'viewers';

export type AppFeaturesWithLimit = Exclude<
  AppFeatures,
  'globalPage' | 'promotions'
>;

export interface DetailFeatures {
  blocked: boolean;
  limit?: number;
  per?: string;
  consumed?: number;
  operation?: string;
  additionalCost?: {
    unit: string;
    value: number;
  };
}

export type ConfigPlan = {
  plan: string;
  editable: boolean;
  basic: boolean;
  label?: string;
  contractStatus?: string;
  feature: Record<AppFeatures, DetailFeatures>;
};

export enum PlanType {
  PLAN_LITE = 'PLAN_LITE',
  PLAN_ESTANDAR = 'PLAN_ESTANDAR',
  PLAN_PRO = 'PLAN_PRO',
}
