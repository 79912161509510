import { useBlockedWordList } from 'hooks';
import { pipe } from 'ramda';
import { FC, memo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';

export type ModalFormInputs = {
  word: string;
};

type ModalFormProps = UseFormReturn<ModalFormInputs> & {
  onSubmit: (data: ModalFormInputs) => void;
};

export const ModalForm: FC<ModalFormProps> = memo(props => {
  const { modalType, selectedWord } = useBlockedWordList();
  const { t } = useTranslation();
  const { register, formState, handleSubmit } = props;
  const { ref: wordRef, ...wordRest } = register('word', {
    value: selectedWord?.value || '',
  });
  const { errors } = formState;
  const onSubmit = pipe(handleSubmit(props.onSubmit), () => false);

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="word">
          {t(`LiveShopping.BlockedWordList.Modal.InputLabel`)}
        </Label>
        <InputGroup className="align-items-center">
          <Input
            id="word"
            invalid={!!errors.word}
            innerRef={wordRef}
            autoComplete="off"
            {...wordRest}
          />
          {modalType === 'add' && (
            <InputGroupAddon addonType="append">
              <Button color="secondary">
                {t(`LiveShopping.BlockedWordList.Modal.AddButton`)}
              </Button>
            </InputGroupAddon>
          )}
          <FormFeedback>{errors.word?.message}</FormFeedback>
        </InputGroup>
      </FormGroup>
    </Form>
  );
});
