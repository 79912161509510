import { Logger } from 'loglevel';
import { GlobalStore } from '../nizza';

export enum RuntimeType {
  Local = 'local',
  VtexLegacy = 'vtexlegacy',
  VtexIO = 'vtexio',
  VtexFastStore = 'faststore',
  External = 'external',
}

export enum RuntimeStage {
  PROD = 'prod',
  DEV = 'dev',
  LOCAL = 'local',
}

export type RuntimeCallback<Args extends any[] = [], R = void> = (
  ...args: Args
) => Promise<R> | R;

export type RuntimeBundler = {
  isProd: boolean;
  stage: RuntimeStage;
};

export type RuntimeManagerConfig<T extends GlobalStore = GlobalStore> = {
  globalId: string;
  getStore: () => T;
  logger: Logger;
  env: Record<string, any>;
  bundler: RuntimeBundler;
};

export type ModuleId = {
  value: string;
  formatted: string;
};

export type RenderProps = Record<string, any>;

export type RenderResponse =
  | {
      element: any;
      unmount: () => void;
      updateProps: (props: RenderProps) => void;
    }
  | undefined;

export type RootId = string;

export type RenderHandler = RuntimeCallback<
  [RootId, RenderProps],
  RenderResponse
>;

export type GlobalComponent = {
  render: RenderHandler;
};

export type GlobalModuleList = Record<string, GlobalComponent>;

export type GlobalObject<T extends GlobalStore> = GlobalModuleList & {
  store: T;
  runtime: Record<string, any>;
  core: Record<string, any>;
  utils: Record<string, any>;
};
