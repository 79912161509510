import { csx } from '@vtex/admin-ui';
import { FC } from 'react';

interface Props {
  username: string;
}

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const ChatUsername: FC<Props> = ({ username }) => {
  const name = capitalizeFirstLetter(username?.replace('_admin', ''));

  return (
    <h2 className={csx({ fontSize: 16, fg: 'gray50', fontWeight: 600 })}>
      {name}
    </h2>
  );
};
