import React from 'react';

const BackTenSeconds = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="52.244"
      viewBox="0 0 38 52.244"
    >
      <g
        id="Grupo_3"
        data-name="Grupo 3"
        transform="translate(-897.244 -587.756)"
      >
        <g id="Grupo_2" data-name="Grupo 2">
          <path
            id="Trazado_5"
            data-name="Trazado 5"
            d="M2.977,50H4.295V41.545H2.982L.756,43.127v1.289L2.883,42.9h.094Zm6.7.205a2.658,2.658,0,0,0,1.676-.539,3.393,3.393,0,0,0,1.072-1.529,6.607,6.607,0,0,0,.381-2.361v-.012a6.559,6.559,0,0,0-.381-2.355,3.393,3.393,0,0,0-1.072-1.529,2.658,2.658,0,0,0-1.676-.539A2.658,2.658,0,0,0,8,41.879a3.393,3.393,0,0,0-1.072,1.529,6.652,6.652,0,0,0-.375,2.355v.012a6.7,6.7,0,0,0,.375,2.361A3.393,3.393,0,0,0,8,49.666,2.658,2.658,0,0,0,9.674,50.205Zm0-1.1a1.319,1.319,0,0,1-.967-.4,2.671,2.671,0,0,1-.615-1.149,6.629,6.629,0,0,1-.211-1.781v-.012a6.564,6.564,0,0,1,.211-1.775,2.623,2.623,0,0,1,.615-1.143,1.358,1.358,0,0,1,1.934,0,2.545,2.545,0,0,1,.615,1.143,6.4,6.4,0,0,1,.217,1.775v.012a6.461,6.461,0,0,1-.217,1.781,2.591,2.591,0,0,1-.615,1.149A1.307,1.307,0,0,1,9.674,49.109Z"
            transform="translate(906.244 587.756)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_3"
            data-name="Rectángulo 3"
            width="37"
            height="37"
            rx="18.5"
            transform="translate(934.744 625.256) rotate(180)"
            fill="rgba(51,51,51,0.7)"
            stroke="#fff"
            strokeWidth="1"
          />
          <path
            id="Trazado_6"
            data-name="Trazado 6"
            d="M17.823,12.073,8.917,19l8.906,6.927Z"
            transform="translate(896.244 587.756)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Trazado_7"
            data-name="Trazado 7"
            d="M28.708,12.073,19.8,19l8.906,6.927Z"
            transform="translate(896.244 587.756)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <text
            id="s"
            transform="translate(921 638)"
            fill="#fff"
            fontSize="9"
            fontFamily="SegoeUI, Segoe UI"
          >
            <tspan x="0" y="0">
              s
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export default BackTenSeconds;
