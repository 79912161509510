/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import { getEvent } from 'api';
import { FC, memo, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  EventSocketActions,
  EventStatus,
  LiveShoppingEvent,
  OriginOfProducts,
  StreamStatus,
  ChatMessage,
} from 'types';
import useLocalStorage from 'use-local-storage';
import { useFetchEventStatus } from '../useFetchEventStatus';
import { useSocket } from '../useSocket';
import { EventDetailContext, EventDetailCtx } from './eventDetailContext';
import { useAccount } from 'hooks';
import {
  getOriginOfProducts,
  editableSettings as defaultSettings,
} from 'shared';

type PinnedMessageStorage = Record<string, ChatMessage | null>;

interface Props {
  eventId: string;
  consumer?: (context: EventDetailCtx) => React.ReactNode;
  children?: React.ReactNode;
}

const STATUS_UPDATE_TIMEOUT = 5000;

export const EventDetailProvider: FC<Props> = memo(props => {
  const { eventId, consumer, children } = props;
  const {
    accountInBase64,
    accountInfo: { account, contractType },
  } = useAccount();
  const { eventStatus, loadingStatus, changeStatus, setEventStatus } =
    useFetchEventStatus(eventId, accountInBase64);

  const {
    isLoading: loadingEventData,
    data: eventData,
    refetch,
  } = useQuery<LiveShoppingEvent>(
    ['eventData', eventId],
    () => getEvent(eventId, accountInBase64),
    {
      enabled: !!accountInBase64,
      refetchInterval:
        eventStatus === EventStatus.LIVE ? STATUS_UPDATE_TIMEOUT : false,
    },
  );

  const { send, onMessage, isConnected, pin, unPin } = useSocket(
    eventData?.config.webClient.streamWSS,
    'Event',
  );

  const [transmissionType, setTransmitionType] = useState(
    eventData?.config?.webClient?.transmitionType ?? 'horizontal',
  );
  const [isLive, setIsLive] = useState(false);
  const [isTransmitting, setIsTransmitting] = useState<boolean | null>(null);
  const [isTransmittingOld, setIsTransmittingOld] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<ChatMessage | null>(
    null,
  );

  const [pinnedMessageStorage, setPinnedMessageStorage] =
    useLocalStorage<PinnedMessageStorage>('pinnedMessage', {
      [eventId]: null,
    });

  const pinnedMessage = pinnedMessageStorage?.[eventId] ?? null;

  const [isOffline, setIsOffline] = useState(false);
  const [originOfProducts, setOriginOfProducts] = useState<
    '' | OriginOfProducts
  >('');
  const [settings, setSettings] = useState(defaultSettings);
  const [endInProgress, setEndInProgress] = useState(false);

  const sendTransmissionType = (type?: string) => {
    send({
      action: EventSocketActions.SET_TRANSMISSION_TYPE,
      data: type ?? transmissionType,
    });
  };

  const setTransmissionType = (type: string) => {
    setTransmitionType(type);
    sendTransmissionType(type);
  };

  const setPinnedMessage = (message: ChatMessage | null) => {
    setPinnedMessageStorage({ ...pinnedMessageStorage, [eventId]: message });
  };

  useEffect(() => {
    if (!eventData?.config?.webClient?.transmitionType) return;
    setTransmitionType(eventData?.config?.webClient?.transmitionType);
  }, [eventData?.config?.webClient?.transmitionType]);

  useEffect(() => {
    if (!eventData?.status) return;
    setEventStatus(eventData?.status);
  }, [eventData?.status]);

  useEffect(() => {
    if (isTransmittingOld && !isTransmitting) {
      setEndInProgress(true);
      // this time is necesary for disable toggle event
      // if bundle app finally event
      setTimeout(() => setEndInProgress(false), 35000);
    }
  }, [isTransmitting, isTransmittingOld]);

  useEffect(() => {
    const status = eventStatus;

    if (status) {
      switch (status) {
        case EventStatus.LIVE:
          refetch();
          setIsLive(true);
          setIsOffline(false);
          break;

        case EventStatus.FINALIZED:
          setIsLive(false);
          setIsOffline(true);
          setTimeout(() => {
            setIsTransmittingOld(!!isTransmitting);
            setIsTransmitting(false);
          }, 500);
          break;

        default:
          setIsLive(false);
          setIsOffline(false);
          break;
      }
    }
  }, [eventStatus]);

  useEffect(() => {
    if (!contractType) return;
    setOriginOfProducts(getOriginOfProducts(contractType));
  }, [contractType]);

  useEffect(() => {
    if (!eventData || !eventData.config.webClient) return;

    const {
      config: { webClient },
    } = eventData;

    const objectSetting = {
      cmsCompatibility:
        webClient?.cmsCompatibility ?? defaultSettings.cmsCompatibility,
      inactiveSidebarProducts:
        webClient?.showProductsSidebar ??
        defaultSettings.inactiveSidebarProducts,
      inactiveProductsCarousel:
        webClient?.showProductsCarousel ??
        defaultSettings.inactiveProductsCarousel,
      inactivateChat: webClient?.showChat ?? defaultSettings.inactivateChat,
      inactivateLike: webClient?.showLike ?? defaultSettings.inactivateLike,
      inactivateViewers:
        webClient?.showCounter ?? defaultSettings.inactivateViewers,
      isInfinite: webClient?.infiniteScroll ?? defaultSettings.isInfinite,
      pdp: webClient?.redirectToPdp ?? defaultSettings.pdp,
      time: webClient?.scrollTime ?? defaultSettings.time,
    };

    setSettings(objectSetting);
  }, [eventData?.config?.webClient]);

  useEffect(() => {
    if (eventData?.streamData.state) {
      setIsTransmittingOld(!!isTransmitting);
      setIsTransmitting(eventData.streamData.state === StreamStatus.LIVE);
    }

    if (eventData?.status) {
      setIsLive(eventData.status === EventStatus.LIVE);
    }
  }, [eventData?.streamData?.state, eventData?.status]);

  const context: EventDetailCtx = {
    eventId,
    eventStatus,
    loadingStatus,
    eventData: eventData as LiveShoppingEvent,
    loadingEventData: accountInBase64 ? loadingEventData : true,
    endInProgress,
    isLive,
    isOffline,
    isTransmitting,
    transmissionType,
    changeStatus,
    setTransmissionType,
    eventSocket: {
      isConnected,
      sendMessage: send,
      pinMessage: pin,
      unPinMessage: unPin,
      onMessage,
    },
    account,
    originOfProducts,
    setSettings,
    settings,
    setSelectedMessage,
    selectedMessage,
    pinnedMessage,
    setPinnedMessage,
    refetch,
  };

  return (
    <EventDetailContext.Provider value={context}>
      {consumer ? consumer(context) : children}
    </EventDetailContext.Provider>
  );
});
