/* eslint-disable react-hooks/exhaustive-deps */
import { Product } from '@nizza/event-products';
import Fuse from 'fuse.js';
import { useHighlightProduct, useProductList } from 'hooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Input, ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import { ProductItem } from './ProductItem';

export const ProductList = () => {
  const { t } = useTranslation();
  const { productList, loadingProducts } = useProductList();
  const { productsSelected } = useHighlightProduct();

  const [search, setSearch] = useState('');
  const fuseProductList = new Fuse(productList, { keys: ['title'] });

  const products: Product[] = useMemo(() => {
    return search
      ? fuseProductList.search(search).map(x => x.item)
      : productList;
  }, [productList, search]);

  const listHighlight = useMemo(() => {
    const filterProducts = productList.filter(p =>
      productsSelected.includes(p.id),
    );
    return { data: filterProducts, show: !!filterProducts.length };
  }, [productList, productsSelected]);

  return (
    <div>
      {loadingProducts ? (
        <Spinner color="primary" />
      ) : !productList.length ? (
        <h5 className="text-secondary mt-4">
          {t('ProductCollections.Products.Empty')}
        </h5>
      ) : (
        <div>
          <p className="mb3 c-muted-2 fs-6 fw-lighter">
            {t('LiveShopping.HighlightProduct.AlertInfoMultipleHighlight')}
          </p>
          <div className="search-box chat-search-box py-4">
            <div className="position-relative">
              <Input
                type="text"
                placeholder={t(
                  'LiveShopping.HighlightProduct.SearchPlaceholder',
                )}
                onChange={x => setSearch(x.target.value)}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{ maxHeight: '30vh' }}
          >
            <ListGroup flush>
              {listHighlight.data.map(x => (
                <ListGroupItem className="bg-light" key={x.id}>
                  <ProductItem data={x} />
                </ListGroupItem>
              ))}
            </ListGroup>
          </PerfectScrollbar>
          <h5 className="mb-3 mt-3">
            {t('LiveShopping.HighlightProduct.ListTitle')}
          </h5>

          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{ maxHeight: '30vh' }}
          >
            <ListGroup flush>
              {products.map(x => (
                <ListGroupItem key={x.id}>
                  <ProductItem data={x} />
                </ListGroupItem>
              ))}
            </ListGroup>
          </PerfectScrollbar>
        </div>
      )}
    </div>
  );
};
