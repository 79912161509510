const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (window?.liveshopping?.customizeDeviceType)
    return window.liveshopping.customizeDeviceType as string;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
    return 'Android';
  }

  return 'unknown';
};

const getDeviceType = () => {
  if (window.innerWidth <= 993) return 'mobile';
  return 'desktop';
};

const getDeviceTypeStrict = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (window?.liveshopping?.customizeDevice)
    return window.liveshopping.customizeDevice;

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      userAgent,
    ) ||
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0
  ) {
    return 'mobile';
  }

  return 'desktop';
};

const isAppleDevice = () => {
  const isIOs = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
  const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
  const isMac = navigator.userAgent.indexOf('Mac OS X') !== -1;

  return isIOs || isSafari || isMac;
};

export { getMobileOS, getDeviceType, isAppleDevice, getDeviceTypeStrict };
