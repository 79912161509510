import { AccountCurrencyConfig, IvsChatClientSocket, useIVSChatClient } from '@nizza/core';
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useWebSocket } from '../hooks';
import { InfoSocket, PromotionTrigger } from '../types';

type AlertMessage = {
  type: 'error' | 'success' | 'info' | 'warning';
  value: string;
};

type SettingCtx = {
  collectionId?: string;
  productsInBD?: any[];
  infoSocket?: InfoSocket;
  ivsChatSocket?: IvsChatClientSocket;
  isModalLive: boolean | undefined;
  widthPlayer: number;
  alertMessage: AlertMessage | null;
  setIsModalLive: (isModalLive: boolean) => void;
  wssStream?: string;
  showCarouselChat?: boolean;
  showCarouselChatButton?: boolean;
  setAlertMessage: (message: AlertMessage | null) => void;
  setShowCarouselChat?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCarouselChatButton?: React.Dispatch<React.SetStateAction<boolean>>;
  setActivePromo?: React.Dispatch<
    React.SetStateAction<PromotionTrigger | undefined>
  >;
  setWidthPlayer?: React.Dispatch<React.SetStateAction<number>>;
  activePromo?: PromotionTrigger;
  setUpdateLivestreaming?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  updateLivestreaming?: string;
  setCollection?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setProductsInBD?: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  utm?: string;
  setUTM?: React.Dispatch<React.SetStateAction<string | undefined>>;
  accountCurrencyConfig?: AccountCurrencyConfig;
  setAccountCurrencyConfig?: React.Dispatch<
    React.SetStateAction<AccountCurrencyConfig | undefined>
  >;
  variationThumbnailName?: string;
  children?: ReactNode;
  idLivestreaming: string;
  salesChannelId?: string;
  setSalesChannel?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const settingDefault: SettingCtx = {
  isModalLive: false,
  widthPlayer: 0,
  showCarouselChat: false,
  alertMessage: null,
  setIsModalLive: () => null,
  setShowCarouselChatButton: () => null,
  setActivePromo: () => null,
  setCollection: () => null,
  setProductsInBD: () => null,
  setAlertMessage: () => null,
  setUTM: () => null,
  idLivestreaming: '',
  setSalesChannel: () => null,
};

export const SettingContext = createContext<SettingCtx>(settingDefault);

export const useSettings = () => useContext(SettingContext);

export const SettingProvider: FC<SettingCtx> = ({
  collectionId,
  productsInBD,
  children,
  isModalLive,
  setIsModalLive,
  wssStream,
  utm,
  variationThumbnailName,
  idLivestreaming,
  salesChannelId,
}) => {
  const infoSocket = useWebSocket({ wssStream });
  const ivsChatSocket = useIVSChatClient({
    eventId: idLivestreaming,
    userName: `${idLivestreaming}-${uuidv4()}`,
  });
  const [showCarouselChat, setShowCarouselChat] = useState(true);
  const [showCarouselChatButton, setShowCarouselChatButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);
  const [activePromo, setActivePromo] = useState<PromotionTrigger>();
  const [updateLivestreaming, setUpdateLivestreaming] = useState<string>();
  const [collection, setCollection] = useState(collectionId);
  const [salesChannel, setSalesChannel] = useState(salesChannelId);
  const [products, setProductsInBD] = useState(productsInBD);
  const [utmText, setUTMText] = useState(utm);
  const [widthPlayer, setWidthPlayer] = useState<number>(0);
  const [accountCurrencyConfig, setAccountCurrencyConfig] =
    useState<AccountCurrencyConfig>();

  useEffect(() => {
    setUTMText(utm);
  }, [utm]);

  useEffect(() => {
    setSalesChannel(salesChannelId);
  }, [salesChannelId]);

  const contex: SettingCtx = {
    collectionId: collection,
    productsInBD: products,
    infoSocket: {
      ...infoSocket,
      pinnedMessage: ivsChatSocket.pinnedMessage,
      setPinnedMessage: ivsChatSocket.setPinnedMessage,
      highlightProduct: ivsChatSocket.highlightProduct
    },
    ivsChatSocket,
    isModalLive,
    alertMessage,
    setIsModalLive,
    showCarouselChat,
    setAlertMessage,
    setShowCarouselChat,
    showCarouselChatButton,
    setShowCarouselChatButton,
    activePromo,
    setActivePromo,
    updateLivestreaming,
    setUpdateLivestreaming,
    setCollection,
    setProductsInBD,
    widthPlayer,
    setWidthPlayer,
    utm: utmText,
    setUTM: setUTMText,
    accountCurrencyConfig,
    setAccountCurrencyConfig,
    variationThumbnailName,
    idLivestreaming,
    salesChannelId: salesChannel,
    setSalesChannel,
  };

  return (
    <SettingContext.Provider value={contex}>{children}</SettingContext.Provider>
  );
};
