import logoDark from 'assets/images/logo-dark.png';
import logoLightPng from 'assets/images/logo-light.png';
import logoLightSvg from 'assets/images/logo-light.svg';
import logo from 'assets/images/logo.svg';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SidebarContent } from './SidebarContent';

export const Sidebar: FC = () => {
  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img src={logo} alt="" height="25" />
          </span>
          <span className="logo-lg">
            <img src={logoDark} alt="" height="35" />
          </span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src={logoLightSvg} alt="" height="25" />
          </span>
          <span className="logo-lg">
            <img src={logoLightPng} alt="" height="35" />
          </span>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        <SidebarContent />
      </div>
      <div className="sidebar-background"></div>
    </div>
  );
};
