import { useSetAtom } from 'jotai';
import { ivsChatMessageListAtom } from '../state';
import { normalizeChatMessage } from '../utils';

/**
 * Handle event that can be performed with the event IVS chat room.
 */
export const useIVSChatHandlers = () => {
  const setIvsChatMessageList = useSetAtom(ivsChatMessageListAtom);

  const handleEvent = (event: any) => {
    if (event.EventName === 'senddeletemessage') {
      const msgDelete = JSON.parse(event.Attributes?.data);
      setIvsChatMessageList(prev =>
        prev.filter(msg => msg.id !== msgDelete.id),
      );
    }
  };

  const handleMessage = (data: any) => {
    setIvsChatMessageList(prev => [...prev, normalizeChatMessage(prev, data)]);
  };

  return {
    handleEvent,
    handleMessage,
  };
};

export default useIVSChatHandlers;
