import {
  NizzaGetProductHandler,
  NizzaGetProductListHandler,
  NizzaProductDataSource,
  NizzaProductDataSourceConfig,
  apiCall,
} from '@nizza/core';
import env from '~env';
import { filterAvailableVtexProducts } from '../../vtex';

const { apiUrls } = env;

export class CACEProductDataSource implements NizzaProductDataSource {
  constructor(readonly config: NizzaProductDataSourceConfig) {}

  getProduct: NizzaGetProductHandler = async input => {
    const { accountApplyTax } = this.config;
    const { productId } = input;

    const url = `${apiUrls.corsProxy}?url=https://livestreamingdemo.myvtex.com/api/catalog_system/pub/products/search?fq=productId:${productId}`;

    const data = await apiCall({ url });

    if (data && data.length > 0) {
      const result: any = filterAvailableVtexProducts(data[0]);
      const { item, seller, isAvailable } = result;

      return {
        ...data[0],
        id: data[0]?.productId,
        name: data[0]?.productName,
        priceWithDiscount: !accountApplyTax
          ? seller?.commertialOffer.Price
          : seller?.commertialOffer.Price + (seller?.commertialOffer?.Tax || 0),
        price: !accountApplyTax
          ? seller?.commertialOffer.ListPrice
          : seller?.commertialOffer.ListPrice +
            (seller?.commertialOffer?.Tax || 0),
        imageUrl: item?.images[0]?.imageUrl,
        addToCartLink: item?.complementName
          ? item?.complementName
          : seller?.addToCartLink,
        items: data[0]?.items,
        isAvailable,
        variationSelector: data[0]?.skuSpecifications,
        pdpLink: data[0]?.link,
        skuId: item?.itemId,
      };
    }

    return null;
  };

  getProductList: NizzaGetProductListHandler = async (input = {}) => {
    const { accountApplyTax } = this.config;
    const { collectionId } = input;

    const url = `${apiUrls.corsProxy}?url=https://www.livestreaming.link/api/catalog_system/pub/products/search?fq=productClusterIds:${collectionId}&_from=0&_to=49`;

    const data = await apiCall({ url });

    if (data && data.length > 0) {
      return data.map((product: any) => {
        const result: any = filterAvailableVtexProducts(product);
        const { item, seller, isAvailable } = result;

        return {
          ...product,
          id: product.productId,
          name: product?.productName,
          priceWithDiscount: !accountApplyTax
            ? seller?.commertialOffer.Price
            : seller?.commertialOffer.Price +
              (seller?.commertialOffer?.Tax || 0),
          price: !accountApplyTax
            ? seller?.commertialOffer.ListPrice
            : seller?.commertialOffer.ListPrice +
              (seller?.commertialOffer?.Tax || 0),
          imageUrl: item?.images[0]?.imageUrl,
          addToCartLink: item?.complementName
            ? item?.complementName
            : seller?.addToCartLink,
          isAvailable,
          variationSelector: product?.skuSpecifications || [],
          pdpLink: product.link,
          skuId: item?.itemId,
        };
      });
    }

    return [];
  };
}
