/* eslint-disable react-hooks/exhaustive-deps */
import { useNZ } from '@nizza/core';
import { customizeLiveStore } from '@nizza/customize-live';
import { CommonProviders } from '@nizza/ui';
import { useEffect, useState } from 'react';
import { Spinner } from './components';
import { LiveShopping } from './components/LiveShopping';
import {
  ActionsProvider,
  ControlsContextProvider,
  LivestreamingProvider,
  SettingProvider,
  settingDefault,
  useLivestreamingReducer,
  useSetLivestreaming,
} from './context';
import { useLivestreamingConfig } from './hooks';
import { I18nProvider, LOCALES } from './i18n';
import styles from './styles.module.css';
import type { LivestreamingProps } from './types';

const stores = [customizeLiveStore];

export const Livestreaming = (props: LivestreamingProps) => {
  const nizza = useNZ();
  const { idLivestreaming, account, environment, currentOrderForm } = props;
  const [locale, setLocale] = useState(LOCALES.en);
  const [loading, setLoading] = useState(true);
  const [isConfiguringNizza, setIsConfiguringNizza] = useState(true);
  const [actionsProps, setActionsProps] = useState(props);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    nizza
      .configure({
        account: props.account,
      })
      .then(() => {
        setIsConfiguringNizza(false);
      });
  }, []);

  const {
    collectionId,
    isModalLive,
    setIsModalLive,
    wssStream,
    host,
    playBackStartTime,
    settings,
    isLoading,
    utm,
    variationThumbnailName,
    accountType,
    salesChannelId,
  } = useLivestreamingConfig({
    id: idLivestreaming,
    account,
    environment,
  });

  const settingProps = {
    ...settingDefault,
    collectionId,
    isModalLive,
    setIsModalLive,
    wssStream,
    utm,
    variationThumbnailName,
    idLivestreaming,
    salesChannelId,
  };

  useEffect(() => {
    const languageBrowser = window.navigator.language.trim().split(/-|_/)[0];
    const languageAvailable = [LOCALES.en, LOCALES.es, LOCALES.pt];

    setLocale(
      languageAvailable.includes(languageBrowser)
        ? languageBrowser
        : LOCALES.en,
    );
  }, []);

  const setActProps = (actProps: LivestreamingProps) => {
    setActionsProps({
      ...actProps,
      showViewers:
        settings?.showViewers === undefined
          ? actProps.showViewers
          : settings.showViewers,
      redirectTo:
        settings?.redirectTo === undefined
          ? actProps.redirectTo
          : settings.redirectTo,
      showChat:
        settings?.showChat === undefined
          ? actProps.showChat
          : settings.showChat,
      showLike:
        settings?.showLike === undefined
          ? actProps.showLike
          : settings.showLike,
      showProductsCarousel:
        settings?.showProductsCarousel === undefined
          ? actProps.showProductsCarousel
          : settings.showProductsCarousel,
      showSidebarProducts:
        settings?.showSidebarProducts === undefined
          ? actProps.showSidebarProducts
          : settings.showSidebarProducts,
      isInfinite:
        settings?.isInfinite === undefined
          ? actProps.isInfinite
          : settings.isInfinite,
      time: settings?.time === undefined ? actProps.time : settings.time,
      isLoading,
    });
  };

  useEffect(() => {
    setActProps(props);
  }, [props]);

  useEffect(() => {
    if (!settings || isLoading === undefined || isLoading) return;
    setActProps(actionsProps);
  }, [settings, isLoading]);

  const [state, dispatch] = useLivestreamingReducer();

  useSetLivestreaming(
    idLivestreaming,
    account,
    host,
    playBackStartTime,
    dispatch,
    accountType,
  );

  useEffect(() => {
    const { isInGlobalPage } = props;
    if (typeof isInGlobalPage === 'boolean' && isInGlobalPage)
      setIsModalLive(false);
    const timeout = setTimeout(() => setLoading(false), 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isModalLive]);

  useEffect(() => {
    if (firstLoad) {
      sessionStorage.removeItem('initialWidth');
      sessionStorage.removeItem('initialHeight');
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    const initialWidth = window.sessionStorage.getItem('initialWidth');
    const initialHeight = window.sessionStorage.getItem('initialHeight');

    if (!initialHeight || !initialWidth) {
      window.sessionStorage.setItem(
        'initialWidth',
        `${window.innerWidth > window.innerHeight
          ? window.innerHeight
          : window.innerWidth
        }`,
      );
      window.sessionStorage.setItem(
        'initialHeight',
        `${window.innerHeight < window.innerWidth
          ? window.innerWidth
          : window.innerHeight
        }`,
      );
    }
  });

  useEffect(() => {
    if (currentOrderForm && currentOrderForm !== 'default-order-form') {
      sessionStorage.setItem('currentOrderForm', currentOrderForm);
    }
  }, [currentOrderForm]);

  return (
    <CommonProviders
      stores={stores}
      enableJotaiDevTools={false}
      enableVtexAdminUI={false}
    >
      <I18nProvider locale={locale}>
        <ActionsProvider props={actionsProps}>
          <SettingProvider {...settingProps}>
            <ControlsContextProvider>
              <LivestreamingProvider value={state} dispatch={dispatch}>
                <div
                  className={styles.liveShoppingContainer}
                  id="in-view-nizza-player"
                >
                  {loading || isConfiguringNizza ? (
                    <Spinner />
                  ) : (
                    <LiveShopping setLoading={setLoading} />
                  )}
                </div>
              </LivestreamingProvider>
            </ControlsContextProvider>
          </SettingProvider>
        </ActionsProvider>
      </I18nProvider>
    </CommonProviders>
  );
};

export default Livestreaming;
