import styled from 'styled-components';
import { Spinner as ReactstrapSpinner } from 'reactstrap';

export const PageSpinner = () => {
  return (
    <Wrapper>
      <ReactstrapSpinner
        style={{ width: '3rem', height: '3rem' }}
        color="primary"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
