/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const CollectionList = lazyLoad(
  () => import('./CollectionList'),
  module => module.CollectionList,
);

export const CollectionDetail = lazyLoad(
  () => import('./CollectionDetail'),
  module => module.CollectionDetail,
);
