/* eslint-disable react-hooks/exhaustive-deps */
import { NizzaProductRequestOriginTypes, apiCall } from '@nizza/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  ActionsContext,
  SettingContext,
  useLivestreamingContext,
} from '../context';
import { config } from '../enviroment/config';
import { resolveProductList } from '../services';
import { PlayerProduct } from '../types';

type useFetchProductsProps = {
  collectionId: string | undefined;
  setCollection?: React.Dispatch<React.SetStateAction<string | undefined>>;
  productsInBD?: any[];
  setProductsInBD?: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  salesChannelId?: string | undefined;
  setSalesChannel?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const useFetchProducts = ({
  collectionId,
  setCollection,
  productsInBD,
  setProductsInBD,
  salesChannelId,
  setSalesChannel,
}: useFetchProductsProps) => {
  const {
    setting: { account, environment, getProducts, originOfProducts },
  } = useContext(ActionsContext);

  const { infoSocket } = useContext(SettingContext);
  const { showTax } = infoSocket || {};
  const { idLivestreaming } = useLivestreamingContext();
  const { activePromo, updateLivestreaming } = useContext(SettingContext);
  const [products, setProducts] = useState<PlayerProduct[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [timer, setTimer] = useState<any>();
  const [forceUpdate, setForceUpdate] = useState('');

  const customResolveProductList = async () => {
    if (!getProducts) {
      throw new Error(
        '[useFetchProducts] customer custom function to get the products is not defined',
      );
    }

    return getProducts(collectionId, account, showTax);
  };

  const getUrl = () => {
    let URL = '__GET_LIVESTREAMING_CONFIG_URL';
    const { GET_LIVESTREAMING_CONFIG_URL } = config(environment || '');
    if (GET_LIVESTREAMING_CONFIG_URL && GET_LIVESTREAMING_CONFIG_URL !== URL) {
      URL = GET_LIVESTREAMING_CONFIG_URL;
    }
    return URL;
  };

  const scheduledSidebarReload = () => {
    setForceUpdate(`${Date.now()}`);
    if (timer) {
      clearInterval(timer); // cancel old timer
    }
    const newTimer = setInterval(() => {
      setForceUpdate(`${Date.now()}`);
    }, 420000); // reload sidebar each 7 min
    setTimeout(() => {
      clearTimeout(newTimer);
    }, 1260000); // cancel auto-reloads after 3 times
    setTimer(newTimer);
  };

  useEffect(() => {
    if (activePromo) {
      const { isCoupon } = activePromo;
      if (!isCoupon) {
        scheduledSidebarReload();
      }
    }
  }, [activePromo]);

  useEffect(() => {
    const url = getUrl();
    apiCall({
      url: `${url}?id=${idLivestreaming}&account=${account}`,
    }).then(data => {
      if (data && setCollection) {
        setCollection(data?.collection?.id);
        salesChannelId &&
          setSalesChannel &&
          setSalesChannel(data?.collection?.salesChannel);
        setProductsInBD && setProductsInBD(data?.collection?.products);
      }
    });
  }, [updateLivestreaming]);

  const successResponse = useCallback(
    (response: any) => {
      if (!response?.length && productsInBD?.[0]?.pdpLink) {
        setProducts(productsInBD);
      } else {
        setProducts(response);
      }
    },
    [productsInBD],
  );

  useEffect(() => {
    if (!collectionId) return;

    if (getProducts) {
      customResolveProductList()
        .then(successResponse)
        .finally(() => setLoading(false));
    } else {
      resolveProductList({
        collectionId,
        origin: originOfProducts as NizzaProductRequestOriginTypes,
        accountApplyTax: showTax,
        salesChannelId,
      })
        .then(successResponse)
        .finally(() => setLoading(false));
    }
  }, [collectionId, forceUpdate]);

  return { products, loading };
};
