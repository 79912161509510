import { FC, SVGProps } from 'react';

export const ChatStoreIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      {...props}
      width="26"
      height="26"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.24999 8.09315C4.49852 8.09315 4.69999 8.29462 4.69999 8.54315V11.675H11.3V8.54323C11.3 8.2947 11.5015 8.09322 11.75 8.09322C11.9985 8.09322 12.2 8.2947 12.2 8.54323V11.75C12.2 11.9688 12.1131 12.1786 11.9584 12.3333C11.8036 12.488 11.5938 12.575 11.375 12.575H4.62499C4.40619 12.575 4.19635 12.488 4.04163 12.3333C3.88691 12.1786 3.79999 11.9688 3.79999 11.75V8.54315C3.79999 8.29462 4.00146 8.09315 4.24999 8.09315Z"
        fill="#F71963"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.58943 4.32499L4.09657 6.04999H11.9034L11.4106 4.32499H4.58943ZM4.03604 3.59135C4.17917 3.48339 4.35357 3.42499 4.53285 3.42499H11.4671C11.6464 3.42499 11.8208 3.48339 11.9639 3.59135C12.1071 3.69931 12.2111 3.85096 12.2604 4.02334L12.9327 6.37636C12.9715 6.51214 12.9443 6.65824 12.8593 6.77098C12.7742 6.88371 12.6412 6.94999 12.5 6.94999H3.49999C3.35879 6.94999 3.22577 6.88371 3.14073 6.77098C3.0557 6.65824 3.02851 6.51214 3.06731 6.37636L3.7396 4.02334C3.78885 3.85096 3.89291 3.69931 4.03604 3.59135Z"
        fill="#F71963"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.49999 6.04999C3.74852 6.04999 3.94999 6.25146 3.94999 6.49999V7.24999C3.94999 7.52847 4.06062 7.79554 4.25753 7.99245C4.45444 8.18936 4.72151 8.29999 4.99999 8.29999C5.27847 8.29999 5.54554 8.18936 5.74245 7.99245C5.93937 7.79554 6.04999 7.52847 6.04999 7.24999V6.49999C6.04999 6.25146 6.25146 6.04999 6.49999 6.04999C6.74852 6.04999 6.94999 6.25146 6.94999 6.49999V7.24999C6.94999 7.76716 6.74455 8.26315 6.37885 8.62885C6.01315 8.99454 5.51716 9.19999 4.99999 9.19999C4.48282 9.19999 3.98683 8.99454 3.62113 8.62885C3.25544 8.26315 3.04999 7.76716 3.04999 7.24999V6.49999C3.04999 6.25146 3.25146 6.04999 3.49999 6.04999Z"
        fill="#F71963"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.49999 6.04999C6.74852 6.04999 6.94999 6.25146 6.94999 6.49999V7.24999C6.94999 7.52847 7.06062 7.79554 7.25753 7.99245C7.45444 8.18936 7.72152 8.29999 7.99999 8.29999C8.27847 8.29999 8.54554 8.18936 8.74245 7.99245C8.93937 7.79554 9.04999 7.52846 9.04999 7.24999V6.49999C9.04999 6.25146 9.25146 6.04999 9.49999 6.04999C9.74852 6.04999 9.94999 6.25146 9.94999 6.49999V7.24999C9.94999 7.76716 9.74455 8.26315 9.37885 8.62885C9.01315 8.99454 8.51717 9.19999 7.99999 9.19999C7.48282 9.19999 6.98683 8.99454 6.62113 8.62885C6.25544 8.26315 6.04999 7.76716 6.04999 7.24999V6.49999C6.04999 6.25146 6.25146 6.04999 6.49999 6.04999Z"
        fill="#F71963"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.49999 6.04999C9.74852 6.04999 9.94999 6.25146 9.94999 6.49999V7.24999C9.94999 7.52846 10.0606 7.79554 10.2575 7.99245C10.4544 8.18936 10.7215 8.29999 11 8.29999C11.2785 8.29999 11.5455 8.18936 11.7425 7.99245C11.9394 7.79554 12.05 7.52846 12.05 7.24999V6.49999C12.05 6.25146 12.2515 6.04999 12.5 6.04999C12.7485 6.04999 12.95 6.25146 12.95 6.49999V7.24999C12.95 7.76716 12.7445 8.26315 12.3789 8.62885C12.0132 8.99454 11.5172 9.19999 11 9.19999C10.4828 9.19999 9.98683 8.99454 9.62113 8.62885C9.25544 8.26315 9.04999 7.76716 9.04999 7.24999V6.49999C9.04999 6.25146 9.25146 6.04999 9.49999 6.04999Z"
        fill="#F71963"
      />
      <circle cx="8" cy="8" r="7.5" stroke="#F71963" />
    </svg>
  );
};
