import React, { useContext } from 'react';
import styles from './NoVideo.module.css';
import { FormattedMessage } from 'react-intl';
import { ActionsContext, SettingContext } from '../../context';

const messages = {
  noVideo: <FormattedMessage id="store/text.live-no-video" />,
  UNKNOWN: <FormattedMessage id="store/text.live-no-video" />,
  LIVE: <FormattedMessage id="store/text.live-no-video" />,
};

type NoVideoProps = {
  isLive: string | undefined;
  liveStatus: boolean;
  isMobile: boolean;
  isVerticalLayout: boolean;
};

export const NoVideo = ({
  isLive,
  liveStatus,
  isMobile,
  isVerticalLayout,
}: NoVideoProps) => {
  const { isModalLive } = useContext(SettingContext);
  const {
    setting: { isInGlobalPage },
  } = useContext(ActionsContext);

  return (
    <div
      style={{
        background: isMobile && !isVerticalLayout ? '#f7f7f7' : 'unset',
        height: isMobile && !isVerticalLayout ? '78.2vh' : '100%',
      }}
    >
      <div
        id="no-video"
        className={`${
          isModalLive && !isInGlobalPage && styles.noVideoContainerPopoup
        } ${styles.noVideoContainer} ${
          isMobile &&
          !isVerticalLayout &&
          styles.noVideoContainerMobileHorizontal
        }`}
      >
        <span className={styles.noVideoText}>
          {isLive && (isLive === 'LIVE' || (liveStatus && isLive === 'UNKNOWN'))
            ? messages[isLive]
            : messages.noVideo}
        </span>
      </div>
    </div>
  );
};
