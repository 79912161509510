import React from 'react';

declare interface Props {
  size: string;
  viewBox: string;
  isMobile?: boolean;
}

const PlayIcon = ({ size, viewBox, isMobile }: Props) => {
  return !isMobile ? (
    <svg
      fill="white"
      id="fi_3121571"
      height={size}
      viewBox={viewBox}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="pause-grounded-live-streaming"
        transform="matrix(3.384615, 0, 0, 3.384615, 28.969231, 28.969231)"
      >
        <circle
          cx="50"
          cy="50"
          r="45.5"
          style={{
            fill: '#000000',
            fillOpacity: 0.5,
            stroke: 'none',
            strokeWidth: 0.398621,
          }}
        />
        <rect
          x="52.87"
          y="28.68"
          width="14.76"
          height="43.04"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
        <rect
          x="29.91"
          y="28.68"
          width="14.76"
          height="43.04"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="2px"
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="58"
      viewBox="0 0 58 58"
    >
      <g id="Group_1742" data-name="Group 1742">
        <rect
          id="Rectángulo_2"
          data-name="Rectángulo 2"
          width="57"
          height="57"
          rx="28.5"
          transform="translate(0.5 0.5)"
          fill="rgba(51,51,51,0.7)"
          stroke="#fff"
          stroke-width="1"
        />
      </g>
      <g
        id="Icon_ionic-ios-pause"
        data-name="Icon ionic-ios-pause"
        transform="translate(8.018 5.453)"
      >
        <path
          id="Trazado_3"
          data-name="Trazado 3"
          d="M24.649,40.344h-2.9c-.2,0-.369-.378-.369-.84V7.59c0-.462.164-.84.369-.84h2.9c.2,0,.369.378.369.84V39.5C25.017,39.966,24.853,40.344,24.649,40.344Z"
          transform="translate(5.611)"
          fill="#fff"
          stroke="#fff"
          stroke-width="1"
        />
        <path
          id="Trazado_4"
          data-name="Trazado 4"
          d="M24.646,40.344h-2.9c-.2,0-.369-.378-.369-.84V7.59c0-.462.164-.84.369-.84h2.9c.2,0,.369.378.369.84V39.5C25.015,39.966,24.851,40.344,24.646,40.344Z"
          transform="translate(-10.039)"
          fill="#fff"
          stroke="#fff"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default PlayIcon;
