/* eslint-disable react-hooks/exhaustive-deps */

import { useCustomizations } from '@nizza/customize-live';
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import type { LivestreamingProps } from '../types';

type ActionCtx = {
  setting: LivestreamingProps;
  setSetting: React.Dispatch<React.SetStateAction<LivestreamingProps>>;
};

const actionsDefault: ActionCtx = {
  setting: {
    account: '',
    environment: '',
    getProductId: () => Promise.resolve({} as any),
    getProducts: () => Promise.resolve([]),
    idLivestreaming: '',
    isInGlobalPage: false,
    isInfinite: true,
    originOfProducts: '',
    redirectTo: true,
    showChat: true,
    showLike: true,
    showQuickView: true,
    showProductsCarousel: false,
    showSidebarProducts: false,
    showViewers: true,
    time: 10,
    setUTM: () => Promise.resolve(),
  },
  setSetting: () => null,
};

export const ActionsContext = createContext<ActionCtx>(actionsDefault);

export const useActions = () => useContext(ActionsContext);

export type ActionsProviderProps = {
  props: LivestreamingProps;
  children: ReactNode;
};

export const ActionsProvider: FC<ActionsProviderProps> = ({
  children,
  props,
}) => {
  const {
    addToCartConfig,
    account,
    environment,
    idLivestreaming,
    isInGlobalPage,
    isInfinite,
    originOfProducts,
    redirectTo,
    showChat,
    showLike,
    showQuickView,
    showProductsCarousel,
    showSidebarProducts,
    showViewers,
    time,
    isLoading,
    getProductId,
    getProducts,
    setUTM,
    // LowerBanner,
    // CustomAddToCartButton,
  } = props;

  const { setAccountCustomization } = useCustomizations();

  const getSettings = () => {
    return {
      addToCartConfig,
      account,
      environment,
      getProductId,
      getProducts,
      idLivestreaming,
      isInGlobalPage:
        isInGlobalPage === undefined ? false : isInGlobalPage === true,
      isInfinite: isInfinite === undefined ? true : isInfinite === true,
      originOfProducts,
      redirectTo: redirectTo === undefined ? true : redirectTo === true,
      showChat: showChat === undefined ? true : showChat === true,
      showLike: showLike === undefined ? true : showLike === true,
      showQuickView:
        showQuickView === undefined ? true : showQuickView === true,
      showProductsCarousel:
        showProductsCarousel === undefined
          ? false
          : showProductsCarousel === true,
      showSidebarProducts:
        showSidebarProducts === undefined
          ? false
          : showSidebarProducts === true,
      showViewers: showViewers === undefined ? true : showViewers === true,
      time,
      setUTM,
      // LowerBanner,
      // CustomAddToCartButton,
    };
  };

  const [setting, setSetting] = useState<LivestreamingProps>(getSettings());

  useEffect(() => {
    if (isLoading == null || isLoading) return;
    const newSettings = getSettings();
    setSetting(newSettings);
  }, [isLoading, props]);

  useLayoutEffect(() => {
    setAccountCustomization(account);
  }, [account]);

  const context: ActionCtx = {
    setting,
    setSetting,
  };

  return (
    <ActionsContext.Provider value={context}>
      {children}
    </ActionsContext.Provider>
  );
};
