import styled from 'styled-components';

export const EventListContainer = styled.div`
  display: inline;
  flex-direction: column;
`;

export const EventListShowMoreContainer = styled.div`
  display: flex;
  justify-content: right;
`;
