/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncAddProduct = lazyLoad(
  () => import('./AddProduct'),
  module => module.AddProduct,
);

export const AsyncProductDetail = lazyLoad(
  () => import('./ProductDetail'),
  module => module.ProductDetail,
);

export const AsyncProductList = lazyLoad(
  () => import('./ProductList'),
  module => module.ProductList,
);
