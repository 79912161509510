/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncTwoStepVerification = lazyLoad(
  () => import('./TwoStepVerification'),
  module => module.TwoStepVerification,
);

export const AsyncLogin = lazyLoad(
  () => import('./Login'),
  module => module.Login,
);

export const AsyncSelectAccount = lazyLoad(
  () => import('./SelectAccount'),
  module => module.SelectAccount,
);
