import { useCollection } from '@nizza/event-products';
import {
  HighlightProductProvider,
  ProductListProvider,
  useAccount,
  useEventDetail,
} from 'hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { ProductList } from './components';

export const HighlightProductBlock = observer(() => {
  const { t } = useTranslation();
  const { isLive, eventData } = useEventDetail();
  const collectionId = eventData.collection.id;
  const { accountInfo } = useAccount();
  const account = accountInfo.account;
  const { store } = useCollection({ collectionId, account });
  const { getProductList } = store;
  const collectionProducts = getProductList(collectionId);
  return (
    <HighlightProductProvider
      consumer={() => (
        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              <h4>{t('LiveShopping.HighlightProduct.Title')}</h4>
            </CardTitle>
            {isLive ? (
              <Row>
                <Col>
                  <ProductListProvider
                    products={collectionProducts}
                    disableQuery={true}
                  >
                    <ProductList />
                  </ProductListProvider>
                </Col>
              </Row>
            ) : (
              <h5 className="text-secondary">
                {t('LiveShopping.EventDetails.SectionAvailableInTransmission')}
              </h5>
            )}
          </CardBody>
        </Card>
      )}
    />
  );
});

HighlightProductBlock.displayName = 'HighlightProductBlock';
export default HighlightProductBlock;
