/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncCreateUser = lazyLoad(
  () => import('./CreateUser'),
  module => module.CreateUser,
);

export const AsyncListUsers = lazyLoad(
  () => import('./ListUsers'),
  module => module.ListUsers,
);
