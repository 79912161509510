
  import { Environment } from '@nizza/core';
  import coreEnvs from '@nizza/core/envs/environment.prod';

  const config = {
    ...coreEnvs,
  };

  export const environment: Environment<typeof config> = config;
  export default environment;
  