import { useStore } from '@nizza/core';
import { useEffect, useState } from 'react';
import { MutationStatus, useMutation, useQuery } from '@tanstack/react-query';
import { Collection, CollectionSearchInput } from '../core';
import { CollectionStore } from '../stores';

const isValidSearchInput = (input?: CollectionSearchInput) => {
  return input && input.search;
};

const getCollectionListKey = (input?: CollectionSearchInput) =>
  isValidSearchInput(input)
    ? ['eventProductCollections', input]
    : ['eventProductCollections'];

const getCollectionKey = (id?: string) => [
  'eventProductCollection',
  id ?? null,
];

interface Props {
  collectionId?: string;
  account?: string;
}

export const useCollection = (props: Props = {}) => {
  const store = useStore(CollectionStore);
  const { collectionId, account } = props;
  const [searchInput, setSearchInput] = useState<CollectionSearchInput>();
  const collectionListKey = getCollectionListKey(searchInput);
  const [enableFindQuery, setEnableFindQuery] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState<
    Record<string, MutationStatus>
  >({});

  useEffect(() => {
    account && store.setAccount(account);

    if (account && collectionId && !store.getCollection(collectionId)) {
      setEnableFindQuery(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const searchQuery = useQuery(
    collectionListKey,
    () => store.search(searchInput),
    {
      enabled: !!account,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const findQuery = useQuery(
    getCollectionKey(collectionId),
    () => store.find(collectionId!),
    {
      enabled: enableFindQuery,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const createMutation = useMutation(store.create);
  const updateMutation = useMutation(store.update);
  const deleteMutation = useMutation(store.delete);
  const syncProductListMutation = useMutation(store.syncProducts);
  const syncProductMutation = useMutation(store.syncProduct);

  const deleteCollection = (collection: Collection) => {
    setDeleteStatus(v => ({ ...v, [collection.id]: 'loading' }));

    deleteMutation.mutate(collection, {
      onError: () => {
        setDeleteStatus(v => ({
          ...v,
          [collection.id]: 'error',
        }));
      },
      onSuccess: () => {
        setDeleteStatus(v => {
          const statusList = { ...v };
          delete statusList[collection.id];
          return statusList;
        });
      },
    });
  };

  useEffect(() => {
    if (!collectionId) return;

    const collection = store.getCollection(collectionId) ?? findQuery.data;

    if (collection) {
      store.openDetail(true, collection);
    }

    return () => {
      store.openDetail(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findQuery.status, collectionId]);

  return {
    store,
    searchQuery,
    findQuery,
    createMutation,
    updateMutation,
    deleteMutation,
    deleteCollection,
    deleteStatus,
    setSearchInput,
    syncProductListMutation,
    syncProductMutation,
  };
};
