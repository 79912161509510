import { ApiGraphqlTypes as AGT, ChatMessage } from '@nizza/core';
import { GraphQLClient } from 'graphql-request';
import env from '~env';
import { UserRepository } from '../domain/repositories/userRepository';

const API_URL = env.apiUrls.graphql;

export class GraphqlUserRepository implements UserRepository {
  private client: GraphQLClient;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async blockUser(
    params: Pick<ChatMessage, 'idEvent' | 'account' | 'username' | 'ip'>,
  ): Promise<void> {
    const { blockUser } = await this.client.request(AGT.BlockUserDocument, {
      data: {
        account: params.account,
        idEvent: params.idEvent,
        username: params.username,
        ip: params.ip,
      },
    });

    this.propagateGraphqlErrorIfExists(blockUser as AGT.BlockUserResult);
  }

  private propagateGraphqlErrorIfExists(res: AGT.BlockUserResult) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
