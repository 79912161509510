import { setPlan } from 'api';
import { useState } from 'react';

export const useSetPlan = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleSetPlan = async (
    planType: string,
    descriptionPlan: string,
    account: string,
    selectFreeTrial = false,
  ) => {
    try {
      await setPlan(planType, descriptionPlan, account, selectFreeTrial);
      //history.push('/login');
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  return { errorMessage, handleSetPlan };
};
