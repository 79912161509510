import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';

type Item = {
  title: string;
  link?: string;
};

type BreadcrumbProps = {
  breadcrumbItem?: string;
  title?: string;
  items?: Item[];
};

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { title = '', breadcrumbItem = '' } = props;

  if (!title && !breadcrumbItem && !props.items) return null;

  const items: Item[] = props.items ?? [{ title }, { title: breadcrumbItem }];

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {items.map((x, i) => (
                <BreadcrumbItem key={i} active={i === items.length - 1}>
                  {x.link ? (
                    <Link to={x.link}>{x.title}</Link>
                  ) : (
                    <span>{x.title}</span>
                  )}
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};
