import {
  ComponentChildSchema,
  withCustomizeSchema,
} from '@nizza/customize-live';
import clsx from 'clsx';
import styles2 from '../ProductSlider/productSlider.module.css';
import styles from './highlightProduct.module.css';
import { intlFormatMessageOutsideFC } from '../../utils/intlFormatMessageOutsideFC';
import type { PlayerProduct } from '../../types';

const intl = intlFormatMessageOutsideFC();

interface MobileStructureProps {
  fullScreen: boolean;
  handleFullScreen: () => void;
  isVerticalLayout: boolean;
  addToCart: (() => void) | undefined;
  animationToShow: boolean | undefined;
  product: PlayerProduct;
  deconstructedPrice: any;
  formatMessage: any;
  normalPriceFormat: string;
}

const MobileStructure = ({
  fullScreen,
  handleFullScreen,
  isVerticalLayout,
  addToCart,
  animationToShow,
  product,
  deconstructedPrice,
  formatMessage,
  normalPriceFormat,
  selector,
  formData,
}: ComponentChildSchema<MobileStructureProps>) => {
  const ignoreFormat = ['R$', 'S/.', 'S/'].includes(deconstructedPrice.symbol);

  return (
    <div
      customize-field={selector}
      onClick={() => {
        fullScreen && handleFullScreen();
        addToCart && addToCart();
      }}
      className={clsx(
        styles.highlightProductContainer,
        styles.highlightProductContainerMobile,
        !isVerticalLayout && styles.highlightProductContainerMobileHorizontal,
        isVerticalLayout
          ? animationToShow
            ? styles.bounce_in_right
            : styles.exit_left
          : '',
        !isVerticalLayout
          ? animationToShow
            ? styles.appear
            : styles.vanish
          : '',
      )}
      style={{
        backgroundColor: `${
          (formData?.data?.backgroundColor ?? '#747474') +
          (formData?.data?.opacity ? '80' : '')
        }`,
      }}
    >
      <div className={styles.productContainer}>
        <img
          className={`${styles.productPicture} ${styles.productPictureMobile}`}
          src={product.imageUrl}
          alt="productPicture"
        />
        <div
          style={{
            color: `${formData?.data?.priceColor ?? '#ffffff'}`,
            marginTop: '5px',
          }}
          className={`${styles2.productDeatailContent} ${styles2.flexDirectionRow}`}
        >
          {ignoreFormat && (
            <div
              style={{
                color: `${formData?.data?.priceColor ?? '#ffffff'}`,
                fontSize: 10,
              }}
              className={styles.priceWithDiscount}
            >
              {product.isAvailable
                ? normalPriceFormat
                : formatMessage({
                    id: 'chat-carousel.product-unavailable-message',
                  })}
            </div>
          )}
          {!ignoreFormat && (
            <>
              {' '}
              <div
                style={{
                  fontSize: '6px',
                  whiteSpace: 'nowrap',
                  margin: '0px 1px 0px 0px',
                  padding: '0px',
                  lineHeight: '6px',
                }}
              >
                {product.isAvailable &&
                  String(deconstructedPrice.symbol).replace('$', '')}
              </div>
              <div
                style={{
                  lineHeight: '10px',
                  fontSize: '11px',
                  fontWeight: '300',
                  letterSpacing: '-1px',
                }}
                className={styles.priceWithDiscount}
              >
                {product.isAvailable
                  ? deconstructedPrice.symbol &&
                    String(deconstructedPrice.symbol).includes('$')
                    ? '$' + deconstructedPrice.integerPart
                    : deconstructedPrice.integerPart
                  : formatMessage({
                      id: 'chat-carousel.product-unavailable-message',
                    })}
              </div>
              <div
                style={{
                  fontSize: '8px',
                  whiteSpace: 'nowrap',
                  margin: '0px 0px 0px 0px',
                  padding: '0px',
                  lineHeight: '7px',
                }}
              >
                {product.isAvailable && deconstructedPrice.decimalPart}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export const MobileOutput = withCustomizeSchema(MobileStructure, {
  products: {
    id: 'highligh_products_mobile',
    label: intl.formatMessage({
      id: 'customize/highlight-product.product-highlight',
    }),
    category: 'mobile',
    fields: {
      type: 'object',
      properties: {
        backgroundColor: {
          type: 'string',
          title: intl.formatMessage({
            id: 'customize/general.background',
          }),
          default: '#747474',
        },
        opacity: {
          type: 'boolean',
          title: intl.formatMessage({
            id: 'customize/highlight-product.opacity',
          }),
          default: false,
        },
        priceColor: {
          type: 'string',
          title: intl.formatMessage({
            id: 'customize/general.price',
          }),
          default: '#ffffff',
        },
      },
    },
    ui: {
      backgroundColor: {
        'ui:widget': 'color',
      },
      priceColor: {
        'ui:widget': 'color',
      },
    },
  },
});
