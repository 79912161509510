import { useGlobalPage } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  icon?: JSX.Element;
}

const EventCategoryItem: React.FC<Props> = ({ name, icon }) => {
  const { t } = useTranslation();
  const { setCategory, category } = useGlobalPage();

  const selectCategory = () => {
    setCategory(name);
  };

  return (
    <button
      className={`category-list-item ${category === name ? 'active' : ''}`}
      onClick={selectCategory}
    >
      {icon && <div className="category-item-icon">{icon}</div>}
      <div className="category-item-name">
        {t(`GlobalPage.Category.${name}`)}
      </div>
    </button>
  );
};

export default EventCategoryItem;
