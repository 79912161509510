/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-unused-vars */
import IconClose from '@vtex/styleguide/lib/icon/Close';
import React, { useEffect, useRef, useState } from 'react';
import {
  ButtonProductsMobile,
  Chat,
  Feed,
  HorizontalProductSlider,
  Live,
  SliderProductMobile,
  VariationSelector,
  VerticalProductSlider,
  Viewers,
} from '.';
import { useActions, useControlsContext, useSettings } from '../context';
import {
  useIsPlayerSupported,
  useLivestreamingComponentOnScreen,
  useLivestreamingConfig,
  usePlayerLayout,
  useAccountCurrencyConfig,
  useAddToCartEventListener,
} from '../hooks';
import styles from './../styles.module.css';
import type { IvsRealTime } from '../types';
import { getDeviceType, getMobileOS } from '../utils';
import { Alert } from './commonComponents';
import ShopIcon from './icons/ShopIcon';
import styles2 from './liveShopping.module.css';
import HideChatMobile from './icons/HideChatMobile';
import { MobileHoverControls } from './Feed/Control/MobileHoverControls';
import { useIntl } from 'react-intl';

type LiveShoppingProps = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LiveShopping = (props: LiveShoppingProps) => {
  const { setLoading } = props;
  const divVideoContent = useRef<HTMLDivElement>(null);
  const [showSliderProducts, setShowSliderProducts] = useState(false);
  const variationSelectorState = useState('');
  const [showVariation, setShowVariation] = variationSelectorState;
  const [height, setHeight] = useState('0');
  const [detector, setDetector] = useState('');
  const [transmissionType, setTransmissionType] = useState<
    string | undefined
  >();
  const [fullScreen, setFullScreen] = useState(false);

  const [ivsRealTime, setIvsRealTime] = useState<IvsRealTime>({
    startTime: 'UNKNOWN',
    viewerCount: 0,
    status: 'UNKNOWN',
  });

  const [hideChat, setHideChat] = useState(false);
  const [auxHideChat, setAuxHideChat] = useState(false);
  const [hideChatTimeOut, setHideChatTimeOut] = useState<
    undefined | NodeJS.Timeout
  >();
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [playerZoom, setPlayerZoom] = useState<string | null>(null);
  const { isVerticalLayout } = usePlayerLayout(transmissionType);
  const isMobile = getDeviceType() === 'mobile';

  const { formatMessage } = useIntl();
  const { isPlayerSupported, playerScriptLoaded } = useIsPlayerSupported();
  const { setting, setSetting } = useActions();

  //const { ivsRealTime } = useLivestreamingContext();

  const {
    account,
    environment,
    idLivestreaming,
    isInGlobalPage,
    originOfProducts,
    showChat,
    showQuickView,
    showProductsCarousel,
    showSidebarProducts,
    setUTM,
    // LowerBanner,
  } = setting;

  const {
    collectionId,
    infoSocket,
    isModalLive,
    showCarouselChatButton: socketShowCarouselChatButton,
    setIsModalLive,
    setShowCarouselChatButton,
    setShowCarouselChat,
    setActivePromo,
    setUpdateLivestreaming,
    setWidthPlayer,
    showCarouselChat,
    utm,
    setAccountCurrencyConfig,
  } = useSettings();

  const { mobileHoverCtrlsProps, isChatFocus, hideQuestion, questionTime } =
    useControlsContext();
  useAddToCartEventListener(infoSocket);

  const {
    streamUrl,
    emailIsRequired,
    pinnedMessage: initPinnedMessage,
    transmitionType: initTransmissionType,
    status,
    showGifButton,
    showCarouselChatButton,
    showTax,
  } = useLivestreamingConfig({
    id: idLivestreaming,
    account,
    environment,
  });

  const { accountCurrencyConfig } = useAccountCurrencyConfig({
    account,
    environment,
  });

  useEffect(() => {
    if (setAccountCurrencyConfig) {
      setAccountCurrencyConfig(accountCurrencyConfig);
    }
  }, [accountCurrencyConfig]);

  const { livestreamingComponentInView } = useLivestreamingComponentOnScreen({
    rootMargin: '0px 0px',
  });

  const {
    scriptProperties,
    setShowCounter,
    setEmailIsRequired,
    socket,
    sessionId,
    pinnedMessage,
    setPinnedMessage,
    transmitiontype: socketTransmissionType,
    showCarouselChatButton: socketCarouselChatButton,
    activePromo,
    updateLivestreaming,
    showCounter,
    setShowTax,
  } = infoSocket || {};

  useEffect(() => {
    isModalLive
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = '');
  }, [isModalLive]);

  const getHeight = () => {
    setDetector(getMobileOS());

    if (divVideoContent.current && divVideoContent.current?.clientHeight > 0)
      setHeight(divVideoContent.current?.clientHeight.toString());
  };

  useEffect(() => {
    setWidthPlayer?.(divVideoContent.current?.clientWidth ?? 0);
  }, [divVideoContent.current?.clientWidth]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--carousel-base',
      !showCarouselChat ? '0px' : '8vh',
    );
  }, [showCarouselChat]);

  useEffect(() => {
    getHeight();
    window.addEventListener('resize', function () {
      getHeight();
    });
  }, [infoSocket, divVideoContent]);

  useEffect(() => {
    if (!scriptProperties) return;
    const {
      chat,
      isInGlobalPage,
      infinite,
      like,
      pdp,
      showQuickView,
      productsCarousel,
      sidebarProducts,
      time,
      viewers,
    } = scriptProperties;

    const viewersFlag = viewers === undefined ? showCounter : viewers;

    if (setShowCounter) setShowCounter(viewersFlag);

    setSetting(v => ({
      ...v,
      isInGlobalPage,
      isInfinite: infinite,
      redirectTo: pdp,
      showChat: chat,
      showLike: like,
      showQuickView,
      showProductsCarousel: productsCarousel,
      showSidebarProducts: sidebarProducts,
      showViewers: viewersFlag,
      time,
    }));
  }, [scriptProperties]);

  useEffect(() => {
    if (setEmailIsRequired) setEmailIsRequired(emailIsRequired);
  }, [emailIsRequired]);

  useEffect(() => {
    if (setShowTax) setShowTax(showTax);
  }, [showTax]);

  useEffect(() => {
    if (!livestreamingComponentInView || !utm) return;

    if (window.vtexjs || originOfProducts === 'vtex-io') {
      const timeOutSetUTM = setTimeout(() => {
        if (!setUTM) throw new Error('[LiveShopping] setUTM is undefined');
        setUTM(utm);
      }, 10000);

      return () => clearTimeout(timeOutSetUTM);
    }
  }, [livestreamingComponentInView, utm]);

  useEffect(() => {
    setTimeout(() => {
      if (!socket || !window.vtexjs) return;
      const eventAddToCartStorage = localStorage.getItem(
        'sectionIdClickedOnForAddToCart',
      );
      if (!eventAddToCartStorage) return;
      const { productId, productName, sectionIdClickedOn } = JSON.parse(
        eventAddToCartStorage,
      );

      const currentCart = {
        action: 'sendaddtocart',
        data: {
          name: productName,
          productId,
        },
        sectionIdClickedOn,
        orderForm:
          window.vtexjs.checkout.orderForm.orderFormId ??
          sessionStorage.getItem('currentOrderForm'),
        sessionId,
        email: '',
      };

      if (!window.sessionStorage.cartCachedOrderFormId) {
        window.sessionStorage.cartCachedOrderFormId = currentCart.orderForm;
      }

      if (!window.sessionStorage.cartCachedOrderFormId) {
        window.sessionStorage.cartCachedOrderFormId = currentCart.orderForm;
      }

      if (
        currentCart.orderForm !== window.sessionStorage.cartCachedOrderFormId
      ) {
        socket.send(JSON.stringify(currentCart));
        localStorage.removeItem('sectionIdClickedOnForAddToCart');
      }
    }, 1000);
  }, [socket]);

  useEffect(() => {
    if (initPinnedMessage) {
      setPinnedMessage?.(initPinnedMessage);
    }
  }, [initPinnedMessage]);

  useEffect(() => {
    if (!initTransmissionType && !socketTransmissionType) return;

    if (socketTransmissionType) {
      setTransmissionType(socketTransmissionType);
    } else {
      setTransmissionType(initTransmissionType);
    }
  }, [initTransmissionType, socketTransmissionType]);

  useEffect(() => {
    if (setShowCarouselChatButton) {
      if (socketCarouselChatButton !== undefined) {
        setShowCarouselChatButton(socketCarouselChatButton);
      } else {
        if (showCarouselChatButton)
          setShowCarouselChatButton(showCarouselChatButton);
      }
    }
  }, [showCarouselChatButton, socketCarouselChatButton]);

  useEffect(() => {
    if (!setActivePromo) return;
    setActivePromo(activePromo);
  }, [activePromo, setActivePromo]);

  useEffect(() => {
    if (!setUpdateLivestreaming) return;
    setUpdateLivestreaming(updateLivestreaming);
  }, [updateLivestreaming, setUpdateLivestreaming]);

  useEffect(() => {
    hideChatTimeOut && clearTimeout(hideChatTimeOut);
    const delay = hideChat ? 1 : 0;
    const hideChatTimeOutLocal = setTimeout(() => {
      setAuxHideChat(hideChat);
    }, delay);
    setHideChatTimeOut(hideChatTimeOutLocal);
  }, [hideChat]);

  useEffect(() => {
    setShowCarouselChat && setShowCarouselChat(showSidebarProducts);
    setShowCarouselChatButton && setShowCarouselChatButton(showSidebarProducts);
  }, [showSidebarProducts]);

  useEffect(() => {
    function setZoom() {
      if (!(getDeviceType() === 'desktop')) return;
      const element = document.getElementById('encapsulate-liveshopping');
      if (!element) return;
      const width = element.offsetWidth;
      if (width > 640 && width < 986.1) {
        const zoom = width / 9.861;
        setPlayerZoom(`${zoom}%`);
      } else {
        setPlayerZoom(null);
      }
    }
    setZoom();
    window.addEventListener('resize', setZoom);
    return () => window.removeEventListener('resize', setZoom);
  }, []);

  return (
    <div
      className={`${styles2.livestreaming} ${isModalLive && !isInGlobalPage && styles2.livestreamingPopoup
        } ${isMobile && styles2.livestreamingMobile}`}
    >
      <Alert />
      <div
        className={`${styles2.livestreamingContainer} ${isModalLive && !isInGlobalPage && styles2.livestreamingPopoupContainer
          }`}
      >
        <div
          id="containerFullScreenMobile"
          className={`${styles2.livestreamingContent} ${isMobile && styles2.livestreamingContentMobile
            } ${isModalLive && !isInGlobalPage && styles2.livePopoup}`}
          style={
            getMobileOS() === 'unknown'
              ? {
                width: playerZoom ? '' : 'auto',
                zoom: playerZoom ? playerZoom : '100%',
                maxHeight:
                  !fullScreen &&
                    auxHideChat &&
                    isMobile &&
                    !isVerticalLayout &&
                    status === 'FINALIZED'
                    ? 'calc(356px + 2rem - 8px)'
                    : '',
              }
              : {
                maxHeight:
                  !fullScreen &&
                    auxHideChat &&
                    isMobile &&
                    !isVerticalLayout &&
                    status === 'FINALIZED'
                    ? 'calc(356px + 2rem - 8px)'
                    : '',
              }
          }
        >
          {showQuickView && (
            <VariationSelector
              showVariation={showVariation}
              setShowVariation={setShowVariation}
            />
          )}
          {showSidebarProducts || showProductsCarousel ? (
            <SliderProductMobile
              height={height}
              showSliderProducts={showSliderProducts}
              setShowSliderProducts={setShowSliderProducts}
              variationSelectorState={variationSelectorState}
            />
          ) : null}
          <div
            style={{ height: parseInt(height) }}
            className={`${showSidebarProducts && collectionId
                ? styles2.sliderProductContent
                : styles2.displayNone
              } ${isModalLive && styles2.flexAuto}`}
          >
            {showSidebarProducts && (
              <VerticalProductSlider
                height={(parseInt(height) - 58).toString()}
                variationSelectorState={variationSelectorState}
                transmitionType={transmissionType}
              />
            )}
          </div>
          <div
            style={
              detector === 'unknown'
                ? isModalLive &&
                  !isInGlobalPage &&
                  transmissionType === 'vertical'
                  ? { height: '100%', width: '25vw' }
                  : { height: '100%' }
                : transmissionType === 'vertical' &&
                  getDeviceType() === 'desktop'
                  ? { height: '100%' }
                  : { width: '100%' }
            }
            className={`${transmissionType === 'vertical'
                ? styles2.videoContainerVertical
                : styles2.videoContainer
              } ${isModalLive &&
              transmissionType !== 'vertical' &&
              !isInGlobalPage &&
              styles2.videoContainerPopoup
              }`}
          >
            <div
              ref={divVideoContent}
              className={`${isModalLive && !isInGlobalPage && styles2.heightPopoup
                } ${styles2.fittedContainer}`}
              style={transmissionType === 'horizontal' ? { width: '100%' } : {}}
            >
              <div
                className={`${isModalLive && !isInGlobalPage && styles2.heightPopoup
                  } ${styles2.videoContent}`}
              >
                {playerScriptLoaded && (
                  <Feed
                    isPlayerSupported={isPlayerSupported}
                    variationSelectorState={variationSelectorState}
                    streamUrl={streamUrl}
                    transmitionType={transmissionType}
                    livestreamingStatus={status}
                    setFullScreen={setFullScreen}
                    hideChat={auxHideChat && !fullScreen}
                    setIvsRealTime={setIvsRealTime}
                  />
                )}
                <div
                  className={`${styles2.feedHeader} ${isMobile && styles2.feedHeaderMobile
                    }`}
                >
                  <div
                    className={`${styles2.leftHeader} ${isMobile && styles2.leftHeaderMobile
                      } ${isMobile &&
                      status === 'FINALIZED' &&
                      styles2.leftHeaderMobileFinalized
                      }`}
                  >
                    <Live
                      isMobile={isMobile}
                      isFinalized={status === 'FINALIZED'}
                      ivsRealTime={ivsRealTime}
                    />
                    <Viewers
                      isMobile={isMobile}
                      isFinalized={status === 'FINALIZED'}
                      ivsRealTime={ivsRealTime}
                    />
                  </div>
                  <div className={styles2.rightHeader}>
                    {(showSidebarProducts || showProductsCarousel) &&
                      !isMobile ? (
                      <ButtonProductsMobile
                        setShowSliderProducts={setShowSliderProducts}
                      />
                    ) : null}
                    {getMobileOS() !== 'unknown' &&
                      isModalLive &&
                      !isInGlobalPage && (
                        <div
                          className={styles2.closePopoup}
                          onClick={() => {
                            setLoading(true);
                            setIsModalLive(false);
                          }}
                        >
                          <IconClose />
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className={styles.horizontalProductsContent}>
                {showProductsCarousel && !isModalLive && (
                  <HorizontalProductSlider
                    variationSelectorState={variationSelectorState}
                    transmitionType={transmissionType}
                  />
                )}
              </div>

              {/* {LowerBanner && !isMobile && !isModalLive && <LowerBanner />} */}
            </div>
          </div>
          <div
            className={`${showChat
                ? `${styles2.chatContent} ${isMobile &&
                isVerticalLayout &&
                styles2.chatContentMobileVertical
                } ${status === 'FINALIZED' &&
                isMobile &&
                isVerticalLayout &&
                styles2.chatContentMobileVerticalFinalized
                } ${collectionId &&
                showCarouselChat &&
                !isChatFocus &&
                isMobile &&
                isVerticalLayout &&
                styles2.chatContentMobileVerticalCarouselOpen
                } ${status === 'FINALIZED' &&
                collectionId &&
                showCarouselChat &&
                isMobile &&
                isVerticalLayout &&
                styles2.chatContentMobileVerticalCarouselOpenFinalized
                } ${!isVerticalLayout &&
                isMobile &&
                styles2.chatContentMobileHorizontal
                } ${status === 'FINALIZED' &&
                !isVerticalLayout &&
                isMobile &&
                styles2.chatContentMobileHorizontalFinalized
                } ${status === 'FINALIZED' &&
                isVerticalLayout &&
                isMobile &&
                styles2.verticalFinalized
                }`
                : styles2.displayNone
              } ${isModalLive && styles2.flexAuto}`}
            style={
              getDeviceType() !== 'mobile'
                ? { height: parseInt(height) }
                : { height: 'auto' }
            }
          >
            {showChat && (
              <Chat
                pinnedMessage={pinnedMessage}
                transmitionType={transmissionType}
                initShowGif={showGifButton}
                livestreamingStatus={status}
                isVerticalLayout={isVerticalLayout}
                fullScreen={fullScreen}
                hideChat={hideChat ? hideChat : auxHideChat}
                setUnreadMessages={setUnreadMessages}
              />
            )}
            {showChat &&
              !hideChat &&
              isMobile &&
              status === 'FINALIZED' &&
              isVerticalLayout && (
                <div className={styles2.chatReplayMobileVertical}>
                  {formatMessage({
                    id: 'chat.chat-replay-mobile-vertical-layout-message',
                  })}
                </div>
              )}
          </div>
          {isMobile &&
            showChat &&
            (isVerticalLayout ||
              (!isVerticalLayout && status === 'FINALIZED' && !fullScreen)) &&
            !isChatFocus && (
              <div
                role="button"
                tabIndex={0}
                className={`${isVerticalLayout
                    ? styles.playerVideoMobileChatButtonPosition
                    : styles.playerVideoMobileChatButtonPositionHorizontal
                  } ${status === 'FINALIZED' &&
                  isVerticalLayout &&
                  styles.playerVideoMobileChatButtonPositionFinalized
                  } ${isVerticalLayout &&
                  showCarouselChat &&
                  collectionId &&
                  styles.playerVideoMobileChatButtonPositionCarouselOpen
                  } ${status === 'FINALIZED' &&
                  isVerticalLayout &&
                  showCarouselChat &&
                  collectionId &&
                  styles.playerVideoMobileChatButtonPositionCarouselOpenFinalized
                  }`}
                aria-label="Player video mobile chat button position"
                onClick={() => {
                  setUnreadMessages(0);
                  if (!setHideChat) return;
                  setHideChat(prev => !prev);
                }}
              >
                {(hideQuestion || questionTime === 0) && (
                  <HideChatMobile
                    isVerticalLayout={isVerticalLayout}
                    hideChat={hideChat}
                    unreadMessages={unreadMessages}
                  />
                )}
              </div>
            )}
          {isMobile &&
            showSidebarProducts &&
            socketShowCarouselChatButton &&
            isVerticalLayout &&
            collectionId &&
            !isChatFocus && (
              <div
                role="button"
                tabIndex={0}
                className={`${styles.playerVideoMobileCarouselButtonPosition} ${status === 'FINALIZED' &&
                  styles.playerVideoMobileCarouselButtonPositionFinalized
                  } ${showCarouselChat &&
                  styles.playerVideoMobileCarouselButtonPositionOpen
                  } ${status === 'FINALIZED' &&
                  showCarouselChat &&
                  styles.playerVideoMobileCarouselButtonPositionOpenFinalized
                  }`}
                aria-label="Player video mobile carousel button position"
                onClick={() => {
                  if (!setShowCarouselChat) return;
                  setShowCarouselChat(prev => !prev);
                }}
              >
                <ShopIcon showCarouselChat={showCarouselChat} />
              </div>
            )}
          {getMobileOS() === 'unknown' && isModalLive && !isInGlobalPage && (
            <div
              className={styles2.closePopoupDeskotp}
              onClick={() => {
                setLoading(true);
                setIsModalLive(false);
              }}
            >
              <IconClose />
            </div>
          )}
          {isMobile &&
            mobileHoverCtrlsProps &&
            isVerticalLayout &&
            status === 'FINALIZED' && (
              <MobileHoverControls {...mobileHoverCtrlsProps} />
            )}
        </div>

        {/* {LowerBanner && isMobile && !isModalLive && <LowerBanner />} */}

        {isModalLive && !isInGlobalPage && (
          <div className={styles2.backdropContainer} />
        )}
      </div>
    </div>
  );
};
