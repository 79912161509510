import { formatCurrency } from '@nizza/utils';
import { PlayerProduct } from 'lib/types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSettings } from '../../context';
import { useAddToCart } from '../../hooks';
import { useHighlightProduct } from '../../hooks/useHighlightProduct';
import { DesktopOutput } from './HighlightDesktopOutput';
import { MobileOutput } from './HighlightMobileOutput';

import styles from './highlightProduct.module.css';
interface HighlightProductProps {
  fullScreen: boolean;
  handleFullScreen: () => void;
  variationSelectorState: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
  isFinalized: boolean;
  isVerticalLayout: boolean;
  isMobile: boolean;
  setShowHighlightFlag?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

export default function HighlightProducts({
  fullScreen,
  handleFullScreen,
  variationSelectorState,
  isFinalized,
  isVerticalLayout,
  isMobile,
}: HighlightProductProps) {
  const { formatMessage } = useIntl();

  const {
    collectionId,
    infoSocket,
    setCollection,
    accountCurrencyConfig,
    productsInBD,
    setProductsInBD,
  } = useSettings();

  const { highlightProduct: highlightProductSocket } = infoSocket || {};

  const highlightProducts = useMemo(
    () => highlightProductSocket ?? { products: [] },
    [highlightProductSocket],
  );

  const { productHighlight } = useHighlightProduct({
    highlightProducts,
    collectionId,
    isFinalized,
    setCollection,
    productsInBD,
    setProductsInBD,
  });

  const addToCart = useAddToCart({ variationSelectorState });

  const getNormalPriceFormat = useCallback(
    (product: PlayerProduct) =>
      formatCurrency(
        product?.priceWithDiscount ?? 0,
        accountCurrencyConfig,
      ),
    [accountCurrencyConfig],
  );

  const getDeconstructedPrice = useCallback(
    (product: PlayerProduct) => {
      return JSON.parse(
        formatCurrency(
          product?.priceWithDiscount ?? 0,
          accountCurrencyConfig,
          true,
        ) ??
        '{integerPart: "0",decimalPart: "00",symbol: "$", decimalSeparator: "."}',
      );
    },
    [accountCurrencyConfig],
  );

  const getSelectorCss = (attribute: [string, string]) => (right: boolean) =>
    right ? styles[attribute[0]] : styles[attribute[1]];

  if (!collectionId) return null;

  if (isMobile) {
    const componentsHighlightMobile = [...productHighlight].map(product => (
      <MobileOutput
        key={product.id}
        fullScreen={fullScreen}
        handleFullScreen={handleFullScreen}
        isVerticalLayout={isVerticalLayout}
        addToCart={() => addToCart(product)}
        animationToShow={true}
        product={product}
        deconstructedPrice={getDeconstructedPrice(product)}
        formatMessage={formatMessage}
        normalPriceFormat={getNormalPriceFormat(product)}
      />
    ));

    return (
      <div
        className={getSelectorCss([
          'highlightListMobileVertical',
          'highlightListMobileHorizontal',
        ])(isVerticalLayout)}
      >
        {componentsHighlightMobile}
      </div>
    );
  }

  const componentsHighlightDesktop = [...productHighlight].map(product => (
    <DesktopOutput
      key={product.id}
      fullScreen={fullScreen}
      handleFullScreen={handleFullScreen}
      variationSelectorState={variationSelectorState}
      addToCart={() => addToCart(product)}
      product={{
        ...product,
        source: 'live_shopping_highlight_product',
      }}
      formatMessage={formatMessage}
      normalPriceFormat={getNormalPriceFormat(product)}
    />
  ));

  return (
    <div
      className={getSelectorCss([
        'highlightListDesktopVertical',
        'highlightListDesktopHorizontal',
      ])(isVerticalLayout)}
    >
      {componentsHighlightDesktop}
    </div>
  );
}
