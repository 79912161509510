import { ChatMessage } from '@nizza/core';
import { AlertVariants } from '@vtex/admin-ui';
import { DisclosureState } from 'ariakit/ts/disclosure/disclosure-state';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { ReactNode } from 'react';
import { AdminChatConfig } from './types';

// Settings supplied to the chat component
// It is configured in each integration environment (vtex.io, vtex cms and platform)
export const chatConfigAtom = atom<AdminChatConfig>({} as AdminChatConfig);
chatConfigAtom.debugLabel = 'chatConfigAtom';

// defines whether the chat is loading the necessary resources to run
export const isChatLoadingAtom = atom<boolean>(false);
isChatLoadingAtom.debugLabel = 'isChatLoadingAtom';

// Selected message to answer a question in the chat
export const selectedMessageForReplyAtom = atom<ChatMessage | null>(null);
selectedMessageForReplyAtom.debugLabel = 'selectedMessageForReplyAtom';

// Message pinned in the chat
export const pinnedMessageAtom = atom<ChatMessage | null>(null);
pinnedMessageAtom.debugLabel = 'pinnedMessageAtom';

// Administrator name of user
export const adminNameAtom = atomWithStorage<string>('adminName', 'Admin'); // TODO: traducciones
adminNameAtom.debugLabel = 'adminNameAtom';

export const confirmModalAtom = atom<DisclosureState | null>(null);
confirmModalAtom.debugLabel = 'confirmModalAtom';

export const confirmModalConfigAtom = atom<{
  title: string;
  content: string | ReactNode;
  onConfirm: () => void;
}>({
  title: 'are you sure?',
  content: '',
  onConfirm: () => {
    throw new Error('No implemented');
  },
});
confirmModalConfigAtom.debugLabel = 'confirmModalConfigAtom';

export const alertConfigAtom = atom<{
  text: string;
  variant: AlertVariants;
  showing: boolean;
}>({
  text: '',
  variant: 'info',
  showing: false,
});
alertConfigAtom.debugLabel = 'alertConfigAtom';

export const customModalAtom = atom<DisclosureState | null>(null);
confirmModalAtom.debugLabel = 'customModalAtom';
