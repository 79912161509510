/* eslint-disable react-hooks/exhaustive-deps */
import { EventNames, useNZ } from '@nizza/core';
import { finishEvent, getEventStatus, startEvent } from 'api';
import { useEffect, useRef, useState } from 'react';
import { EventStatus } from 'types';


export const useFetchEventStatus = (
  eventId: string,
  account: string | undefined,
) => {
  const nizza = useNZ();
  const timeOutVar = useRef<number>();
  const [eventStatus, setEventStatus] = useState<EventStatus>(
    EventStatus.CREATED,
  );
  const [loadingStatus, setLoadingStatus] = useState(true);

  const checkEventStatus = () => {
    getEventStatus(eventId, account).then(resp => {
      if (resp.success) {
        setEventStatus(resp.data.status);
        if (resp.data.status !== EventStatus.STARTING) setLoadingStatus(false);
        setTimeout(
          () =>
            nizza.eventBus.emit(
              EventNames.EVENT_STATUS_CHANGED,
              resp.data.status,
            ),
          1000,
        );
      }
    });
  };

  useEffect(() => {
    if (!account) return;
    checkEventStatus();
  }, [eventId, account]);

  useEffect(() => {
    if (eventStatus === EventStatus.LIVE) validateEventStatus(true);
  }, [eventStatus]);

  const validateEventStatus = (isLive: boolean) => {
    checkEventStatus();

    if (isLive) {
      setLoadingStatus(false);
      clearTimeout(timeOutVar.current);
    } else {
      timeOutVar.current = window.setTimeout(validateEventStatus, 10000);
    }
  };

  const changeStatus = () => {
    if (!account) return;

    setLoadingStatus(true);

    if (eventStatus === EventStatus.CREATED) {
      // setEventStatus(null);
      startEvent(eventId, account).then(resp => {
        if (resp.success) {
          validateEventStatus(false);
        }
      });
    } else if (eventStatus === EventStatus.LIVE) {
      finishEvent(eventId, account).then(resp => {
        if (resp.success) {
          setLoadingStatus(false);
          setEventStatus(EventStatus.FINALIZED);
        }
      });
    }
  };

  return { eventStatus, loadingStatus, changeStatus, setEventStatus };
};
