/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoute } from '../../types';
import {
  AppConfigContext,
  AppConfigCtx,
  AppConfigCtxDefault,
} from './appConfigContext';

interface Props {
  consumer?: (context: AppConfigCtx) => ReactNode;
  children: ReactNode;
}

export const AppConfigProvider: FC<Props> = props => {
  const { children, consumer } = props;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [protectedRoutes, setProtectedRoutes] = useState<AppRoute[]>([]);

  const layout = useMemo(() => {
    const data = query.get('layout');
    return JSON.parse(data ? atob(data) : 'null');
  }, []);

  const modules = useMemo(() => {
    const data = query.get('modules');
    const parsed = JSON.parse(data ? atob(data) : 'null');

    if (parsed?.omit?.length && parsed?.only?.length) {
      throw new Error(
        "The configuration for AppConfig.modules is ambiguous, you have defined 'omit' and 'only' at the same time, define only one of them",
      );
    }

    return parsed;
  }, []);

  const components = useMemo(() => {
    const data = query.get('components');
    const parsed = JSON.parse(data ? atob(data) : 'null');

    return parsed && Object.keys(parsed).length ? parsed : null;
  }, []);

  const auth = useMemo(() => {
    const data = query.get('auth');
    return JSON.parse(data ? atob(data) : 'null');
  }, []);

  const context: AppConfigCtx = useMemo(
    () => ({
      ...AppConfigCtxDefault,
      ...(layout && { layout }),
      ...(modules && { modules }),
      ...(components && { components }),
      ...(auth && { auth }),
      protectedRoutes,
      setProtectedRoutes,
    }),
    [layout, modules, protectedRoutes],
  );

  return (
    <AppConfigContext.Provider value={context}>
      {consumer ? consumer(context) : children}
    </AppConfigContext.Provider>
  );
};
