import { createStore } from '@nizza/core';
import { SchemaStore } from './SchemaStore';
import { UiManagementStore } from './UiManagementStore';

export * from './SchemaStore';
export * from './UiManagementStore';

export class CustomizeLiveStore {
  public uiStore: UiManagementStore;
  public schemaStore: SchemaStore;
  constructor() {
    this.uiStore = new UiManagementStore();
    this.schemaStore = new SchemaStore();
  }
}

export const customizeLiveStore = createStore(CustomizeLiveStore);
