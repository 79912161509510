import { ApiGraphqlTypes as AGT } from '@nizza/core';
import { TimelineRepository } from '../../domain';
import { GraphQLClient } from 'graphql-request';
import env from '~env';
import { Timeline } from 'api/liveShopping/domain/entities';

const API_URL = env.apiUrls.graphql;

export class TimelineGraphqlRepository implements TimelineRepository {
  private client: GraphQLClient;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async getTimeLine(account: string, idEvent: string): Promise<Timeline[]> {
    const { getTimeLine } = await this.client.request(AGT.GetTimeLineDocument, {
      data: {
        account,
        idEvent,
      },
    });

    this.propagateGraphqlErrorIfExists(getTimeLine as AGT.GetTimeLineResult);

    const data = (getTimeLine as AGT.TimeLineArray)?.data;
    if (!data) return [];
    return data?.map(
      x =>
        ({
          hour: x.hour,
          minute: x.minute,
          time: x.time,
          likes: x.likes,
          comments: x.comments,
          viewers: x.viewers,
          orders: x.orders,
        } as Timeline),
    );
  }

  private propagateGraphqlErrorIfExists(res: AGT.GetTimeLineResult) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
