import { createContext, useContext } from 'react';
import { BackgroundType } from 'types';

export interface HighlightProductCtx {
  backgroundSelected: BackgroundType;
  productsSelected: string[];
  setBackgroundSelected: (value: BackgroundType) => void;
  setProductsSelected: (value: string[]) => void;
}

export const highlightProductCtxDefault: HighlightProductCtx = {
  backgroundSelected: 'white',
  setBackgroundSelected: () => null,
  productsSelected: [],
  setProductsSelected: () => null,
};

export const HighlightProductContext = createContext<HighlightProductCtx>(
  highlightProductCtxDefault,
);
export const useHighlightProduct = () => useContext(HighlightProductContext);
