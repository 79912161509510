import {
  ActionExecutedPayload,
  AddToCartActionTypes,
  EventBusWildcards,
  EventNames,
  useNZ,
} from '@nizza/core';
import { useEventEffect } from '@nizza/utils';
import logger from '~logger';
import { InfoSocket, PlayerProduct } from '../types';
import { getDeviceType } from '../utils';

export const useAddToCartEventListener = (infoSocket?: InfoSocket) => {
  const nizza = useNZ();

  const typesForNotifySocket = [
    AddToCartActionTypes.ADD_TO_CART,
    AddToCartActionTypes.OPEN_TAB,
  ];

  // Listen all add to cart hook events from eventBus
  useEventEffect(nizza, EventBusWildcards.ADD_TO_CART_HOOKS, event => {
    if (event.name === EventNames.ATCH_ACTION_EXECUTED) {
      const { success, actionType, product }: ActionExecutedPayload =
        event.data;

      if (success && typesForNotifySocket.includes(actionType) && infoSocket) {
        notifySocket(infoSocket, product as PlayerProduct);
      }
    }
  });
};

function notifySocket(infoSocket: InfoSocket, product: PlayerProduct) {
  const socket = infoSocket.socket;
  const isMobile = getDeviceType() === 'mobile';

  if (socket?.readyState === 1) {
    const currentCart = {
      action: 'sendaddtocart',
      data: {
        productId: product.id,
        name: product.name,
        imageUrl: product.imageUrl,
      },
      sessionId: infoSocket?.sessionId,
      sectionIdClickedOn:
        product?.source ?? getEventAddToCartSource() ?? 'Undefined',
      isMobile: isMobile,
      email: '-',
      orderForm:
        window?.vtexjs?.checkout?.orderForm?.orderFormId ??
        sessionStorage.getItem('currentOrderForm'),
    };
    socket.send(JSON.stringify(currentCart));
    window.sessionStorage.cartCachedOrderFormId = currentCart.orderForm;
  }
}

function getEventAddToCartSource() {
  try {
    const eventAddToCartStorage = localStorage.getItem(
      'sectionIdClickedOnForAddToCart',
    );

    if (eventAddToCartStorage) {
      const { sectionIdClickedOn } = JSON.parse(eventAddToCartStorage);
      return sectionIdClickedOn;
    }
  } catch (err) {
    logger.error('getEventAddToCartSource:', err);
  }
  return 'Undefined';
}

export default useAddToCartEventListener;
