/* eslint-disable react-hooks/exhaustive-deps */
import { useNZ } from '@nizza/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createSubscription,
  getAccountInformation,
  updateAdminUsername,
  updateSectionsGlobal,
  updateSubscription,
  updateSubscriptionStatus,
  updateSubscriptionType,
} from 'api';
import { useExternalUpdatePlan } from 'hooks/billing';
import { FC, ReactNode, useEffect, useState } from 'react';
import { AccountInfo } from 'types';
import { useAppConfig } from '../appConfig';
import { useCognito } from '../cognito';
import {
  ADMIN_NAME,
  AccountContext,
  AccountCtx,
  AccountCtxDefault,
} from './accountContext';


type ExternalAccount = string | null;

interface Props {
  externalAccount?: string;
  retryAccountQuery?: any;
  consumer?: (context: AccountCtx) => ReactNode;
  children: ReactNode;
}

export const AccountProvider: FC<Props> = props => {
  const nizza = useNZ();
  const { children, consumer } = props;
  const queryClient = useQueryClient();
  const { cognitoUser } = useCognito();
  const { auth } = useAppConfig();
  const [account, setAccount] = useState<string>('');
  const [retryAccountQuery, setRetryAccountQuery] = useState<any>(
    props.retryAccountQuery ?? true,
  );

  const cognitoAccount = cognitoUser?.attributes?.['custom:account'];

  const [externalAccount, setExternalAccount] = useState<ExternalAccount>(
    props.externalAccount || null,
  );

  const [eventAccountParams, setEventAccountParams] = useState<string | null>(
    null,
  );

  const {
    status: accountInfoStatus,
    data: accountInfo = AccountCtxDefault.accountInfo,
    refetch: refetchInfoAccount,
  } = useQuery<AccountInfo>(
    ['accountInfo', account],
    async ({ queryKey: [_, account] }) =>
      getAccountInformation(account as string),
    {
      refetchOnWindowFocus: false,
      enabled: !!account,
      retry: retryAccountQuery,
    },
  );

  useExternalUpdatePlan({ account, refetchInfoAccount });

  const adminName = accountInfo.username || ADMIN_NAME;

  const { mutate: changeAdminName, status: adminNameStatus } = useMutation(
    updateAdminUsername,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['accountInfo']);
      },
    },
  );

  const { mutate: changeSubscriptionType, isLoading: loadingSubscriptionType } =
    useMutation(updateSubscriptionType, {
      onSuccess: () => {
        queryClient.invalidateQueries(['accountInfo']);
      },
    });

  const { mutate: changeSection, isLoading: loadingSection } = useMutation(
    updateSectionsGlobal,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['accountInfo']);
      },
    },
  );

  const { mutate: changeSubscriptionStatus } = useMutation(
    updateSubscriptionStatus,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['accountInfo']);
      },
    },
  );

  /**
   * The account is defined manually to avoid the login process,
   * this is used by external platforms such as shopify
   */
  useEffect(() => {
    if (!auth?.account) return;
    setAccount(auth?.account);
    nizza.configure({ account: auth?.account });
  }, [auth]);

  useEffect(() => {
    if (!externalAccount && !cognitoAccount) return;
    nizza.configure({ account: externalAccount ?? cognitoAccount });
    setAccount(externalAccount ?? btoa(cognitoAccount));
  }, [externalAccount, cognitoAccount]);

  const context: AccountCtx = {
    accountInfo,
    accountInfoStatus,
    adminName,
    adminNameStatus,
    loadingSubscriptionType,
    externalAccount,
    eventAccountParams,
    changeAdminName,
    changeSubscriptionType,
    setExternalAccount,
    setEventAccountParams,
    changeSection,
    loadingSection,
    createSubscription,
    changeSubscriptionStatus,
    updateSubscription,
    refetchInfoAccount,
    setAccount,
    accountInBase64: account,
    setRetryAccountQuery,
  };

  return (
    <AccountContext.Provider value={context}>
      {consumer ? consumer(context) : children}
    </AccountContext.Provider>
  );
};
