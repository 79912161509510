interface DomainErrorDTO {
  name: string;
  message: string;
  value?: any;
}

export class DomainError extends Error {
  constructor(name: string, message: string, public value?: any) {
    super(message);
    this.name = name;
  }

  toDTO(): DomainErrorDTO {
    return {
      name: this.name,
      message: this.message,
      ...(this.value && { value: this.value }),
    };
  }
}
