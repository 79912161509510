/* eslint-disable react-hooks/exhaustive-deps */
import { Product } from '@nizza/event-products';
import { useHighlightProduct } from 'hooks';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import styled from 'styled-components';

interface Props {
  data: Product;
}

const MAX_HIGHLIGHT_ALLOW = 2;

export const ProductItem: FC<Props> = props => {
  const { t } = useTranslation();
  const { productsSelected, setProductsSelected } = useHighlightProduct();
  const { data } = props;

  const toogleSelectProductResult = (id: string, products_: string[]) => {
    const productsDeep = [...products_];
    if (!productsDeep.includes(id)) return productsDeep.concat(id);
    return productsDeep.filter(p => p !== id);
  };

  const selectProduct = (id: string) =>
    setProductsSelected(toogleSelectProductResult(id, productsSelected));

  const isMarked = useMemo(
    () => productsSelected.includes(data.id),
    [productsSelected, data],
  );

  const isDisabled = useMemo(
    () => !isMarked && productsSelected.length >= MAX_HIGHLIGHT_ALLOW,
    [productsSelected.length, isMarked],
  );

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center" style={{ gap: '1rem' }}>
        <WrapperProductImage className="w-20 d-flex align-items-center justify-content-center">
          <ProductImage src={data.image} alt="product" />
        </WrapperProductImage>
        <div>
          <h5>{data.title}</h5>
          <h6 className="font-size-15">
            {data.hasOffer ? (
              <>
                <del className="text-muted" style={{ marginRight: '15px' }}>
                  {t('FormatCurrency', { price: data.price })}
                </del>
                <b>{t('FormatCurrency', { price: data.salesPrice })}</b>
              </>
            ) : (
              <b>{t('FormatCurrency', { price: data.price })}</b>
            )}
          </h6>
        </div>
      </div>

      <Button
        disabled={isDisabled}
        color={`${isMarked ? 'secondary' : 'primary'}`}
        onClick={() => selectProduct(data.id)}
      >
        {isMarked
          ? t('LiveShopping.HighlightProduct.SecondaryButtonTitle')
          : t('LiveShopping.HighlightProduct.ButtonTitle')}
        <i className="bx bx-award" style={{ marginLeft: '0.3rem' }} />
      </Button>
    </div>
  );
};

const WrapperProductImage = styled.div`
  width: 80px;
  height: 80px;
`;
const ProductImage = styled.img`
  max-width: 70px;
  max-height: 70px;
`;
