import { Breadcrumb, PageSpinner } from 'app/components';
import { ProductListProvider } from 'hooks';
import { useIsAccountActive } from 'hooks/utils/useIsAccountActive';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

export const ProductList = () => {
  const { t } = useTranslation();
  const isAccountActive = useIsAccountActive();

  return (
    <ProductListProvider
      consumer={({ productList, loadingProducts }) => (
        <div className="page-content">
          <Helmet>
            <title>{t('Products.List.Title')}</title>
          </Helmet>

          {loadingProducts ? (
            <PageSpinner />
          ) : (
            <Container fluid>
              <Breadcrumb
                items={[
                  { title: t('Menu.Products'), link: '/products' },
                  { title: t('Products.List.Title') },
                ]}
              />
              {!isAccountActive ? (
                <Card>
                  <CardBody>
                    <p>{t('LiveShopping.Events.DeniedPermissions')}</p>
                  </CardBody>
                </Card>
              ) : (
                <Row>
                  <Col>
                    <Row>
                      {!!productList.length &&
                        productList.map((product, key) => (
                          <Col
                            xl="3"
                            md="4"
                            sm="6"
                            key={'_col_' + key}
                            className="d-flex align-items-stretch"
                          >
                            <Card className="w-100">
                              <CardBody>
                                <Link to={product.detailLink}>
                                  <div className="product-img position-relative">
                                    {product.hasOffer && (
                                      <div className="avatar-sm product-ribbon">
                                        <span className="avatar-title rounded-circle bg-primary">
                                          {`-${product.offer}%`}
                                        </span>
                                      </div>
                                    )}
                                    <ProductImg
                                      src={product.image}
                                      alt={product.title}
                                    ></ProductImg>
                                  </div>
                                </Link>

                                <div className="mt-4 text-center">
                                  <h5 className="mb-3 text-truncate">
                                    <Link
                                      to={product.detailLink}
                                      className="text-dark"
                                    >
                                      {product.title}{' '}
                                    </Link>
                                  </h5>
                                  <h5 className="my-0">
                                    {product.hasOffer ? (
                                      <>
                                        <del className="text-muted d-block mb-1">
                                          {t('FormatCurrency', {
                                            price: product.price,
                                          })}
                                        </del>
                                        <b>
                                          {t('FormatCurrency', {
                                            price: product.salesPrice,
                                          })}
                                        </b>
                                      </>
                                    ) : (
                                      <b>
                                        {t('FormatCurrency', {
                                          price: product.price,
                                        })}
                                      </b>
                                    )}
                                  </h5>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
              )}
            </Container>
          )}
        </div>
      )}
    />
  );
};

const ProductImg = styled.img`
  width: 100%;
  height: 18vw;
  object-fit: contain;
`;
