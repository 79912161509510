import { Livestreaming } from '@nizza/player';
import { FC, memo } from 'react';
import { isProduction } from 'shared';
import { PropsLiveShopping } from 'types';

interface Props {
  liveProps: PropsLiveShopping;
  renderOnError?: JSX.Element;
}

export const Preview: FC<Props> = memo(props => {
  const { liveProps } = props;
  return (
    <div style={{ minHeight: 'auto' }}>
      <Livestreaming
        account={liveProps.account}
        environment={isProduction ? 'prod' : 'dev'}
        idLivestreaming={liveProps.idLivestreaming}
        isInGlobalPage={liveProps.isInGlobalPage}
        isInfinite={liveProps.isInfinite}
        originOfProducts={liveProps.originOfProducts || ''}
        redirectTo={liveProps.pdp}
        showChat={liveProps.inactivateChat}
        showLike={liveProps.inactivateLike}
        showQuickView={liveProps.quickView}
        showProductsCarousel={liveProps.inactiveProductsCarousel}
        showSidebarProducts={liveProps.inactiveSidebarProducts}
        showViewers={liveProps.inactivateViewers}
        time={liveProps.time}
      />
    </div>
  );
});
