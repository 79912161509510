/* eslint-disable no-unused-vars */
import { getFingerprint } from '@nizza/utils';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Heart,
  HighlightProduct,
  InfoSocket,
  Message,
  PlayerProduct,
  PromotionTrigger,
  Question,
  ScriptProperties,
} from '../types';
import { Queue, getDeviceType, getRandomColor } from '../utils';
import { useSessionId } from './useSessionId';

declare interface Props {
  wssStream: string | undefined;
}

const useWebSocket = ({ wssStream }: Props): InfoSocket => {
  const [socket, setSocket] = useState<WebSocket>();
  const [chat, setChat] = useState<Message[]>([]);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [hearts, setHearts] = useState<Heart[]>([]);
  const [showCounter, setShowCounter] = useState<boolean | undefined>(true);
  const [showGif, setShowGif] = useState<boolean | undefined>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showCarouselChatButton, setShowCarouselChatButton] = useState<
    boolean | undefined
  >();
  const [highlightProduct, setHighlightProduct] = useState<
    HighlightProduct | undefined
  >();
  const { sessionId } = useSessionId();
  const [uniqueViewer, setUniqueViewer] = useState(false);
  const [scriptProperties, setScriptProperties] = useState<
    ScriptProperties | undefined
  >();
  const [emailIsRequired, setEmailIsRequired] = useState<boolean | undefined>();
  const [question, setQuestion] = useState<Question>();
  const [queueLikeSocket, setQueueLikeSocket] = useState<
    Queue<number> | undefined
  >();
  const [queueBubbleProductSocket, setQueueBubbleProductSocket] = useState<
    Queue<number> | undefined
  >();
  const [messageToDelete, setMessageToDelete] = useState<Message | undefined>();
  const [pinnedMessage, setPinnedMessage] = useState<Message | undefined>();
  const [transmitiontype, setTransmitiontype] = useState<string | undefined>();
  const [productsInCart, setProductsInCart] = useState<PlayerProduct[]>([]);
  const [activePromo, setActivePromo] = useState<PromotionTrigger>();
  const [updateLivestreaming, setUpdateLivestreaming] = useState<string>();
  const [showTax, setShowTax] = useState<boolean | undefined>(true);

  const createWebSocket = useCallback(() => {
    let queueLikeSocketInit: Queue<number>;
    let queueBubbleProductSocketInit: Queue<number>;

    if (!wssStream || socket) return;
    const connection = new WebSocket(wssStream as string);

    connection.onopen = () => {
      setIsConnected(true);
      queueLikeSocketInit = new Queue();
      queueBubbleProductSocketInit = new Queue();
      setQueueLikeSocket(queueLikeSocketInit);
      setQueueBubbleProductSocket(queueBubbleProductSocketInit);
    };

    connection.onclose = () => {
      setIsConnected(false);
      setSocket(undefined);
      createWebSocket();
    };

    connection.onerror = () => {
      connection.close();
    };

    connection.onmessage = (event: MessageEvent) => {
      const {
        action,
        data,
        sendDate,
        username,
        showGifButton,
        products,
        collection,
        livestreamingId,
        sidebarProducts,
        productsCarousel,
        chat,
        like,
        infinite,
        time,
        backgroundWhiteHighlight,
        pdp,
        emailIsRequired,
        isAdmin,
        responseAdmin,
        isInGlobalPage,
        type,
        showQuickView,
        showCarouselChatButton,
        viewers,
        trigger,
        showTax,
      } = JSON.parse(event.data);

      switch (action) {
        case 'sendmessage':
          setChat(prev => [
            {
              data,
              username,
              sendDate,
              isAdmin,
              responseAdmin,
              type,
              color:
                prev.find(i => i.username === username)?.color ||
                getRandomColor(),
            },
            ...prev,
          ]);
          setShowLoader(false);
          break;

        case 'sendlike':
          if (document.hidden) break;
          if (queueLikeSocketInit.size() <= 4) {
            const id = Date.now();

            setHearts(prev => [
              ...prev,
              {
                id,
                color: getRandomColor(),
              },
            ]);
            queueLikeSocketInit.add(id);
          }
          break;

        case 'sendshowCounter':
          setShowCounter(data);
          setShowGif(showGifButton);
          setEmailIsRequired(emailIsRequired);
          setShowCarouselChatButton(showCarouselChatButton);
          setShowTax(showTax);
          break;

        case 'sendhighlightproduct':
          setHighlightProduct({
            products,
            collection,
            livestreamingId,
            backgroundWhiteHighlight,
          });
          break;

        case 'sendPropertiesToCms':
          setScriptProperties({
            sidebarProducts,
            productsCarousel,
            chat,
            like,
            infinite,
            time,
            showQuickView,
            pdp,
            isInGlobalPage,
            viewers,
          });
          break;

        case 'sendquestion':
          data.date = sendDate ?? Date.now();
          setQuestion(data);
          break;

        case 'senddeletemessage':
          setMessageToDelete(data);
          break;

        case 'sendpinmessage':
          setPinnedMessage(data);
          break;

        case 'sendunpinmessage':
          setPinnedMessage({});
          break;

        case 'sendtransmitiontype':
          setTransmitiontype(data);
          break;

        case 'sendaddtocart':
          if (queueBubbleProductSocketInit.size() <= 4) {
            const id = Date.now();

            setProductsInCart(prev => [
              ...prev,
              {
                id: id.toString(),
                name: '',
                price: 0,
                priceWithDiscount: 0,
                imageUrl: data.imageUrl,
                addToCartLink: '',
                items: [],
                isAvailable: false,
                variationSelector: [],
                pdpLink: '',
                skuId: '',
              },
            ]);

            queueBubbleProductSocketInit.add(id);
          }
          break;

        case 'sendactivepromotion':
          setActivePromo(trigger);
          setTimeout(() => {
            setActivePromo(undefined);
          }, 65000);
          break;
        case 'sendupdatelivestreaming':
          setUpdateLivestreaming(uuidv4());
          break;

        default:
          break;
      }
    };

    setSocket(connection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wssStream]);

  const sendAccountId = useCallback(
    (username?: string, email?: string) => {
      if (!isConnected || !socket) return;

      const getId = async () => {
        socket.send(
          JSON.stringify({
            action: 'sendaccountid',
            data: sessionId,
            username: username,
            device: getDeviceType(),
            email: email,
            fingerprint: getFingerprint(),
          }),
        );
      };

      getId().catch(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isConnected, socket, uniqueViewer],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!socket) return () => {};

    return () => {
      socket.close();
    };
  }, [socket]);

  useEffect(() => {
    createWebSocket();
  }, [createWebSocket]);

  useEffect(() => {
    if (sendAccountId) {
      sendAccountId();
    }
  }, [sendAccountId]);

  useEffect(() => {
    setTimeout(() => {
      setUniqueViewer(true);
    }, 10000);
  }, []);

  return {
    socket,
    chat,
    sessionId,
    hearts,
    showCounter,
    showGif,
    showLoader,
    setShowLoader,
    emailIsRequired,
    highlightProduct,
    scriptProperties,
    question,
    messageToDelete,
    setHearts,
    setChat,
    sendAccountId,
    showTax,
    setScriptProperties,
    setShowCounter,
    setEmailIsRequired,
    setQuestion,
    queueLikeSocket,
    queueBubbleProductSocket,
    setMessageToDelete,
    pinnedMessage,
    transmitiontype,
    productsInCart,
    setProductsInCart,
    setQueueLikeSocket,
    setQueueBubbleProductSocket,
    showCarouselChatButton,
    activePromo,
    updateLivestreaming,
    setShowTax,
  };
};

export default useWebSocket;
