import { ApiV1Response, customFetch, searchParams } from '@nizza/core';
import envs from '~env';
import {
  GetEventInput,
  NizzaEvent,
  NizzaEventRepository,
  RawNizzaEvent,
} from '../domain';

const BASE_URL = `${envs.apiUrls.restV1}/liveshopping/events`;

export class EventRestRepository implements NizzaEventRepository {
  async get(input: GetEventInput): Promise<NizzaEvent | null> {
    const { id, account, admin } = input;
    const params = searchParams({ account, ...(admin && { admin }) });
    const url = `${BASE_URL}/${id}${params}`;

    const { data } = await customFetch<ApiV1Response<RawNizzaEvent>>({
      url,
    });

    return NizzaEvent.toDomain(data);
  }
}
