import { AccountRestRepository } from './infrastructure';
import { AccountService } from './services';

const accountRepository = new AccountRestRepository();
const accountService = new AccountService(accountRepository);

export * from './domain';
export * from './infrastructure';
export * from './services';
export { accountService };
