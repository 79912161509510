import { CognitoUser } from 'amazon-cognito-identity-js';
import { FC, ReactNode, useState } from 'react';
import { AuthContext, AuthCtx } from '.';

interface Props {
  consumer?: (context: AuthCtx) => ReactNode;
  children: ReactNode;
}

export const AuthProvider: FC<Props> = ({ children, consumer }) => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

  const context: AuthCtx = {
    cognitoUser,
    setCognitoUser,
  };

  return (
    <AuthContext.Provider value={context}>
      {consumer ? consumer(context) : children}
    </AuthContext.Provider>
  );
};
