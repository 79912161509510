import validator from '@rjsf/validator-ajv6';
import Form, { FormProps } from '@rjsf/core';
import { observer } from 'mobx-react-lite';
import { RegistryWidgetsType } from '@rjsf/utils';

import { useCustomizations } from '../hooks';
import { TranslatableInput } from './widgets/TranslatableInput';

const widgets: RegistryWidgetsType = {
  translatableText: TranslatableInput,
};

export const FormSchema = observer(
  (
    props: Omit<FormProps, 'validator'> & {
      section: string;
      id: string;
      textSubmitButton: string;
      isDataChange: boolean;
      handleSaveItem: () => void;
    },
  ) => {
    const { section, id, textSubmitButton, isDataChange, handleSaveItem } =
      props;
    const {
      getDataComponent,
      saveDataComponent,
      selectComponent,
      saveDataInServer,
      isLoading,
    } = useCustomizations();

    const componentInstance = getDataComponent(props.section, props.id);

    return (
      <Form
        {...props}
        widgets={widgets}
        formData={componentInstance?.data}
        onChange={e =>
          saveDataComponent({
            data: e.formData,
            section,
            id,
          })
        }
        onSubmit={async e => {
          await saveDataInServer(componentInstance!);
          selectComponent(section, id, e.formData);
          handleSaveItem();
        }}
        validator={validator}
      >
        <button
          disabled={isLoading}
          type="submit"
          className={`btn ${
            !isDataChange ? 'btn-secondary disabled' : 'btn-info'
          }`}
        >
          {textSubmitButton}
        </button>
      </Form>
    );
  },
);
