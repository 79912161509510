import { createStore } from '@nizza/core';
import { isObservable, makeAutoObservable } from 'mobx';
import {
  Product,
  ProductProps,
  SyncStatus,
  createProduct,
} from '../../products';
import {
  Collection,
  CollectionInput,
  CollectionProps,
  CollectionSearchInput,
  CollectionUpdateInput,
  ProductToSync,
  collectionService,
} from '../core';
import { CollectionViewType } from '../utils';

export class CollectionStore {
  private _collectionList: Map<string, Collection> = new Map();
  private _isOpenModal = false;
  private _activeView: CollectionViewType = CollectionViewType.LIST;
  private _selectedCollection: Collection | null = null;

  private prevActiveView: CollectionViewType = CollectionViewType.LIST;
  private snapshots: Map<string, Record<string, any>> = new Map();

  constructor() {
    makeAutoObservable(
      this,
      {},
      { name: 'CollectionStore', autoBind: true, deep: true },
    );
  }

  openModal(type: CollectionViewType | null = null, collection?: Collection) {
    if (type === CollectionViewType.LIST) return;

    if (type) {
      this._isOpenModal = true;
      this.activeView = type;
      if (collection) this._selectedCollection = collection;
    } else {
      this._isOpenModal = false;
      this.resetView();
    }
  }

  openDetail(value: boolean, collection?: Collection) {
    if (value) {
      this.activeView = CollectionViewType.DETAIL;

      if (collection) {
        this._selectedCollection = collection;
      }
    } else {
      this.resetView();
    }
  }

  async create(input: CollectionInput) {
    const collection = await collectionService.create(input);
    this.addCollection(collection);
  }

  async update(input: CollectionUpdateInput) {
    const { id, ...rest } = input;
    const collection = this.getCollection(id);

    if (!collection) return;

    if (rest.products) {
      this.syncProducts(collection);
    } else {
      try {
        await collectionService.update({
          ...input,
          account: collection.account,
        });
        collection.root.setProps(input);
      } catch (err) {
        this.discardChanges();
        throw err;
      }
    }
  }

  async syncProducts(collection: Collection) {
    const snapshot = this.snapshots.get(collection.id);

    if (!snapshot) return;

    const snapshotProducts = (snapshot as CollectionProps).products;
    const existIn = (list: any[], id: string) => !!list.find(x => x.id === id);
    const productToSync = (x: any, method: 'add' | 'remove') =>
      ({
        account: x.account,
        id: collection.id,
        productId: x.id,
        method,
      } as ProductToSync);

    const toAdd = collection
      .toPrimitives()
      .products.filter(x => !existIn(snapshotProducts, x.id))
      .map(x => productToSync(x, 'add'));

    const toRemove = snapshotProducts
      .filter(x => !existIn(collection.toPrimitives().products, x.id))
      .map(x => productToSync(x, 'remove'));

    const res = await collectionService.syncProducts({ toAdd, toRemove });
    const allRes = [...res.added, ...res.removed];

    allRes.forEach(x => {
      this.setProductProps(collection, x.id, {
        syncStatus: x ? SyncStatus.SUCCESS : SyncStatus.ERROR,
      });
    });

    if (allRes.every(x => x.synced)) {
      this.createSnapshot(collection);
    }
  }

  async syncProduct(item: ProductToSync) {
    const res = await collectionService.syncProducts({
      toAdd: item.method === 'add' ? [item] : [],
      toRemove: item.method === 'remove' ? [item] : [],
    });

    const allRes = [...res.added, ...res.removed];
    const collection = this.getCollection(item.id)!;

    this.setProductProps(collection, item.id, {
      syncStatus: allRes[0] ? SyncStatus.SUCCESS : SyncStatus.ERROR,
    });

    if (allRes[0].synced) {
      this.createSnapshot(collection);
    }
  }

  async delete(collection: Collection) {
    await collectionService.delete(collection.id);
    this.removeCollection(collection.id);
  }

  async search(input?: CollectionSearchInput) {
    const collections: Collection[] = await collectionService.search(input);

    this.collectionList = collections;
  }

  async find(id: string) {
    if (!id) return null;
    const collection = await collectionService.find(id);
    return collection ? this.addCollection(collection) : null;
  }

  private addCollection(item: Collection) {
    let collection = this.getCollection(item.id);

    if (!collection) {
      this.createSnapshot(item);
      collection = isObservable(item) ? item : makeAutoObservable(item);
      this._collectionList.set(collection.id, collection);
    }

    return collection;
  }

  private setProps(collection: Collection, input: CollectionUpdateInput) {
    collection.root.setProps(input);
  }

  private removeCollection(id: string) {
    this._collectionList.delete(id);
  }

  addProduct(collection: Collection, item: Product) {
    let product = this.getProduct(collection, item.id);

    if (!product) {
      product = isObservable(item) ? item : makeAutoObservable(item);
      collection.products.push(product);
    }

    return product;
  }

  private resetView() {
    this.activeView = CollectionViewType.LIST;
    this._selectedCollection = null;

    if (
      this.collectionsWithPendingChanges.size &&
      this.prevActiveView === CollectionViewType.DETAIL
    ) {
      this.discardChanges();
    }
  }

  private discardChanges() {
    // Discard changes without saving
    for (const id of this.collectionsWithPendingChanges.keys()) {
      const snapshot = this.snapshots.get(id);
      const collection = Collection.create({
        ...snapshot,
        products: (snapshot as CollectionProps).products.map(createProduct),
      } as CollectionInput);

      this.removeCollection(id);
      this.addCollection(collection);
    }
  }

  private createSnapshot(collection: Collection) {
    this.snapshots.set(collection.id, collection.toPrimitives());
  }

  private setProductProps(
    collection: Collection,
    id: string,
    input: Partial<ProductProps>,
  ) {
    const product = this.getProduct(collection, id);
    if (!product) return;
    product.root.setProps(input);
  }

  removeProduct(collection: Collection, product: Product) {
    const index = collection.products.findIndex(x => x.id === product.id);
    collection.products.splice(index, 1);
  }

  getCollection(id: string) {
    return this._collectionList.get(id) ?? null;
  }

  getProductList(id: string) {
    return this.getCollection(id)?.products ?? [];
  }

  getProduct(collection: Collection, id: string) {
    return collection.products.find(x => x.id === id) ?? null;
  }

  setAccount(value: string) {
    collectionService.setAccount(value);
  }

  get selectedProducts(): Record<string, Product> {
    return (
      this._selectedCollection?.products.reduce(
        (obj, p) => ({ ...obj, [p.id]: p }),
        {},
      ) ?? {}
    );
  }

  set collectionList(collections: Collection[]) {
    collections.forEach(this.addCollection);
  }

  get collectionList() {
    return [...this._collectionList.values()];
  }

  get activeView() {
    return this._activeView;
  }

  private set activeView(value: CollectionViewType) {
    this.prevActiveView = this._activeView;
    this._activeView = value;
  }

  get isOpenModal() {
    return this._isOpenModal;
  }

  get selectedCollection() {
    return this._selectedCollection;
  }

  get collectionsWithPendingChanges(): Map<string, boolean> {
    const collections = new Map<string, boolean>();

    for (const x of this.collectionList) {
      const current = JSON.stringify(x.toPrimitives());
      const snapshot = JSON.stringify(this.snapshots.get(x.id));
      const areEquals = current === snapshot;
      !areEquals && collections.set(x.id, true);
    }

    return collections;
  }
}

export const collectionStore = createStore(CollectionStore);
