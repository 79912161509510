import {
  LanguageTypes,
  TranslationFiles,
  registerTranslations,
} from '@nizza/core';

const translationFiles: TranslationFiles = {
  [LanguageTypes.English]: import.meta.glob('./en/**/*.json', {
    eager: true,
    import: 'default',
  }),
  [LanguageTypes.Spanish]: import.meta.glob('./es/**/*.json', {
    eager: true,
    import: 'default',
  }),
  [LanguageTypes.Portuguese]: import.meta.glob('./pt/**/*.json', {
    eager: true,
    import: 'default',
  }),
};

registerTranslations(translationFiles);
