import {
  customLabels,
  languages,
  languagesKeys,
  LOCALES,
  getLocale,
} from '../../utils';
import { useEffect, useMemo, useState, useRef } from 'react';
import ReactLanguageSelect from 'react-languages-select';
import styled from 'styled-components';

import 'react-languages-select/css/react-languages-select.css';

interface TranslatableInputProps {
  value: string;
  onChange: (val: string) => void;
}

const message: any = {
  en: 'Set the text for each language, this depends on the browser language',
  pt: 'Defina o texto para cada idioma, isso depende do idioma do navegador',
  es: 'Establezca el texto para cada idioma, este depende del idioma del navegador',
};

export const TranslatableInput = ({
  onChange,
  value,
}: TranslatableInputProps) => {
  const [lang, setLang] = useState(LOCALES.en);
  const divRef = useRef<HTMLDivElement>(null);

  const parseValues = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch (error) {
      return {};
    }
  }, [value]);

  const text = useMemo(() => parseValues?.[lang] ?? '', [lang, parseValues]);

  useEffect(() => {
    divRef.current?.addEventListener('click', e => {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    const locale = getLocale();
    setLang(locale);
  }, []);

  return (
    <>
      <WrapperTranslatable>
        <div ref={divRef}>
          <ReactLanguageSelect
            defaultLanguage={languagesKeys.EN}
            languages={languages}
            onSelect={(language: languagesKeys) => setLang(language)}
            customLabels={customLabels}
          />
        </div>

        <div>
          <input
            className="text-input"
            onChange={e =>
              onChange(
                JSON.stringify({ ...parseValues, [lang]: e.target.value }),
              )
            }
            type="text"
            value={text}
          />
        </div>
      </WrapperTranslatable>
      <ControlLabel className="control-label" htmlFor="root_textInsideButton">
        {message[lang]}
      </ControlLabel>
    </>
  );
};

const ControlLabel = styled.label`
  font-size: 0.675rem !important;
`;

const WrapperTranslatable = styled.div`
  display: inline-flex;

  .text-input {
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    margin-left: 5px;
  }

  [class*='flag-select__option'] {
    padding: 2px;
  }

  #select_flag_button {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
`;
