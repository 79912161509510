import { useStore } from '@nizza/core';
import { CustomizeLiveStore, StateFetchCustomization } from '../stores';

export const useCustomizations = () => {
  const store = useStore(CustomizeLiveStore);
  const { uiStore, schemaStore } = store;

  const setViewMobile = uiStore.setViewMobile.bind(uiStore);
  const setViewDesktop = uiStore.setViewDesktop.bind(uiStore);
  const selectComponent = uiStore.selectComponent.bind(uiStore);

  const clearSelectedComponent = () =>
    uiStore.selectComponent('', '', undefined);
  const setAccountCustomization = schemaStore.setAccount.bind(schemaStore);

  const getDataComponent = schemaStore.getDataOfComponent.bind(schemaStore);
  const saveDataComponent = schemaStore.saveInStateData.bind(schemaStore);
  const saveDataInServer = schemaStore.saveDataInServer.bind(schemaStore);
  const deleteAllCustomizationServer =
    schemaStore.deleteAllCustomizationData.bind(schemaStore);

  const componentSelectedData = uiStore.componentSelectedData;
  const stateCustomization = schemaStore.stateCustomization;
  const schemas = schemaStore.schemas;
  const viewIsMobile = uiStore.viewIsMobile;

  const isLoading =
    schemaStore.stateCustomization === StateFetchCustomization.IsLoading;

  const componentSelected = store.uiStore.componentSelected;

  return {
    store,
    schemas,
    isLoading,
    viewIsMobile,
    setViewMobile,
    setViewDesktop,
    selectComponent,
    saveDataInServer,
    getDataComponent,
    saveDataComponent,
    stateCustomization,
    componentSelectedData,
    clearSelectedComponent,
    setAccountCustomization,
    deleteAllCustomizationServer,
    componentSelected,
  };
};
