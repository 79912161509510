import { AppModule } from '@nizza/core';
import { collectionStore } from './stores';

// const CollectionList = lazyLoad(
//   () => import('./components/CollectionList'),
//   module => module.default,
// );

export const collectionModule: AppModule = {
  key: 'event-collections',
  // TODO: Implementar traducciones
  label: 'Collections',
  stores: [collectionStore],
  route: {
    path: '/product-collections',
    // component: CollectionList,
  },
};
