import { ChatMessage } from '@nizza/core';
import clsx from 'clsx';
import { FC, memo } from 'react';
import {
  ChatAdminButtons,
  ChatMessageContent,
  ChatMessageContentProps,
  ChatMessageUserIcon,
  ChatUserButtons,
  ChatUsername,
} from '../../atoms';
import { ChatMessageResponse } from '../ChatMessageResponse';
import styles from './styles.module.css';

interface Props extends ChatMessageContentProps {
  message: ChatMessage;
  isPinned?: boolean;
  onRespondMessage: (message: ChatMessage) => void;
  onTogglePinnedMessage: (message: ChatMessage) => void;
  onDeleteMessage: (message: ChatMessage) => void;
  onBlockUser: (message: ChatMessage) => void;
}

export const ChatMessageRender: FC<Props> = memo(props => {
  const {
    message,
    isPinned = false,
    onRespondMessage,
    onTogglePinnedMessage,
    onDeleteMessage,
    onBlockUser,
  } = props;

  return (
    <div className={`${styles.messageBorder}`}>
      <div
        className={`${styles.messageContainer} ${
          message.isAdmin && styles.messageContainerAdmin
        }`}
      >
        <div className={`${styles.leftContainer}`}>
          <div className={`${styles.usernameContainer}`}>
            <ChatMessageUserIcon isAdmin={message.isAdmin} />
            <ChatUsername username={message.username} />
          </div>
          <div className={`${styles.messageData}`}>
            <ChatMessageResponse message={message} />
            <ChatMessageContent
              data={message.data}
              type={message.type}
              className={clsx(message.question ? 'mt-3 mb-1' : 'mt-1')}
            />
          </div>
        </div>

        <div className={`${styles.rightContainer}`}>
          {message.isAdmin ? (
            <ChatAdminButtons
              isPinned={isPinned}
              onTogglePinnedMessage={() => onTogglePinnedMessage(message)}
              onDelete={() => onDeleteMessage(message)}
            />
          ) : (
            <ChatUserButtons
              message={message}
              onRespondMessage={onRespondMessage}
              onDeleteMessage={onDeleteMessage}
              onBlockUser={onBlockUser}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default ChatMessageRender;
