import { ApiGraphqlTypes as AGT } from '../../../types';
import { GraphQLClient } from 'graphql-request';
import env from '~env';
import {
  EventChatRoomRepository,
  EventChatRoomTokenError,
  GetChatRoomInput,
} from '../domain';
import { EventChatRoom } from '../domain/entities';

const API_URL = env.apiUrls.graphql;

export class GraphqlEventChatRoomRepository implements EventChatRoomRepository {
  private client: GraphQLClient;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async getChatRoom(input: GetChatRoomInput): Promise<EventChatRoom> {
    const { getChatRoomToken } = await this.client.request(
      AGT.GetChatRoomTokenDocument,
      {
        data: {
          account: input.account,
          idEvent: input.eventId,
          userName: input.userName,
        },
      },
    );

    this.propagateGraphqlErrorIfExists(
      getChatRoomToken as AGT.GetChatRoomTokenResult,
    );

    const chatRoomToken = (getChatRoomToken as AGT.ChatRoomTokenData).data
      ?.token;

    if (!chatRoomToken) throw new EventChatRoomTokenError();

    return EventChatRoom.toDomain({ token: chatRoomToken });
  }

  private propagateGraphqlErrorIfExists(res: AGT.GetChatRoomTokenResult) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
