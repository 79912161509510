import { Product } from '@nizza/event-products';
import imageNotAvailable from 'assets/images/products/image-not-available.jpg';
import {
  AppFeatures,
  AppModuleConfig,
  AppRoute,
  ConfigPlan,
  AccountContractType,
  OriginOfProducts,
} from 'types';

export const getStrDate = (date: string | Date) => {
  date = typeof date === 'string' ? new Date(date) : date;
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const time = date.toTimeString().split(' ')[0];
  const addZero = (n: number) => (String(n).length === 1 ? '0' + String(n) : n);

  return `${addZero(day)}/${addZero(month)}/${year} - ${time}`;
};

export function randomColor() {
  const letters = '0123456789ABCDEF';
  let color = '';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function invertColor(hex: string, bw = true) {
  function padZero(str: string, len = 2) {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '000000' : 'FFFFFF';
  }
  // invert color components
  const ir = (255 - r).toString(16);
  const ig = (255 - g).toString(16);
  const ib = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + padZero(ir) + padZero(ig) + padZero(ib);
}

export const getRoutesWithAppConfig = (
  routes: AppRoute[],
  modules?: AppModuleConfig,
) => {
  let filteredRoutes: AppRoute[] = routes;
  const omit = modules?.omit;
  const only = modules?.only;

  if (omit) {
    filteredRoutes = routes.filter(
      x => x.module == null || !omit.includes(x.module),
    );
  }

  if (only) {
    filteredRoutes = routes.filter(
      x => x.module == null || only.includes(x.module),
    );
  }

  return filteredRoutes;
};

export const getAllowedRoutesAccordingPlan = (
  routes: AppRoute[],
  planData: ConfigPlan,
) => {
  const featureIsBlocked = (feature?: AppFeatures) =>
    feature ? planData.feature?.[feature]?.blocked : null;

  return routes.filter(x => {
    const isBlocked = featureIsBlocked(x.enableUsingFeaturePlan);
    return x.enableUsingFeaturePlan == null
      ? true
      : !planData.plan || isBlocked
      ? false
      : true;
  });
};

export const getOriginOfProducts = (contractType: AccountContractType) => {
  let originOfProducts: OriginOfProducts;

  switch (contractType) {
    case 'platform_module':
      originOfProducts = OriginOfProducts.PLATFORM;
      break;

    case 'app_store':
      originOfProducts = OriginOfProducts.GLOBALPAGE;
      break;

    case 'subscription_module':
      originOfProducts = OriginOfProducts.GLOBALPAGE;
      break;

    default:
      originOfProducts = OriginOfProducts.PLATFORM;
      break;
  }

  return originOfProducts;
};

export const addGeneratedProductProps = (products: Product[]) =>
  products.map(x => {
    const detailLink = `/products/${x.id}`;
    const image = x.pictures[0] || imageNotAvailable;
    x.root.setProps({ detailLink, image });
    return x;
  });

export const formatSeconds = (seconds: number): string => {
  const date = new Date(0);
  date.setSeconds(Number.isNaN(seconds) ? 0 : seconds); // specify value for SECONDS here
  return date.toISOString().substring(11, 19);
};

export function getSeconds(time: string /* [HH:MM:SS.sss] */) {
  const arr = time.split(':');
  if (arr.length === 1) return +arr[0]!;
  if (arr.length === 2) return +arr[0]! * 60 + +arr[1]!;
  if (arr.length === 3) return +arr[0]! * 3600 + +arr[1]! * 60 + +arr[2]!;
  return 0;
}
