import { ChatMessage, HighlightProduct } from '../../types';
import { useState } from 'react';
import { useConnectIVSChat, useIVSChatHandlers } from './';
import { IvsChatClientSocket } from '../core';
import { useSetAtom } from 'jotai';
import { ivsChatMessageListAtom } from '../state';
import { normalizeChatMessage } from '../utils';

declare interface Props {
  eventId: string;
  userName: string;
}

export const useIVSChatClient = (props: Props): IvsChatClientSocket => {
  const { eventId, userName } = props;
  const [showLoader, setShowLoader] = useState(false);
  const [pinnedMessage, setPinnedMessage] = useState<ChatMessage | undefined>();
  const [highlightProduct, setHighlightProduct] = useState<
    HighlightProduct | undefined
  >();

  const { handleEvent } = useIVSChatHandlers();
  const setIvsChatMessageList = useSetAtom(ivsChatMessageListAtom);

  const handleMessageCustom = (msg: any) => {
    setIvsChatMessageList(prev => {
      return [normalizeChatMessage(prev, msg), ...prev];
    });
    setShowLoader(false);
  };

  const handleEventCustom = (event: any) => {
    handleEvent(event);
    if (event.EventName === 'sendpinmessage') {
      const msg = JSON.parse(event.Attributes?.data);
      setPinnedMessage({
        data: msg.data,
        id: msg.id,
        username: msg.username,
      } as unknown as ChatMessage);
    }
    if (event.EventName === 'sendunpinmessage') {
      setPinnedMessage(undefined);
    }
    if (event.EventName === 'sendhighlightproduct') {
      const msg = JSON.parse(event.Attributes?.data);

      setHighlightProduct({
        products: msg.productIds,
        collection: msg.collection,
        livestreamingId: msg.idEvent,
      });
    }
  };

  const {
    ivsChatSocket: socket,
    ivsChatMessageList: chat,
    setIvsChatMessageList: setChat,
  } = useConnectIVSChat({
    onMessage: handleMessageCustom,
    onEvent: handleEventCustom,
    eventId,
    userName,
  });

  return {
    socket,
    chat,
    showLoader,
    pinnedMessage,
    highlightProduct,
    setChat,
    setShowLoader,
    setPinnedMessage,
  };
};

export default useIVSChatClient;
