/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import logger from '~logger';
import {
  SettingContext,
  useActions,
  useLivestreamingReducer,
  useSettings,
} from '../../context';
import { usePlayerLayout } from '../../hooks';
import { useRefDepsEffectWihtAutomaticUpdate } from '../../hooks/useRefDepsEffect';
import type { IvsRealTime } from '../../types';
import { getDeviceType } from '../../utils';
import debounce from '../../utils/debounce';
import { ChatCarousel } from '../ChatCarousel/ChatCarousel';
import HighlightProduct from '../HighlightProduct/HighlightProduct';
import { NoVideo } from '../NoVideo/NoVideo';
import { StreamPlayer } from './StreamPlayer/StreamPlayer';
import styles from './feed.module.css';

type FeedProps = {
  isPlayerSupported: boolean;
  variationSelectorState: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
  streamUrl: string | undefined;
  transmitionType: string | undefined;
  livestreamingStatus: string;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setIvsRealTime: React.Dispatch<React.SetStateAction<IvsRealTime>>;
  hideChat: boolean;
};

interface HighlightProps {
  detector: boolean;
  fullScreen: boolean;
  handleFullScreen: () => void;
  handleFullScreenMobile: () => void;
}

const errorCache = new Set<string>();

export const Feed = ({
  isPlayerSupported,
  variationSelectorState,
  streamUrl,
  transmitionType,
  livestreamingStatus,
  setFullScreen,
  setIvsRealTime,
  hideChat,
}: FeedProps) => {
  const { isModalLive } = useContext(SettingContext);
  const { showCarouselChat, showCarouselChatButton, collectionId } =
    useSettings();

  const {
    setting: { isInGlobalPage },
  } = useActions();

  const { isVerticalLayout } = usePlayerLayout(transmitionType);

  const [, dispatch] = useLivestreamingReducer();

  const { IVSPlayer } = window;
  const { MediaPlayer, ErrorType } = IVSPlayer;
  const [playing, setPlaying] = useState(false);

  const [, setPlayerCurrent] = useState(false);

  const [highlightProps, setHighlightProps] = useState<HighlightProps>({
    detector: false,
    fullScreen: false,
    handleFullScreen: () => null,
    handleFullScreenMobile: () => null,
  });
  const player: typeof MediaPlayer = useRef(null);
  const isFirstRender = useRef(true);

  const isFinalized = livestreamingStatus === 'FINALIZED';
  const isMobile = getDeviceType() === 'mobile';

  let stateDetected = false;

  const reconnection = () => {
    setTimeout(() => player.current.load(streamUrl), 3000);
    stateDetected = true;
  };
  /**
   * This hook is responsible of constantly reading the transmission status
   * bearing in mind the value of duration of state (core.state.duration) to retry the reconnection.
   *
   * If core.state.duration is Infinity it means that the transmition is in progress.
   * If core.state.duration is a number it means that the transmition is not in progress / stopped or ended.
   *
   * It was made only for IOS and MAC devices, for Windows and Android devices it is not necessary.
   */
  useRefDepsEffectWihtAutomaticUpdate(() => {
    const player_ = player.current;

    if (!player_?.core?.state.duration) return;
    if (player_.core.state.duration === Infinity) return;
    if (isFinalized) return;

    reconnection();
  }, [player]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setStatePlaying = useCallback(
    debounce(() => {
      const st = player.current.getState();

      /* It was made for all devices to avoid a loop reconnection. */
      if (st === IVSPlayer.PlayerState.IDLE) {
        return reconnection();
      }

      setPlaying(
        st === IVSPlayer.PlayerState.PLAYING ||
        st === IVSPlayer.PlayerState.BUFFERING ||
        st === IVSPlayer.PlayerState.READY,
      );
    }, 3000),
    [],
  );

  const dispatchIvsDataRealTime = ({
    startTime,
    viewerCount,
    status,
  }: {
    startTime: string;
    viewerCount: number;
    status: string;
  }) => {
    setIvsRealTime({ startTime, viewerCount, status });

    if (dispatch) {
      dispatch({
        type: 'SET_IVS_DATA_REAL_TIME',
        args: {
          ivsRealTime: {
            startTime,
            viewerCount,
            status,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!isFirstRender.current && !isPlayerSupported) {
      logger.warn(
        'The current browser does not support the Amazon IVS player.',
      );

      return;
    }

    const { ERROR, TEXT_METADATA_CUE } = IVSPlayer.PlayerEventType;

    const onTextMetadataCue = (cue: any) => {
      const { startTime, viewerCount, status } = JSON.parse(cue?.text);
      dispatchIvsDataRealTime({ startTime, viewerCount, status });
    };

    const onStateChange = (state: unknown) => {
      setStatePlaying();
      /**
       * It was made for all devices to identify if transmission was stopped or ended and
       * try to the reconnect again to know if show the video player (StreamPlayer component)
       * or the message "Live BroadCasting" (NoVideo component).
       *
       * But IOS and MAC devices don't detect / listen for the ENDED state, they never enter here,
       * for that reason the useRefDepsEffectWihtAutomaticUpdate hook was developed.
       * */
      if (state === IVSPlayer.PlayerState.ENDED) {
        reconnection();
      }
    };

    const onError = (error: typeof ErrorType) => {
      if (stateDetected === true)
        logger.log('[Feed] After a few seconds the state still ended');

      const statusTooManyRequests = 429;
      if (
        error.type === ErrorType.NOT_AVAILABLE &&
        error.code === statusTooManyRequests
      ) {
        logger.error('Concurrent-viewer limit reached', error);
      } else {
        const errorKey = `${error.type}-${error.code}-${error.source}`;

        if (!errorCache.has(errorKey)) {
          logger.error('ERROR', error);
          errorCache.add(errorKey);
        }
      }
      const validErrors = [
        ErrorType.NOT_AVAILABLE,
        ErrorType.NO_SOURCE,
        ErrorType.NETWORK,
        ErrorType.NETWORK_IO,
      ];

      if (validErrors.includes(error.type)) {
        setPlaying(false);
        dispatchIvsDataRealTime({
          startTime: 'UNKNOWN',
          viewerCount: 0,
          status: 'UNKNOWN',
        });
        reconnection();
      }
    };

    player.current = IVSPlayer.create();
    player.current.load(streamUrl);

    player.current.addEventListener(TEXT_METADATA_CUE, onTextMetadataCue);
    player.current.addEventListener(ERROR, onError);

    for (const state of Object.values(IVSPlayer.PlayerState)) {
      player.current.addEventListener(state, () => onStateChange(state));
    }

    player.current.addEventListener(IVSPlayer.PlayerState.PLAYING, () => {
      if (stateDetected === true) stateDetected = false;
    });
    setPlayerCurrent(true);
    isFirstRender.current = false;

    return () => {
      for (const state of Object.values(IVSPlayer.PlayerState)) {
        player.current.removeEventListener(state, () => onStateChange(state));
      }

      player.current.removeEventListener(TEXT_METADATA_CUE, onTextMetadataCue);
      player.current.removeEventListener(ERROR, onError);
    };
  }, [IVSPlayer, isPlayerSupported, streamUrl]);

  if (!isPlayerSupported) {
    return null;
  }

  return (
    <div
      className={`${isModalLive && !isInGlobalPage && styles.playerUiPopoup}  ${isVerticalLayout ? styles.verticalLayout : styles.horizontalLayout
        }`}
    >
      {!(isMobile && !isVerticalLayout) && (
        <div>
          <HighlightProduct
            isVerticalLayout={isVerticalLayout}
            isMobile={isMobile}
            fullScreen={highlightProps.fullScreen}
            handleFullScreen={
              highlightProps.detector
                ? highlightProps.handleFullScreen
                : highlightProps.handleFullScreenMobile
            }
            variationSelectorState={variationSelectorState}
            isFinalized={isFinalized}
          />
        </div>
      )}
      {isMobile && !isVerticalLayout && (
        <div
          style={{
            height: '8px',
            width: '100%',
            position: 'absolute',
            background: '#f7f7f7',
            top: 'calc(220px - 6px)',
            borderRadius: '6px 6px 0px 0px',
            zIndex: '2',
          }}
        ></div>
      )}
      {isMobile &&
        isVerticalLayout &&
        showCarouselChat &&
        (showCarouselChatButton || isVerticalLayout) && (
          <ChatCarousel
            isVerticalLayout={isVerticalLayout}
            variationSelectorState={variationSelectorState}
            fullScreen={highlightProps.fullScreen}
            handleFullScreen={highlightProps.handleFullScreenMobile}
            isMobile={isMobile}
            isFinalized={isFinalized}
            highlightProps={highlightProps}
          />
        )}
      {playing ? (
        <StreamPlayer
          player={player.current}
          streamUrl={streamUrl}
          setShowVariation={variationSelectorState?.[1]}
          transmitionType={transmitionType}
          isFinalized={isFinalized}
          setHighlightProps={setHighlightProps}
          setFullScreen={setFullScreen}
          collectionId={collectionId}
          hideChat={hideChat}
          variationSelectorState={variationSelectorState}
          highlightProps={highlightProps}
        />
      ) : (
        <NoVideo
          isLive={livestreamingStatus}
          liveStatus={playing}
          isMobile={isMobile}
          isVerticalLayout={isVerticalLayout}
        />
      )}
      {!playing && !isVerticalLayout && showCarouselChat && isMobile && (
        <ChatCarousel
          isVerticalLayout={isVerticalLayout}
          variationSelectorState={variationSelectorState}
          fullScreen={highlightProps.fullScreen}
          handleFullScreen={highlightProps.handleFullScreenMobile}
          isMobile={isMobile}
          isFinalized={isFinalized}
          highlightProps={highlightProps}
        />
      )}
    </div>
  );
};
