import {
  ComponentChildSchema,
  withCustomizeSchema,
} from '@nizza/customize-live';
import clsx from 'clsx';
import React from 'react';
import { ProductButton } from '../ProductsButton/ProductButton';
import styles2 from '../ProductSlider/productSlider.module.css';
import styles from './highlightProduct.module.css';
import { intlFormatMessageOutsideFC } from '../..//utils/intlFormatMessageOutsideFC';
import type { PlayerProduct } from '../../types';

const intl = intlFormatMessageOutsideFC();

interface DesktopStructureProps {
  fullScreen: boolean;
  handleFullScreen: () => void;
  variationSelectorState: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
  addToCart: (() => void) | undefined;
  product: PlayerProduct;
  formatMessage: any;
  normalPriceFormat: string;
}

const DesktopStructure = ({
  fullScreen,
  handleFullScreen,
  variationSelectorState,
  addToCart,
  product,
  formatMessage,
  normalPriceFormat,
  selector,
  formData,
}: ComponentChildSchema<DesktopStructureProps>) => {
  return (
    <div
      customize-field={selector}
      onClick={() => {
        fullScreen && handleFullScreen();
        addToCart &&
          formData?.data?.priceOrButton === 'show_price' &&
          addToCart();
      }}
      className={clsx(styles.highlightProductContainer)}
      style={{
        backgroundColor: `${
          (formData?.data?.backgroundColor ?? '#ffffff') +
          (formData?.data?.opacity ? '80' : '')
        }`,
      }}
    >
      <div className={styles.productContainer}>
        <img
          className={`${styles.productPicture}`}
          src={product.imageUrl}
          alt="productPicture"
        />
        {formData?.data?.priceOrButton === 'show_button' ? (
          <div className={styles.productAddCartContent}>
            <ProductButton
              product={product}
              sectionIdClickedOn="live_shopping_highlight_product"
              variationSelectorState={variationSelectorState}
            />
          </div>
        ) : (
          <div
            style={{ color: 'white', marginTop: '5px' }}
            className={`${styles2.productDeatailContent} ${styles2.flexDirectionRow}`}
          >
            <div
              style={{
                color: `${formData?.data?.priceColor ?? '#ffffff'}`,
              }}
              className={styles.priceWithDiscount}
            >
              {product.isAvailable
                ? normalPriceFormat
                : formatMessage({
                    id: 'chat-carousel.product-unavailable-message',
                  })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export const DesktopOutput = withCustomizeSchema(DesktopStructure, {
  products: {
    id: 'highligh_products_desktop',
    label: intl.formatMessage({
      id: 'customize/highlight-product.product-highlight',
    }),
    category: 'desktop',
    fields: {
      type: 'object',
      properties: {
        backgroundColor: {
          type: 'string',
          title: intl.formatMessage({
            id: 'customize/general.background',
          }),
          default: '#ffffff',
        },
        opacity: {
          type: 'boolean',
          title: intl.formatMessage({
            id: 'customize/highlight-product.opacity',
          }),
          default: true,
        },
        priceColor: {
          type: 'string',
          title: intl.formatMessage({
            id: 'customize/general.price',
          }),
          default: '#000000',
        },
        priceOrButton: {
          type: 'string',
          title: intl.formatMessage({
            id: 'customize/highlight-product.show',
          }),
          oneOf: [
            {
              const: 'show_button',
              title: intl.formatMessage({
                id: 'customize/highlight-product.show-button',
              }),
            },
            {
              const: 'show_price',
              title: intl.formatMessage({
                id: 'customize/highlight-product.show-price',
              }),
            },
          ],
          default: 'show_button',
        },
      },
    },
    ui: {
      backgroundColor: {
        'ui:widget': 'color',
      },
      priceOrButton: {
        'ui:widget': 'radio',
        'ui:options': {
          inline: false,
        },
      },
      priceColor: {
        'ui:widget': 'color',
      },
    },
  },
});
