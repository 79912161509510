import { ApiV1Response, customFetch } from '@nizza/core';
import { API_REST_URL_V1 } from '../shared';
import {
  AccountInfo,
  ChangeSubscriptionTypeArgs,
  CreateAccountArgs,
  UpdateAdminNameArgs,
} from '../types';

const ACCOUNTS_V1 = `${API_REST_URL_V1}/accounts`;
const ACCOUNTS_V2 = `${API_REST_URL_V1}/accounts/v2`;

export const getAccountInformation = async (account: string) => {
  const resource = `/${account ?? ''}`;
  const url = ACCOUNTS_V2 + resource;

  const { data } = await customFetch<ApiV1Response<AccountInfo>>({ url });

  return data;
};

export const updateAdminUsername = async (args: UpdateAdminNameArgs) => {
  const url = `${ACCOUNTS_V1}/admin-username`;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'PUT',
    body: JSON.stringify({ username: args.username, account: args.account }),
  });

  return Boolean(success);
};

export const updateSubscriptionType = async (
  args: ChangeSubscriptionTypeArgs,
) => {
  const url = `${ACCOUNTS_V1}/subscription`;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'PUT',
    body: JSON.stringify({
      subscriptionType: args.type,
      account: args.account,
    }),
  });

  return Boolean(success);
};

type bodySections = {
  accountId: string;
  activeSections: string[];
};

export const updateSectionsGlobal = async (args: bodySections) => {
  const url = `${ACCOUNTS_V1}/global-page/${args.accountId}`;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'PUT',
    body: JSON.stringify({ activeSections: args.activeSections }),
  });

  return Boolean(success);
};

export const createAccount = async (args: CreateAccountArgs, token: string) => {
  const url = `${ACCOUNTS_V2}/`;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return Boolean(success);
};

export const showCreatePage = async (token: string) => {
  const url = `${ACCOUNTS_V1}/show-create-page`;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return Boolean(success);
};

export const setPlan = async (
  plan: string,
  descriptionPlan: string,
  account: string,
  selectFreeTrial?: boolean,
) => {
  const url = `${ACCOUNTS_V1}/set-plan`;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'PUT',
    body: JSON.stringify({
      planType: plan,
      descriptionPlan,
      account: account,
      selectFreeTrial: !!selectFreeTrial,
    }),
  });

  return Boolean(success);
};
