import { useState, useRef, useEffect } from 'react';
import { add } from 'date-fns';

import { SocialNetworkIcon } from './SocialNetworkIcon';
import { google, outlook, yahoo } from 'calendar-link';

interface Props {
  title: string;
  date: string;
  link: string;
}

export const AddToCalendarBtn = ({ title, date, link }: Props) => {
  const singleEventArgs = {
    title: title,
    start: new Date(date),
    end: add(new Date(date), { hours: 1 }),
    description: `${title} ${link}`,
    isAllDay: false,
  };

  const googleUrl = google(singleEventArgs);
  const yahooUrl = yahoo(singleEventArgs);
  const outlookUrl = outlook(singleEventArgs);

  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleCloseCard = () => setTimeout(() => setShowModal(false), 150);

  useEffect(() => {
    modalRef.current?.focus();
  }, [showModal]);

  return (
    <div className="add-to-calendar-container">
      {showModal && (
        <div
          tabIndex={0}
          ref={modalRef}
          className="add-to-calendar-modal"
          onBlur={handleCloseCard}
        >
          <div className="add-to-calendar-modal-container">
            <SocialNetworkIcon
              name="Google"
              iconName="google"
              size={16}
              link={googleUrl}
              color="white"
              background="#3b5998"
              containerSize={20}
            />
          </div>
          <div className="add-to-calendar-modal-container">
            <SocialNetworkIcon
              name="Yahoo"
              iconName="yahoo"
              size={16}
              link={yahooUrl}
              color="white"
              background="#3b5998"
              containerSize={20}
            />
          </div>
          <div className="add-to-calendar-modal-container">
            <SocialNetworkIcon
              name="Outlook"
              iconName="microsoft-outlook"
              size={16}
              link={outlookUrl}
              color="white"
              background="#3b5998"
              containerSize={20}
            />
          </div>
        </div>
      )}
      <button
        className="add-to-calendar-icon"
        onClick={() => setShowModal(!showModal)}
      >
        <i className="mdi mdi-calendar-range text-white"></i>
      </button>
    </div>
  );
};
