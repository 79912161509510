import { useCognito } from 'hooks';
import { Navigate } from 'react-router-dom';

export const AuthMiddleware = ({
  component,
  layout: Layout,
  isAuthProtected,
  ...rest
}: any) => {
  const { success } = useCognito();

  return (
    <Layout>
      {isAuthProtected && success ? (
        <Navigate to="/login" replace />
      ) : (
        component
      )}
    </Layout>
  );
};
