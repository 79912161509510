import { ScriptSettings } from 'types';

export const editableSettings: Partial<ScriptSettings> = {
  inactiveSidebarProducts: true,
  inactiveProductsCarousel: false,
  inactivateChat: true,
  inactivateLike: true,
  inactivateViewers: true,
  isInfinite: true,
  pdp: false,
  time: 10,
  cmsCompatibility: false,
};
