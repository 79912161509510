import { ResourceNotFoundError, UnexpectedError } from '../../../errors';
import { AccountRepository } from '../domain';

export class AccountService {
  constructor(private accountRepository: AccountRepository) {}

  async get(name: string) {
    try {
      const account = await this.accountRepository.get(name);

      if (!account) {
        throw new ResourceNotFoundError(name);
      }

      return account;
    } catch (err) {
      throw new UnexpectedError(err);
    }
  }
}
