/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-unused-vars */
import { useNizza } from '@nizza/core';

declare interface Props {
  account: string;
  environment: string;
}

const useAccountCurrencyConfig = ({ account, environment }: Props) => {
  const accountCurrencyConfig = useNizza(x => x.account?.currencyConfig);
  const isLoading = useNizza(x => x.accountStatus === 'loading');

  return {
    accountCurrencyConfig,
    isLoading,
  };
};

export default useAccountCurrencyConfig;
