import { createContext, useContext } from 'react';
import { AnalyticSummary, TimeLine } from 'types';

export interface AnalyticCtx {
  loadingAnalytics: boolean;
  analyticsData: AnalyticSummary;
  loadingTimeLineData: boolean;
  timeLineData: TimeLine[];
}

export const analyticCtxDefault: AnalyticCtx = {
  loadingAnalytics: false,
  analyticsData: {} as AnalyticSummary,
  loadingTimeLineData: false,
  timeLineData: [],
};

export const AnalyticContext = createContext<AnalyticCtx>(analyticCtxDefault);
export const useAnalytic = () => useContext(AnalyticContext);
