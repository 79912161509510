import { ApiV1Response, customFetch, searchParams } from '@nizza/core';
import { API_REST_URL_V1 } from '../shared';
import { ConfigPlan } from './../types/plans';

const BASE_URL = `${API_REST_URL_V1}/plans`;

export const createPlan = async (planInfo: ConfigPlan) => {
  const url = `${BASE_URL}/create`;
  const { success } = await customFetch<ApiV1Response<void>>({
    url,
    method: 'POST',
    body: JSON.stringify(planInfo),
  });

  return Boolean(success);
};

export const getPlan = async (planType: string) => {
  const params = searchParams({ planType });
  const resource = `/info${params}`;
  const url = BASE_URL + resource;

  const { data } = await customFetch<ApiV1Response<ConfigPlan>>({ url });

  return data;
};

export const getBasicPlans = async () => {
  const resource = `/basic`;
  const url = BASE_URL + resource;

  const { data } = await customFetch<ApiV1Response<ConfigPlan[]>>({ url });

  return data;
};
