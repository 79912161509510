import { makeAutoObservable, reaction } from 'mobx';
import { ViewType } from '../types';

enum SelectorsDOM {
  iframeHead = 'frame-head-customize',
  giphyId = 'ReactGiphySearchbox',
  iframeElementId = 'encapsulate-liveshopping',
  tagCustomize = 'customize-field',
}

export class UiManagementStore {
  public viewSelected: ViewType = ViewType.DESKTOP;
  public componentSelected = '';
  public componentSelectedData: Record<string, any> | undefined = undefined;
  public stylesGiphy: Record<string, string> = {};

  constructor() {
    makeAutoObservable(this, {});
    reaction(() => this.viewSelected, this.setGlobalStateCustomize);
    reaction(() => this.componentSelected, this.highlighComponentSelected);
  }

  highlighComponentSelected(component: string, componentPrev: string) {
    const iframeElement = (
      document.getElementById(SelectorsDOM.iframeElementId) as HTMLIFrameElement
    )?.contentDocument;
    const component_ = component || componentPrev;
    const child = iframeElement?.querySelectorAll(
      `[${SelectorsDOM.tagCustomize}="${component_}"]`,
    );
    child?.forEach(e => {
      const content = ' highlight_component_customize';
      if (component) return (e.className += content);
      e.className = e.className.replace(content, '');
    });
  }

  selectComponent(section: string, id: string, data?: Record<string, any>) {
    this.componentSelected = this.key_(section, id);
    this.componentSelectedData = data;
  }

  injectStylesIframe(document_: Document, styles: Record<string, string>) {
    for (const [key, value] of Object.entries({
      ...styles,
      ...Object.assign({}, this.stylesGiphy),
    })) {
      const id = key.replace('./', '');
      const style = document.createElement('style');

      style.setAttribute('type', 'text/css');
      style.setAttribute('id', id);
      style.innerHTML = value;

      setTimeout(() =>
        document_.getElementById(SelectorsDOM.iframeHead)?.appendChild(style),
      );
    }
  }

  injectStylesGiphyIframe(document_: Document) {
    const styles = Array.from(
      document.querySelectorAll(
        `*[id^="${SelectorsDOM.giphyId}"]`,
      ) as NodeListOf<HTMLStyleElement>,
    ).reduce((prev, curr) => ({ ...prev, [curr.id]: curr.innerHTML }), {});

    this.stylesGiphy = styles;
    this.injectStylesIframe(document_, {});
  }

  get viewIsMobile() {
    return this.viewSelected === ViewType.MOBILE;
  }

  get stylesGiphyIsEmpty() {
    return Object.keys(this.stylesGiphy).length === 0;
  }

  setViewMobile() {
    this.viewSelected = ViewType.MOBILE;
  }

  setViewDesktop() {
    this.viewSelected = ViewType.DESKTOP;
  }

  private setGlobalStateCustomize(view: ViewType) {
    const parameters = {
      customizeDevice: ViewType.MOBILE,
      customizeDeviceType: ViewType.iOS,
    };
    (window as any).liveshopping = view === ViewType.MOBILE ? parameters : {};
  }

  private key_(section: string, id: string) {
    if (!section || !id) return '';
    return `${section}__${id}`;
  }
}
