import React from 'react';
const BeautyIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 218.688 218.688"
  >
    <g>
      <g>
        <g>
          <path
            d="M100.796,74.164h-0.332V47.596c-0.052,0.048-0.112,0.084-0.164,0.128c0.072-0.276,0.164-0.544,0.164-0.844
				c0-4.004-6.792-5.82-13.192-6.648V9.352C87.272,4.196,82.404,0,76.42,0S65.568,4.196,65.568,9.352v30.896
				c-5.34,0.688-10.896,2.072-12.612,4.836c-0.004-0.012-0.012-0.024-0.012-0.036l-0.568,0.568v28.552H52.04
				c-10.308,0-18.692,8.388-18.692,18.692v115.836c0,0.656,0.14,1.168,0.62,1.584c3.944,6.568,27.808,8.408,42.452,8.408
				c14.86,0,39.244-1.884,42.644-8.692c0.008,0.008,0.012,0.012,0.02,0.02c0.404-0.412,0.408-0.856,0.408-1.32v-0.336
				c0-0.004,0-0.008,0-0.008c0-0.004,0-0.008,0-0.008V92.856h-0.004C119.488,82.552,111.104,74.164,100.796,74.164z M69.528,9.352
				c0-2.916,3.152-5.384,6.884-5.384c3.732,0,6.884,2.464,6.884,5.384v34.712c-4.628,1.1-9.152,1.1-13.768,0V9.352z M65.56,44.248
				v1.344l1.436,1.908c3.144,0.908,6.316,1.368,9.416,1.368c3.1,0,6.272-0.46,9.416-1.368l1.436-1.908v-1.348
				c4.968,0.716,8.156,1.808,9.068,2.636c-1.46,1.324-8.668,3.372-19.92,3.372c-11.256,0-18.46-2.048-19.92-3.372
				C57.404,46.052,60.592,44.96,65.56,44.248z M56.336,51.272c6.348,2.824,18.284,2.948,20.08,2.948s13.728-0.124,20.08-2.948V82.4
				c-1.364,1.044-8.32,2.852-20.08,2.852S57.7,83.444,56.336,82.4V51.272z M81.148,143.428c-1.576,0.064-3.136,0.168-4.732,0.168
				s-3.156-0.104-4.732-0.168v-8.556c1.524,0.384,3.092,0.648,4.732,0.648s3.208-0.264,4.732-0.648V143.428z M107.36,142.208v37.436
				c-9.204,2.852-19.876,4.352-30.944,4.352s-21.744-1.5-30.944-4.352v-37.436c9.28,2.764,19.932,4.224,30.944,4.224
				C87.428,146.432,98.08,144.972,107.36,142.208z M76.416,132.684c-9.208,0-16.7-7.492-16.7-16.7s7.492-16.7,16.7-16.7
				s16.696,7.492,16.696,16.7S85.62,132.684,76.416,132.684z M81.148,97.096c-1.524-0.384-3.092-0.648-4.732-0.648
				s-3.208,0.264-4.732,0.648v-7.98c1.924,0.08,3.592,0.1,4.732,0.1s2.812-0.024,4.732-0.1V97.096z M115.524,208.292
				c-0.736,2.244-14.816,6.42-39.112,6.42c-24.296,0-38.376-4.18-39.112-6.312c0.736-2.244,14.816-6.424,39.112-6.424
				c6.284,0,12.312,0.284,17.916,0.844c1.072,0.14,2.06-0.68,2.172-1.772c0.108-1.092-0.688-2.068-1.776-2.172
				c-5.74-0.576-11.9-0.868-18.312-0.868c-12.272,0-31.016,1.296-39.104,5.576V92.856c0-8.116,6.604-14.724,14.724-14.724h0.336
				v4.652c0,4.2,9.112,5.672,16.48,6.176v9.016c-7.024,2.964-11.968,9.92-11.968,18.008c0,8.088,4.944,15.044,11.968,18.008v9.336
				c-8.716-0.552-17.032-2.02-24.36-4.388l-1.852,1.348v40.4l0.984,1.348c9.7,3.14,21.04,4.8,32.8,4.8c11.76,0,23.1-1.66,32.8-4.8
				l0.984-1.348v-40.4l-1.852-1.348c-7.328,2.368-15.644,3.84-24.36,4.388v-9.336c7.02-2.964,11.964-9.92,11.964-18.004
				s-4.944-15.04-11.964-18.004v-9.016c7.368-0.504,16.48-1.976,16.48-6.176V78.14h0.332c8.12,0,14.724,6.604,14.724,14.724
				l0.048,115.096c0.008,0.008,0.016,0.008,0.028,0.016C115.572,208.076,115.524,208.176,115.524,208.292z"
          />
          <path
            d="M172.716,100.976V79.308c-0.02,0.02-0.044,0.032-0.064,0.052c-0.352-4.4-9.044-5.988-16.56-5.988
				c-7.668,0-16.62,1.636-16.62,6.248c0,0.284,0.132,0.52,0.196,0.78c-0.06-0.06-0.136-0.104-0.196-0.164v20.74
				c-6.996,0.28-12.624,6.976-12.624,15.224v92.168c0,0.448,0.032,0.86,0.312,1.24c2.128,4.66,15.968,6.804,28.932,6.804
				c13.496,0,27.936-2.324,29.132-7.388c0.02,0.024,0.048,0.04,0.064,0.064c0.048-0.228,0.052-0.468,0.052-0.72V116.2
				C185.34,107.952,179.712,101.256,172.716,100.976z M156.092,77.34c6.656,0,11.18,1.34,12.432,2.276
				c-1.252,0.936-5.78,2.276-12.432,2.276c-6.652,0-11.176-1.34-12.428-2.276C144.916,78.68,149.44,77.34,156.092,77.34z
				 M143.44,83.9c3.316,1.38,8.18,1.96,12.652,1.96c4.472,0,9.34-0.584,12.656-1.96v23.856c-1.084,0.752-5.548,1.984-12.656,1.984
				c-7.108,0-11.572-1.236-12.652-1.984V83.9z M156.092,213.576c-17.052,0-26.352-3.452-26.352-5.228s9.3-5.232,26.352-5.232
				c0.8,0,1.444-0.636,1.444-1.416c0-0.78-0.648-1.416-1.444-1.416c-9.672,0-19.848,1.188-25.276,3.728V116.2
				c0-6.044,3.848-10.956,8.656-11.252v3.236c0,5.128,12.724,5.528,16.62,5.528c3.9,0,16.624-0.4,16.624-5.528v-3.236
				c4.808,0.296,8.656,5.208,8.656,11.252v87.82c-1.24-0.576-2.74-1.104-4.56-1.572c-0.784-0.2-1.564,0.256-1.764,1.012
				c-0.204,0.756,0.26,1.532,1.032,1.732c5.2,1.336,6.368,2.764,6.368,3.16C182.448,210.12,173.144,213.576,156.092,213.576z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default BeautyIcon;
