import { Environment } from '@nizza/core';
import coreEnvs from '@nizza/core/envs/environment.prod';

const config = {
  ...coreEnvs,
  production: true,

  publicUrls: {
    ...coreEnvs.publicUrls,
    playerScript: `${coreEnvs.publicUrls.nizzaCdn}/player-script/prod`,
  },

  //  Payments
  stripe: {
    publicKey:
      'pk_test_51JpwSmGd8kbMB7CkvcU0azpZBVVHEzbvtZQa7fSIvrFFAAEKf6dDjiU9vYa0SXhwpYaWzUOacs8eta0OB2PRZ7Uy00mU66Khc6',
  },
};

export const environment: Environment<typeof config> = config;
export default environment;
