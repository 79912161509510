import env from '~env';

export const API_REST_URL_V1 = env.apiUrls.restV1;
export const API_REST_URL_V2 = env.apiUrls.restV2;

export const AMPLIFY = {
  auth: {
    ...env.AWS.cognito,
    region: env.AWS.region,
  },
};

export const isProduction = env.production;

export const SCRIPT_URL = `${env.publicUrls.playerScript}/nz-ps-index.js`;

export const ACCOUNT = 'plataforma';

export const GLOBAL_PAGE_URL = `${env.publicUrls.platformSite}/global-page`;

export const STRIPE = {
  ...env.stripe,
};

export const BUNDLE_URL = env.publicUrls.bundleSite;

export const CHAT_CHARACTER_LIMIT = 180;
