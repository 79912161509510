/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncUnderDevelopment = lazyLoad(
  () => import('./Page'),
  module => module.UnderDevelopment,
);
