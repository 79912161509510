import { createContext, useContext } from 'react';
import { MutationStatus, QueryStatus } from '@tanstack/react-query';
import {
  AccountInfo,
  CreateSubscriptionArgs,
  CreateSubscriptionResponse,
  UpdateSubscriptionArgs,
  ChangeSubscriptionTypeArgs,
  UpdateAdminNameArgs,
  AccountContractStatusType,
  AccountContractType,
  AccountPlanDescriptionType,
  AccountType,
} from 'types';

export const ADMIN_NAME = 'Admin';

interface BodySections {
  accountId: string;
  activeSections: string[];
}

export interface AccountCtx {
  accountInfo: AccountInfo;
  accountInfoStatus: QueryStatus;
  adminName: string;
  adminNameStatus: MutationStatus;
  loadingSubscriptionType: boolean;
  externalAccount: string | null;
  eventAccountParams: string | null;
  changeAdminName: (args: UpdateAdminNameArgs) => void;
  changeSubscriptionType: (args: ChangeSubscriptionTypeArgs) => void;
  setExternalAccount: (id: string | null) => void;
  setEventAccountParams: (id: string | null) => void;
  changeSection: (args: BodySections) => void;
  loadingSection: boolean;
  createSubscription: (
    args: CreateSubscriptionArgs,
  ) => Promise<CreateSubscriptionResponse>;
  changeSubscriptionStatus: (accountId: string) => void;
  updateSubscription: (args: UpdateSubscriptionArgs) => Promise<any>;
  refetchInfoAccount: () => void;
  setAccount: React.Dispatch<React.SetStateAction<string>>;
  accountInBase64: string;
  setRetryAccountQuery: (value: any) => void;
}

export const AccountCtxDefault: AccountCtx = {
  accountInfo: {
    account: '',
    username: '',
    id: '',
    contractType: AccountContractType.PLATFORM,
    nextDateMetricRecord: '',
    spendedMinutes: 0,
    unlimitedService: false,
    hoursAvailable: 0,
    contractStatus: AccountContractStatusType.ACTIVE,
    createdAt: '',
    installationDateApp: '',
    cxAccount: '',
    freeService: true,
    subscriptionType: null,
    minutesAvailable: 0,
    globalPage: {
      activeSections: [],
    },
    planType: '',
    descriptionPlan: AccountPlanDescriptionType.NONE,
    selectFreeTrial: false,
    currencyConfig: {
      currency: 'USD',
      currencyCustomDecimalsNumber: 2,
      currencyCustomSymbol: '$',
    },
    accountType: AccountType.PLATFORM,
    host: '',
  },
  accountInfoStatus: 'idle',
  adminName: ADMIN_NAME,
  adminNameStatus: 'idle',
  loadingSubscriptionType: false,
  externalAccount: null,
  eventAccountParams: null,
  changeAdminName: () => null,
  changeSubscriptionType: () => null,
  setExternalAccount: () => null,
  setEventAccountParams: () => null,
  changeSection: () => null,
  loadingSection: false,
  createSubscription: () => new Promise(() => null),
  changeSubscriptionStatus: () => null,
  updateSubscription: () => new Promise(() => null),
  refetchInfoAccount: () => null,
  setAccount: () => null,
  accountInBase64: '',
  setRetryAccountQuery: () => true,
};

export const AccountContext = createContext<AccountCtx>(AccountCtxDefault);

export const useAccount = () => useContext(AccountContext);
