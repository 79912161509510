/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { OnMessageCallback, SocketMessage } from 'types';

export const useSocket = (wssStream?: string, debugName?: string) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const listeners = useRef<string[]>([]);

  const createWebSocket = useCallback(() => {
    if (!wssStream) return;

    const connection = new WebSocket(wssStream);

    connection.onopen = () => {
      setIsConnected(true);
    };

    connection.onclose = () => {
      setIsConnected(false);
      setSocket(null);
      createWebSocket();
    };

    connection.onerror = event => {
      console.error(`${debugName || ''}Socket error:`, event);
    };

    setSocket(connection);
  }, [wssStream]);

  useEffect(() => {
    createWebSocket();
  }, [createWebSocket]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!socket) return () => {};

    return () => {
      socket.close();
    };
  }, [socket]);

  const onMessage = useCallback(
    <T,>(cb: OnMessageCallback<T>, execOnlyIfIsAction?: string) => {
      if (!socket || !isConnected || !cb) return;
      const fnName = cb.name || cb.toString();

      if (!listeners.current.includes(fnName)) {
        listeners.current.push(fnName);

        socket.addEventListener('message', (event: MessageEvent<any>) => {
          const data = JSON.parse(event.data);
          if (execOnlyIfIsAction && execOnlyIfIsAction !== data.action) return;
          cb(data);
        });
      }
    },
    [socket, isConnected],
  );

  const send = useCallback(
    (data: SocketMessage) => {
      if (!socket || !isConnected) return;

      const strData = JSON.stringify(data);
      socket.send(strData);
    },
    [socket, isConnected],
  );

  const pin = useCallback(
    (data: SocketMessage) => {
      if (!socket || !isConnected) return;
      socket?.send(
        JSON.stringify({
          action: 'sendpinmessage',
          data: { ...data },
        }),
      );
    },
    [socket, isConnected],
  );

  const unPin = useCallback(
    (data: SocketMessage) => {
      if (!socket || !isConnected) return;
      socket?.send(
        JSON.stringify({
          action: 'sendunpinmessage',
          data: { ...data },
        }),
      );
    },
    [socket, isConnected],
  );

  return { socket, isConnected, onMessage, send, pin, unPin };
};
