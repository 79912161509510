import {
  ComponentChildSchema,
  withCustomizeSchema,
} from '@nizza/customize-live';
import { formatCurrency } from '@nizza/utils';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSettings } from '../../context';
import { useAddToCart } from '../../hooks';
import type { PlayerProduct } from '../../types';
import { intlFormatMessageOutsideFC } from '../../utils/intlFormatMessageOutsideFC';
import styles from './productSlider.module.css';

const intl = intlFormatMessageOutsideFC();

type ChatCarouselProductItemProps = {
  product: PlayerProduct;
  variationSelectorState?: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
  sectionIdClickedOn?: string;
  fullScreen: boolean;
  handleFullScreen: () => void;
};

const ChatCarouselProductItemBase = (
  props: ComponentChildSchema<
    ChatCarouselProductItemProps,
    {
      backgroundColor: string;
      priceColor: string;
    }
  >,
) => {
  const { formatMessage } = useIntl();
  const { accountCurrencyConfig } = useSettings();
  const {
    product,
    variationSelectorState,
    fullScreen,
    handleFullScreen,
    formData,
  } = props;
  const { priceWithDiscount, imageUrl } = product;
  const addToCart = useAddToCart({ variationSelectorState });

  const deconstructedPrice = JSON.parse(
    formatCurrency(priceWithDiscount, accountCurrencyConfig, true) ??
    '{integerPart: "0",decimalPart: "00",symbol: "$", decimalSeparator: "."}',
  );

  const ignoreFormat = ['R$', 'S/.', 'S/'].includes(deconstructedPrice.symbol);
  const getNormalPriceFormat = useCallback(
    (product: PlayerProduct) =>
      formatCurrency(
        product?.priceWithDiscount ?? 0,
        accountCurrencyConfig,
      ),
    [accountCurrencyConfig],
  );

  return (
    <div
      customize-field={props.selector}
      onClick={() => {
        if (fullScreen) handleFullScreen();
        product.isAvailable && addToCart(product);
      }}
      style={{ backgroundColor: formData?.data?.backgroundColor }}
      className={`${styles.productItemContent}`}
    >
      <div className={styles.pictureContent}>
        <div className={styles.productLink}>
          <img
            className={`${styles.picture} ${!product.isAvailable && styles.pictureUnavailableMobile
              }`}
            src={imageUrl}
            alt="product"
          />
        </div>
      </div>
      <div
        className={`${styles.productDeatailContent} ${styles.flexDirectionRow}`}
      >
        {ignoreFormat && (
          <div
            style={{
              color: `${formData?.data?.priceColor ?? '#ffffff'}`,
              fontSize: 10,
            }}
            className={styles.priceWithDiscount}
          >
            {product.isAvailable
              ? getNormalPriceFormat(product)
              : formatMessage({
                id: 'chat-carousel.product-unavailable-message',
              })}
          </div>
        )}
        {!ignoreFormat && (
          <>
            <div
              style={{
                color: formData?.data?.priceColor,
                fontSize: '6px',
                whiteSpace: 'nowrap',
                margin: '0px 1px 0px 0px',
                padding: '0px',
                lineHeight: '6px',
              }}
            >
              {product.isAvailable &&
                String(deconstructedPrice.symbol).replace('$', '')}
            </div>

            <div
              style={{
                lineHeight: '10px',
                fontSize: '11px',
                fontWeight: '300',
                letterSpacing: '-1px',
                color: formData?.data?.priceColor,
              }}
              className={styles.priceWithDiscount}
            >
              {product.isAvailable
                ? deconstructedPrice.symbol &&
                  String(deconstructedPrice.symbol).includes('$')
                  ? '$' + deconstructedPrice.integerPart
                  : deconstructedPrice.integerPart
                : formatMessage({
                  id: 'chat-carousel.product-unavailable-message',
                })}
            </div>

            <div
              style={{
                color: formData?.data?.priceColor,
                fontSize: '8px',
                whiteSpace: 'nowrap',
                margin: '0px 0px 0px 0px',
                padding: '0px',
                lineHeight: '7px',
              }}
            >
              {product.isAvailable && deconstructedPrice.decimalPart}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const ChatCarouselProductItem = withCustomizeSchema(
  ChatCarouselProductItemBase,
  {
    products: {
      label: intl.formatMessage({
        id: 'customize/chat-carousel-product-item.product-carousel',
      }),
      id: 'carousel_products',
      category: 'mobile',
      fields: {
        type: 'object',
        properties: {
          backgroundColor: {
            type: 'string',
            title: intl.formatMessage({
              id: 'customize/general.background',
            }),
            default: 'white',
          },
          priceColor: {
            type: 'string',
            title: intl.formatMessage({
              id: 'customize/general.price',
            }),
            default: '#767676',
          },
        },
      },
      ui: {
        backgroundColor: {
          'ui:widget': 'color',
        },
        priceColor: {
          'ui:widget': 'color',
        },
      },
    },
  },
);
