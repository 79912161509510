/* eslint-disable react-hooks/exhaustive-deps */
import { chatMessageRepository } from '@nizza/event-chat';
import { Dispatch, useContext, useEffect } from 'react';
import { ActionsContext } from '../../..';
import { config } from '../../../../enviroment/config';
import { Actions } from '../reducer';

export const useSetChatHistory = (dispatch: Dispatch<Actions>) => {
  const {
    setting: { environment, idLivestreaming, account },
  } = useContext(ActionsContext);

  useEffect(() => {
    if (!environment || !idLivestreaming || !account) return;

    let URL = '__GET_CHAT_BY_ID_URL';
    const { GET_CHAT_BY_ID_URL } = config(environment || '');

    if (GET_CHAT_BY_ID_URL && GET_CHAT_BY_ID_URL !== URL) {
      URL = GET_CHAT_BY_ID_URL;
    }

    if (!URL) return;

    const getChat = async () => {
      const chat = await chatMessageRepository.getAll(
        idLivestreaming,
        account!,
      );
      if (chat?.length) {
        dispatch({
          type: 'SET_CHAT_HISTORY',
          args: {
            chatHistory: chat,
          },
        });
      }
    };
    getChat().catch(null);
  }, [idLivestreaming, account, environment]);
};
