import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
//import { useLivestreamingContext } from '../../context';

import styles from './live.module.css';
import { IvsRealTime } from '../../types/livestreaming';

interface LiveProps {
  isMobile: boolean;
  isFinalized?: boolean;
  ivsRealTime?: IvsRealTime;
}

export const Live = ({ isMobile, isFinalized, ivsRealTime }: LiveProps) => {
  const { formatMessage } = useIntl();

  //const { ivsRealTime } = useLivestreamingContext();
  const [transmiting, setTransmiting] = useState(false);

  useEffect(() => {
    if (ivsRealTime) {
      setTransmiting(ivsRealTime.status === 'LIVE');
    }
  }, [ivsRealTime]);

  return (
    <Fragment>
      {transmiting && isMobile && (
        <div
          style={{
            height: '5px',
            width: '5px',
            background: `${isFinalized ? '#f7f7f7' : '#ff0000ad'}`,
            borderRadius: '20px',
            marginLeft: `${isFinalized ? '0px' : '4.5px'}`,
            marginRight: `${isFinalized ? '2.9px' : ''}`,
          }}
        ></div>
      )}
      <span
        className={`${styles.liveText} ${isMobile && styles.liveTextMobile}`}
      >
        {isFinalized
          ? formatMessage({
              id: 'chat.mobile-record-playback-indicator-message',
            })
          : transmiting
          ? 'LIVE'
          : 'Offline'}
      </span>
    </Fragment>
  );
};
