/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { StoreProvider } from '@nizza/core';
import { customizeLiveModule } from '@nizza/customize-live';
import { collectionModule } from '@nizza/event-products';
import { lazyLoad } from '@nizza/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AccountProvider, AppConfigProvider } from 'hooks';
import { AuthProvider } from 'hooks/authentication';
import { PlanProvider } from 'hooks/plans';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import 'styles/scss/theme.scss';
import { AppRouter } from './routes';

const isProd = import.meta.env.PROD;

const ReactQueryDevtools = lazyLoad(
  () => import('@tanstack/react-query-devtools'),
  module => module.ReactQueryDevtools,
);

// Create react-query client
const queryClient = new QueryClient();

// CLEAN App Modules
const modules = [collectionModule, customizeLiveModule];
const stores = modules.flatMap(module => module?.stores || []);

export function App() {
  const { i18n } = useTranslation();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StoreProvider stores={[...stores]}>
          <AppConfigProvider>
            <AuthProvider>
              <AccountProvider>
                <PlanProvider>
                  {/* @ts-ignore */}
                  <Helmet
                    titleTemplate="%s - Nizza"
                    defaultTitle="Nizza"
                    htmlAttributes={{ lang: i18n.language }}
                  >
                    <meta name="description" content="Nizza application" />
                  </Helmet>
                  <AppRouter />
                  <GlobalStyle />
                </PlanProvider>
              </AccountProvider>
            </AuthProvider>
          </AppConfigProvider>
        </StoreProvider>

        {!isProd && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </BrowserRouter>
  );
}
