/* eslint-disable react-hooks/exhaustive-deps */
import { useAppConfig, usePlan } from 'hooks';
import { Fragment, useEffect, useMemo } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from '.';
import {
  getAllowedRoutesAccordingPlan,
  getRoutesWithAppConfig,
} from '../../shared';
import {
  AsyncNotFoundPage,
  NonAuthLayout,
  VerticalLayout,
} from '../components';
import { AuthMiddleware } from './AuthMiddleware';

export const AppRouter = () => {
  const { modules, setProtectedRoutes, auth } = useAppConfig();
  const { planInfo } = usePlan();

  const filteredPublicRoutes = useMemo(
    () => getRoutesWithAppConfig(publicRoutes, modules),
    [modules],
  );

  const filteredAuthProtectedRoutes = useMemo(
    () =>
      getAllowedRoutesAccordingPlan(
        getRoutesWithAppConfig(authProtectedRoutes, modules),
        planInfo,
      ),
    [modules, planInfo],
  );

  useEffect(() => {
    setProtectedRoutes(filteredAuthProtectedRoutes);
  }, [filteredAuthProtectedRoutes]);

  return (
    <Routes>
      {filteredPublicRoutes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          index={route.index ?? true}
          element={
            <AuthMiddleware
              layout={NonAuthLayout}
              component={route.element}
              isAuthProtected={false}
            />
          }
        />
      ))}

      <Route
        path="/"
        element={
          <VerticalLayout>
            <Outlet />
          </VerticalLayout>
        }
      >
        {filteredAuthProtectedRoutes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            index={route.index ?? true}
            element={
              <AuthMiddleware
                layout={Fragment}
                component={route.element}
                isAuthProtected={auth?.enable ?? true}
              />
            }
          />
        ))}
      </Route>

      <Route path="*" element={<AsyncNotFoundPage />} />
    </Routes>
  );
};
