import { useAppConfig } from 'hooks';
import { useTranslation } from 'react-i18next';
import { AppModule } from 'types';

export const useSidebar = () => {
  const { protectedRoutes, modules, auth } = useAppConfig();
  const { t } = useTranslation();
  const authIsDisabled = auth?.enable === false;

  const getMenuItemsByModule = (...modules: AppModule[]) => {
    return protectedRoutes
      .filter(x => modules.includes(x.module as AppModule) && x.menu)
      .map(x => ({
        link: x.path,
        title: t(x.menu?.title as string),
        icon: x.menu?.icon,
      }));
  };

  const showModule = (...moduleList: AppModule[]) => {
    const selectedModules =
      modules?.omit || modules?.only
        ? modules?.omit
          ? Object.values(AppModule).filter(x => !modules?.omit?.includes(x))
          : modules?.only
        : Object.values(AppModule);

    return moduleList.some(x => !!selectedModules?.includes(x));
  };

  const billingModule = authIsDisabled
    ? AppModule.EXTERNAL_BILLING
    : AppModule.BILLING;

  const accountManagementModules = [
    AppModule.ACCOUNT_MANAGEMENT,
    AppModule.USERS,
    billingModule,
  ];

  return {
    getMenuItemsByModule,
    showModule,
    accountManagementModules,
  };
};
