import { createSchemaUtils } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv6';
import { observer } from 'mobx-react-lite';
import { ComponentType, ReactNode } from 'react';
import { useCustomizations } from '../hooks';
import { CustomizeLiveStore, customizeLiveStore } from '../stores';
import { CustomizeSchemaType } from '../types/schema';

import './styles.css';

const runTasks = (callback: () => void) => setTimeout(callback);

export const withCustomizeSchema = <T,>(
  Component: ComponentType<T>,
  schema: CustomizeSchemaType,
) => {
  const section = Object.keys(schema)[0];
  const { id } = Object.values(schema)[0];

  const registerSchema = (store: CustomizeLiveStore) =>
    store.schemaStore.registerSchemaComponent(schema);

  const saveFormData = async (store: CustomizeLiveStore) => {
    const schemaUtils = createSchemaUtils(validator, schema);
    const defaultData = schemaUtils.getDefaultFormState(schema[section].fields);
    store.schemaStore.saveDefaultData({
      section,
      id,
      data: defaultData,
    });
  };

  runTasks(async () => {
    const store = customizeLiveStore.value;
    registerSchema(store);
    saveFormData(store);
  });

  return observer((props: T & { children?: ReactNode }) => {
    const selector = `${section}__${id}`;
    const { getDataComponent } = useCustomizations();
    const formData = getDataComponent(section, id);

    return (
      <Component
        {...{
          ...props,
          selector,
          formData,
        }}
      />
    );
  });
};
