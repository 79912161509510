import { createContext, useContext } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

export interface AuthCtx {
  cognitoUser: CognitoUser | null;
  setCognitoUser: (cognitoUser: CognitoUser) => void;
}

export const authCtxDefault: AuthCtx = {
  cognitoUser: null,
  setCognitoUser: () => null,
};

export const AuthContext = createContext<AuthCtx>(authCtxDefault);
export const useAuth = () => useContext(AuthContext);
