import { useState, useRef, useEffect } from 'react';
import { Share as ShareType } from 'types';
import { SocialNetworkIcon } from './SocialNetworkIcon';
import copy from 'copy-text-to-clipboard';
import { ShareIcon } from './icons';
interface Props {
  share: ShareType;
  link: string;
  colorButton?: string;
  backgroundButton?: string;
  sizeButton?: number;
}

export const Share = ({
  share,
  link,
  colorButton,
  backgroundButton,
  sizeButton,
}: Props) => {
  const { twitter, facebook, whatsapp } = share;
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleCloseCard = () => setTimeout(() => setShowModal(false), 150);

  useEffect(() => {
    modalRef.current?.focus();
  }, [showModal]);

  return (
    <div className="share-container">
      {showModal && (
        <div
          ref={modalRef}
          className="share-modal flex col"
          tabIndex={0}
          onBlur={handleCloseCard}
        >
          <button className="close-card-button" onClick={handleCloseCard}>
            <i className="mdi mdi-close"></i>
          </button>
          <div className="flex align-center mb5px">
            Compartir: &nbsp;
            {facebook && (
              <SocialNetworkIcon
                name=""
                iconName="facebook"
                size={24}
                link={facebook}
                color="white"
                background="#3b5998"
                containerSize={30}
              />
            )}
            {twitter && (
              <SocialNetworkIcon
                name=""
                iconName="twitter"
                size={24}
                link={twitter}
                color="white"
                background="#00aaee"
                containerSize={30}
              />
            )}
            {whatsapp && (
              <SocialNetworkIcon
                name=""
                iconName="whatsapp"
                size={24}
                link={whatsapp}
                color="white"
                background="#25d366"
                containerSize={30}
              />
            )}
          </div>
          <div className="flex align-center">
            <input
              value={link}
              disabled
              type="text"
              className="share-modal-copy-link"
            />
            <button
              onClick={() => copy(link)}
              className="flex justify-center align-center share-modal-copy-button"
            >
              <i className="mdi mdi-content-copy mdi-24px"></i>
            </button>
          </div>
        </div>
      )}
      <button onClick={() => setShowModal(!showModal)} className="share-button">
        <ShareIcon
          size={sizeButton}
          color={colorButton}
          background={backgroundButton}
        />
      </button>
    </div>
  );
};
