import * as yup from 'yup';
import { CreateProductDTO, UpdateProductDTO } from 'types';
import { ProductStatus } from '@nizza/event-products';

const baseSchema = {
  title: yup.string().required(),
  price: yup.number().positive().required(),
  salesPrice: yup
    .number()
    .positive()
    .nullable()
    .transform((_, originalValue: string) => {
      return originalValue ? Number(originalValue) : null;
    }),
  link: yup.string().url().required(),
  pictures: yup
    .array(yup.string().url().required())
    .transform((_, originalValue: string) => {
      return originalValue ? originalValue.split(/\r?\n/) : [];
    }),
};

export const createProductSchema: yup.SchemaOf<CreateProductDTO> = yup
  .object(baseSchema)
  .defined();

export const updateProductSchema: yup.SchemaOf<UpdateProductDTO> = yup
  .object({
    ...baseSchema,
    status: yup.mixed().oneOf(Object.values(ProductStatus)),
  })
  .defined();
