import clsx from 'clsx';
import { FC } from 'react';

export interface ChatMessageContentProps {
  data?: string;
  type?: string;
  className?: string;
}

export const ChatMessageContent: FC<ChatMessageContentProps> = ({
  data,
  type,
  className,
}) => {
  return (
    <div className={clsx(className)}>
      {type === 'gif' ? (
        <img alt="gif" src={data?.split(',')[0]} />
      ) : (
        <p className="text-base">{data}</p>
      )}
    </div>
  );
};
