/**
 * Asynchronously loads the components
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncEventChat = lazyLoad(
  () => import('./EventChat'),
  module => module.EventChat,
);

export const AsyncEventDetail = lazyLoad(
  () => import('./EventDetail'),
  module => module.EventDetail,
);

export const AsyncEventList = lazyLoad(
  () => import('./EventList'),
  module => module.EventList,
);

export const AsyncExternalPreview = lazyLoad(
  () => import('./ExternalPreview'),
  module => module.ExternalPreview,
);

export const AsyncEventCustomize = lazyLoad(
  () => import('./EventCustomize'),
  module => module.EventCustomize,
);

export const AsyncNewEvent = lazyLoad(
  () => import('./NewEvent'),
  module => module.NewEvent,
);

export const AsyncEventAnalytics = lazyLoad(
  () => import('./EventAnalytics'),
  module => module.EventAnalytics,
);
