interface Props {
  width?: number;
  background?: string;
  color?: string;
}

export const LiveIcon = ({
  width = 83,
  background = '#ff1024',
  color = '#f8f7fc',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width - 63}
      viewBox="0 0 98 35"
    >
      <rect width="98" height="35" rx="3" fill={background} />
      <g transform="translate(1268 -705)">
        <text
          transform="translate(-1235 730)"
          fill={color}
          fontSize="22"
          fontFamily="Montserrat-Medium, Montserrat"
          fontWeight="500"
        >
          <tspan x="0" y="0">
            LIVE
          </tspan>
        </text>
        <circle
          cx="5.5"
          cy="5.5"
          r="5.5"
          transform="translate(-1252 717)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
