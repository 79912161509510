import { WC_NAME } from './constants';

// TODO: Esto tenemos que revisarlo si queremos tener multiples
// instancias del reproductor en la misma página
export const getElementById = (id: string) => {
  const mainElement = document.querySelector(WC_NAME)?.shadowRoot! || document;
  return mainElement.getElementById(id);
};

export const querySelectorAll = (selector: string) => {
  const mainElement = document.querySelector(WC_NAME)?.shadowRoot! || document;
  return mainElement.querySelectorAll(selector);
};
