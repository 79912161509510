import { LOCALES } from '../locales';

export default {
  [LOCALES.es]: {
    'store/button.close': 'Cerrar',
    'store/button.expecting': 'Esperando...',
    'store/button.send': 'Enviar',
    'store/inputs.required-placeholder': 'Requerido',
    'store/live.chat': 'Chat en vivo',
    'store/live.chat-login': 'Ingrese su nombre para continuar',
    'store/live.chat-login-btn': 'Continuar con el chat',
    'store/live.chat-login-email-no-required-placeholder': 'Email',
    'store/live.chat-login-email-required-placeholder': 'Email*',
    'store/live.chat-login-invalid-email': 'Email no valido',
    'store/live.chat-login-invalid-username': 'Nombre de usuario no válido',
    'store/live.chat-login-name-placeholder': 'Nombre*',
    'store/live.chat-login-username-already-exist':
      'Este nombre de usuario ya existe',
    'store/live.chat-votes': 'votos',
    'store/seconds': 'Segundos',
    'store/text.add': 'Agregar',
    'store/text.anonymous-user': 'Anónimo',
    'store/text.before': 'Antes',
    'store/text.chat-placeholder': 'Escribe un comentario',
    'store/text.email': 'Email',
    'store/text.email-invalid': 'Email inválido',
    'store/text.false': 'Falso',
    'store/text.likes': 'Me Gustas',
    'store/text.live-no-video': 'Transmisión en vivo.',
    'store/text.live-products': 'Productos',
    'store/text.name': 'Nombre',
    'store/text.no-video': 'Esperando conexión con el stream.',
    'store/text.not-stock': 'Agotado',
    'store/text.now': 'Ahora',
    'store/text.products': 'Productos destacados',
    'store/text.recording-text': 'Eventos anteriores',
    'store/text.select-answer': 'Debe seleccionar una respuesta.',
    'store/text.true': 'Verdadero',
    'store/text.unread-messages': 'Mensajes sin leer',
    'store/time': 'Tiempo',
    'store/total-votes': 'Total votos',
    'store/text.add-to-cart': 'Producto agregado al carrito.',
    'store/text.add-to-cart-error': 'Error al agregar al carrito.',
    'store/text.share': 'Compartir',
    'store/text.details': 'Ver detalles',
    'chat.message-max-length':
      'Los mensajes pueden tener un máximo de 180 caracteres',
    'chat.show-chat-horizontal-layout-message': 'Ver repetición del chat',
    'chat.hide-chat-horizontal-layout-message': 'Ocultar repetición del chat',
    'chat.mobile-record-playback-indicator-message': 'Grabación',
    'chat.chat-replay-mobile-vertical-layout-message': 'Repetición del chat',
    'chat-carousel.product-unavailable-message': 'No disponible',

    'customize/general.back': 'Atrás',
    'customize/general.background': 'Fondo',
    'customize/general.price': 'Precio',
    'customize/general.text': 'Texto',
    'customize/general.message': 'Mensaje',
    'customize/general.section-title': 'Título sección',
    'customize/general.section-name': 'Nombre sección',
    'customize/general.icon': 'Ícono',
    'customize/general.desktop': 'Escritorio',
    'customize/general.mobile': 'Móvil',
    'customize/product-button.add-to-cart-button': 'Botón agregar al carrito',
    'customize/product-button.text-inside-button': 'Texto dentro del botón',
    'customize/chat-carousel-product-item.product-carousel':
      'Productos en móvil',
    'customize/highlight-product.product-highlight': 'Producto destacado',
    'customize/highlight-product.show': 'Mostrar',
    'customize/highlight-product.show-button': 'Mostrar botón',
    'customize/highlight-product.show-price': 'Mostrar precio',
    'customize/highlight-product.opacity': 'Opacidad',
    'customize/product-item.product-cards': 'Productos en escritorio',
    'customize/product-item.highlight-promotion': 'Destacar promoción',
    'customize/vertical-product-slider.section-title':
      'Título sección productos',
    'customize/message-container-admin.admin-message':
      'Mensaje del administrador',
    'customize/message-container-admin.background-layout-horizontal':
      'Fondo layout horizontal',
    'customize/message-container-admin.message-layout-horizontal':
      'Mensaje layout horizontal',
    'customize/message-container-admin.background-layout-vertical':
      'Fondo layout vertical',
    'customize/message-container-admin.message-layout-vertical':
      'Mensaje layout vertical',
    'customize/chat.chat-section-title': 'Título sección chat',
    'customize/chat.comment-box-text': 'Texto caja de comentarios',
    'customize/chat.comment-box-title': 'Caja de comentarios',
    'customize/chat.send-message-button.desktop.title':
      'Botón enviar en escritorio',
    'customize/modal-trigger.select-product-variations.title':
      'Selección de variaciones de producto',
    'customize/modal-trigger.round-or-square-selector':
      'Estilo en que se muestra las variaciones',
    'customize/modal-trigger.round-selector': 'Selector redondo',
    'customize/modal-trigger.square-selector': 'Selector cuadrado',
  },
};
