import { getPlan } from 'api';
import { useAccount } from 'hooks';
import { FC, ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ConfigPlan } from 'types';
import { useDebounce } from 'use-debounce';
import { PlanContext, PlanCtx, PlanCtxDefault } from './planContext';

interface Props {
  consumer?: (context: PlanCtx) => ReactNode;
  children: ReactNode;
}

export const PlanProvider: FC<Props> = props => {
  const { children, consumer } = props;
  const { accountInfo, accountInfoStatus } = useAccount();

  const { status: planInfoStatus, data: planInfo = PlanCtxDefault.planInfo } =
    useQuery<ConfigPlan>(
      ['planInfo', accountInfo.descriptionPlan],
      ({ queryKey: [_, planType] }) => getPlan(planType as string),
      {
        enabled: !!accountInfo.planType,
        refetchOnWindowFocus: false,
      },
    );

  const [loadingPlanInfo] = useDebounce(
    planInfoStatus === 'loading' || accountInfoStatus === 'loading',
    500,
  );

  const [errorPlanInfo] = useDebounce(
    planInfoStatus === 'error' || accountInfoStatus === 'error',
    500,
  );

  const context: PlanCtx = {
    loadingPlanInfo,
    errorPlanInfo,
    planInfo,
  };

  return (
    <PlanContext.Provider value={context}>
      {consumer ? consumer(context) : children}
    </PlanContext.Provider>
  );
};
