import { Auth } from 'aws-amplify';
import { useCognito } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Avatar } from './Avatar';

export const ProfileMenu = () => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);

  const {
    cognitoUser: {
      attributes: { email },
    },
  } = useCognito();

  const handleSignOut = async () => {
    await Auth.signOut();
  };

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item "
        id="page-header-user-dropdown"
        tag="button"
      >
        <Avatar text={email} />
        <span className="d-none d-xl-inline-block ms-2 me-1">{email}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="a" href="/profile">
          {' '}
          <i className="bx bx-user font-size-16 align-middle me-1" />
          {t('Header.Profile')}{' '}
        </DropdownItem>
        <DropdownItem tag="a" href="/crypto-wallet">
          <i className="bx bx-wallet font-size-16 align-middle me-1" />
          {t('Header.Wallet')}
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <span className="badge bg-success float-end">11</span>
          <i className="bx bx-wrench font-size-16 align-middle me-1" />
          {t('Header.Settings')}
        </DropdownItem>
        <DropdownItem tag="a" href="auth-lock-screen">
          <i className="bx bx-lock-open font-size-16 align-middle me-1" />
          {t('Header.LockScreen')}
        </DropdownItem>
        <div className="dropdown-divider" />
        <Link to="/login" className="dropdown-item" onClick={handleSignOut}>
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>{t('Header.Logout')}</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
};
