import React from 'react';
const BabyIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 510.971 510.971"
  >
    <g>
      <path
        d="M510.106,245.481c-3.488-19.859-18.232-36.663-37.563-42.809c-8.19-2.604-16.553-3.32-24.854-2.128
		c-0.35,0.051-0.49-0.285-0.532-0.431c-11.747-40.733-36.863-77.41-70.721-103.274c-34.987-26.727-76.811-40.854-120.951-40.854
		s-85.964,14.127-120.951,40.854c-33.858,25.864-58.974,62.541-70.721,103.273c-0.042,0.146-0.18,0.482-0.532,0.432
		c-8.3-1.193-16.663-0.477-24.854,2.128c-19.33,6.146-34.074,22.949-37.563,42.808c-2.887,16.431,1.523,33.08,12.1,45.675
		c10.581,12.603,26.079,19.83,42.521,19.83c2.581,0,5.188-0.186,7.752-0.552c0.359-0.051,0.513,0.205,0.577,0.429
		c11.748,40.732,36.864,77.407,70.722,103.271c34.987,26.726,76.811,40.852,120.949,40.852s85.962-14.126,120.949-40.852
		c33.858-25.864,58.974-62.539,70.722-103.271c0.064-0.224,0.217-0.479,0.577-0.429c2.563,0.366,5.171,0.552,7.752,0.552
		c16.441,0,31.939-7.228,42.521-19.83C508.582,278.56,512.992,261.911,510.106,245.481z M486.519,281.51
		c-7.724,9.199-19.035,14.476-31.033,14.476c-1.873,0-3.768-0.135-5.631-0.401c-7.741-1.103-14.934,3.573-17.111,11.122
		c-10.863,37.663-34.094,71.582-65.415,95.507c-32.349,24.711-71.023,37.772-111.843,37.772s-79.495-13.062-111.843-37.772
		c-31.321-23.925-54.552-57.844-65.415-95.507c-1.964-6.81-8.011-11.283-14.856-11.283c-0.744,0-1.497,0.053-2.254,0.161
		c-1.863,0.267-3.758,0.401-5.631,0.401c-11.998,0-23.309-5.276-31.033-14.476c-7.832-9.328-10.962-21.202-8.813-33.435
		c2.534-14.424,13.263-26.635,27.334-31.108c6.018-1.914,12.133-2.444,18.176-1.575c7.717,1.108,14.899-3.569,17.078-11.123
		c10.862-37.665,34.093-71.584,65.414-95.51c18.114-13.837,38.211-24.019,59.558-30.303c-7.07,8.117-10.991,17.448-10.991,26.619
		c0,11.13,5.822,20.911,16.394,27.542c10.969,6.88,26.743,10.368,46.884,10.368c16.374,0,29.239-1.955,38.237-5.81
		c13.225-5.667,15.2-14.22,15.2-18.814c0-16.75-23.972-30.376-53.437-30.376c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5
		c24.967,0,38.437,10.914,38.437,15.376c0,1.199-1.888,3.219-6.107,5.026c-4.896,2.098-14.479,4.598-32.33,4.598
		c-17.035,0-30.491-2.792-38.914-8.075c-6.213-3.897-9.364-8.889-9.364-14.835c0-15.765,21.089-34.09,48.278-34.09
		c40.82,0,79.496,13.062,111.845,37.773c31.321,23.926,54.552,57.846,65.414,95.511c2.178,7.553,9.356,12.229,17.077,11.122
		c6.044-0.868,12.159-0.339,18.176,1.575c14.071,4.474,24.8,16.685,27.334,31.108C497.481,260.308,494.351,272.182,486.519,281.51z"
      />
      <path
        d="M255.486,295.986c-35.014,0-63.5,28.486-63.5,63.5s28.486,63.5,63.5,63.5s63.5-28.486,63.5-63.5
		S290.5,295.986,255.486,295.986z M238.986,391.486c0-9.098,7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5s-7.402,16.5-16.5,16.5
		S238.986,400.584,238.986,391.486z M286.546,396.699c0.284-1.697,0.439-3.438,0.439-5.214c0-14.785-10.241-27.216-24-30.591v-9.409
		c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v9.409c-13.759,3.375-24,15.806-24,30.591c0,1.776,0.155,3.517,0.439,5.214
		c-10.65-8.904-17.439-22.279-17.439-37.214c0-26.743,21.757-48.5,48.5-48.5s48.5,21.757,48.5,48.5
		C303.986,374.42,297.196,387.795,286.546,396.699z"
      />
      <path
        d="M190.98,231.186c-0.166-4.139-3.662-7.366-7.794-7.194c-8.74,0.35-18.217,1.75-28.296,9.567
		c-3.273,2.539-3.869,7.25-1.331,10.523c1.479,1.906,3.693,2.904,5.932,2.904c1.605,0,3.224-0.513,4.591-1.573
		c6.418-4.978,12.085-6.128,19.704-6.433C187.924,238.814,191.145,235.324,190.98,231.186z"
      />
      <path
        d="M179.486,255.986c-6.341,0-11.5,5.159-11.5,11.5v8c0,6.341,5.159,11.5,11.5,11.5s11.5-5.159,11.5-11.5v-8
		C190.986,261.145,185.827,255.986,179.486,255.986z"
      />
      <path
        d="M356.082,233.559c-10.08-7.817-19.557-9.218-28.296-9.567c-4.153-0.166-7.628,3.056-7.794,7.194s3.056,7.628,7.194,7.794
		c7.619,0.305,13.285,1.455,19.704,6.433c1.367,1.061,2.985,1.573,4.591,1.573c2.239,0,4.454-0.998,5.932-2.904
		C359.95,240.809,359.355,236.097,356.082,233.559z"
      />
      <path
        d="M331.486,255.986c-6.341,0-11.5,5.159-11.5,11.5v8c0,6.341,5.159,11.5,11.5,11.5s11.5-5.159,11.5-11.5v-8
		C342.986,261.145,337.827,255.986,331.486,255.986z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
export default BabyIcon;
