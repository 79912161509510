import { useSetPlan } from 'hooks/account';
import { useEffect, useState } from 'react';
import { buildPostMessage, SubscriptionActionsEvents } from 'shared';
import { AccountSubscriptionType, PlanType } from 'types';

const postMessage = buildPostMessage<SubscriptionActionsEvents>(window.parent);

interface Props {
  account: string;
  refetchInfoAccount: () => void;
}

export const useExternalUpdatePlan = ({
  account,
  refetchInfoAccount,
}: Props) => {
  const [plan, setPlan] = useState<PlanType>();
  const { handleSetPlan } = useSetPlan();

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        postMessage(
          SubscriptionActionsEvents.SEND_SUBSCRIPTION,
          'notifySendSubscription',
        ),
      2000,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const getPlanType = (planId: string) => {
    return planId === AccountSubscriptionType.LITE
      ? PlanType.PLAN_LITE
      : planId === AccountSubscriptionType.STANDARD
      ? PlanType.PLAN_ESTANDAR
      : AccountSubscriptionType.PRO
      ? PlanType.PLAN_PRO
      : '';
  };

  useEffect(() => {
    const handleMessages = async (event: MessageEvent<any>) => {
      const data = event.data;

      if (
        !data?.source?.includes('Nizza') ||
        data?.target !== 'ExternalPayButton'
      )
        return;

      if (
        data?.name === SubscriptionActionsEvents.SEND_SUBSCRIPTION &&
        data?.payload
      ) {
        const name: string = data.payload?.name ?? '';

        const subsActive = data.payload?.status === 'active';
        const planType = name && subsActive ? getPlanType(name) : '';

        if (!account || !planType) return;

        setPlan(planType);
        await handleSetPlan(planType, planType, atob(account));
      }
    };

    window.addEventListener('message', handleMessages, false);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [handleSetPlan, account]);

  useEffect(() => {
    if (!account) return;
    const timeout = setTimeout(() => refetchInfoAccount(), 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [refetchInfoAccount, account, plan]);

  return { plan };
};
