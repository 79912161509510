import { getElementById } from '../utils';
import { useState, useEffect } from 'react';

declare interface Option {
  rootMargin: string;
}

const useLivestreamingComponentOnScreen = (option: Option) => {
  // TODO: Esto tenemos que revisarlo si queremos tener multiples
  // instancias del reproductor en la misma página
  const livestreamingComponent = getElementById('in-view-nizza-player');

  const [inView, setInView] = useState(false);

  const callback = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setInView(true);

      return;
    }

    setInView(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, option);

    if (livestreamingComponent) observer.observe(livestreamingComponent);
  }, [livestreamingComponent, option]);

  return { livestreamingComponentInView: inView };
};

export default useLivestreamingComponentOnScreen;
