import { Product, ProductService } from '@nizza/event-products';
import { createContext, useContext } from 'react';
import { UseMutationResult } from '@tanstack/react-query';

export interface ProductCtx {
  selectedProduct: Product | null;
  setSelectedProduct: (product: Product) => void;
  productService: ProductService;
  showNotFound: boolean;
  loadingProduct: boolean;
  deleteMutation: UseMutationResult<void, unknown, string, unknown>;
}

export const productCtxDefault: ProductCtx = {
  selectedProduct: null,
  setSelectedProduct: () => null,
  productService: undefined as any,
  showNotFound: false,
  loadingProduct: false,
  deleteMutation: undefined as any,
};

export const ProductContext = createContext<ProductCtx>(productCtxDefault);
export const useProduct = () => useContext(ProductContext);
