export enum QuestionStatus {
  CREATED = 'CREATED',
  SENDED = 'SENDED',
}

export enum QuestionTypes {
  QUIZ = 'quiz',
  POLL = 'poll',
}

export enum QuestionSocketActions {
  SEND = 'sendquestion',
}

export interface Question {
  index: string;
  question: string;
  type: QuestionTypes;
  answers: Answer[];
  status: QuestionStatus;
  time: number;
  totalVotes: number;
}

export interface Answer {
  text: string;
  votes: number;
}

export type AnswerDTO = Omit<Answer, 'votes'>;

export type QuestionDTO = Pick<Question, 'question' | 'time' | 'type'> & {
  answers: AnswerDTO[];
};
