import { Button, IconTrash } from '@vtex/admin-ui';
import { ChatPinIcon, ChatUnPinIcon } from 'assets/icons';
import { FC } from 'react';

interface Props {
  isPinned?: boolean;
  onDelete: () => void;
  onTogglePinnedMessage: () => void;
}

export const ChatAdminButtons: FC<Props> = ({
  isPinned = false,
  onDelete,
  onTogglePinnedMessage,
}) => (
  <>
    <Button
      className="flex items-center justify-center"
      icon={isPinned ? <ChatUnPinIcon /> : <ChatPinIcon />}
      variant="neutralTertiary"
      onClick={() => onTogglePinnedMessage()}
    ></Button>
    <Button
      className="flex items-center justify-center"
      icon={<IconTrash />}
      variant="neutralTertiary"
      onClick={() => onDelete()}
    ></Button>
  </>
);
