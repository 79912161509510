import { UnexpectedError } from '@nizza/core';
import { CustomizeComponentDomain, CustomizeRepository } from '../domain';

export class CustomizeServices {
  private account!: string;
  constructor(private customizeRepository: CustomizeRepository) {}

  updateCustomization(data: CustomizeComponentDomain) {
    try {
      return this.customizeRepository.updateCustomization(data);
    } catch (error) {
      throw new UnexpectedError(error);
    }
  }

  deleteAllCustomizations() {
    try {
      return this.customizeRepository.deleteAllCustomization(this.account);
    } catch (error) {
      throw new UnexpectedError(error);
    }
  }

  getAllCustomizations() {
    try {
      return this.customizeRepository.getAllCustomizations(this.account);
    } catch (error) {
      throw new UnexpectedError(error);
    }
  }

  setAccount(account: string) {
    this.account = account;
  }
}
