import { useEffect, useState } from 'react';
import type { HighlightProduct, PlayerProduct } from '../types';
import { useLivestreamingContext } from '../context';
import { useFetchProducts } from './useFetchProducts';
import ProductLocalStorage from 'lib/utils/localStorageProductHighlight';
import { useShowHighlightsForFinishedEvents } from './useShowHightlightsForFinishedEvents';

type useHighlightProductProps = {
  highlightProducts: HighlightProduct;
  collectionId: string | undefined;
  isFinalized: boolean;
  setCollection?: React.Dispatch<React.SetStateAction<string | undefined>>;
  productsInBD?: any[];
  setProductsInBD?: React.Dispatch<React.SetStateAction<any[] | undefined>>;
};

export const useHighlightProduct = ({
  highlightProducts,
  collectionId,
  isFinalized,
  setCollection,
  productsInBD,
  setProductsInBD,
}: useHighlightProductProps) => {
  const [productHighlight, setProductHighlight] = useState<PlayerProduct[]>([]);
  const { idLivestreaming } = useLivestreamingContext();
  const { products } = useFetchProducts({
    collectionId,
    setCollection,
    productsInBD,
    setProductsInBD,
  });

  const handleSetProduct = (
    productIds: string[],
    products: PlayerProduct[],
  ) => {
    const findProductsId = (product: { id: string | undefined }) =>
      product.id && productIds.includes(product.id);
    setProductHighlight(products.filter(findProductsId));
  };

  useShowHighlightsForFinishedEvents(handleSetProduct, isFinalized, products);

  useEffect(() => {
    localStorage.removeItem('collectionId');

    if (collectionId) localStorage.setItem('collectionId', collectionId);

    let productIds: string[] = [];

    try {
      const { livestreamingId, products: productsLocalStorage } =
        ProductLocalStorage.getContent();
      if (livestreamingId === idLivestreaming)
        productIds = productsLocalStorage;
    } catch (error) {
      console.error(`[LocalStorage Item product]: Do not get value in item`);
    }

    if (products) {
      const productsId = new Set([
        ...(highlightProducts?.livestreamingId
          ? highlightProducts.products
          : productIds),
      ]);

      ProductLocalStorage.save({
        ...highlightProducts,
        livestreamingId: idLivestreaming,
        products: productIds,
      });

      handleSetProduct(Array.from(productsId), products);
    }
  }, [highlightProducts, collectionId, products, idLivestreaming]);

  return { productHighlight };
};
