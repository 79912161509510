import { ApiV1Response, customFetch } from '@nizza/core';
import { API_REST_URL_V1 } from '../shared';
import {
  CreateSubscriptionArgs,
  CreateSubscriptionResponse,
  UpdateSubscriptionArgs,
} from '../types';

const BASE_URL = `${API_REST_URL_V1}/checkout`;

export const createSubscription = async (args: CreateSubscriptionArgs) => {
  const url = `${BASE_URL}/create-subscription`;
  const { data } = await customFetch<ApiV1Response<CreateSubscriptionResponse>>(
    {
      url,
      method: 'POST',
      body: JSON.stringify({
        paymentGateway: args.paymentGateway,
        data: args.data,
        planId: args.planId,
        accountId: args.accountId,
      }),
    },
  );

  return data;
};

export const updateSubscription = async (args: UpdateSubscriptionArgs) => {
  const url = `${BASE_URL}/update-subscription`;
  const { data } = await customFetch<ApiV1Response<any>>({
    url,
    method: 'POST',
    body: JSON.stringify({
      planId: args.planId,
      accountId: args.accountId,
    }),
  });

  return data;
};

export const updateSubscriptionStatus = async (accountId: string) => {
  const url = `${BASE_URL}/update-subscription-status`;
  const { success } = await customFetch<ApiV1Response<boolean>>({
    url,
    method: 'PUT',
    body: JSON.stringify({
      accountId,
    }),
  });

  return Boolean(success);
};
