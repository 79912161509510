import {
  assignedTranslateValues,
  ComponentChildSchema,
  getTranslatedText,
  withCustomizeSchema,
} from '@nizza/customize-live';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAddToCart } from '../../hooks';
import type { PlayerProduct } from '../../types';
import { intlFormatMessageOutsideFC } from '../../utils/intlFormatMessageOutsideFC';
import styles from './productButton.module.css';

const intl = intlFormatMessageOutsideFC();

type ProductButtonProps = {
  product: PlayerProduct;
  sectionIdClickedOn?: string;
  variationSelectorState?: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
  handleClose?: () => void;
};

export const ProductButtonBase = (
  props: ComponentChildSchema<ProductButtonProps>,
) => {
  const {
    product,
    sectionIdClickedOn,
    handleClose,
    variationSelectorState,
    formData,
  } = props;

  const { id, name, isAvailable } = product;
  const { formatMessage } = useIntl();
  const addToCart = useAddToCart({ variationSelectorState });

  const handleClick = () => {
    if (sectionIdClickedOn) {
      const eventAddToCart = JSON.stringify({
        sectionIdClickedOn,
        id,
        name,
      });

      localStorage.setItem('sectionIdClickedOnForAddToCart', eventAddToCart);
    }

    addToCart(product);

    if (handleClose) handleClose();
  };

  return (
    <button
      customize-field={props.selector}
      style={{
        backgroundColor: props.formData?.data?.backgroundColor,
        color: props.formData?.data?.textColor,
      }}
      className={clsx(styles.productAddCart, !isAvailable && styles.noActive)}
      disabled={!isAvailable}
      onClick={() => handleClick()}
    >
      {isAvailable
        ? getTranslatedText(formData?.data?.textInsideButton) ||
          formatMessage({ id: 'store/text.add' })
        : formatMessage({ id: 'chat-carousel.product-unavailable-message' })}
    </button>
  );
};

export const ProductButton = withCustomizeSchema(ProductButtonBase, {
  products: {
    label: intl.formatMessage({
      id: 'customize/product-button.add-to-cart-button',
    }),
    id: 'add_to_cart_button',
    category: 'mix',
    fields: {
      type: 'object',
      properties: {
        textInsideButton: {
          type: 'string',
          title: intl.formatMessage({
            id: 'customize/product-button.text-inside-button',
          }),
          default: assignedTranslateValues({
            en: 'Add to cart',
            pt: 'Adicionar',
            es: 'Agregar',
          }),
        },
        backgroundColor: {
          type: 'string',
          title: intl.formatMessage({
            id: 'customize/general.background',
          }),
          default: '#e3e4e6',
        },
        textColor: {
          type: 'string',
          title: intl.formatMessage({
            id: 'customize/general.text',
          }),
          default: '#001738',
        },
      },
    },
    ui: {
      textInsideButton: {
        'ui:widget': 'translatableText',
      },
      backgroundColor: {
        'ui:widget': 'color',
      },
      textColor: {
        'ui:widget': 'color',
      },
    },
  },
});
