import { CustomizeServices } from './services';
import { CustomizationGraphQLRepository } from './infrastructure';

export * from './domain';
export * from './infrastructure';
export * from './services';

export const customizationServices = new CustomizeServices(
  new CustomizationGraphQLRepository(),
);
