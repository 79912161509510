import { PlayerMobileHoverControls } from 'lib/types';
import { getElementById } from '../utils';
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getDeviceType } from '../utils';
import { useDetectKeyboardOpen } from '../hooks';

type ControlsContext = {
  mobileHoverCtrlsProps?: PlayerMobileHoverControls | null;
  setMobileHoverCtrlsProps?: React.Dispatch<
    React.SetStateAction<PlayerMobileHoverControls | null>
  > | null;
  isChatFocus?: boolean | null;
  setIsChatFocus?: React.Dispatch<React.SetStateAction<boolean | null>> | null;
  isKeyboardOpen?: boolean | null;
  questionTime?: number;
  setQuestionTime?: React.Dispatch<React.SetStateAction<number>>;
  hideQuestion: boolean;
  setHideQuestion: React.Dispatch<React.SetStateAction<boolean>>;
};

type ControlsContextProviderProps = {
  children: ReactNode;
};

//Default value
export const ctrlsContextDefault: ControlsContext = {
  mobileHoverCtrlsProps: null,
  setMobileHoverCtrlsProps: () => null,
  isChatFocus: null,
  setIsChatFocus: () => null,
  isKeyboardOpen: null,
  questionTime: 0,
  setQuestionTime: () => null,
  hideQuestion: true,
  setHideQuestion: () => null,
};

//Create context
export const ControlsContext =
  createContext<ControlsContext>(ctrlsContextDefault);

//Consume context
export const useControlsContext = () => useContext(ControlsContext);

//Define context provider
export const ControlsContextProvider: FC<ControlsContextProviderProps> = ({
  children,
}) => {
  const [mobileHoverCtrlsProps, setMobileHoverCtrlsProps] =
    useState<PlayerMobileHoverControls | null>(null);
  const [isChatFocus, setIsChatFocus] = useState<boolean | null>(false);
  const [questionTime, setQuestionTime] = useState<number>(0);
  const [hideQuestion, setHideQuestion] = useState<boolean>(true);
  const IS_MOBILE = getDeviceType() === 'mobile';
  const isKeyboardOpen = useDetectKeyboardOpen();

  useEffect(() => {
    if (isKeyboardOpen === false && IS_MOBILE) {
      const inputChat = getElementById('liveshopping_input_chat');
      inputChat && inputChat.blur();
      setIsChatFocus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKeyboardOpen]);

  const contex: ControlsContext = {
    mobileHoverCtrlsProps,
    setMobileHoverCtrlsProps,
    isChatFocus,
    setIsChatFocus,
    isKeyboardOpen,
    questionTime,
    setQuestionTime,
    hideQuestion,
    setHideQuestion,
  };

  return (
    <ControlsContext.Provider value={contex}>
      {children}
    </ControlsContext.Provider>
  );
};
