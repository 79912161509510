import { ApiGraphqlTypes as AGT } from '@nizza/core';
import { GraphQLClient } from 'graphql-request';
import env from '~env';
import {
  MinimalNizzaEventRepository,
  MinimalNizzaEvent,
  MinimalSearchInput,
} from '../domain';

const API_URL = env.apiUrls.graphql;

export class MinimalEventGraphQLRepository
  implements MinimalNizzaEventRepository
{
  private client: GraphQLClient;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async get(id: string, account: string): Promise<MinimalNizzaEvent | null> {
    const { findEvent } = await this.client.request(
      AGT.FindMinimalEventDocument,
      { data: { id, account } },
    );

    this.propagateGraphqlErrorIfExists(findEvent as AGT.FindEventResult);

    // TODO: corregir error de tipado, eliminar any
    return MinimalNizzaEvent.toDomain(
      (findEvent as AGT.EventData).data! as any,
    );
  }

  async search(input: MinimalSearchInput): Promise<MinimalNizzaEvent[]> {
    const { searchEvent } = await this.client.request(
      AGT.SearchMinimalEventsDocument,
      { data: input as AGT.SearchEventInput },
    );

    this.propagateGraphqlErrorIfExists(searchEvent as AGT.SearchEventResult);

    // TODO: corregir error de tipado, eliminar any
    return (searchEvent as AGT.EventArray).data?.map(
      MinimalNizzaEvent.toDomain as any,
    )!;
  }

  private propagateGraphqlErrorIfExists(
    res: AGT.SearchEventResult | AGT.FindEventResult,
  ) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
