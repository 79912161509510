interface Props {
  size?: number;
  background?: string;
  color?: string;
}

export const ShareIcon = ({
  size = 24,
  background = 'rgba(50,56,69,0.32)',
  color = '#f8f7fc',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 36 36"
    >
      <g fill={background} stroke={color} strokeWidth="1">
        <circle cx="18" cy="18" r="18" stroke="none" />
        <circle cx="18" cy="18" r="17.5" fill="none" />
      </g>
      <path
        d="M985.961,570.3v-4.8l7.823,8.4-7.823,8.4v-4.92c-5.588,0-9.5,1.92-12.294,6.12C974.784,577.5,978.137,571.5,985.961,570.3Z"
        transform="translate(-965.725 -557.5)"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
