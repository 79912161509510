/* eslint-disable react-hooks/exhaustive-deps */
import { shortId } from '@nizza/core';
import { useQuestion } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import { AnswerDTO, QuestionTypes } from 'types';
import { AnswerForm } from './Form';

interface Props {
  type: QuestionTypes;
  onChange: (answers: AnswerDTO[]) => void;
}

type Item = AnswerDTO & {
  id: string;
  canDeleted: boolean;
};

export const AnswerList: FC<Props> = props => {
  const { type, onChange } = props;
  const { selectedQuestion } = useQuestion();
  const { t } = useTranslation();
  const isQuiz = type === QuestionTypes.QUIZ;
  const [items, setItems] = useState<Item[]>([]);
  const newAnswer = (canDeleted = true): Item => ({
    id: shortId(5),
    text: '',
    canDeleted,
  });

  useEffect(() => {
    if (selectedQuestion?.answers.length) {
      setItems(
        selectedQuestion?.answers.map((x, i) => ({
          ...newAnswer(isQuiz && i > 1),
          ...x,
        })),
      );
    } else {
      const answers = isQuiz
        ? Array.from({ length: 2 }, () => newAnswer(false))
        : Array.from({ length: 4 }, () => newAnswer(false));

      setItems(answers);
    }
  }, [type]);

  const addAnswer = () => {
    setItems([...items, newAnswer()]);
  };

  const getAnswers = (items: Item[]) => items.map(x => ({ text: x.text }));

  const removeAnswer = (id: string) => {
    const newItems = [...items.filter(x => x.id !== id)];
    setItems(newItems);
    onChange(getAnswers(newItems));
  };

  const handleSave = (id: string, text: string) => {
    const arr = [...items];
    const index = arr.findIndex(x => x.id === id);
    arr[index] = {
      ...arr[index],
      text,
    };
    setItems(arr);
    onChange(getAnswers(arr));
  };

  return (
    <Row>
      <Col>
        {items.map(x => (
          <div
            key={x.id}
            className="d-flex align-items-start justify-content-between mb-3"
            style={{ gap: '1rem' }}
          >
            <AnswerForm id={x.id} text={x.text} onSave={handleSave} />

            {x.canDeleted && (
              <Button color="primary" onClick={() => removeAnswer(x.id)}>
                <i className="mdi mdi-trash-can"></i>
              </Button>
            )}
          </div>
        ))}

        {isQuiz && (
          <div className="d-flex justify-content-end">
            <Button
              color="primary"
              className="mt-2"
              onClick={() => addAnswer()}
            >
              {t(`LiveShopping.Questions.Form.AnswerAddButton`)}
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};
