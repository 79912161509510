type BuildPostMessageConfig = {
  targetOrigin?: string;
  customTarget?: string;
};

export function buildPostMessage<T = Record<string, string>>(
  windowRef: Window,
  config: BuildPostMessageConfig = {},
) {
  const { targetOrigin = '*', customTarget = '*' } = config;

  return (name: T, payload: any = null) => {
    const event = {
      source: 'Nizza',
      target: customTarget,
      name,
      payload,
    };

    windowRef.postMessage(event, targetOrigin);
  };
}

export enum CollectionSelectEvents {
  INPUT_CHANGED = 'EventCollectionInputChanged',
  SET_PRODUCT_COLLECTIONS = 'SetProductCollections',
  GET_PRODUCT_COLLECTIONS = 'GetProductCollections',
  COLLECTION_CHANGED = 'EventCollectionChanged',
}

export enum ProductListProviderEvents {
  GET_PRODUCTS = 'GetProductList',
  SET_PRODUCTS = 'SetProductList',
}

export enum SubscriptionActionsEvents {
  CREATE_SUBSCRIPTION = 'CreateSubscription',
  SEND_SUBSCRIPTION = 'SendSubscription',
  CANCEL_SUBSCRIPTION = 'CancelSubscription',
  UPDATE_SUBSCRIPTION = 'UpdateSubscription',
}

export enum BillingEvents {
  GET_PLANS = 'GetBillingPlans',
  SET_PLANS = 'SetBillingPlans',
}
