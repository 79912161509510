import { NizzaEventStatus } from '@nizza/core';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { NizzaEvent } from './core';

// Global live streaming event; It is used to have the event
// information for the detail page in administrator
export const nizzaEventAtom = atomWithStorage<NizzaEvent | null>(
  'globalNizzaEvent',
  null,
);
nizzaEventAtom.debugLabel = 'nizzaEventAtom';

// Status of the event
export const eventStatusAtom = atom<NizzaEventStatus>(NizzaEventStatus.CREATED);
eventStatusAtom.debugLabel = 'eventStatusAtom';
