import { NavigateToEvents } from 'app/components';
import {
  AsyncAccountInfo,
  AsyncAddProduct,
  AsyncBlockedWordListWrapper,
  AsyncCreateAccount,
  AsyncCreateUser,
  AsyncDashboard,
  AsyncEventAnalytics,
  AsyncEventChat,
  AsyncEventDetail,
  AsyncEventList,
  AsyncExternalPreview,
  AsyncGlobalPage,
  AsyncGlobalPageAdmin,
  AsyncListUsers,
  AsyncLogin,
  AsyncNewEvent,
  AsyncProductDetail,
  AsyncProductList,
  AsyncSelectAccount,
  AsyncSetPlan,
  AsyncTwoStepVerification,
  AsyncUnderDevelopment,
  AsyncEventCustomize,
  CollectionDetail,
  CollectionList,
  ConditionalBilling,
  AsyncLiveEdit,
} from 'app/pages';
import { AppModule, AppRoute } from 'types';

const authProtectedRoutes: AppRoute[] = [
  { path: '/dashboard', element: <AsyncDashboard /> },
  { path: '/underdevelopment', element: <AsyncUnderDevelopment /> },
  {
    path: '/events',
    element: <AsyncEventList />,
    module: AppModule.LIVE_SHOPPING,
    menu: {
      icon: 'bx bx-calendar-event',
      title: 'LiveShopping.Events.Title',
    },
  },
  {
    path: '/events/new',
    element: <AsyncNewEvent />,
    module: AppModule.LIVE_SHOPPING,
  },
  {
    path: '/events/:eventId/edit',
    element: <AsyncNewEvent />,
    module: AppModule.LIVE_SHOPPING,
  },
  {
    path: '/events/:eventId/details',
    element: <AsyncEventDetail />,
    module: AppModule.LIVE_SHOPPING,
  },
  {
    path: '/events/:eventId/analytics',
    element: <AsyncEventAnalytics />,
    module: AppModule.LIVE_SHOPPING,
  },
  {
    path: '/blocked-word-list',
    element: <AsyncBlockedWordListWrapper />,
    module: AppModule.LIVE_SHOPPING,
    menu: {
      icon: 'bx bx-list-ul',
      title: 'LiveShopping.BlockedWordList.Title',
    },
  },
  {
    path: '/products',
    element: <AsyncProductList />,
    module: AppModule.PRODUCTS,
    menu: {
      title: 'Products.List.Title',
    },
  },
  {
    path: '/products/add',
    element: <AsyncAddProduct />,
    module: AppModule.PRODUCTS,
    menu: {
      title: 'Products.Create.Title',
    },
  },
  {
    path: '/products/:productId',
    element: <AsyncProductDetail />,
    module: AppModule.PRODUCTS,
  },
  {
    path: '/product-collections',
    element: <CollectionList />,
    module: AppModule.PRODUCTS,
    menu: {
      title: 'ProductCollections.List.Title',
    },
  },
  {
    path: '/product-collections/:collectionId',
    element: <CollectionDetail />,
    module: AppModule.PRODUCTS,
  },
  {
    path: '/global-page-admin',
    element: <AsyncGlobalPageAdmin />,
    module: AppModule.LIVE_SHOPPING,
    enableUsingFeaturePlan: 'globalPage',
    menu: {
      icon: 'bx bx-world',
      title: 'LiveShopping.GlobalPage.Title',
    },
  },
  {
    path: '/users',
    element: <AsyncListUsers />,
    module: AppModule.USERS,
    menu: {
      icon: 'bx bx-user',
      title: 'Users.Title',
    },
  },
  {
    path: '/users/new',
    element: <AsyncCreateUser />,
    module: AppModule.USERS,
  },
  {
    path: '/account-info',
    element: <AsyncAccountInfo />,
    module: AppModule.ACCOUNT_MANAGEMENT,
    menu: {
      icon: 'bx bx-cog',
      title: 'Menu.Spended',
    },
  },
  {
    path: '/stripe-billing',
    element: <ConditionalBilling type="stripe" />,
    module: AppModule.BILLING,
    menu: {
      icon: 'bx bx-spreadsheet',
      title: 'Menu.Plans',
    },
  },
  {
    path: '/external-billing',
    element: <ConditionalBilling type="external" />,
    module: AppModule.EXTERNAL_BILLING,
    menu: {
      icon: 'bx bx-spreadsheet',
      title: 'Menu.Plans',
    },
  },
  {
    path: '/global-page-admin/edit-live/:eventId',
    element: <AsyncLiveEdit />,
    module: AppModule.LIVE_SHOPPING,
  },
  { path: '/set-plan', element: <AsyncSetPlan /> },
  { path: '/', index: true, element: <NavigateToEvents /> },
];

const publicRoutes: AppRoute[] = [
  { path: '/login', element: <AsyncLogin /> },
  {
    path: '/auth-two-step-verification',
    element: <AsyncTwoStepVerification />,
  },
  {
    path: '/events/customize',
    element: <AsyncEventCustomize />,
  },
  {
    path: '/events/preview',
    element: <AsyncExternalPreview />,
    module: AppModule.LIVE_SHOPPING,
  },
  {
    path: '/events/:eventId/chat',
    element: <AsyncEventChat />,
    module: AppModule.LIVE_SHOPPING,
  },
  {
    path: '/global-page/*',
    index: false,
    element: <AsyncGlobalPage />,
    module: AppModule.LIVE_SHOPPING,
  },
  {
    path: '/global-page-admin/:account',
    element: <AsyncGlobalPageAdmin />,
    module: AppModule.LIVE_SHOPPING,
  },
  {
    path: '/global-page-admin/:account/edit-live/:eventId',
    element: <AsyncLiveEdit />,
    module: AppModule.LIVE_SHOPPING,
  },
  { path: '/create-account', element: <AsyncCreateAccount /> },
  {
    path: '/select-account',
    element: <AsyncSelectAccount />,
  },
];

export * from './AppRouter';
export * from './AuthMiddleware';
export { authProtectedRoutes, publicRoutes };
