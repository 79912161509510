import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  MenuContextProps,
  Sidebar,
  SidebarProps,
  menuClasses,
} from 'react-pro-sidebar';
import { useLocation } from 'react-router-dom';
import { AppModule } from 'types';
import { sidebarIsCollapsedAtom } from '../state';
import { MenuItem } from './MenuItem';
import { useSidebar } from './useSidebar';

type SidebarStyles = SidebarProps['rootStyles'];
type MenuItemStyles = MenuContextProps['menuItemStyles'];
type Item = {
  module: AppModule | AppModule[];
  title: string;
  icon: string;
};

export const SidebarContent: FC = () => {
  const { t } = useTranslation();
  const isCollapsed = useAtomValue(sidebarIsCollapsedAtom);
  const { pathname } = useLocation();
  const activeColor = '#556ee6';
  const { getMenuItemsByModule, showModule, accountManagementModules } =
    useSidebar();

  const items: Item[] = [
    {
      module: AppModule.LIVE_SHOPPING,
      title: 'LiveShopping',
      icon: 'bx-color-fill',
    },
    {
      module: AppModule.PRODUCTS,
      title: 'Products',
      icon: 'bxl-product-hunt',
    },
    {
      module: accountManagementModules,
      title: 'AccountManagement',
      icon: 'bx-user-circle',
    },
  ];

  const sidebarStyles: SidebarStyles = {
    [`.${menuClasses.subMenuRoot}.active > .${menuClasses.button}`]: {
      color: activeColor,
    },
  };

  const menuItemStyles: MenuItemStyles = {
    subMenuContent: ({ level }) => {
      if (level === 0)
        return {
          inset: '80px auto auto 0px !important',
        };
    },
    button: {
      [`&.active`]: {
        color: activeColor,
      },
    },
  };

  const item = ({ module, title, icon }: Item, id: number) => {
    const modules = Array.isArray(module) ? module : [module];
    if (!showModule(...modules)) return null;
    const items = getMenuItemsByModule(...modules);
    const defaultOpen = items.some(x => x.link === pathname);

    return (
      <MenuItem
        key={id}
        id={id}
        items={items}
        icon={`bx ${icon}`}
        defaultOpen={defaultOpen}
        title={t(`Menu.${title}`)}
      />
    );
  };

  return (
    <Sidebar
      collapsedWidth="70px"
      transitionDuration={150}
      collapsed={isCollapsed}
      rootStyles={sidebarStyles}
    >
      <Menu menuItemStyles={menuItemStyles}>{items.map(item)}</Menu>
    </Sidebar>
  );
};

export default SidebarContent;
