import type { ChatMessage } from '../../types';
import { shortId } from '../../utils';
import { getRandomColor } from './get-random-color.util';
import { addQuestionData } from './add-question-data.util';

export const normalizeChatMessage = (
  chat: ChatMessage[],
  data: any,
): ChatMessage => {
  const {
    Content,
    Attributes: attributes,
    Sender: { Attributes: senderAttributes },
  } = data;
  const { username, type, email } = attributes;
  const { isAdmin } = senderAttributes;
  const id = attributes?.id ?? shortId(8);
  const color =
    chat.find(i => i.username === username)?.color || getRandomColor();

  const messageNormalized = {
    id,
    type,
    color,
    username,
    email,
    data: Content,
    sendDate: attributes.sendDate,
    isAdmin: isAdmin === 'true',
    responseAdmin: false,
    question: null,
    account: attributes.account,
    idEvent: attributes.idEvent,
    urlGif: attributes.urlGif,
    ip: attributes.ip,
  } as unknown as ChatMessage;

  return addQuestionData(messageNormalized);
};
