import { ChatMessage } from '@nizza/core';
import { FC } from 'react';
import { ChatMessageContent, ChatUsername } from '../../atoms';
import styles from './styles.module.css';

export interface Props {
  message: ChatMessage;
}

export const ChatMessageResponse: FC<Props> = ({ message }) => {
  if (!message.isResponse || !message.question) return null;

  return (
    <div className={styles.messageResponseContent}>
      <ChatUsername username={message.question?.username} />
      <ChatMessageContent
        data={message?.question?.data}
        type={message?.question?.type}
      />
    </div>
  );
};
