import env from '~env';
import { ApiV1Response } from '../../../types';
import { customFetch } from '../../../utils';
import { AccountRepository, NizzaAccount, RawAccount } from '../domain';

const BASE_URL = `${env.apiUrls.restV1}/accounts/v2`;

export class AccountRestRepository implements AccountRepository {
  async get(name: string) {
    const url = `${BASE_URL}/${name}`;

    const { data } = await customFetch<ApiV1Response<RawAccount>>({
      url,
    });

    return data ? NizzaAccount.toDomain(data) : null;
  }
}
