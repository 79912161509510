import {
  MinimalEventGraphQLRepository,
  EventRestRepository,
} from './infrastructure';
import { MinimalNizzaEventService, NizzaEventService } from './services';

const minimalEventRepository = new MinimalEventGraphQLRepository();
const minimalEventService = new MinimalNizzaEventService(
  minimalEventRepository,
);

const eventRepository = new EventRestRepository();
const eventService = new NizzaEventService(eventRepository);

export * from './domain';
export * from './infrastructure';
export * from './services';

export { minimalEventService, eventService };
