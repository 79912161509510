import clsx from 'clsx';
import { FC, memo } from 'react';
import { Button } from 'reactstrap';

interface Props {
  words: string[];
  repeatedWords: string[];
  onClick: (index: number, value: string) => void;
}

export const AddedWords: FC<Props> = memo(props => {
  const { words, repeatedWords, onClick } = props;

  return (
    <div className="d-flex align-items-center flex-wrap">
      {words.map((x, i) => {
        const isRepeated = Boolean(repeatedWords.find(y => y === x));

        return (
          <Button
            key={i}
            color={clsx(isRepeated ? 'danger' : 'secondary', 'btn-rounded')}
            size="sm"
            className="mx-1 mt-2 text-light"
            onClick={() => onClick(i, x)}
          >
            {x}
            <i className="bx bx-x font-size-16" />
          </Button>
        );
      })}
    </div>
  );
});
