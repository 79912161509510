import {
  IAggregateRoot,
  createAggregateRoot,
} from '../../../clean-architecture';
import {
  AccountContractStatusTypes,
  AccountContractTypes,
  AccountPlanDescriptionTypes,
  AccountTypes,
} from './shared';
import {
  AccountCurrencyConfig,
  AccountGlobalPage,
  AccountProps,
  AccountStripe,
  ExceededFeatures,
  RawAccount,
  VariationsConfig,
} from './types';

export class NizzaAccount implements IAggregateRoot, AccountProps {
  readonly root = createAggregateRoot<AccountProps, NizzaAccount>(this);

  id!: string;
  account!: string;
  accountType = AccountTypes.UNKNOWN;
  username!: string;
  currencyConfig!: AccountCurrencyConfig;
  contractType!: AccountContractTypes;
  nextDateMetricRecord!: string;
  spendedMinutes = 0;
  unlimitedService = false;
  hoursAvailable = 0;
  contractStatus = AccountContractStatusTypes.ACTIVE;
  createdAt!: Date;
  installationDateApp!: string;
  cxAccount!: string;
  freeService = false;
  subscriptionType = null;
  minutesAvailable = 0;
  globalPage!: AccountGlobalPage;
  stripe!: AccountStripe;
  descriptionPlan!: AccountPlanDescriptionTypes;
  planType!: string;
  selectFreeTrial!: boolean;
  exceededFeatures?: ExceededFeatures;
  host = null;
  variationThumbnailName?: string;
  variationsConfig?: VariationsConfig;

  constructor(props: AccountProps) {
    this.root.setProps(props);
  }

  static toDomain(raw: RawAccount): NizzaAccount {
    const { createdAt, username, ...rest } = raw;

    const data: AccountProps = {
      ...rest,
      username: username ?? rest.account,
      createdAt: new Date(Number(createdAt)),
    };

    return new NizzaAccount(data);
  }

  toPrimitives() {
    const { root, createdAt, ...rest } = this;
    return {
      ...rest,
      created: createdAt.toISOString(),
    };
  }
}
