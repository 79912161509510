import { csx, IconUser } from '@vtex/admin-ui';
import { ChatStoreIcon } from 'assets/icons';
import styles from './styles.module.css';
import { FC } from 'react';

interface Props {
  isAdmin?: boolean;
}

export const ChatMessageUserIcon: FC<Props> = ({ isAdmin = false }) => {
  return (
    <div className={`${styles.messageIcon}`}>
      {isAdmin ? (
        <ChatStoreIcon />
      ) : (
        <div className={`${styles.userIcon}`}>
          <IconUser className={csx({ fg: '$pink40' })} />
        </div>
      )}
    </div>
  );
};

export default ChatMessageUserIcon;
