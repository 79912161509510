export const GPEventListQueryId = {
  PAST: ['pastEventList'],
  LIVE: ['liveEventList'],
  FUTURE: ['futureEventList'],
} as const;

export type GPEventListQueryValue =
  (typeof GPEventListQueryId)[keyof typeof GPEventListQueryId];

export const GPEventKeyListQueryId = {
  PAST: ['pastEventKeyList'],
  LIVE: ['liveEventKeyList'],
  FUTURE: ['futureEventKeyList'],
} as const;

export type GPEventKeyListQueryValue =
  (typeof GPEventKeyListQueryId)[keyof typeof GPEventKeyListQueryId];

export enum GPEventType {
  PAST = 'past',
  LIVE = 'live',
  FUTURE = 'future',
}

export enum AlertType {
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum AlertPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum EditingStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ENDED = 'ENDED',
  FAILED = 'FAILED',
}

export type EventLoadResult = 'loading' | 'success' | 'error';

export interface GlobalPageEvent {
  id: string;
  account: string;
  eventName: string;
  thumbnail: string;
  link: string;
  share: Share;
  date: string;
  time: string;
  eventCategory: string;
  showInGlobalPage: number;
  editingStatus?: EditingStatus;
}

export interface Share {
  whatsapp: string;
  twitter: string;
  facebook: string;
}

export type GPEventKeysResponse = {
  items: GPEventKey[];
  lastEvaluatedKey: string | null;
};

export interface GPEventKeysParams {
  admin?: number;
  account?: string;
  category?: string;
  enable?: number;
  limit?: number;
  lastEvaluatedKey?: string | null;
}

export interface GPEventPagination {
  limit?: number;
  lastEvaluatedKey?: string | null;
}

export interface DataEventBody {
  showInGlobalPage: boolean;
  eventCategory: string;
  account: string;
  eventId: string;
  eventType?: GPEventType;
}

export type GPEventKey = {
  id: string;
  account: string;
};

export type LiveVideo = {
  account: string;
  id: string;
  eventName: string;
  eventCategory: string;
  recordPath: string;
  thumbnails: {
    Name: string;
    Prefix: string;
    MaxKeys?: number;
    KeyCount?: number;
    Images: string[];
  };
  thumbnail: string;
};

export type VideoEdit = {
  finalTime: number;
  initialTime: number;
};

export type VideoEditView = {
  finalTime: string;
  initialTime: string;
};
