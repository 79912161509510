import { ChatMessage } from '../types/nizza/chat';
import { atom } from 'jotai';

// Socket connection to IVS chat
export const ivsChatSocketAtom = atom<WebSocket | undefined>(undefined);
ivsChatSocketAtom.debugLabel = 'ivsChatSocketAtom';

// Flag to indicate if IVS chat is connected
export const ivsChatIsConnectedAtom = atom(false);
ivsChatIsConnectedAtom.debugLabel = 'ivsChatIsConnectedAtom';

// List of messages received at the IVS chat room
export const ivsChatMessageListAtom = atom<ChatMessage[]>([]);
ivsChatMessageListAtom.debugLabel = 'ivsChatMessageListAtom';
