import { useAccount } from 'hooks/account';
import { usePlan } from 'hooks/plans';

export const useIsAccountActive = (): boolean => {
  const { accountInfo } = useAccount();
  const { planInfo } = usePlan();
  const { spendedMinutes, contractStatus, planType } = accountInfo;
  const { plan, feature } = planInfo;
  return (
    plan !== 'PLAN_FREE_TRIAL' &&
    spendedMinutes <= feature?.minutesAvailable?.limit! &&
    planType !== 'NO_PLAN' &&
    contractStatus === 'active_contract'
  );
};
