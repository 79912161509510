import { AddToCartConfig, ReactHostElement } from '@nizza/core';
import { unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import logger from '~logger';
import ReactNizzaPlayer from './Livestreaming';
import { playerStyleModules } from './modules.style';
import { LivestreamingProps } from './types';
import { WC_NAME } from './utils';

logger.info(`🍿 Nizza Player - Loaded successfully!`);

const inlineStyles = Object.values(playerStyleModules).map((x: any) => x);
const mainStyles = unsafeCSS(inlineStyles.join('\n'));

@customElement(WC_NAME)
export class NzPlayer extends ReactHostElement<LivestreamingProps> {
  static styles = [ReactHostElement.baseStyles, mainStyles];

  @property({ attribute: false })
  declare config: Partial<LivestreamingProps>;

  constructor () {
    super();
    this.config = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.handleHMR(playerStyleModules);
  }

  setAddToCartConfig(addToCartConfig: AddToCartConfig) {
    this.config = {
      ...this.config,
      addToCartConfig,
    };
    this.requestUpdate();
  }

  protected getReactComponent() {
    return ReactNizzaPlayer;
  }

  protected getReactProps() {
    return this.config as LivestreamingProps;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    // @ts-expect-error for dynamic name
    [WC_NAME]: NzPlayer;
  }
}
