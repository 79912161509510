/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppConfig } from '../../../hooks';
import { useSetAtom } from 'jotai';
import { sidebarIsCollapsedAtom } from './state';
import { ProfileMenu } from '../ProfileMenu';
import logoLightSvg from 'assets/images/logo-light.svg';
import logo from 'assets/images/logo.svg';

export const Header = () => {
  const { t } = useTranslation();
  const { layout, auth } = useAppConfig();
  const setIsCollapsed = useSetAtom(sidebarIsCollapsedAtom);

  // Determine whether to show the profile menu based on auth
  const showProfileMenu = auth?.enable == null || auth?.enable === true;

  const toggleSidebar = () => {
    const body = document.body;
    const sidebarEnabled = 'sidebar-enable'

    if (window.screen.width <= 998) {
      body.classList.toggle(sidebarEnabled);
    } else {
      setIsCollapsed(prev => !prev);
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle(sidebarEnabled);
    }
  };

  const collapseSidebar = () => {
    setIsCollapsed(prev => !prev);
    const body = document.body;
    body.classList.add('sidebar-enable');
    body.classList.add('vertical-collpsed');
  };

  useLayoutEffect(() => {
    if (layout.sideBarIsOpen) {
      collapseSidebar();
    }
  }, []);

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-lg-none d-md-block">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLightSvg} alt="" height="22" />
              </span>
            </Link>
          </div>
          <button
            type="button"
            onClick={toggleSidebar}
            className="btn btn-sm px-3 font-size-16 header-item "
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
          <form className="app-search d-none d-lg-block">
            <div className="position-relative d-none">
              <input
                type="text"
                className="form-control"
                placeholder={t('Search') + '...'}
              />
              <span className="bx bx-search-alt" />
            </div>
          </form>
        </div>
        {showProfileMenu && (
          <div className="d-flex">
            <ProfileMenu />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
