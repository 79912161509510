import TextDivider from 'app/components/TextDivider';
import { UseEventListQueryResult } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPageEvent } from 'types';
import {
  EventListContainer,
  EventListShowMoreContainer,
} from './StyledEventList';

interface Props {
  eventList: GlobalPageEvent[];
  title: string;
  eventCards: JSX.Element;
  eventListQuery: UseEventListQueryResult;
}

const EventList: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    eventList,
    title,
    eventCards,
    eventListQuery: { hasNextPage, fetchNextPage, isFetchingNextPage },
  } = props;

  return eventList?.length ? (
    <EventListContainer className="mt40px">
      <h4 className={'text-title'}>
        {t(title)}
        <TextDivider />
      </h4>
      {eventCards}
      <EventListShowMoreContainer>
        {hasNextPage && (
          <button
            className="btn btn-sm btn-primary-live"
            disabled={!hasNextPage || isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            {isFetchingNextPage
              ? t('GlobalPage.Carousel.ShowMoreButton.Loading')
              : t('GlobalPage.Carousel.ShowMoreButton.Idle')}
          </button>
        )}
      </EventListShowMoreContainer>
    </EventListContainer>
  ) : null;
};

export default EventList;
