const { NZ_BACKOFFICE_BACK_URL } = import.meta.env;

const dev = {
  GET_LIVESTREAMING_CONFIG_URL:
    'https://xdr18ajfu3.execute-api.us-east-1.amazonaws.com/Prod/livestreamingconfig',
  GET_CHAT_BY_ID_URL:
    'https://pabprg32bj.execute-api.us-east-1.amazonaws.com/global-page/chat',
  GET_QUESTION_URL:
    'https://xdr18ajfu3.execute-api.us-east-1.amazonaws.com/Prod/livestreamingquestion',
  USERNAME_EXIST_URL:
    'https://xdr18ajfu3.execute-api.us-east-1.amazonaws.com/Prod/usernameexist',
  GET_HIGHTLIGHT_BY_ID_URL:
    'https://pabprg32bj.execute-api.us-east-1.amazonaws.com/global-page/highlight-products',
  API_PLATFORM:
    NZ_BACKOFFICE_BACK_URL ??
    'https://pabprg32bj.execute-api.us-east-1.amazonaws.com',
  CORS_PROXY_URL: 'https://1qmd8jteh1.execute-api.us-east-1.amazonaws.com',
  GET_INFO_ACCOUNT_URL:
    'https://pabprg32bj.execute-api.us-east-1.amazonaws.com/accounts/v2',
};

const prod = {
  GET_LIVESTREAMING_CONFIG_URL:
    'https://9n976nhd6g.execute-api.us-east-1.amazonaws.com/Prod/livestreamingconfig',
  GET_CHAT_BY_ID_URL:
    'https://b133h34s56.execute-api.us-east-1.amazonaws.com/global-page/chat',
  GET_QUESTION_URL:
    'https://9n976nhd6g.execute-api.us-east-1.amazonaws.com/Prod/livestreamingquestion',
  USERNAME_EXIST_URL:
    'https://9n976nhd6g.execute-api.us-east-1.amazonaws.com/Prod/usernameexist',
  GET_HIGHTLIGHT_BY_ID_URL:
    'https://b133h34s56.execute-api.us-east-1.amazonaws.com/global-page/highlight-products',
  API_PLATFORM:
    NZ_BACKOFFICE_BACK_URL ??
    'https://b133h34s56.execute-api.us-east-1.amazonaws.com',
  CORS_PROXY_URL: 'https://3hvqfl2xcg.execute-api.us-east-1.amazonaws.com',
  GET_INFO_ACCOUNT_URL:
    'https://b133h34s56.execute-api.us-east-1.amazonaws.com/accounts/v2',
};

export const config = (environment: string) =>
  environment === 'prod' ? prod : dev;
