/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-unused-vars */
import { AccountTypes, apiCall, customFetch } from '@nizza/core';
import { useEffect, useState } from 'react';
import { config } from '../enviroment/config';
import type { Message, Settings } from '../types';

import { LOCAL_STORAGE_WEB_CLIENT } from '../utils/constants';
import ProductLocalStorage from '../utils/localStorageProductHighlight';

declare interface Props {
  id: string;
  account: string;
  environment: string;
}

const useLivestreamingConfig = ({ id, account, environment }: Props) => {
  const [wssStream, setWssStream] = useState<string>();
  const [streamUrl, setStreamUrl] = useState<string>();
  const [collectionId, setCollectionId] = useState<string>();
  const [salesChannelId, setSalesChannelId] = useState<string>();
  const [productsInBD, setProductsInBD] = useState<any[]>([]);
  const [utm, setUtm] = useState<string>();
  const [emailIsRequired, setEmailIsRequired] = useState<boolean>();
  const [pinnedMessage, setPinnedMessage] = useState<Message>();
  const [transmitionType, setTransmitionType] = useState<string>();
  const [isModalLive, setIsModalLive] = useState<boolean>();
  const [status, setStatus] = useState('');
  const [showGifButton, setShowGifButton] = useState<boolean>();
  const [showCarouselChatButton, setShowCarouselChatButton] =
    useState<boolean>();
  const [host, setHost] = useState<string>('');
  const [accountType, setAccountType] = useState<AccountTypes>(
    AccountTypes.UNKNOWN,
  );
  const [playBackStartTime, setPlayBackStartTime] = useState<number>(0);
  const [settings, setSettings] = useState<Settings>();
  const [isLoading, setIsLoading] = useState(true);
  const [variationThumbnailName, setVariationThumbnailName] =
    useState<string>();

  const [showTax, setShowTax] = useState<boolean>();

  useEffect(() => {
    let URL_LIVESTREAMING_CONFIG = '__GET_LIVESTREAMING_CONFIG_URL';
    const { GET_LIVESTREAMING_CONFIG_URL } = config(environment || '');

    if (
      GET_LIVESTREAMING_CONFIG_URL &&
      GET_LIVESTREAMING_CONFIG_URL !== URL_LIVESTREAMING_CONFIG
    ) {
      URL_LIVESTREAMING_CONFIG = GET_LIVESTREAMING_CONFIG_URL;
    }

    if (!GET_LIVESTREAMING_CONFIG_URL) return;

    const getLivestreaming = async () => {
      await apiCall({
        url: `${URL_LIVESTREAMING_CONFIG}?id=${id}&account=${account}`,
      }).then(data => {
        if (data) {
          setWssStream(data?.webClient?.streamWSS);
          setStreamUrl(data?.webClient?.streamURL);
          setEmailIsRequired(data?.webClient?.emailIsRequired);
          setCollectionId(data?.collection?.id);
          setSalesChannelId(data?.collection?.salesChannel);
          setProductsInBD(data?.collection?.products);
          setUtm(data?.utm);
          setPinnedMessage(data?.pinnedMessage);
          setTransmitionType(data?.webClient?.transmitionType);
          setIsModalLive(data?.webClient.modalLive);
          setStatus(data?.status);
          setShowGifButton(data?.webClient?.showGif);
          setShowCarouselChatButton(data?.webClient?.showCarouselChat);
          setShowTax(data?.webClient?.showTax);
          setHost(data?.host);
          setAccountType(data?.accountType);
          setSettings({
            showChat: data?.webClient?.showChat,
            showLike: data?.webClient?.showLike,
            showProductsCarousel: data?.webClient?.showProductsCarousel,
            showSidebarProducts: data?.webClient?.showProductsSidebar,
            isInfinite: data?.webClient?.infiniteScroll,
            redirectTo: data?.webClient?.redirectToPdp,
            showViewers: data?.webClient?.showCounter,
            time: data?.webClient?.scrollTime,
          });
          if (data?.playBackStartTime && !isNaN(data?.playBackStartTime)) {
            setPlayBackStartTime(data?.playBackStartTime);
          } else {
            setPlayBackStartTime(0);
          }
          if (data?.webClient?.highlightProduct) {
            ProductLocalStorage.save({
              products: data?.webClient?.highlightProduct?.products ?? [],
              collection: data?.collection?.id,
              livestreamingId: id,
            });
          }
          if (data?.webClient)
            localStorage.setItem(
              LOCAL_STORAGE_WEB_CLIENT,
              JSON.stringify(data?.webClient),
            );

          setIsLoading(false);
        }
      });
    };

    getLivestreaming().catch(null);

    const { GET_INFO_ACCOUNT_URL } = config(environment || '');

    const getAccountInfo = async () => {
      await customFetch<any>({
        url: `${GET_INFO_ACCOUNT_URL}/${account}`,
      }).then(data => {
        if (data) {
          setVariationThumbnailName(data?.data?.variationThumbnailName);
        }
      });
    };
    getAccountInfo().catch(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, account]);

  return {
    wssStream,
    streamUrl,
    collectionId,
    setCollectionId,
    productsInBD,
    setProductsInBD,
    utm,
    setUtm,
    emailIsRequired,
    pinnedMessage,
    transmitionType,
    isModalLive,
    setIsModalLive,
    status,
    showGifButton,
    showCarouselChatButton,
    host,
    playBackStartTime,
    settings,
    isLoading,
    showTax,
    variationThumbnailName,
    accountType,
    salesChannelId,
    setSalesChannelId,
  };
};

export default useLivestreamingConfig;
