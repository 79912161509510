import { ApiGraphqlTypes as AGT } from '@nizza/core';
import { GraphQLClient } from 'graphql-request';
import env from '~env';
import { AccountRepository } from '../domain/repositories/accountRepository';

const API_URL = env.apiUrls.graphql;

export class GraphqlAccountRepository implements AccountRepository {
  private client: GraphQLClient;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async changeAccountUsername(
    account: string,
    username: string,
  ): Promise<void> {
    const { changeAccountUsername } = await this.client.request(
      AGT.ChangeAccountUsernameDocument,
      {
        data: {
          account: account,
          username: username,
        },
      },
    );

    this.propagateGraphqlErrorIfExists(
      changeAccountUsername as AGT.ChangeUsernameResult,
    );
  }

  private propagateGraphqlErrorIfExists(res: AGT.ChangeUsernameResult) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
