import { useAppConfig } from 'hooks';
import { Navigate } from 'react-router-dom';
import { AppModule } from 'types';

export const NavigateToEvents = () => {
  const { modules } = useAppConfig();
  let path = '/events';

  if (
    modules?.only?.find(module => module === AppModule.EXTERNAL_BILLING) &&
    modules?.only.length === 2
  ) {
    path = '/external-billing';
  }

  return <Navigate to={path} replace />;
};
