import { useAppConfig, useEventDetail } from 'hooks';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { CopyBlock, github } from 'react-code-blocks';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'reactstrap';
import { SCRIPT_URL } from 'shared';
import { ScriptSettings } from 'types';
import { Preview } from './Preview';
import { WC_NAME } from '@nizza/player';

interface Props {
  render?: boolean;
  settings?: Partial<ScriptSettings>;
}

export const editableSettings: Partial<ScriptSettings> = {
  inactiveSidebarProducts: true,
  inactiveProductsCarousel: false,
  inactivateChat: true,
  inactivateLike: true,
  inactivateViewers: true,
  isInfinite: true,
  pdp: true,
  time: 10,
};

export const Script: FC<Props> = memo(props => {
  const { eventId, isLive, account, originOfProducts } = useEventDetail();
  const { components } = useAppConfig();
  const showPreviewBtn = components?.eventPreview?.enable ?? true;
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(true);
  const { render = false, settings } = props;
  const config = {
    id: eventId,
    account,
    originOfProducts,
    isInGlobalPage: false,
    quickView: false,
    ...editableSettings,
    ...settings,
  };

  delete config.cmsCompatibility;
  const searchParams = Object.entries(config).map(([k, v]) => `${k}=${v}`);
  const copyBlockUrl = `${SCRIPT_URL}?${searchParams.join(
    settings?.cmsCompatibility ? '&#38;' : '&',
  )}`;

  const query = useMemo(
    () => new URLSearchParams(searchParams.join('&')),
    [searchParams],
  );

  const liveParams = {
    idLivestreaming: query.get('id') || '',
    account: query.get('account') || '',
    isInGlobalPage: query.get('isInGlobalPage') === 'true',
    quickView: query.get('quickView') === 'true',
    inactiveSidebarProducts: query.get('inactiveSidebarProducts') === 'true',
    inactiveProductsCarousel: query.get('inactiveProductsCarousel') === 'true',
    inactivateChat: query.get('inactivateChat') === 'true',
    inactivateLike: query.get('inactivateLike') === 'true',
    inactivateViewers: query.get('inactivateViewers') === 'true',
    isInfinite: query.get('isInfinite') === 'true',
    originOfProducts: query.get('originOfProducts') || '',
    pdp: query.get('pdp') === 'true',
    time: Number(query.get('time')),
  };

  const text = `<div id="${WC_NAME}"></div>\n\n<script id="${WC_NAME}-script" type="module" src="${copyBlockUrl}"></script>`;

  useEffect(() => {
    if (isLive) {
      setShowPreview(false);
      setTimeout(() => {
        setShowPreview(true);
      });
    }
  }, [isLive]);

  const openExternalPreview = () => {
    const url = `/events/preview?${searchParams.join('&')}`;
    window.open(url, '_blank');
  };

  return render ? (
    showPreview ? (
      <Preview liveProps={liveParams} />
    ) : null
  ) : (
    <div className="d-flex flex-column">
      <Card body>
        <CopyBlock
          text={text}
          language="html"
          showLineNumbers={true}
          theme={github}
          wrapLines
        />
      </Card>
      {showPreviewBtn && (
        <Button
          color="primary"
          className="align-self-end mb-3"
          onClick={() => openExternalPreview()}
        >
          {t('LiveShopping.ClientScript.Preview.OpenButtonTitle')}
          <i className="bx bx-link-external" style={{ marginLeft: '0.5rem' }} />
        </Button>
      )}
    </div>
  );
});
