/* eslint-disable no-unused-vars */
import { AccountTypes } from '@nizza/core';
import { createContext, useContext } from 'react';
import {
  HightLightHistoryElement,
  IvsRealTime,
  Message,
  StreamPlayerType,
} from '../../types';

export interface LivestreamingCtx {
  idLivestreaming: string;
  account: string;
  videoEl: React.RefObject<StreamPlayerType>;
  chat: Message[];
  chatHistory: Message[];
  highlightHistory: HightLightHistoryElement[];
  currentHightLightProductId: string[];
  handleSetChat: (chat: Message[]) => void;
  host: string;
  handleSetHightLight: (productsId: string[]) => void;
  playBackStartTime: number;
  ivsRealTime?: IvsRealTime;
  accountType?: AccountTypes;
}

export const livestreamingCtxDefault: LivestreamingCtx = {
  idLivestreaming: '',
  account: '',
  videoEl: {
    current: null,
  },
  chat: [],
  chatHistory: [],
  host: '',
  accountType: AccountTypes.UNKNOWN,
  handleSetChat: () => null,
  highlightHistory: [],
  currentHightLightProductId: [],
  handleSetHightLight: () => null,
  playBackStartTime: 0,
  ivsRealTime: {
    startTime: 'UNKNOWN',
    viewerCount: 0,
    status: 'UNKNOWN',
  },
};

export const LivestreamingContext = createContext<LivestreamingCtx>(
  livestreamingCtxDefault,
);
export const useLivestreamingContext = () => useContext(LivestreamingContext);
