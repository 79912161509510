import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
} from 'react';
import { LiveVideo, VideoEdit } from 'types';

export interface LiveEditCtx {
  liveVideoInfo: LiveVideo;
  setLiveVideoInfo: Dispatch<SetStateAction<LiveVideo>>;
  videoEditData: VideoEdit;
  setVideoEditData: Dispatch<SetStateAction<VideoEdit>>;
  playerRef?: MutableRefObject<HTMLVideoElement | undefined>;
}

export const liveEditCtxDefault: LiveEditCtx = {
  liveVideoInfo: {
    account: '',
    id: '',
    eventName: '',
    eventCategory: '',
    recordPath: '',

    thumbnails: {
      Name: '',
      Prefix: '',
      Images: [],
    },
  },
  setLiveVideoInfo: () => null,
  videoEditData: {
    finalTime: 0,
    initialTime: 60,
  },
  setVideoEditData: () => null,
};

export const LiveEditContext = createContext<LiveEditCtx>(liveEditCtxDefault);

export const useLiveEdit = () => useContext(LiveEditContext);
