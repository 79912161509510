import React from 'react';
const HomeIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
  >
    <g>
      <g>
        <path
          d="M509.014,262.276L263.213,16.475c-3.985-3.983-10.441-3.983-14.425,0L2.987,262.276
			c-2.916,2.916-3.789,7.304-2.211,11.114c1.579,3.81,5.297,6.296,9.423,6.296h40.532v208.627c0,5.633,4.567,10.199,10.199,10.199
			h143.054c5.632,0,10.199-4.566,10.199-10.199v-149.7h83.633v149.7c0,5.633,4.567,10.199,10.199,10.199h144.352
			c5.633,0,10.199-4.566,10.2-10.199V279.686h39.233c4.126,0,7.844-2.485,9.423-6.296
			C512.803,269.58,511.929,265.193,509.014,262.276z M452.368,259.288c-5.632,0-10.199,4.566-10.199,10.199v208.627H318.215v-149.7
			c0-5.633-4.567-10.199-10.199-10.199H203.984c-5.632,0-10.199,4.566-10.199,10.199v149.7H71.129V269.487
			c0-5.633-4.567-10.199-10.199-10.199H34.823L256,38.11l221.177,221.178H452.368z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M166.83,160.564c-3.985-3.983-10.441-3.983-14.425,0l-74.964,74.964c-3.983,3.984-3.983,10.442,0,14.425
			c1.992,1.992,4.601,2.987,7.212,2.987c2.611,0,5.22-0.995,7.213-2.987l74.964-74.964
			C170.813,171.005,170.813,164.547,166.83,160.564z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M198.448,128.946c-3.985-3.983-10.441-3.983-14.425,0l-6.629,6.63c-3.983,3.984-3.983,10.442,0,14.425
			c1.992,1.992,4.601,2.987,7.212,2.987c2.611,0,5.22-0.995,7.213-2.987l6.629-6.63
			C202.431,139.387,202.431,132.929,198.448,128.946z"
        />
      </g>
    </g>
  </svg>
);
export default HomeIcon;
