import {
  GlobalStore,
  GlobalStoreConfig,
  RuntimeStage,
  RuntimeType,
} from '../../types';
import { Runtime } from '../runtime';
import { RuntimeStrategy, RuntimeStrategyConfig } from '../types';

export class LocalRuntime implements RuntimeStrategy {
  static type = RuntimeType.Local;

  constructor(private config: RuntimeStrategyConfig) {}

  static isApplicable(runtime: Runtime<GlobalStore>): boolean {
    return RuntimeStage.LOCAL === runtime.bundler.stage;
  }

  async execute(): Promise<void> {
    const { runtime } = this.config;
    if (!runtime.env?.account) {
      runtime.logger.error(`missing account in local environment`);
      return;
    }

    await runtime.configureStore(runtime.env as GlobalStoreConfig);
  }
}
