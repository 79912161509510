/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import logger from '~logger';
import { SettingContext, useControlsContext } from '../../context';
import { useFetchProducts } from '../../hooks/useFetchProducts';
import { getElementById } from '../../utils';
import HighlightProduct from '../HighlightProduct/HighlightProduct';
import { ChatCarouselProductItem } from '../ProductSlider/ChatCarouselProductItem';
import styles from '../ProductSlider/productSlider.module.css';
import { Spinner } from '../commonComponents';

interface HighlightProps {
  detector: boolean;
  fullScreen: boolean;
  handleFullScreen: () => void;
  handleFullScreenMobile: () => void;
}

type ChatCarouselProps = {
  variationSelectorState: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
  fullScreen: boolean;
  isVerticalLayout: boolean;
  handleFullScreen: () => void;
  isMobile: boolean;
  isFinalized: boolean;
  highlightProps: HighlightProps;
};

export const ChatCarousel = ({
  variationSelectorState,
  fullScreen,
  handleFullScreen,
  isVerticalLayout,
  isMobile,
  isFinalized,
  highlightProps,
}: ChatCarouselProps) => {
  const [showHighlightFlag, setShowHighlightFlag] = useState<
    boolean | undefined
  >(false);

  const {
    collectionId,
    setCollection,
    productsInBD,
    setProductsInBD,
    salesChannelId,
    setSalesChannel,
  } = useContext(SettingContext);

  const { products, loading } = useFetchProducts({
    collectionId,
    setCollection,
    productsInBD,
    setProductsInBD,
    salesChannelId,
    setSalesChannel,
  });

  const { isChatFocus } = useControlsContext();

  useEffect(() => {
    if (isChatFocus) {
      setTimeout(() => {
        const isKeyboardOpen =
          (window.visualViewport?.height || 0) <= +window.screen.height - 150;
        isKeyboardOpen && scrollChat();
      }, 800);
    }
  }, [window.innerHeight, isChatFocus]);

  const scrollChat = () => {
    try {
      const elementLivestreaming = getElementById('chat_id');
      elementLivestreaming?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    } catch (error) {
      logger.error('ChatCarousel.scrollChat:', error);
    }
  };

  return collectionId && loading ? (
    <div
      id="chatcarouselmobile"
      className={`${styles.chatCarouselContainer} ${
        !isVerticalLayout && styles.chatCarouselContainerHorizontal
      } ${
        isVerticalLayout &&
        isFinalized &&
        styles.chatCarouselContainerVerticalFinalized
      }`}
    >
      <Spinner />
    </div>
  ) : collectionId && products ? (
    <div
      id="chatcarouselmobile"
      className={`${styles.chatCarouselContainer} ${
        !isVerticalLayout && styles.chatCarouselContainerHorizontal
      } ${
        isVerticalLayout &&
        isFinalized &&
        styles.chatCarouselContainerVerticalFinalized
      } ${
        isChatFocus &&
        isVerticalLayout &&
        styles.chatCarouselContainerHorizontalDisplayNone
      }`}
    >
      {!isVerticalLayout ? (
        <HighlightProduct
          isVerticalLayout={isVerticalLayout}
          isMobile={isMobile}
          fullScreen={highlightProps.fullScreen}
          handleFullScreen={
            highlightProps.detector
              ? highlightProps.handleFullScreen
              : highlightProps.handleFullScreenMobile
          }
          variationSelectorState={variationSelectorState}
          isFinalized={isFinalized}
          setShowHighlightFlag={setShowHighlightFlag}
        />
      ) : null}
      <div
        className={`${styles.chatCarouselProductsWrapper} ${
          !isVerticalLayout && showHighlightFlag
            ? styles.bounce_in_right
            : styles.exit_left
        }`}
      >
        {products &&
          products.length > 0 &&
          products.map((product: any) => (
            <div key={product.id} className={styles.horizontalProductList}>
              <ChatCarouselProductItem
                product={{ ...product, source: 'live_shopping_sidebar' }}
                variationSelectorState={variationSelectorState}
                sectionIdClickedOn="live_shopping_carousel"
                fullScreen={fullScreen}
                handleFullScreen={handleFullScreen}
              />
            </div>
          ))}
      </div>
    </div>
  ) : null;
};
