import { ApiGraphqlTypes as AGT, ChatMessage, addQuestionData } from '@nizza/core';
import { GraphQLClient } from 'graphql-request';
import env from '~env';
import { ChatMessageRepository } from '../domain/repositories/chatMessageRepository';

const API_URL = env.apiUrls.graphql;

export class GraphqlChatMessageRepository implements ChatMessageRepository {
  private client: GraphQLClient;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async deleteMessage(message: ChatMessage): Promise<void> {
    const { deleteChatMessage } = await this.client.request(
      AGT.DeleteChatMessageDocument,
      {
        data: {
          account: message.account,
          idEvent: message.idEvent,
          sendDate: message.sendDate ?? '',
          id: message.id,
          username: message.username,
        },
      },
    );

    this.propagateGraphqlErrorIfExists(
      deleteChatMessage as AGT.DeleteChatMessageResult,
    );
  }
  async getAll(idEvent: string, account: string): Promise<ChatMessage[]> {
    const { getAllChatMessage } = await this.client.request(
      AGT.GetAllChatMessageDocument,
      {
        data: {
          account: account,
          idEvent: idEvent,
        },
      },
    );

    this.propagateGraphqlErrorIfExists(
      getAllChatMessage as AGT.GetAllChatMessageResult,
    );

    const data = (getAllChatMessage as AGT.ChatMessageArray).data;

    return data?.map(x => {
      const msg = {
        account: x.account,
        connectionId: x.connectionId,
        data: x.data.replace('replace_gif', x.urlGif ?? ''),
        email: x.email,
        id: x.id,
        idEvent: x.idEvent,
        sendDate: x.sendDate,
        sessionId: x.sessionId,
        type: x.type,
        username: x.username,
        isAdmin: x.isAdmin,
        second: x.second,
        ip: x.ip,
      };

      return addQuestionData(msg as unknown as ChatMessage);
    }) as unknown as ChatMessage[];
  }

  private propagateGraphqlErrorIfExists(
    res: AGT.GetAllChatMessageResult | AGT.DeleteChatMessageResult,
  ) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
