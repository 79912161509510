/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from '@nizza/utils';

export const AsyncNotFoundPage = lazyLoad(
  () => import('./index'),
  module => module.NotFoundPage,
);
