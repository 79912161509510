import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import {
  EventCategory,
  GlobalPageEvent,
  DataEventBody,
  EventLoadResult,
} from 'types';
import { UseEventListQueryResult } from './useEventList';

export interface GlobalPageCtx {
  pastEventList: GlobalPageEvent[];
  futureEventList: GlobalPageEvent[];
  nowEventList: GlobalPageEvent[];
  pastEventListQuery: UseEventListQueryResult;
  nowEventListQuery: UseEventListQueryResult;
  futureEventListQuery: UseEventListQueryResult;
  playbackScript: string;
  setEventAccount: Dispatch<SetStateAction<string>>;
  setEventId: Dispatch<SetStateAction<string>>;
  setPlaybackScript: Dispatch<SetStateAction<string>>;
  setChooseAnEvent: Dispatch<SetStateAction<boolean>>;
  eventCategoryList: EventCategory[];
  category: string;
  dataEventUpdateLoading: boolean;
  eventLoadResult: EventLoadResult;
  setCategory: Dispatch<SetStateAction<string>>;
  setFilterEnableEvents: Dispatch<SetStateAction<boolean | null>>;
  setPageLimitForEvents: Dispatch<SetStateAction<number>>;
  setPageLimitForEventKeys: Dispatch<SetStateAction<number>>;
  setIsGlobalAdminPage: Dispatch<SetStateAction<boolean>>;
  dataEventUpdate: (args: DataEventBody) => void;
}

export const globalPageCtxDefault: GlobalPageCtx = {
  pastEventList: [],
  futureEventList: [],
  nowEventList: [],
  playbackScript: '',
  pastEventListQuery: {} as UseEventListQueryResult,
  nowEventListQuery: {} as UseEventListQueryResult,
  futureEventListQuery: {} as UseEventListQueryResult,
  setEventAccount: () => null,
  setEventId: () => null,
  setPlaybackScript: () => null,
  setChooseAnEvent: () => null,
  eventCategoryList: [],
  category: 'General',
  dataEventUpdateLoading: false,
  eventLoadResult: 'loading',
  setCategory: () => null,
  setFilterEnableEvents: () => null,
  setPageLimitForEvents: () => null,
  setPageLimitForEventKeys: () => null,
  setIsGlobalAdminPage: () => null,
  dataEventUpdate: () => null,
};

export const GlobalPageContext =
  createContext<GlobalPageCtx>(globalPageCtxDefault);

export const useGlobalPage = () => useContext(GlobalPageContext);
