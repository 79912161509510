import { useContext, useMemo } from 'react';

import { SettingContext } from '../../context';
import { ProductCart } from './ProductCart';

export const ProductToCart = () => {
  const BUBBLE_PRODUCT_LIMIT = 5;

  const { infoSocket } = useContext(SettingContext);

  const { productsInCart, queueBubbleProductSocket } = infoSocket || {};

  return useMemo(
    () =>
      productsInCart &&
      queueBubbleProductSocket &&
      queueBubbleProductSocket.size() < BUBBLE_PRODUCT_LIMIT &&
      productsInCart.map((product, index) => (
        <ProductCart
          key={index}
          image={product.imageUrl}
          queueBubbleProductSocket={queueBubbleProductSocket}
        />
      )),
    [productsInCart, queueBubbleProductSocket],
  );
};
