import { ApiGraphqlTypes as AGT } from '@nizza/core';
import { GraphQLClient } from 'graphql-request';
import { CustomizeComponentDomain, CustomizeRepository } from '../domain';
import env from '~env';

const API_URL = env.apiUrls.graphql;

export class CustomizationGraphQLRepository implements CustomizeRepository {
  private client: GraphQLClient;

  constructor() {
    this.client = new GraphQLClient(API_URL);
  }

  async getAllCustomizations(
    account: string,
  ): Promise<CustomizeComponentDomain[]> {
    const data = { account };
    const { getAllCustomPlayer } = await this.client.request<
      AGT.GetAllCustomizationQuery,
      AGT.GetAllCustomizationQueryVariables
    >(AGT.GetAllCustomizationDocument, { data });

    this.propagateGraphqlErrorIfExists(
      getAllCustomPlayer as AGT.CustomPlayerSuccessArray,
    );

    return (getAllCustomPlayer as AGT.CustomPlayerSuccessArray).data!.map(
      component =>
        CustomizeComponentDomain.fromPrimitives({
          account: component.account,
          id: component.id,
          data: component.data,
          section: component.section,
        }),
    );
  }

  async updateCustomization(data: CustomizeComponentDomain): Promise<void> {
    const primitives = data.toPrimitives();
    const { updateCustomPlayer } = await this.client.request<
      AGT.UpdateCustomizationMutation,
      AGT.UpdateCustomizationMutationVariables
    >(AGT.UpdateCustomizationDocument, {
      data: { ...primitives },
    });

    this.propagateGraphqlErrorIfExists(updateCustomPlayer);
  }

  async deleteAllCustomization(account: string): Promise<void> {
    const { deleteAllCustomPlayer } = await this.client.request<
      AGT.DeleteAllCustomizationMutation,
      AGT.DeleteAllCustomizationMutationVariables
    >(AGT.DeleteAllCustomizationDocument, {
      data: { account },
    });

    this.propagateGraphqlErrorIfExists(deleteAllCustomPlayer);
  }

  private propagateGraphqlErrorIfExists(
    res: AGT.CustomPlayerResult | AGT.CustomPlayerSuccessArray,
  ) {
    if (res.__typename === 'ErrorGeneral') throw new Error(res.message);
  }
}
