export type BackgroundType = 'white' | 'black';

export enum HighlightProductSocketActions {
  SEND = 'sendhighlightproduct',
}

export type HighlightProductItem = {
  backgroundSelected: BackgroundType;
  products: string[];
};

export type HighlightProductStorage = {
  [key: string]: HighlightProductItem;
};
