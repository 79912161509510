import TextDivider from 'app/components/TextDivider';
import React, { useMemo } from 'react';
import { EventCategory } from 'types';
import EventCategoryItem from './EventCategoryItem';
import IconSelector from '../IconSelector';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface Props {
  list: EventCategory[];
}

const EventCategoryList: React.FC<Props> = ({ list }) => {
  const { t } = useTranslation();

  const existGeneralCategory = useMemo(
    () => list.some(({ name }) => name === 'General'),
    [list],
  );

  return (
    <div>
      <h4 className={'text-title'}>
        {t('GlobalPage.Category.Categories')}
        <TextDivider />
      </h4>

      <PerfectScrollbar>
        <div className="category-list">
          {existGeneralCategory && (
            <EventCategoryItem
              key="General"
              name="General"
              icon={IconSelector('General')}
            />
          )}
          {list.map(category => {
            if (category.name === 'General') return null;
            return (
              <EventCategoryItem
                key={`${category.name}`}
                name={category.name}
                icon={IconSelector(category.name)}
              />
            );
          })}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default EventCategoryList;
